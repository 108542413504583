import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Login.css';

function ForgetForm() {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/forgot_password/`, values)
        .then(response => {
          if (response.status === 200) {
            //console.log(response.data);
            //console.log(response.status);
            navigate('/resetn');
          } else {
            setErrorMessage(t('signprocess.forgeterror'));
          }
        })
        .catch(error => {
          setErrorMessage(t('signprocess.forgeterror'));
          // console.error('There was an error!', error);
        });
    },
  });

  return (
        <div className="p-3 custom-width">
           <form onSubmit={formik.handleSubmit}>
           {errorMessage && <div style={{
                            textAlign: 'center', 
                            color: 'red', 
                            fontWeight: '500', 
                            marginBottom: '10px'
                    }}><p>{errorMessage}</p></div>}
                <div className="form-group mb-5">
                <input 
                    type="email" 
                    className="form-control inputArea" 
                    id="email" 
                    aria-describedby="email" 
                    placeholder="Enter Email" 
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                />
                </div>

                
                <button type="submit" 
                className="btn btn-primary mb-4 w-100 button3D">
                    {t('signprocess.sendbtn')}</button>
            </form>
        </div>
  );
}

export default ForgetForm;