import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import ShopListingSlider1 from '../../components/Shop/ShopListingSlider1';
import ProductSlider1 from '../../components/Shop/ProductSlider1';
import ShopListingSlider2 from '../../components/Shop/ShopListingSlider2';
import SubListing from '../../components/Shop/SubListing';

import styles from './ShopListing.module.css';

export default function ShopListing() {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <>
            <PageNav />
            <SubListing />
            <div>
                <div className='container'>
                    {/* =========== start first section========== */}
                    <div className={styles.firstSection}>
                        <div className={styles.shopListingSlider}>
                            <ShopListingSlider1 />
                        </div>
                        <Link to="/contact">
                        <div className={styles.customerServ}>
                            <span>
                                <svg width='40' height='40' fill='#2849ad' 
                                id="Layer_1" enableBackground="new 0 0 56 56" 
                                viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                                <path d="m49 23h-4v-.1601563c0-9.2856445-7.5546875-16.8398437-16.8398437-16.8398437h-.3203125c-9.2851563 0-16.8398438 7.5541992-16.8398438 16.8398438v.1601562h-4c-2.2060547 0-4 1.7944336-4 4v8c0 2.2055664 1.7939453 4 4 4h5c.5527344 0 1-.4477539 1-1v-14-1.1601563c0-8.1826171 6.6572266-14.8398437 14.8398438-14.8398437h.3203125c8.1826171 0 14.8398437 6.6572266 14.8398437 14.8398438v1.1601562 13 .1000977c0 4.6033325-3.5139771 8.4014893-8 8.8543091v-.9544068c0-.5522461-.4472656-1-1-1h-4.0195313c-1.6435546 0-2.9804687 1.3369141-2.9804687 3.0200195 0 1.6430664 1.3369141 2.9799805 2.9804688 2.9799805h4.0195312c.5527344 0 1-.4477539 1-1v-1.0456543c4.9542236-.407959 8.9714355-4.1377563 9.8231201-8.9543457h4.1768799c2.2060547 0 4-1.7944336 4-4v-8c0-2.2055664-1.7939453-4-4-4zm-38 14h-4c-1.1025391 0-2-.8969727-2-2v-8c0-1.1030273.8974609-2 2-2h4zm22 11h-3.0195313c-.5410156 0-.9804687-.4394531-.9804687-1.0200195 0-.5405274.4394531-.9799805.9804688-.9799805h3.0195312zm18-13c0 1.1030273-.8974609 2-2 2h-4v-12h4c1.1025391 0 2 .8969727 2 2z" /></svg>
                            </span>
                            <h5>{t('landing.sec1-title')}</h5>
                            <h5> {t('vshop.shop2')}</h5>
                            <p>{t('vshop.shop3')}<br /> {t('vshop.shop4')}</p>
                        </div>
                        </Link>
                    </div>
                    {/* ===========end first section========== */}
                    <div className={styles.secondSection}>
                        <h4>{t('vshop.trend')}</h4>
                        <ProductSlider1 />
                    <h4>      </h4>
                    <h4>      </h4>
                    <h4>{t('vshop.full-shop')}</h4>
                    <ShopListingSlider2 />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
