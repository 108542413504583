import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import axios from 'axios';


// Local Imports
import '../creationPopupStyle.css';


// Icon Imports
import { PiWifiLowBold } from 'react-icons/pi';
import { PiWifiHighBold } from 'react-icons/pi';
import { CgDollar } from 'react-icons/cg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { GiExpense } from 'react-icons/gi';
import { IoMdPricetags } from 'react-icons/io';
import { MdApartment, MdContactMail, MdContactPhone, MdContacts, MdFormatListNumbered, MdLanguage } from 'react-icons/md';
import { AiOutlineRedo } from 'react-icons/ai';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { BsCalendarDateFill } from 'react-icons/bs';
import { FaSuitcase } from "react-icons/fa";
import { FaEuroSign } from 'react-icons/fa6';


function PopupEditUser({ onClose, currentUser }) {

    // Data from Local Storage
    const user = JSON.parse(localStorage.getItem('user'));
    const accessToken = localStorage.getItem('accessToken');
    const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
    //console.log(user);
  
  const formik = useFormik({
    initialValues: {
      department_code: currentUser.department_code || '',
      department_name: currentUser.department_name || '',
      function: currentUser.function || '',
      budget: currentUser.budget || '',
      role: currentUser.role || '',
    },
    onSubmit: async values => {
      try {
        //console.log(values);
        const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/user/${currentUser.id}/updateinfo/`,
          values,
         {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        //console.log(response.data);
        console.log('User Information Updated Successfully!');
        onClose();
      } catch (error) {
        console.error("There was an error updating user info. ", error);
      }
    },
  });

  return (
    <div className="creation_popup">
      <Modal show={true} onHide={onClose}>
        <Modal.Header className="flex-row-reverse bg-neutral-50">
        <button
            className="btn text-white bg-red-400 border-none hover:bg-red-500"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className="text-2xl m-auto font-sans font-semibold ">
            Edir User Information
          </h2>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={formik.handleSubmit}
            className="bg-white"
          >

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-red-400">
                <FaSuitcase />{' '}
              </span>
              <label className="popup_review_label">
                Role in Client Suite
              </label>
              <div className="grow">
                <select
                  className="input w-full  text-gray-500"
                  name="role"
                  value={formik.values.role}
  onChange={formik.handleChange}
                >
                  <option value="HD">Head</option>
                  <option value="MG">Manager</option>
                  <option value="NM">Normal</option>
                </select>
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-red-400">
                <MdApartment />{' '}
              </span>
              <label className="popup_review_label">
                Department
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="text"
                  name="department_name"
                  value={formik.values.department_name}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-red-400">
                <MdApartment />{' '}
              </span>
              <label className="popup_review_label">
                Department Code
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="text"
                  name="department_code"
                  value={formik.values.department_code}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-red-400">
                <FaSuitcase />{' '}
              </span>
              <label className="popup_review_label">
                Job Title
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="text"
                  name="function"
                  value={formik.values.function}
                  onChange={formik.handleChange}
                />
              </div>
            </div>


            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-red-400">
                <FaEuroSign />{' '}
              </span>
              <label className="popup_review_label">
                Assigned Budget
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="number"
                  name="budget"
                  value={formik.values.budget}
                  onChange={formik.handleChange}
                />
              </div>
              <span className="text-lg">
                <FaEuroSign />{' '}
              </span>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
            >
              Edit User Info
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PopupEditUser;
