import React, { useState } from 'react';
import Footer from '../../components/Home/Footer';
import PageNav from '../../components/Home/PageNav';
import { TbShoppingBagCheck } from "react-icons/tb";
import { IoCopyOutline } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaRegCircleCheck } from "react-icons/fa6";



const OrderCompleted = () => {
    const navigate = useNavigate();
    const [showNotification, setShowNotification] = useState(false);
    
    const location = useLocation();
    const { orderNumber } = location.state? location.state : '012905245';

    const copyToClipboard = () => {
        navigator.clipboard.writeText(orderNumber)
            .then(() => {
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <>
            <PageNav />
            <AnimatePresence>
                {showNotification && (
                    <div className="fixed bottom-5 z-10 left-1/2 transform -translate-x-1/2 text-[#439e4a] font-bold px-6 py-3">
                        <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 10 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}>
                            <div className='flex items-center gap-2'>
                                <FaRegCircleCheck />
                                <p>Order number copied!</p>
                            </div>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
            <div className="w-full lg:w-1/3 m-auto flex flex-col items-center justify-center h-[85dvh]">
                <TbShoppingBagCheck size={70} className="text-[#439e4a]" />
                <h2 className='text-3xl font-semibold my-3'>Thank you</h2>
                <p className="text-xs font-semibold text-gray-500 text-center mb-6">
                    Your order will be prepared carefully and as soon as possible.<br />
                    will be delivered to the cargo. Your order will be shipped<br />
                    SMS notification to you when delivered<br />
                    will be done
                </p>
                <div className='w-2/5 max-w-xs flex flex-col gap-3'>
                    <div className='flex gap-2 bg-[#f8f8f8] py-2 px-2 rounded'>
                        <p className='text-xs font-semibold text-[#68675d]'>Order Number: {orderNumber}</p>
                        <span onClick={copyToClipboard}>
                            <IoCopyOutline className='text-[#a6a7a8] cursor-pointer' />
                        </span>
                    </div>
                    <button onClick={() => navigate('/ClientSuite')} className='text-xs font-semibold py-2 px-2 bg-custom-blue text-white rounded hover:bg-blue-600'>
                        Go Client Suit
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default OrderCompleted;
