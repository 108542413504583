import React from 'react';
import styles from './BaseSign.module.css';
import { useTranslation } from 'react-i18next';

const ResetN = () => {
  const { t } = useTranslation();
  return (

    <div className={styles["my-component-container"]}>
    <div className={styles['left-col']}>
      <div className={styles['background-image']}>
      <div className={`${styles['header-container']} ${styles['no-form']}`}>
        <h2 className={styles['title']}>
        {t('signprocess.forget1')}</h2>
        <div className={styles['icon-parent']}>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 256 256">
  <g fill="#4bad94" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: 'normal'}}>
    <g transform="scale(8.53333,8.53333)">
      <path d="M5.89063,6.14453c-0.73999,0 -1.45001,0.29053 -2,0.81055c-0.57001,0.56 -0.89062,1.30005 -0.89062,2.08008v9.9707c0,1.60004 1.30066,2.88867 2.89063,2.88867h10.41992c-0.30005,-0.65997 -0.48077,-1.36914 -0.55078,-2.11914h-9.86914c-0.41998,0 -0.75977,-0.33954 -0.75977,-0.76953v-9.50586l6.82422,6.11914c0.5387,0.48877 1.2276,0.73828 1.92578,0.73828c0.68823,0 1.37756,-0.24951 1.90625,-0.73828l6.83203,-6.11914v3.0957c0.75,0.02002 1.47083,0.17944 2.13086,0.43945v-4c0,-0.78003 -0.32037,-1.51984 -0.90039,-2.08984c-0.53998,-0.51001 -1.25049,-0.80078 -1.98047,-0.80078zM22.36914,14.58594c-2.56,0 -4.62891,2.08893 -4.62891,4.62891c0,2.57001 2.06891,4.64063 4.62891,4.64063c2.56,0 4.63086,-2.07062 4.63086,-4.64062c0,-2.53998 -2.07086,-4.62891 -4.63086,-4.62891zM23.87305,17.68359c0.2864,-0.05298 0.59509,0.06729 0.76758,0.32227c0.23004,0.35004 0.13904,0.80902 -0.21094,1.03906l-2.33008,1.56055c-0.13001,0.07996 -0.26996,0.11914 -0.41992,0.11914c-0.23004,0 -0.45935,-0.11029 -0.60938,-0.32031l-0.80078,-1.13867c-0.22998,-0.33997 -0.15051,-0.80102 0.18945,-1.04102c0.34003,-0.23999 0.81097,-0.16034 1.04102,0.17969l0.38086,0.54102l1.71875,-1.15039c0.08499,-0.05751 0.17797,-0.09367 0.27344,-0.11133z"></path>
    </g>
  </g>
</svg>
        </div>
        <h4 className={styles['title']}>
        {t('signprocess.resetp1')}</h4>
        <p className={styles['subTitle']}>
        {t('signprocess.resetp2')}</p>
        </div>
      </div>
    </div>
    <div className={styles["right-col"]}>
      <div className={styles['partially-covered-image']}>
      </div>
    </div>
  </div>
);
};
export default ResetN;