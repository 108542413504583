import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import '../../pages/UShop/UserShopListing.css';
import { Link, useLocation } from 'react-router-dom';


export default function BreadCrumbs({data}) {

    
    let currentLink = ''
    // const crumbs = location.pathname.split('/') 
    // .filter(crumb => crumb !== '')
    // .map(crumb => {
    //     currentLink += `/${crumb}`;
    //     return (
    //         <Link to={currentLink} className="breadcrumb-item">
    //             {crumb}
    //         </Link>
    //     )
    // })

  return (
    <div className='pathContainer'>
    <div className='paths'>
        <p><Link to='/'>Home</Link></p>
        <p><span><FontAwesomeIcon icon={faChevronRight} /></span>WebShop</p>
        {data.category && <p className='activePath'><span><FontAwesomeIcon icon={faChevronRight} /></span>{data.category}</p>}
{data.subCategory && <p className='activePath'><span><FontAwesomeIcon icon={faChevronRight} /></span>{data.subCategory}</p>}
    </div>
</div>
  )
}
