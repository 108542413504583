import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { BsFillEyeFill} from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import './Login.css';
import { useTranslation } from 'react-i18next';

function ResetPForm() {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const uidb64 = "1";
    const { uid, token } = useParams();

      // Handling Password visibility
    const [inputType, setInputType] = useState("password");
    const handleClickShowPassword = () => {
        setInputType(inputType === "password" ? "text" : "password");
    };

    const formik = useFormik({
        initialValues: {
          pass1: "",
          pass2: "",
        },
        onSubmit: (values) => {
          if (values.pass1 !== values.pass2) {
            setErrorMessage(t('signprocess.resetperror1'));
            return;
          }

          const resetData = {
            uid: uid,
            token: token,
            password: values.pass1
          }

        // Check if any of the required fields are null or undefined
        if (!resetData.uid || !resetData.token || !resetData.password) {
            setErrorMessage(t('signprocess.forgeterror'));
            return;
        }
    
          axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/reset_password/`, resetData)
            .then(response => {
                if (response.status === 200) {
                    //console.log(response.data);
                    //console.log(response.status);
                    //console.log(resetData);
                    navigate('/successr');
                  } else {
                    setErrorMessage(t('signprocess.forgeterror'));
                  }
            })
            .catch(error => {
                setErrorMessage(t('signprocess.forgeterror')); 
                // console.error('There was an error!', error); 
            });
        },
      });

  return (
        <div className="p-3 custom-width">
            <form onSubmit={formik.handleSubmit}>
            {errorMessage && <div style={{
                            textAlign: 'center', 
                            color: 'red', 
                            fontWeight: '500', 
                            marginBottom: '10px'
                    }}><p>{errorMessage}</p></div>}
                <div className="form-group mb-3">
                <InputGroup>
                    <FormControl
                        type={inputType}
                        className="form-control inputArea"
                        id="pass1"
                        placeholder="Password"
                        required
                        value={formik.values.pass1}
                        onChange={formik.handleChange}
                    />

                    <Button 
                        variant="outline-secondary" 
                        onClick={handleClickShowPassword} 
                        className="inputArea button-no-border"
                        type="button"
                    >
                        <BsFillEyeFill />
                    </Button>
                    </InputGroup>
                </div>
                <div className="form-group mb-5">
                    <InputGroup>
                        <FormControl
                            type={inputType}
                            className="form-control inputArea"
                            id="pass2"
                            placeholder="Enter Password Again"
                            required
                            value={formik.values.pass2}
                            onChange={formik.handleChange}
                        />

                        <Button 
                            variant="outline-secondary" 
                            onClick={handleClickShowPassword} 
                            className="inputArea button-no-border"
                            type="button"
                        >
                            <BsFillEyeFill />
                        </Button>
                        </InputGroup>
                </div>
                <button type="submit" 
                className="btn btn-primary mb-4 w-100 button3D">
                    {t('signprocess.resetp3')}
                </button>
            </form>
        </div>
  );
}

export default ResetPForm;