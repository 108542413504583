import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import JoinUs from '../../components/Info/JoinUs';
import RightSection from '../../components/Info/RSec';
import LeftSection from '../../components/Info/LSec';
import ProductCarousel from '../../components/Home/ProductCarousel';
import VBanner from '../../components/Info/VideoBanner';

import styles from '../Info/AboutUs.module.css';
import styles2 from '../Home/LandingPage.module.css';

import { useTranslation } from 'react-i18next';

export default function ShopInfo() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav />
      <VBanner videoUrl='./img/videos/webshop.mp4' title={t('web-shop.title')}/>
      
      <div className={styles.dsmBackground}>
        <LeftSection 
          head={t('web-shop.head1')}
          text={t('web-shop.p1')}
          imgSrc={'/img/info/23.png'} />

        <RightSection
          head={t('web-shop.head2')} 
          text={t('web-shop.p2')}
          imgSrc={'/img/info/24.png'} />

        <LeftSection 
          head={t('web-shop.head3')}
          text={t('web-shop.p3')}
          imgSrc={'/img/info/25.png'} />

        <RightSection
          head={t('web-shop.head4')} 
          text={t('web-shop.p4')}
          imgSrc={'/img/info/26.png'} />

        <LeftSection 
          head={t('web-shop.head5')}
          text={t('web-shop.p5')}
          imgSrc={'/img/info/27.png'} />
      
      

        <section className={styles2.secThree}>
        <div className={styles2.thirdSec}>
          <h2>{t('landing.sec3-title')}</h2>
          <ProductCarousel />
          <h2>  </h2>
        </div>
      </section>

      </div>
        <JoinUs />
      <Footer />
    </>
  )
}
