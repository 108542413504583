import React from 'react';
import { TbPackages } from "react-icons/tb";
import { BsUiChecksGrid } from "react-icons/bs";
import { IoTabletLandscape } from "react-icons/io5";
import { TbHexagonLetterM } from "react-icons/tb";
import { PiStrategyBold } from "react-icons/pi";
import { GoGoal } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import styles from './LandingPage.module.css';
import Button from '../../components/Home/Button';
import ProductCarousel from '../../components/Home/ProductCarousel';
import Accordion from '../../components/Home/Accordion';
import ContactForm from '../../components/Home/ContactForm';
import Map from '../../components/Home/Map'
import Numbers from '../../components/Home/Numbers';
import Slider from '../../components/Home/Slider';
import TSlider from '../../components/Home/TesSlider';
import InfoCard from '../../components/Home/InfoCard';


export default function LandingPage() {

  const { t } = useTranslation();
  // const { navigate } = useNavigate();

  return (
    <>
      {/*----------------- start section_1--------------------- */}
      <Slider />

      {/*----------------- end section_1--------------------- */}

      {/*----------------- start section_2--------------------- */}
      <section className={styles.secTwo}>
        <div className={styles.secondSec}>
          <h2>{t('landing.sec2-title')}</h2>
          <div className={styles.ourMission}>
            <div>
              <Link to='/package'>
              <span  style={{}}>
                <TbPackages />
              </span> </Link>
              <h5>{t('landing.sec2-s1')}</h5>
              <p>{t('landing.sec2-p1')}</p>
            </div>
            <div>
              <Link to='/dsm'>
              <span>
                <BsUiChecksGrid />
                </span>
                </Link>
              <h5>{t('landing.sec2-s2')}</h5>
              <p>{t('landing.sec2-p2')}</p>
            </div>
            <div>
              <Link to='/clientsuite'>
              <span>
                <IoTabletLandscape />
              </span> </Link>
              <h5>{t('landing.sec2-s3')}</h5>
              <p>{t('landing.sec2-p3')}</p>
            </div>
          </div>
          <div className={styles.firstBtn}>
            <Link to='/package'>
              <Button>{t('landing.read-details-btn')}</Button>
            </Link>
          </div>
        </div>
      </section>
      {/*----------------- end section_2--------------------- */}

      {/*----------------- start section_3--------------------- */}
      <section className={styles.secThree}>
        <div className={styles.thirdSec}>
          <h2>{t('landing.sec3-title')}</h2>
          <ProductCarousel />
          <Link to="/webshop">
            <Button className={styles.secondBtn}>
              {t('landing.view-more-btn')}
            </Button>
          </Link>
        </div>
      </section>
      {/*----------------- End section_3--------------------- */}


      {/*----------------- start section_our vision--------------------- */}
      <div className={styles.ourVisionSec}>
        <img src='../img/info/42.png' alt='vision section img' />
        <div className={styles.ourVision}>
          {/* <span>{t('landing.corporate')}</span> */}
          <h3>{t('landing.sec3-vision')}</h3>
          <p>{t('landing.sec3-content')}</p>
          <Link to="/aboutus">
            <Button >{t('landing.learn-more-btn')}</Button>
          </Link>
        </div>
      </div>
      {/*----------------- end section_our vision--------------------- */}


      {/*----------------- start section_4--------------------- */}
      <section className={styles.secFour}>
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <img src='../img/package-img.png' alt='doctor' />
          </div>
          <div className={styles.rightSide}>
            <h2>{t('landing.sec4-title')}</h2>
            <div className={styles.divs}>
            <div className={styles.icon}>
            <svg id="Layer_1" enable-background="new 0 0 512 512" height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg"><path fill='#2849ad' d="m396.295 57.886c-37.329-37.328-87.153-57.886-140.295-57.886-53.143 0-102.967 20.558-140.296 57.886-69.46 69.46-80.334 152.871-32.319 247.913 39.383 77.956 110.309 148.872 162.098 200.653l2.032 2.033c2.344 2.343 5.414 3.515 8.485 3.515s6.143-1.172 8.485-3.515l2.036-2.036c51.787-51.781 122.713-122.698 162.094-200.653 48.014-95.041 37.14-178.45-32.32-247.91zm10.9 237.089c-36.074 71.408-101.256 138.06-151.195 188.053-49.938-49.992-115.121-116.646-151.194-188.05-43.419-85.947-34.304-157.949 27.869-220.121 34.001-34 78.663-51.001 123.324-51.001 44.662 0 89.324 17 123.325 51.001 62.172 62.171 71.289 134.173 27.871 220.118zm-151.196-173.177c-48.607 0-88.153 39.546-88.153 88.154s39.546 88.154 88.153 88.154c48.608 0 88.154-39.546 88.154-88.154s-39.545-88.154-88.154-88.154zm0 152.308c-35.374 0-64.153-28.779-64.153-64.154s28.779-64.154 64.153-64.154c35.375 0 64.154 28.779 64.154 64.154s-28.779 64.154-64.154 64.154z" /></svg>
              </div>
              <div className={styles.cont}>
                <h5>{t('landing.sec4-sub-t1')}</h5>
                <p>{t('landing.sec4-sub-c1')}</p>
              </div>
            </div>
            <div className={styles.divs}>
            <div className={styles.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512" width="30" height="30"><path fill='#2849ad' d="M482.774,438.55H444.331V163.508a8,8,0,0,0-8-8H387.178a8,8,0,0,0-8,8V438.55H340.5V270.831a8,8,0,0,0-8-8H283.342a8,8,0,0,0-8,8V438.55H236.658V233.823a8,8,0,0,0-8-8H179.505a8,8,0,0,0-8,8V438.55H132.822V326.342a8,8,0,0,0-8-8H75.669a8,8,0,0,0-8,8V438.55H29.226A29.259,29.259,0,0,0,0,467.775V504a8,8,0,0,0,8,8H504a8,8,0,0,0,8-8V467.775A29.259,29.259,0,0,0,482.774,438.55Zm-87.6-267.042h33.153V438.55H395.178ZM291.342,278.831H324.5V438.55H291.342ZM187.505,241.823h33.153V438.55H187.505ZM83.669,334.342h33.153V438.55H83.669ZM496,496H16V467.775A13.241,13.241,0,0,1,29.226,454.55H482.774A13.241,13.241,0,0,1,496,467.775ZM205.007,117.971a8,8,0,0,0-7.819,1.556L41.829,255.262a8,8,0,0,0-.853,11.179l29.665,35.2a8,8,0,0,0,5.518,2.822q.3.023.6.023a8,8,0,0,0,5.279-1.989L214.115,186.505l93.961,31.673a8,8,0,0,0,8.148-1.861L425.24,109.75l23.02,25.389a8,8,0,0,0,13.544-2.928L500.9,10.446A8,8,0,0,0,491.6.181l-125,27.021A8,8,0,0,0,362.357,40.4l21.186,23.366-87.015,85.06ZM481.433,18.747l-30.627,95.38L383.518,39.913ZM304.208,163.688,394.3,75.623l20.188,22.266L308.544,201.451,214.856,169.87a8,8,0,0,0-7.835,1.57L77.613,285.089,58.274,262.14,204.253,134.6l91.808,30.947A8,8,0,0,0,304.208,163.688Z" /></svg>
              </div>
              <div className={styles.cont}>
                <h5>{t('landing.sec4-sub-t2')}</h5>
                <p>{t('landing.sec4-sub-c2')}</p>
              </div>
            </div>
            <div className={styles.divs}>
            <div className={styles.icon}>
            <svg id="Layer_1" enable-background="new 0 0 512 512" height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg"><path fill='#2849ad' d="m70 492.065c0 6.411 5.198 11.61 11.61 11.61s11.61-5.198 11.61-11.61v-224.455h60.471v43.894c0 9.069 7.378 16.447 16.447 16.447h255.416c9.068 0 16.446-7.378 16.446-16.447v-200.984c0-9.068-7.378-16.446-16.446-16.446h-71.725l4.419-42.701c.454-4.387-1.008-8.811-4.011-12.141-3.152-3.495-7.662-5.5-12.374-5.5h-248.643v-13.797c0-6.411-5.198-11.61-11.61-11.61s-11.61 5.198-11.61 11.61zm23.22-435.112h241.108l-19.4 187.438h-221.708zm325.561 247.778h-241.871v-37.121h144.151c8.442 0 15.486-6.558 16.386-15.252l13.978-135.064h67.355v187.437z" /></svg>
                </div>
              <div className={styles.cont}>
                <h5>{t('landing.sec4-sub-t3')}</h5>
                <p>{t('landing.sec4-sub-c3')}</p>
              </div>
            </div>
            <Link to="/package">
              <Button>{t('landing.view-more-btn')}</Button>
            </Link>
          </div>
        </div>
      </section>
      {/*----------------- end section_4--------------------- */}

      {/*----------------- start section_5--------------------- */}
      {/* <div className={styles.customerTestSec}>
        <TSlider />
        <img style={{ width: '100%' }} src='./img/slider21.png' alt='img' /> 
      </div>*/}
      {/*----------------- end section_5--------------------- */}


      {/*----------------- start section_6--------------------- */}
      <section className={styles.secSix}>
        <div className={styles.content}>

          <div className={styles.rightSide}>
            <h2>{t('landing.sec6-title')}</h2>
            <div className={styles.divs}>
            <div className={styles.icon}>
              <svg id="Layer_1" enable-background="new 0 0 512 512" height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg"><path fill='#2849ad' d="m396.295 57.886c-37.329-37.328-87.153-57.886-140.295-57.886-53.143 0-102.967 20.558-140.296 57.886-69.46 69.46-80.334 152.871-32.319 247.913 39.383 77.956 110.309 148.872 162.098 200.653l2.032 2.033c2.344 2.343 5.414 3.515 8.485 3.515s6.143-1.172 8.485-3.515l2.036-2.036c51.787-51.781 122.713-122.698 162.094-200.653 48.014-95.041 37.14-178.45-32.32-247.91zm10.9 237.089c-36.074 71.408-101.256 138.06-151.195 188.053-49.938-49.992-115.121-116.646-151.194-188.05-43.419-85.947-34.304-157.949 27.869-220.121 34.001-34 78.663-51.001 123.324-51.001 44.662 0 89.324 17 123.325 51.001 62.172 62.171 71.289 134.173 27.871 220.118zm-151.196-173.177c-48.607 0-88.153 39.546-88.153 88.154s39.546 88.154 88.153 88.154c48.608 0 88.154-39.546 88.154-88.154s-39.545-88.154-88.154-88.154zm0 152.308c-35.374 0-64.153-28.779-64.153-64.154s28.779-64.154 64.153-64.154c35.375 0 64.154 28.779 64.154 64.154s-28.779 64.154-64.154 64.154z" /></svg>
                </div>
              <div className={styles.cont}>
                <h5>{t('landing.sec6-t1')}</h5>
                <p>{t('landing.sec6-c1')}</p>
              </div>
            </div>
            <div className={styles.divs}>
            <div className={styles.icon}>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512" width="30" height="30"><path fill='#2849ad' d="M482.774,438.55H444.331V163.508a8,8,0,0,0-8-8H387.178a8,8,0,0,0-8,8V438.55H340.5V270.831a8,8,0,0,0-8-8H283.342a8,8,0,0,0-8,8V438.55H236.658V233.823a8,8,0,0,0-8-8H179.505a8,8,0,0,0-8,8V438.55H132.822V326.342a8,8,0,0,0-8-8H75.669a8,8,0,0,0-8,8V438.55H29.226A29.259,29.259,0,0,0,0,467.775V504a8,8,0,0,0,8,8H504a8,8,0,0,0,8-8V467.775A29.259,29.259,0,0,0,482.774,438.55Zm-87.6-267.042h33.153V438.55H395.178ZM291.342,278.831H324.5V438.55H291.342ZM187.505,241.823h33.153V438.55H187.505ZM83.669,334.342h33.153V438.55H83.669ZM496,496H16V467.775A13.241,13.241,0,0,1,29.226,454.55H482.774A13.241,13.241,0,0,1,496,467.775ZM205.007,117.971a8,8,0,0,0-7.819,1.556L41.829,255.262a8,8,0,0,0-.853,11.179l29.665,35.2a8,8,0,0,0,5.518,2.822q.3.023.6.023a8,8,0,0,0,5.279-1.989L214.115,186.505l93.961,31.673a8,8,0,0,0,8.148-1.861L425.24,109.75l23.02,25.389a8,8,0,0,0,13.544-2.928L500.9,10.446A8,8,0,0,0,491.6.181l-125,27.021A8,8,0,0,0,362.357,40.4l21.186,23.366-87.015,85.06ZM481.433,18.747l-30.627,95.38L383.518,39.913ZM304.208,163.688,394.3,75.623l20.188,22.266L308.544,201.451,214.856,169.87a8,8,0,0,0-7.835,1.57L77.613,285.089,58.274,262.14,204.253,134.6l91.808,30.947A8,8,0,0,0,304.208,163.688Z" /></svg>
                </div>
              <div className={styles.cont}>
                <h5>{t('landing.sec6-t2')}</h5>
                <p>{t('landing.sec6-c2')}</p>
              </div>
            </div>
            <div className={styles.divs}>
            <div className={styles.icon}>
              <svg id="Layer_1" enable-background="new 0 0 512 512" height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg"><path fill='#2849ad' d="m70 492.065c0 6.411 5.198 11.61 11.61 11.61s11.61-5.198 11.61-11.61v-224.455h60.471v43.894c0 9.069 7.378 16.447 16.447 16.447h255.416c9.068 0 16.446-7.378 16.446-16.447v-200.984c0-9.068-7.378-16.446-16.446-16.446h-71.725l4.419-42.701c.454-4.387-1.008-8.811-4.011-12.141-3.152-3.495-7.662-5.5-12.374-5.5h-248.643v-13.797c0-6.411-5.198-11.61-11.61-11.61s-11.61 5.198-11.61 11.61zm23.22-435.112h241.108l-19.4 187.438h-221.708zm325.561 247.778h-241.871v-37.121h144.151c8.442 0 15.486-6.558 16.386-15.252l13.978-135.064h67.355v187.437z" /></svg>
                </div>
              <div className={styles.cont}>
                <h5>{t('landing.sec6-t3')}</h5>
                <p>{t('landing.sec6-c3')}</p>
              </div>
            </div>
            <Link to="/dsm">
              <Button>{t('landing.view-more-btn')}</Button>
            </Link>
          </div>
          <div className={styles.leftSide}>
            <img src='../img/dsm2.png' alt='doctor' />
          </div>
        </div>
      </section>
      {/*----------------- end section_6--------------------- */}


      {/*----------------- start section_7--------------------- */}
      <section className={styles.secSeven}>
        <img style={{ width: '100%' }} src='./img/assortimed-img.png' alt='img' />
      </section>
      {/*----------------- end section_7--------------------- */}


      {/*----------------- start section_8--------------------- */}
      <section className={styles.secEight}>
        <img className={styles.dots} src='../img/dots-1.png' alt='dots'/>
        <div className={styles.questions}>
          <div className={styles.rightSide}>
            <h2 id="faqSection">{t('landing.sec8-title')}</h2>
            <Accordion /> 
          </div>
          <div className={styles.logoSide}>
            <img src='../img/Logo.png' alt='logo img' />
          </div>
        </div>
      </section>
      {/*----------------- end section_8--------------------- */}


      {/*----------------- start section_9--------------------- */}
      <section className={styles.secNine}>
        <div className={styles.ninthSec}>
          <h2>{t('landing.sec9-title')}</h2>
          <div className={styles.ourMission2}>
            <div>
              <span>
              <TbHexagonLetterM /> 
              </span>
              <h5>{t('landing.sec9-t1')}</h5>
              <p>{t('landing.sec9-c1')}</p>
            </div>
            <div>
              <span>
                  <PiStrategyBold />
              </span>
              <h5>{t('landing.sec9-t2')}</h5>
              <p>{t('landing.sec9-c2')}</p>
            </div>
            <div>
              <span style={{fontWeight: '600'}}>
                <GoGoal />
              </span>
              <h5>{t('landing.sec9-t3')}</h5>
              <p>{t('landing.sec9-c3')}</p>
            </div>
          </div>
        </div>
      </section>
      {/*----------------- end section_9--------------------- */}


      {/*----------------- start section_10--------------------- */}
      <div className={styles.impNumber}>
        {/**<h3>{t('landing.sec10-title')}</h3>*/}
        <Numbers />
      </div>
      {/*----------------- end section_10--------------------- */}


      {/*----------------- start section_11--------------------- */}
      <section className={styles.sec11}>
        <div className={styles.contact}>
          <div className={styles.contactForm}>
            <ContactForm />
          </div>
          <div className={styles.informations}>
            <InfoCard />
          </div>
        </div>
      </section>
      {/*----------------- end section_11--------------------- */}

      {/*----------------- start section_12--------------------- */}
      <Map />
      {/*----------------- end section_12--------------------- */}
    </>
  )
}
