import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { Row, Col } from 'react-grid-system';
import Card from 'react-bootstrap/Card';
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Icon Imports
import { IoMdSearch } from 'react-icons/io';
import { BsSearch } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';

// Local Imports
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../../components/UShop/UserShopSlider.module.css';
import PopupCreatePackage from './PopupCreatePackage';


const NewPackage = () => {
  // Hadnling Filtration product section
  const [searchQuery, setSearchQuery] = useState('');
  const storedProducts = JSON.parse(localStorage.getItem('products')) || [];

  const filteredProducts = storedProducts.filter(
    (product) =>
      product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  const uniqueCategories = [
    ...new Set(storedProducts.map((product) => product.category)),
  ];
  const uniqueSubCategory1 = [
    ...new Set(storedProducts.map((product) => product.sub_category_1)),
  ];
  const uniqueSubCategory2 = [
    ...new Set(storedProducts.map((product) => product.sub_category_2)),
  ];
  const uniqueBrand = [
    ...new Set(storedProducts.map((product) => product.brand)),
  ];

  const options = [
    { value: 'All', label: 'All' },
    ...uniqueCategories.map((item) => ({ value: item, label: item })),
  ];

  const [activeItem, setActiveItem] = useState('All');
  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  
  // Handling adding products (addedProducts in createPackData)
  const [addedProducts, setAddedProducts] = useState([]);
  const handleAddProduct = (product) => {
    const existingProductIndex = addedProducts.findIndex(
      (p) => p.name === product.name,
    );

    if (existingProductIndex !== -1) {
      const updatedProducts = [...addedProducts];
      updatedProducts[existingProductIndex] = {
        ...updatedProducts[existingProductIndex],
        quantity: updatedProducts[existingProductIndex].quantity + 1,
        id: updatedProducts[existingProductIndex].id,
      };
      setAddedProducts(updatedProducts);
    } else {
      setAddedProducts([...addedProducts, { ...product, quantity: 1, id: product.id }]);
    }
  };

  useEffect(() => {}, [searchQuery, filteredProducts]);

  //console.log("AddedProductsList: ", addedProducts);
  
  // Handling per product items amount change 
  const handleIncrement = (product) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.name === product.name ? { ...p, quantity: p.quantity + 1, id: product.id } : p,
      ),
    );
  };

  const handleDecrement = (product) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.name === product.name && p.quantity > 1
          ? { ...p, quantity: p.quantity - 1, id: product.id }
          : p,
      ),
    );
  };

  // Display of products search results 
  const searchedProduct =
    searchQuery.length > 0
      ? storedProducts.filter((product) =>
          `${product.name}`.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : storedProducts;
  //console.log("Searched Products: ", searchedProduct);
  
  const handleDeleteProduct = (product) => {
    setAddedProducts(addedProducts.filter((p) => p !== product));
  };

  
  // Handling Clicking on Details BTN.
  const [showForm, setShowForm] = useState(false);

  const handleOpenForm = () => {
    setShowForm(true);
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };

  //Pagination Controls
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 6;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, searchedProduct.length);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // Showing Sucess Message after clicing on the Proceed to Review button
  const showToast = (message) => {
    toast.success(message, {
      autoClose: 3000,
    });
  };
  const showFail = (message) => {
    toast.error(message, {
      autoClose: 3000,
    });
  };

  // Handling post sucess
  const handleCreationSucess = () => {
    //setAddedProducts([]);
  }


  return (
    <>
      {/**Filtration section Orders Focus */}
      <ToastContainer className="left-[40%]" />
      <div className=" w-full border-b-2 border-gray-200 bg-white p-2">
        {/* On small screens, display the select dropdown */}
        <div className="block lg:hidden">
          <Select
            options={options}
            value={{ value: activeItem, label: activeItem }}
            onChange={(selectedOption) => handleItemClick(selectedOption.value)}
          />
        </div>

        {/* On larger screens, display the list view */}
        <ul className="text-md flex hidden w-full font-bold lg:flex">
          <li key="all" className="flex-1">
            <a
              className={`block rounded border-b-2 border-transparent px-4 py-1 text-center 
          ${activeItem === 'All' ? 'font-bold text-gray-700' : 'text-gray-400'} 
           hover:font-bold hover:text-gray-700`}
              onClick={() => handleItemClick('All')}
              href="#"
            >
              All
            </a>
          </li>
          {uniqueCategories.map((item, index) => (
            <li key={index} className="flex-1">
              <a
                to="#"
                className={`block cursor-pointer rounded border-b-2 border-transparent px-4 py-1 text-center
          ${activeItem === item ? 'font-bold text-gray-700' : 'text-gray-400'} 
          hover:font-bold hover:text-gray-700`}
                onClick={() => handleItemClick(item)}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/**Filtration section Apply filter button */}
      <div className="shadow-md flex w-full flex-wrap items-center justify-between border-b-2 border-gray-300 bg-white p-3">
        <div className="flex w-full justify-around md:w-[46%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
              <option value="">Sub Category 1</option>
              {uniqueSubCategory1.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
              <option value="">Sub Category 2</option>
              {uniqueSubCategory2.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex w-full justify-around md:w-[44%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 outline-none">
              <option value="">Brand</option>
              {uniqueBrand.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <input
              type="search"
              aria-label="Search"
              className="form-control me-2 w-full rounded border bg-gray-200 p-1 px-2 outline-none"
              placeholder="Search Product"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
          <button className="text-md w-full rounded-lg bg-custom-blue px-6 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue sm:w-auto">
            Filter
          </button>
        </div>
      </div>

      {/* Side bar (Selected Products) */}
      <div className="flex flex-col sm:flex sm:flex-row">
        <aside
          className="sm:md-2 mb-6 grid w-full grid-rows-3 sm:w-[36%]"
          style={{ gridTemplateRows: '84px 670px 50px' }}
        >
          <div>
            <div className="bg-[#c4714e] px-2 py-3 text-center text-base font-bold text-white">
              List of Package Items/Products
            </div>
            <div className="bg-gray-100 p-2 text-center text-xs font-semibold text-neutral-400/75">
              <p>Click + to add a product</p>
            </div>
          </div>

          {addedProducts.length > 0 ? (
            <div className="m-1 w-full ">
              <Scrollbars style={{ width: '100%' }}>
                <ul>
                  {addedProducts.map((product) => (
                    <li key={product.name} className="mt-6 flex justify-center">
                      {/** Product Card */}
                      <div className="hover shadow relative m-1 w-[90%] flex-col items-center justify-center overflow-hidden rounded border bg-white p-1">
                        <div className="flex h-full w-full flex-row items-center justify-between">
                          <span
                            className="absolute right-2 top-2 cursor-pointer bg-white"
                            onClick={() => handleDeleteProduct(product)}
                          >
                            <RiDeleteBin5Line className="text-red-500 " />
                          </span>
                          <div className="mr-2 flex w-2/5">
                            <img
                              src={product.photo}
                              alt="Product"
                              className="w-full flex-grow object-cover"
                            />
                          </div>
                          <div className="mb-3 mr-2 flex w-3/5 flex-col items-center">
                            <p className=" my-3 text-center text-sm font-bold text-custom-blue">
                              {product.name}
                            </p>
                            <div className="flex w-full justify-around text-xs font-semibold">
                              <div className="flex items-center rounded-md bg-[#f1f1f1] text-sm font-semibold">
                                <button className="ml-3 mr-1 p-1 text-2xl text-custom-blue" onClick={() => handleDecrement(product)}>{' '}-{' '}</button>
                                <span className="mx-1 p-1">{product.quantity}</span>
                                <button className="ml-1 mr-3 p-1 text-xl text-custom-blue" onClick={() => handleIncrement(product)}>{' '}+{' '}</button>
                              </div>

                              <div className="flex items-center justify-center">
                                <h4 className="mr-2 text-sm font-bold text-custom-blue">
                                  € {product.sales_price_c1}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/** End Product Card */}
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </div>
          ) : (
            <div className="m-auto text-gray-400">
              <div className="m-auto flex h-20 w-20 items-center  justify-center rounded-full bg-gray-200 ">
                <BsSearch className=" text-4xl " />
              </div>
            </div>
          )}

          {/** Proceed to REview Btn. */}
          <div className="mt-2 flex w-full justify-center">
            {addedProducts.length === 0 ? (
              <button
                disabled
                className="text-md w-full cursor-not-allowed rounded-lg bg-gray-300 py-1 font-semibold text-gray-500"
              >
                <IoMdSearch className="mr-3 inline-block h-6 w-6 align-middle" />
                Proceed to Review
              </button>
            ) : (
              <button
                onClick={handleOpenForm}
                className="text-md w-full rounded-lg bg-custom-blue py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue"
              >
                <IoMdSearch className="mr-3 inline-block h-6 w-6 align-middle" />
                Proceed to Review
              </button>
            )}
            {showForm && (
              <PopupCreatePackage
                addedProducts={addedProducts}
                onClose={handleCloseForm}
                showToast={showToast}
                showFail={showFail}
                handleSucess={handleCreationSucess}
              />
            )}
          </div>
        </aside>

        {/* MiniShop (Products and User Package Listiing) */}
        <div className="w-full overflow-hidden bg-custom-pakage sm:w-[64%]">
          <div className="h-20 w-full bg-[#ffe8de]"></div>
          <Row className="mx-auto mb-8 mt-4 w-[97%]">
            {Array.isArray(storedProducts) && storedProducts.length > 0 ? (
              searchedProduct.slice(startIndex, endIndex).map((card, index) => (
                <Col lg={4} md={6} sm={8} xs={12} key={card.assortimed_pid}>
                  <Card key={card.name} className={styles.card_body}>
                    <div className="h-1/2 w-full">
                      <Card.Img
                        variant="top"
                        src={imgPaths[card.assortimed_pid][0]}
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <Card.Body className="flex flex-grow flex-col justify-between">
                      <div>
                        <Card.Title className="text-center text-base font-bold">
                          {card.category}
                        </Card.Title>
                        <Card.Text className="text-center text-sm font-semibold">
                          {card.name}
                        </Card.Text>
                      </div>
                      <div className="flex cursor-pointer justify-between">
                        <h4 className="mr-2 font-bold text-custom-blue">
                          € {card.sales_price_c1}
                        </h4>
                        <MdAdd
                          className="right-0 rounded-full bg-custom-blue p-1 text-2xl text-white"
                          onClick={() =>
                            handleAddProduct({
                              photo: imgPaths[card.assortimed_pid][0],
                              name: card.name,
                              sales_price_c1: card.sales_price_c1,
                              category: card.category,
                              id: card.id,
                            })
                          }
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="card-title">No products found.</p>
            )}
          </Row>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={storedProducts.length}
            pageRangeDisplayed={4}
            onChange={handlePageChange}
            className="my-10"
          />
        </div>
      </div>
    </>
  );
};

export default NewPackage;
