import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';

// Icon Imports
import { HiOutlineBuildingLibrary } from 'react-icons/hi2';
import { BsCreditCard } from 'react-icons/bs';
import { GoPackageDependents } from 'react-icons/go';
import { SlLocationPin } from "react-icons/sl";
import { TbLoader3 } from "react-icons/tb";


// Local Imports
import Footer from '../../components/Home/Footer';
import PageNav from '../../components/Home/PageNav';
import Address from '../../components/basket/Address';
import RepeatingOrder from '../../components/basket/RepeatingOrder';

const FormControlLabel = ({ value, label, checked, onChange }) => (
  <label className="flex items-center space-x-3">
    <Radio
      value={value}
      checked={checked}
      onChange={onChange}
      sx={{
        '& .MuiSvgIcon-root': {
          fontSize: 20,
        },
        color: '#a6a7a8',
        '&.Mui-checked': {
          color: '#2849ad',
        },
      }}
    />
    <div className="text-sm">
      <p className="text-sm font-semibold">{label}</p>
    </div>
  </label>
);

export default function ProfileShipping() {
  // extract user delivery profiles
  const user = JSON.parse(localStorage.getItem('user'));
  const storedPaymentData = user ? user.payment_data : [];
  const companyData = user ? user.company_data : [];
  
  const [repeatChecked, setRepeatChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { total, tax } = location.state || { total: 0, tax: 0 };


  const initialValues = {
    selectedProfile: "",
  };

  const [selectedProfile, setSelectedProfile] = useState(null);
  const handleProfileChange = (event) => {
    setSelectedProfile(event.target.value);
  };

  const [repeatOrderState, setRepeatOrderState] = useState(null);
  const handleRepeatOrder = (data) => {
    setRepeatOrderState(data);
  };

  const [addressData, setAddressData] = useState(null);
  const [postAddressData, setPostAddressData] = useState(null);
  const handleAddressData = (data) => {
    setAddressData(data);
  }

  const handleSubmit = async () => {
    // Posting if it was a new One Time Address
    //console.log('Address Value on HandleSubmit: ', addressData);
    if ( addressData && addressData.addressType === "one_time_address" ) {
        //console.log('New Address Value in progress....');
        const postData = {
          user_id: user.user_data.id,
          created_by_id: user.user_data.id,
          issuer_id: null,
          address_head: addressData.addressValues.addressSubject? addressData.addressValues.addressSubject : '',
          address_details: addressData.addressValues.addressDetail? addressData.addressValues.addressDetail : '',
          is_approved: 0,
      };
      //console.log(postAddressData);
      console.log("Post Address Data Original: ");
      console.log(postData);
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/onetimedelivery/create/`, postData);
        if (response.status === 201) {
          // Prepare and dispatch profile data if it's not new one
          //console.log("New Address Profile Created");
          const dispatchData = {
            addressType: addressData.addressType,
            addressValues: response.data,
            };
          setPostAddressData(dispatchData);
          //console.log("New Address Profile Created");
          //console.log(dispatchData);
          //console.log("Posted Data", postAddressData);
        } else {
          console.log('Failed to create One Time Address.');
        }
      } catch (error) {
        console.error('Error creating new address:', error);
      }
    } 
    if (addressData && addressData.addressType === "standart_address") {
      setPostAddressData(addressData);
    }
  };


  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setSelectedProfile(storedPaymentData.length > 0 ? storedPaymentData[0].PO_number : null);
  }, []);

  const selectedProfileData = storedPaymentData.find(profile => profile.PO_number === selectedProfile);


  useEffect(() => {
    if (postAddressData) {
      //console.log("Address being Posted: ", postAddressData);
      console.log("Post Address Data: ");
      console.log(postAddressData);
      navigate("/CompleteOrder", {
        state: {
          total,
          tax,
          selectedProfileData,
          companyData,
          user,
          postAddressData,
          repeatOrderState,
        }
      });
    }
  }, [postAddressData, navigate, total, tax, selectedProfileData, companyData, user, repeatOrderState]);

  return (
    <>
      <PageNav />
      <div className="mx-auto my-16 w-[90%]">
        <p className="my-4 text-xl font-semibold">Shipping Information</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex lg:flex-row flex-col justify-center gap-10">
            {/**Payment Profiles Choice Display */}
            <div className="w-full">
              {storedPaymentData.map((profile, index) => (
                <div className={`shadow rounded-lg mb-3 ${selectedProfile === profile.PO_number ? 'border-2 border-[#2849ad]' : ''}`} key={index}>
                  <div className="flex space-x-3 border-b-2 p-2">
                    <FormControlLabel
                      value={profile.PO_number}
                      label={profile.budget_holder_name}
                      checked={selectedProfile === profile.PO_number}
                      onChange={handleProfileChange}
                    />
                  </div>
                  <div className="flex items-center space-x-4 p-4">
                    <div className='w-1/5'>
                      <div className="border-[#f1f1f1] h-16 w-16 rounded-full border text-gray-50">
                        <img
                          className="p-2"
                          src="../../img/colored-4l-100.png"
                          alt="logo"
                        />
                      </div>
                    </div>

                    <div className='w-full'>

                      <div className="flex justify-between text-xs">
                        <div className="flex space-x-2 text-[#4895e9] items-center my-1 w-2/3">
                          <span>
                            <HiOutlineBuildingLibrary />
                          </span>
                          <p>Representitive Personal</p>
                        </div>
                        <p className="text-end">{profile.budget_holder_name}</p>
                      </div>

                      <div className="flex justify-between text-xs">
                        <div className="flex space-x-2 text-[#4895e9] items-center my-1 w-2/3">
                          <span>
                            <GoPackageDependents />
                          </span>
                          <p>PO Number</p>
                        </div>
                        <p className="text-end">{profile.PO_number}</p>
                      </div>

                      <div className="flex justify-between text-xs">
                        <div className="flex space-x-2 text-[#4895e9] items-center my-1 w-2/3">
                          <span>
                            <BsCreditCard />
                          </span>
                          <p>Payment via</p>
                        </div>
                        <p className="text-end">{profile.bank}</p>
                      </div>

                      <div className="flex justify-between text-xs">
                        <div className="flex space-x-2 text-[#4895e9] items-center my-1 w-2/3">
                          <span>
                            <SlLocationPin />
                          </span>
                          <p>Invoicing Address</p>
                        </div>
                        <p className="text-end">{profile.invoicing_address}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="w-full">
              {selectedProfileData && (
                <div className="flex items-center space-x-4 p-4 bg-[#f6f6f6] rounded-lg">
                  <div className='w-1/5'>
                    <div className="border-[#f1f1f1] h-16 w-16 rounded-full border text-gray-50">
                      <img
                        className="p-2"
                        src="../../img/colored-4l-100.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className="flex justify-between text-xs border-b border-[#eee] items-center">
                      <div className="flex space-x-2 text-[#4895e9] items-center my-1 w-2/3 ">
                        <span>
                          <HiOutlineBuildingLibrary />
                        </span>
                        <p>Representitive Personal</p>
                      </div>
                      <p className="text-end">{selectedProfileData.budget_holder_name || ''}</p>
                    </div>

                    <div className="flex justify-between text-xs border-b border-[#eee] items-center">
                      <div className="flex space-x-2 text-[#4895e9] items-center my-1 w-2/3">
                        <span>
                          <GoPackageDependents />
                        </span>
                        <p>PO Number</p>
                      </div>
                      <p className="text-end">{selectedProfileData.PO_number}</p>
                    </div>

                    <div className="flex justify-between text-xs border-b border-[#eee] items-center">
                      <div className="flex space-x-2 text-[#4895e9] items-center my-1 w-2/3">
                        <span>
                          <BsCreditCard />
                        </span>
                        <p>Payment via</p>
                      </div>
                      <p className="text-end">{selectedProfileData.bank}</p>
                    </div>
                    <div className="flex justify-between text-xs border-b border-[#eee] items-center">
                      <div className="flex space-x-2 text-[#4895e9] items-center my-1 w-2/3">
                        <span>
                          <SlLocationPin />
                        </span>
                        <p>Invoicing Address</p>
                      </div>
                      <p className="text-end">{selectedProfileData.invoicing_address || ''}</p>
                    </div>

                  </div>
                </div>
              )}

              {/** Choosen a Delivary Profile */}
              {/* setAddressValue={(value) => dispatch(setAddressValue(value))} */}
              <Address addressData={handleAddressData}/>
              <div className='bg-[#f6f6f6] rounded-lg'>
                <div className='bg-[#ededed] text-sm font-semibold p-3 justify-between flex items-center rounded-lg mt-4'>
                  <div className='flex items-center space-x-1'>
                    <span className='text-lg text-[#a6a7a8]'><TbLoader3 /></span>
                    <p>Repeating Order</p>
                  </div>
                  <input type='checkbox' className='w-5 h-5' checked={repeatChecked} onChange={(e) => setRepeatChecked(e.target.checked)} />
                </div>
                {repeatChecked ? <RepeatingOrder repeatOrder={handleRepeatOrder} /> : null}
              </div>
            </div>

            <div className="mt-2 h-fit lg:w-[70%] w-full rounded-lg bg-[#DCF6F7] p-4 text-custom-blue">
              <h2 className="text-xl font-bold">Card Totals</h2>
              <div className="my-3 text-sm">
                <div className="my-1 grid grid-cols-2 gap-3">
                  <p>Product Total:</p>
                  <span>${total.toFixed(2)}</span>
                </div>
                <div className="my-1 grid grid-cols-2 gap-3">
                  <p>Tax Total:</p>
                  <span>${tax.toFixed(2)}</span>
                </div>
                <div className="my-1 grid grid-cols-2 gap-3">
                  <p>Order Totals:</p>
                  <span>${(total + tax).toFixed(2)}<span> (VAT incl.)</span></span>
                </div>
              </div>

              <div className="mt-4">
                <button type="submit" className="m-auto w-full rounded-md bg-custom-blue py-2 text-sm font-semibold text-white hover:bg-blue-600">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}
