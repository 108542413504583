import React, { useState } from "react";


// In ComBase component
export default function UserCom({ userRecord }) {

    const userRole = userRecord ? 
        (userRecord.role === 'HD' ? 'Head User' :
        userRecord.role === 'MG' ? 'Manager User' : 
        'Normal User') : "";
    const Lang = userRecord ? 
        (userRecord.language_preference === 'en' ? 'English' : 
        userRecord.language_preference === 'de'? 'Dutch':
        'German') : "";
  
    return (
        <div className="flex flex-col justify-between">
            {/** Head User Information Forms */}
            <div className="flex flex-col justify-between">
                <div className="w-[95%] h-full p-6 rounded-md">
                    <form class="w-full max-w-lg">
                    <div class="flex flex-wrap mb-6">
                    <div class="w-full md:w-full mb-6 md:mb-0">
                        <span className="text-lg text-custom-blue font-semibold">
                            Personal Information
                        </span>
                    </div>
                </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-extrabold mb-2" for="grid-full-name">
                                Full Name
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-full-name" type="text" 
                value={userRecord?.fullname? userRecord.fullname : ""} readOnly/>
                        </div>
                        <div class="ml-auto w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="role">
                            Role
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="role" type="text" value={userRecord?.role? userRole : ""} readOnly/>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-address">
                            Business Email
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-password" type="address" value={userRecord?.email? userRecord.email : ""} readOnly/>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="phone">
                            Phone
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="phone" type="phone" value={userRecord?.uphone? userRecord.uphone : ""} readOnly/>
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="birth">
                            Date of Birth
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="birth" type="text" value={userRecord?.date_of_birth? new Date(userRecord.date_of_birth).toLocaleDateString('en-CA') : ""} readOnly/>
                    </div>
                </div>
                </form>
            </div>
                
                <div className="w-[95%] h-full p-6 rounded-md">
                <form class="w-full max-w-lg">
                <div class="flex flex-wrap mb-6">
                    <div class="w-full md:w-full mb-6 md:mb-0">
                        <span className="text-lg text-custom-blue font-semibold">
                            Professional Information
                        </span>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="cod">
                            Function (Job Title)
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="function" type="text" value={userRecord?.function? userRecord.function : ""} readOnly/>
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="lang">
                            Preferred Language
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="lang" type="text" value={userRecord?.language_preference? Lang : ""} readOnly/>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="deptname">
                            Department Name
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="deptname" type="text" value={userRecord?.department_name? userRecord.department_name : ""} readOnly/>
                    </div>
                    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="deptcode">
                            Code
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="deptcode" type="text" value={userRecord?.department_code? userRecord.department_code : ""} readOnly/>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="budget">
                            Assigned Budget (€)
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="budget" type="text" 
        value={userRecord?.budget ? `€  ${userRecord.budget}` : ""} readOnly/>
                    </div>
                </div>
                </form>
            </div> 
        </div>
        {/**End of Head User Information Forms */}


        {/** Payment & Delivey Profiles Information */}
        <div className="flex flex-col items-center mb-4">
            <div className="w-4/5 border-t border-custom-blue"></div>
        </div>
        
    </div>
    );
  }