import React, { useEffect } from 'react';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import ResetP from '../../components/Sign/ResetP';
import useLogged from '../../hooks/useLogged';

export default function ResetPage() {
  useLogged();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav/>
         <ResetP />
        <Footer/>
    </>
  )
}
