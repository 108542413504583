import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './BaseSign.module.css';
import OTPForm from './OTPForm';

const OTP = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["my-component-container"]}>
    <div className={styles['left-col']}>
      <div className={styles['background-image']}>
        <div className={styles['single-header-container']}>
        <h2 className={styles['title']}>
            {t('signprocess.otptitle')}</h2>
          <OTPForm />
        </div>
      </div>
    </div>
    <div className={styles["right-col"]}>
      <div className={styles['partially-covered-image']}>
      </div>
    </div>
  </div>
);
};
export default OTP;