import React from 'react';
import Footer from '../../components/Home/Footer';
import PageNav from '../../components/Home/PageNav';
import { RiDeleteBin5Line, RiShoppingBasketLine } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { 
  removeFromBasket, 
  incrementQuantity, 
  decrementQuantity, 
  incrementProductQuantity, 
  decrementProductQuantity, 
  removeDsmProduct, 
  removePackageProduct 
} from '../../features/basket/basketSlice';
import { useNavigate } from 'react-router-dom';

export default function Basket() {
  const navigate = useNavigate();
  const basket = useSelector((state) => state.basket.items);
  const dispatch = useDispatch();
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths')) || {};
  const packageImageUrl = '../../img/about.jpg';
  const dsmImageUrl = '../../img/dsm-small.jpg';

  const calculateTotal = () => {
    let total = 0;
    basket.forEach((item) => {
      if (item.isDsm && item.dsmProducts) {
        total += item.sales_price_c1;
        item.dsmProducts.forEach((product) => {
          total += product.price * product.maxQuantity;
        });
      } else if (item.isPackage && item.packageProducts) {
        total += item.sales_price_c1;
        item.packageProducts.forEach((product) => {
          total += product.price * product.quantity;
        });
      } else {
        total += item.sales_price_c1 * item.quantity;
      }
    });
    return total;
  };

  const calculateTax = () => {
    return calculateTotal() * 0.14;
  };

  const handleIncrementQuantity = (id) => {
    dispatch(incrementQuantity({ id }));
  };

  const handleDecrementQuantity = (id) => {
    dispatch(decrementQuantity({ id }));
  };

  const handleIncrementProductQuantity = (id, productId) => {
    dispatch(incrementProductQuantity({ id, dsmProductIndex: productId }));
  };

  const handleDecrementProductQuantity = (id, productId) => {
    dispatch(decrementProductQuantity({ id, dsmProductIndex: productId }));
  };

  const handleRemoveDsmProduct = (id, productId) => {
    dispatch(removeDsmProduct({ id, dsmProductIndex: productId }));
  };
  
  const handleRemovePackageProduct = (id, productId) => {
    dispatch(removePackageProduct({ id, packageProductIndex: productId }));
  };

  const proceedToCheckout = () => {
    const total = calculateTotal();
    const tax = calculateTax();
    navigate('/ProfileShipping', { state: { total, tax } });
  };

  return (
    <>
      <PageNav />
      {basket.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[85dvh] bg-[#eee]">
          <RiShoppingBasketLine size={60} className="text-gray-400 mb-3" />
          <p className="text-sm font-semibold text-gray-500">Your basket is empty!</p>
        </div>
      ) : (
        <div className="w-[80%] mx-auto my-16 flex md:flex-row flex-col justify-between gap-10">
          <div className="md:w-[70%] w-full">
            {basket.map((item) => (
              <div className="flex flex-col shadow-custom p-2 mb-3 rounded animation" key={item.id}>
                <div className="flex items-center justify-between">
                  <img
                    className="md:w-20 w-14 md:h-20 h-14 object-cover rounded"
                    src={item.isDsm ? dsmImageUrl : (item.isPackage ? packageImageUrl : (imgPaths[item.assortimed_pid] ? imgPaths[item.assortimed_pid][0] : 'placeholder-image-url.jpg'))}
                    alt={item.id}
                  />
                  <div className="font-semibold md:text-sm text-xs w-1/12 text-center">{item.category}</div>
                  <div className="font-semibold md:text-sm text-xs md:w-2/5 w-1/5">
                    <p className='text-center w-full'>{item.name}{item.isDsm ? " - Creation Fee" : ""}</p>
                  </div>
                  {item.isDsm ? (
                    <div className="flex items-center bg-[#f1f1f1] font-semibold rounded-md">
                      <button className="text-[#2849ad80] md:text-2xl text-base md:p-2 md:mx-2 ml-3 mr-1 p-1" disabled onClick={() => handleDecrementQuantity(item.id)}> - </button>
                      <span className="md:mx-2 md:p-2 mx-1 p-1 md:text-base text-sm text-gray-400">{item.quantity}</span>
                      <button className="text-[#2849ad80] md:p-2 md:text-2xl text-sm md:mx-2 ml-1 mr-3 p-1" disabled onClick={() => handleIncrementQuantity(item.id)}> + </button>
                    </div>
                  ) : (
                    <div className="flex items-center bg-[#f1f1f1] font-semibold rounded-md">
                      <button className="text-custom-blue md:text-2xl text-base md:p-2 md:mx-2 ml-3 mr-1 p-1" onClick={() => handleDecrementQuantity(item.id)}> - </button>
                      <span className="md:mx-2 md:p-2 mx-1 p-1 md:text-base text-sm">{item.quantity}</span>
                      <button className="text-custom-blue md:p-2 md:text-2xl text-sm md:mx-2 ml-1 mr-3 p-1" onClick={() => handleIncrementQuantity(item.id)}> + </button>
                    </div>
                  )}
                  <div className="text-gray-600 font-semibold w-1/12">
                    <p className='w-full text-center md:text-base text-sm'>
                      ${item.isPackage ? item.totalProductCost : item.sales_price_c1}
                    </p>
                  </div>
                  <button className="cursor-pointer" onClick={() => dispatch(removeFromBasket({ id: item.id }))}>
                    <RiDeleteBin5Line className="text-custom-blue md:text-base text-sm" />
                  </button>
                </div>

                {item.dsmProducts && item.dsmProducts.length > 0 && (
                  <div className="mt-[10px]">
                    {item.dsmProducts.map((product, index) => (
                      <div key={`${item.id}-${product.id}-${index}`} className="border-1 border-amber-100 flex justify-between items-center mb-2 p-2 bg-[#f9f9f9] rounded shadow-custom">
                        <img
                          className="md:w-20 w-14 md:h-20 h-14 object-cover rounded"
                          src={product.imageUrl ? product.imageUrl : 'placeholder-image-url.jpg'}
                          alt={product.name}
                        />
                        <div className="md:text-sm text-xs w-1/12 text-center text-amber-500">{product.category}</div>
                        <div className="md:text-sm text-xs md:w-2/5 w-1/5"><p className='text-center w-full text-custom-blue'>{product.name}</p></div>
                        <div className="flex items-center bg-[#f1f1f1] font-semibold rounded-md">
                          <button className="text-custom-blue md:text-2xl text-base md:p-2 md:mx-2 ml-3 mr-1 p-1" onClick={() => handleDecrementProductQuantity(item.id, index)}> - </button>
                          <span className="md:mx-2 md:p-2 mx-1 p-1 md:text-base text-sm">{product.maxQuantity}</span>
                          <button className="text-custom-blue md:p-2 md:text-2xl text-sm md:mx-2 ml-1 mr-3 p-1" onClick={() => handleIncrementProductQuantity(item.id, index)}> + </button>
                        </div>
                        <div className="text-gray-600 font-semibold w-1/12"><p className='w-full text-center md:text-base text-sm'>${product.price}</p></div>
                        <button className="cursor-pointer" onClick={() => handleRemoveDsmProduct(item.id, index)}>
                          <RiDeleteBin5Line className="text-custom-blue md:text-base text-sm" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                {item.packageProducts && item.packageProducts.length > 0 && (
                  <div className="mt-[10px]">
                    <div className="border-1 border-amber-100 flex justify-center items-center mb-2 p-2 bg-[#f9f9f9] rounded shadow-custom">
                      <p className="text-custom-blue font-semibold">Creation Fee: ${item.sales_price_c1}</p>
                    </div>
                    {item.packageProducts.map((product, index) => (
                      <div key={`${item.id}-${product.id}-${index}`} className="border-1 border-amber-100 flex justify-between items-center mb-2 p-2 bg-[#f9f9f9] rounded shadow-custom">
                        <img
                          className="md:w-20 w-14 md:h-20 h-14 object-cover rounded"
                          src={product.imageUrl ? product.imageUrl : 'placeholder-image-url.jpg'}
                          alt={product.name}
                        />
                        <div className="md:text-sm text-xs w-1/12 text-center text-amber-500">{product.category}</div>
                        <div className="md:text-sm text-xs md:w-2/5 w-1/5"><p className='text-center w-full text-custom-blue'>{product.name}</p></div>
                        <div className="flex items-center bg-[#f1f1f1] font-semibold rounded-md">
                          <button className="text-[#2849ad80] md:text-2xl text-base md:p-2 md:mx-2 ml-3 mr-1 p-1" disabled> - </button>
                          <span className="md:mx-2 md:p-2 mx-1 p-1 md:text-base text-sm text-gray-400">{product.quantity}</span>
                          <button className="text-[#2849ad80] md:p-2 md:text-2xl text-sm md:mx-2 ml-1 mr-3 p-1" disabled> + </button>
                        </div>
                        <div className="text-gray-600 font-semibold w-1/12"><p className='w-full text-center md:text-base text-sm'>${product.price}</p></div>
                        <button className="cursor-pointer" onClick={() => handleRemovePackageProduct(item.id, index)}>
                          <RiDeleteBin5Line className="text-custom-blue md:text-base text-sm" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="md:w-[30%] w-full">
            <div className="mt-2 text-custom-blue bg-[#DCF6F7] p-4 rounded-lg h-fit">
              <h2 className="font-bold text-xl">Cart Totals</h2>
              <div className="my-3 text-sm">
                <div className="grid grid-cols-2 gap-3 my-1">
                  <p>Product Total:</p>
                  <span>${calculateTotal()}</span>
                </div>
                <div className="grid grid-cols-2 gap-3 my-1">
                  <p>Tax Total:</p>
                  <span>${calculateTax().toFixed(2)}</span>
                </div>
                <div className="grid grid-cols-2 gap-3 my-1">
                  <p>Order Total:</p>
                  <span>${(calculateTotal() + calculateTax()).toFixed(2)} (VAT incl.)</span>
                </div>
              </div>
              <div className="mt-4">
                <button className="font-semibold w-full py-2 bg-custom-blue text-white rounded-md hover:bg-blue-600 text-sm m-auto" onClick={proceedToCheckout}>Proceed to Checkout</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
