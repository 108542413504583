import React from "react";


// In ComBase component
export default function PackSum({ packRecord }) {
  packRecord = packRecord || null;


    function capitalizeFirstLetter(word) {
      if (!word) return '';
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  
    return (
        packRecord ?
            <div className="w-full mt-3 border-1 border-custom-green px-3 py-1 rounded-lg h-fit shadow-custom font-sans">
              <div className="my-3">
                <div className="my-1 mb-2 text-xl text-custom-blue font-extrabold flex justify-center">
                  <span>{packRecord.package_name}</span>
                </div>
                <div className="my-1 mb-2 text-lg text-custom-blue font-semibold flex justify-center">
                  <span>{packRecord.department} Department</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Number of products</span>
                  <span className="text-custom-blue">Package Status</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{packRecord.num_of_products}</span>
                  <span>{capitalizeFirstLetter(packRecord.package_status)}</span>
                </div>
                
                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Doc. Type</span>
                  <span className="text-custom-blue">Doc. Price</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{capitalizeFirstLetter(packRecord.doc_type)}</span>
                  <span>€ {packRecord.doc_price}</span>
                </div>

                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Creation fee</span>
                  <span className="text-custom-blue">Creation Status</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>€ {packRecord.creation_fee}</span>
                  <span>{capitalizeFirstLetter(packRecord.creation_status)}</span>
                </div>

                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Creation Date</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{packRecord.creation_date.substring(0, 10)}</span>
                </div>

                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Package fullfilment price</span>
                  <span className="text-custom-blue">Total Pack price</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>€ {packRecord.package_fulfillment_price}</span>
                  <span>€ {packRecord.total_pack_price}</span>
                </div>

                

              </div>
          </div> : null 
    );
  }