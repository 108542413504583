import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from "axios";

import Carousel from "react-multi-carousel";
import Card from "react-bootstrap/Card";

import CardBtn from "../Shop/CardBtn";

import "react-multi-carousel/lib/styles.css";
import styles from "./ProductCarousel.module.css";


const ProductCarousel = () => {

  const { t } = useTranslation();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/products/`)
      .then(response => {
        //console.log(response.data);
        setProducts(response.data);
        localStorage.setItem('products', JSON.stringify(response.data));
      })
      .catch(error => {
        //console.error('There was an error!', error);
      });
  }, []);



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Images
  useEffect(() => {
      const imgPaths = {
        "0": ["../../img/database_media/products/imgs/p_0_0.png", "../../img/database_media/products/imgs/p_0_1.png"],
        "1": ["../../img/database_media/products/imgs/p_1_0.png"],
        "2": ["../../img/database_media/products/imgs/p_2_0.png"],
        "3": ["../../img/database_media/products/imgs/p_3_0.png", "../../img/database_media/products/imgs/p_3_1.png"],
        "4": ["../../img/database_media/products/imgs/p_4_1.png", "../../img/database_media/products/imgs/p_4_2.png"],
        "5": ["../../img/database_media/products/imgs/p_5_0.png", "../../img/database_media/products/imgs/p_5_1.png"],
        "6": ["../../img/database_media/products/imgs/p_6_0.png", "../../img/database_media/products/imgs/p_6_1.png"],
        "7": ["../../img/database_media/products/imgs/p_7_0.png", "../../img/database_media/products/imgs/p_7_1.png"],
        "8": ["../../img/database_media/products/imgs/p_8_0.png", "../../img/database_media/products/imgs/p_8_1.png"],
        "9": ["../../img/database_media/products/imgs/p_9_0.png", "../../img/database_media/products/imgs/p_9_1.png", "../../img/database_media/products/imgs/p_9_2.png"],
        "10": ["../../img/database_media/products/imgs/p_10_0.png", "../../img/database_media/products/imgs/p_10_1.png", "../../img/database_media/products/imgs/p_10_2.png"], 
        "11": ["../../img/database_media/products/imgs/p_11_0.png", "../../img/database_media/products/imgs/p_11_1.png", "../../img/database_media/products/imgs/p_11_2.png"], 
        "12": ["../../img/database_media/products/imgs/p_12_0.png", "../../img/database_media/products/imgs/p_12_1.png"],
        "13": ["../../img/database_media/products/imgs/p_13_0.png", "../../img/database_media/products/imgs/p_13_1.png", "../../img/database_media/products/imgs/p_13_2.png"], 
        "14": ["../../img/database_media/products/imgs/p_14_0.png", "../../img/database_media/products/imgs/p_14_1.png"],
        "15": ["../../img/database_media/products/imgs/p_15_0.png", "../../img/database_media/products/imgs/p_15_1.png", "../../img/database_media/products/imgs/p_15_2.png"], 
        "16": ["../../img/database_media/products/imgs/p_16_0.png", "../../img/database_media/products/imgs/p_16_1.png", "../../img/database_media/products/imgs/p_16_2.png"], 
        "17": ["../../img/database_media/products/imgs/p_17_0.png", "../../img/database_media/products/imgs/p_17_1.png"],
        "18": ["../../img/database_media/products/imgs/p_18_0.png", "../../img/database_media/products/imgs/p_18_1.png", "../../img/database_media/products/imgs/p_18_2.png"], 
        "19": ["../../img/database_media/products/imgs/p_19_0.png", "../../img/database_media/products/imgs/p_19_1.png"],
        "20": ["../../img/database_media/products/imgs/p_20_0.png", "../../img/database_media/products/imgs/p_20_1.png"],
        "21": ["../../img/database_media/products/imgs/p_21_0.png", "../../img/database_media/products/imgs/p_21_1.png"],
        "22": ["../../img/database_media/products/imgs/p_22_0.png", "../../img/database_media/products/imgs/p_22_1.png"],
        "23": ["../../img/database_media/products/imgs/p_23_0.png", "../../img/database_media/products/imgs/p_23_1.png"],
        "24": ["../../img/database_media/products/imgs/p_24_0.png", "../../img/database_media/products/imgs/p_24_1.png"],
        "25": ["../../img/database_media/products/imgs/p_25_0.png", "../../img/database_media/products/imgs/p_25_1.png"],
        "26": ["../../img/database_media/products/imgs/p_26_0.png", "../../img/database_media/products/imgs/p_26_1.png"],
        "27": ["../../img/database_media/products/imgs/p_27_0.png", "../../img/database_media/products/imgs/p_27_1.png"]};

    // Convert the object to a JSON string and store it in localStorage
    localStorage.setItem('imgPaths', JSON.stringify(imgPaths));

      }, []);

  // Retrieve the JSON string from localStorage and parse it into an object
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  const cards = products.map((product, index) => ({
    title: product.category,
    text: product.name,
    imgSrc: imgPaths[product.assortimed_pid][0],
  }))

  return (
    <div id="myCarousel" className={styles.container}>
      <Carousel 
        responsive={responsive}>
    {Array.isArray(cards)  && cards.length > 0 ? cards.slice(0, 10).map((card, index) => (
      <Card key={index} className={styles.card_body}>
        <div className={styles.img_container}>
          <Card.Img variant="top" src={card.imgSrc} className={styles.card_img}/>
        </div>
        <Card.Body>
        <div className={styles.content_container}>
        <Card.Title className={styles.card_title}>{card.title}</Card.Title>
          <Card.Text className={styles.card_content}>{card.text}</Card.Text>
          </div>
          <Link to='/shoplisting'><CardBtn >{t("landing.view-cart-btn")}</CardBtn></Link>
        </Card.Body>
      </Card>
    )) : <p className="card-title">Loading...</p>}
      </Carousel>
    </div>
  );
};

export default ProductCarousel;
