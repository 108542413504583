import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./SubListing.css";

export default function SubListing() {
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState("");
  const [categorySubCategoryMap, setCategorySubCategoryMap] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      const storedProducts = JSON.parse(localStorage.getItem('products')) || [];
      const map = storedProducts.reduce((acc, product) => {
        if (!acc[product.category]) {
          acc[product.category] = [];
        }
        if (product.sub_category_1 && !acc[product.category].includes(product.sub_category_1)) {
          acc[product.category].push(product.sub_category_1);
        }
        return acc;
      }, {});
      setCategorySubCategoryMap(map);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const handleNavItem = (link) => {
    setActiveLink(link);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="test">
      <Navbar bg="light" expand="lg" className="subListing-navbar">
        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse
          id="basic-navbar-nav "
          className="justify-content-around"
        >
          <Nav className="subListing">
            {Object.keys(categorySubCategoryMap).map((category, index) => (
              <Nav.Link
                key={index}
                onClick={() => handleNavItem(category)}
                className={activeLink === category ? "active" : ""}
              >
                {category}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}