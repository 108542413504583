import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Styling Imports
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import "./UserShopListing.css";


// Local Imports
import useAuth from "../../hooks/useAuth.js";
import PageNav from "../../components/Home/PageNav";
import Footer from "../../components/Home/Footer";
import USubListing from "../../components/UShop/USubListing";
import UserShopSlider from "../../components/UShop/UserShopSlider";
import UserFilter from "../../components/UShop/UserFilter.jsx";
import Search from "./Search.jsx";


// Contexts
import { SearchProvider, useSearch } from "../../context/SearchContext.jsx";


export default function UserShopList() {
  useAuth();
  const [showFilter, setShowFilter] = useState(true);
  const storedProducts = JSON.parse(localStorage.getItem('products'));



  function toggleFilter() {
    setShowFilter(!showFilter);
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const { t } = useTranslation();
  return (
    <>
      <SearchProvider>
        <PageNav />
        <USubListing />

        <div className="shopListing">
          {/* =============== user filter component =================== */}
          <div className="shopFilter">
            <button
              className="filter"
              onClick={toggleFilter}
              style={{ display: "flex", alignItems: "center" }}
            >
              FILTER
              <span
                style={{
                  fontSize: "20px",
                  marginLeft: "10px"
                }}>
                {showFilter ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
              </span>
            </button>
            {showFilter && <UserFilter />}
          </div>
          {/* =============== user filter component =================== */}

          <div className="shopProducts">
            <div className="productAvilable">
              <p>
                <b style={{ marginRight: '10px' }}>{storedProducts.length}</b> Products available
              </p>

              <div className="sorting">
                <div className="userShopSearch">
                  <Search />
                </div>
                <button>Popular</button>
                <button>Price</button>
                <button>Name</button>
              </div>
            </div>
            <button onClick={scrollToTop} className="productAvilableArrow">
              <FontAwesomeIcon icon={faArrowUp} />
            </button>

            <div className="userShopSliderCont">
              <UserShopSlider />
            </div>
          </div>
        </div>
        <Footer />
      </SearchProvider>
    </>
  );
}
