import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

// Icon Imports
import { FaCalendar, FaEuroSign } from "react-icons/fa";


// Local Imports
import OrderItem from './OrderItem';



const OrdersTable = () => {
  //Pagination Controls
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // Retrieve Orders of the current user
    const currentUser =  JSON.parse(localStorage.getItem('user'))['user_data'];
    const accessToken = localStorage.getItem('accessToken');
    const [orders, setOrders] = useState([]);

    useEffect(() => {
      const getOrder = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/orders/order/user/${currentUser.id}/`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          setOrders(response.data);
          //console.log("Orders Data: " + response.data);
    
          if (response.data) {
          }
        } catch (error) {
          console.error(`Error retrieving data: ${error}`);
        }
      };
    
      getOrder();
    }, []);


  // Click Filter Button
  // Step 1: Define the state
  const [useEffectTrigger, setUseEffectTrigger] = useState(0);
  // Step 2: Update state on click
  const handleFilter = () => {
    setUseEffectTrigger(prev => prev + 1);
  };
  
    // Order DropLists filter
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Date Range Filter
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);

  const toggleDatePicker = () => setDatePickerVisible(!isDatePickerVisible);

  // Order Total Range Filter
  const [minOrderTotal, setMinOrderTotal] = useState(null);
  const [maxOrderTotal, setMaxOrderTotal] = useState(null);

  const handleMinOrderTotalChange = (event) => {
    setMinOrderTotal(event.target.value);
  };
  //console.log('MIn Order:  ' + minOrderTotal);
  const handleMaxOrderTotalChange = (event) => {
    setMaxOrderTotal(event.target.value);
  };
  //console.log('Max Order:  ' + maxOrderTotal);

  
  // Multiple Order Type filter
  const items = [
    'ORDERS',
    'ACTIVE ORDERS',
    'COMPLETED ORDERS',
    'CANCELLED ORDERS',
    'RETURNED ORDERS',
  ];
  const [activeItem, setActiveItem] = useState('ORDERS');
  const [filteredOrders, setFilteredOrders] = useState(orders); 

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  // Filtering Functions
  useEffect(() => {
    // Mapping of activeItem to order_status values
    const statusMap = {
      'ORDERS': ['in_progress', 'in_wharehouse', 'on_the_road', 'waiting', 'delayed', 'recieved', 'delivered', 'completed', 'parthly_delivered'],
      'ACTIVE ORDERS': ['in_progress', 'in_wharehouse', 'on_the_road', 'waiting', 'delayed', 'cancelled', 'missing', 'returned', 'partially_returned'],
      'COMPLETED ORDERS': ['recieved', 'delivered', 'completed', 'parthly_delivered'],
      'CANCELLED ORDERS': ['cancelled', 'missing'],
      'RETURNED ORDERS': ['returned', 'partially_returned']
    };
    // Determine the statuses to filter by based on the activeItem
    const statusesToFilterBy = statusMap[activeItem];
    // Filter orders based on the selectedActiveItem
    const filtered = orders.filter(order => statusesToFilterBy.includes(order.order_status));
    setFilteredOrders(filtered);

    const orderNumbers = filtered.map(order => order.order_number);
    const formattedOrderNumbers = orderNumbers.map(orderNumber => ({
      value: orderNumber,
      label: orderNumber.toString(),
    }));
    const defaultValue = { value: 'default', label: 'All Orders' };
    formattedOrderNumbers.unshift(defaultValue);
    setOrderNumbers(formattedOrderNumbers);

  }, [activeItem, orders]);


  // Order Number Filter
  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.value === 'default') {
        // Nothing to filter
        setActiveItem('ORDERS');
        setFilteredOrders(orders);
        setSelectedOption(null);
        setStartDate(null);
        setEndDate(null);
      } else {
        // Filter based on the selected order number
        const numFilteredOrders = filteredOrders.filter(order => 
          order.order_number === selectedOption.value
        );
        setFilteredOrders(numFilteredOrders);
      }
    }
  }, [selectedOption, filteredOrders]);

  // Date Range Filter
  useEffect(() => {
      if (startDate === '' || endDate === '') {
        // Additional date filtering
        const numFilteredOrders = filteredOrders.filter(order => {
          const orderDate = new Date(order.order_date);
  
          if (startDate && endDate) {
            return orderDate >= startDate && orderDate <= endDate;
          } else if (startDate) {
            return orderDate >= startDate;
          } else if (endDate) {
            return orderDate <= endDate;
          }
          return true; 
        });
        setFilteredOrders(numFilteredOrders);
      }
    
  }, [startDate, endDate, useEffectTrigger]); 


  // Order Total Range Filter
  useEffect(() => {
    if (minOrderTotal || maxOrderTotal) {
      const numFilteredOrders = filteredOrders.filter(order => {
        const orderTotal = parseFloat(order.order_total);

        if (minOrderTotal && maxOrderTotal) {
          return orderTotal >= minOrderTotal && orderTotal <= maxOrderTotal;
        } else if (minOrderTotal) {
          return orderTotal >= minOrderTotal;
        } else if (maxOrderTotal) {
          return orderTotal <= maxOrderTotal;
        }
        return true; 
      });
      setFilteredOrders(numFilteredOrders);
    }
  
  }, [minOrderTotal, maxOrderTotal, useEffectTrigger]); 



  return (
    <>
      {/* Filtration section Orders Focus */}
      <div className=" w-100 container top-0 border-b-2 border-gray-200 bg-white p-2">
        <ul className="flex w-full flex-wrap text-sm font-bold">
          {items.map((item, index) => (
            <li key={index} className="flex-1 sm:w-auto">
              <a
                className={`block rounded border-b-2 border-transparent px-4 py-1 text-center 
                  ${
                    activeItem === item
                      ? 'font-bold text-gray-700'
                      : 'text-gray-400'
                  } 
                   hover:font-bold hover:text-gray-700`}
                onClick={() => handleItemClick(item)}
                href="#"
                style={{ width: 'max-content' }}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Filtration section Apply filter button */}
      <div className="flex w-full flex-wrap items-center justify-between border-b-2 border-gray-300 bg-white p-3 shadow-md">
        <div className="flex w-full justify-around md:w-[46%]">
        <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <Select
            className="w-full"
            options={orderNumbers}
            placeholder="Order Number"
            onChange={handleChange}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: 'gray-400',
                primary: 'border',
              },
            })}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: 'gray-600',
                  fontStyle: 'italic',
                  fontWeight: 'semibold',
                };
              },
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? 'lightblue' : 'white',
                color: state.isFocused ? 'black' : 'gray',
                cursor: 'pointer',
              }),
            }}
          />
        </div>

          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
           <button className="w-full h-full text-left pl-3 pr-3 py-1 text-base border-gray-300 border-1 focus:outline-none focus:ring-indigo-500 italic text-gray-800 flex justify-between items-center" onClick={toggleDatePicker}>
            {/* {startDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : "Select Date Range"} */}
            <span>Select Date Range</span>
            <FaCalendar className="text-slate-400 text-right right-0"/>
          </button>
          {isDatePickerVisible && (
            <div className="absolute z-50 bg-white border-2 border-gray-300 p-4">
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                  setDatePickerVisible(false);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </div>
          )}
          </div>
        </div>

        <div className="flex w-full justify-around md:w-[44%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <input
              type="number"
              className="w-full h-full text-left px-3 py-1 text-base border-gray-300 border-1 italic placeholder:text-gray-800"
              placeholder="Min. Order Total - €"
              value={minOrderTotal}
              onChange={handleMinOrderTotalChange}
            />
          </div>
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <input
              type="number"
              className="w-full h-full text-left px-3 py-1 text-base border-gray-300 border-1 italic placeholder:text-gray-800"
              placeholder="Max. Order Total - €"
              value={maxOrderTotal}
              onChange={handleMaxOrderTotalChange}
            />
          </div>
        </div>

        <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
          <button className="text-md w-full rounded-lg text-custom-blue px-6 py-1 font-semibold border-2 border-custom-blue hover:bg-custom-blue hover:text-white sm:w-auto"
          onClick={handleFilter}>
            Filter
          </button>
        </div>
      </div>

      {/**Display User / Filtered Orders */}
      <div className="w-100 container mx-auto overflow-auto pb-8">
      {Array.isArray(filteredOrders) && filteredOrders.length > 0 ? (
        filteredOrders
          .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
          .map((order) => <OrderItem key={order.id} invoice={order} />)
      ) : (
        <p className="w-100 self-center text-center font-bold text-gray-600 m-4 mx-auto">No 
        <span className='text-custom-blue'> {activeItem} </span> 
        Orders to Display!</p>
      )}
    </div>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={filteredOrders.length}
        pageRangeDisplayed={7}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </>
  );
};

export default OrdersTable;
