import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Local Imports
import Report from './ad-hock/Reports3';
import Product1 from './products/Products1';
import Invoice from './invoice/Invoice';
import ChatPage from './chat/ChatPage';
import ComBase from './company/Company';
import TL2 from './notify/TL2';

// Icons
import { MdOutlineInventory } from 'react-icons/md';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { MdAdminPanelSettings } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { icon } from '@fortawesome/fontawesome-svg-core';


export default function BoardBase() {
  
  // useEffect(() => {
  //   // Save the original overflow style
  //   const originalOverflow = document.body.style.overflow;
  
  //   // Set the overflow style to 'hidden'
  //   document.body.style.overflow = 'hidden';
  
  //   // Return a cleanup function that resets the overflow style
  //   return () => {
  //     document.body.style.overflow = originalOverflow;
  //   };
  // }, []);


  // Display Pages at will once selected
  const [selectedPage, setSelectedPage] = useState('Reports');
  const Reports = () => <Report/>;
  const Products = () => <Product1/>;
  const Invoices = () => <Invoice/>;
  const Company = () => <ComBase/>;
  const Notifications = () => <TL2/>;
  const Chat = () => <ChatPage/>;

  const pages = {
    'Reports': Reports,
    'Products': Products,
    'Invoices': Invoices,
    'Company': Company,
    'Notifications': Notifications,
    'Chat': Chat,
  };

  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));

  // Toggling between Tabs
  const tabs = [
    { text: 'Add-Hock Reports', value: 'Reports', status: 'active', icon: <BiSolidReport/>},
    { text: 'New Products', value: 'Products', status: 'inactive', icon: <MdOutlineInventory />},
    { text: 'Custom Invoices', value: 'Invoices', status: 'inactive', icon: <BiSolidReport />},
    { text: 'Customer Info.', value: 'Company', status: 'inactive', icon: <BiSolidReport />},
    { text: 'User Activity', value: 'Notifications', status: 'inactive', icon: <BiSolidReport />},
    { text: 'Internal Chat', value: 'Chat', status: 'inactive', icon: <IoChatbubblesOutline />},
  ];

  const handleClick = (value) => {
    setSelectedPage(value);
  };

  return (
    <div key="1" className="flex items-start flex-col min-h-screen bg-gray-50">
      <header className="fixed top-0 z-10 w-full bg-gray-50">
        <div className=" mx-auto mb-3 flex max-w-7xl px-4 py-3 shadow-sm">
          <MdAdminPanelSettings className="mt-2 mr-4 text-2xl leading-tight text-custom-blue"/>
          <h1 className="mt-2 text-xl font-bold leading-tight text-custom-blue">
            Assortimed Admin Control Board
          </h1>
         
          <Link to="/" className='ml-auto'>
              <img
                className="ml-auto h-12 w-60"
                src="../img/Logo.png"
                alt="Assortimed Home"
              />
          </Link>
        </div>

        <div>
          <ul className="flex border-b">
            {tabs.map((tab, index) => (
              <li className={tab.value === selectedPage ? '-mb-px mr-1' : 'mr-1'} key={index}>
                <a 
                  className={`bg-white inline-block leading-tight ${tab.value === selectedPage ? 'border-l border-t border-r rounded-t' : ''} py-2 px-4 ${tab.value === selectedPage ? 'text-custom-blue font-bold' : 'text-custom-green font-semibold hover:font-bold hover:text-custom-blue'} cursor-pointer flex items-center`} 
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedPage(tab.value)
                  }}
                >
                  <span className='text-sm mr-2'>{tab.icon}</span>
                  {tab.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </header>
      
      {/**Place Holder for Different Componenets */}
      <main className="pt-36 w-full">
            <div className='p-2'>
              {pages[selectedPage]()}
            </div>
      </main>
   
    </div>
  );
}

