import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default function Map() {
  // const mapRef = useRef(null);
  const position = [51.60327801510128, 4.726795263010896];
  const locationIcon = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + "/img/location.png",

    iconSize: [150, 150],
    iconAnchor: [70, 100],
    popupAnchor: [5, -45],
  });

  return (
    <MapContainer
      center={position}
      zoom={13}
      scrollWheelZoom={true}
      style={{ height: "300px", width: "100%" }}
    >
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=baa35984-3439-47a1-9008-248b3eac9d55"
        maxZoom={20}
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      />

      <Marker position={position} icon={locationIcon}>
        <Popup>
          AssortiMed
          <br />
          International
          <br />
        </Popup>
      </Marker>
    </MapContainer>
  );
}
