import React, { useState, useEffect, useContext } from 'react'

// Styling Imports
import '../../pages/UShop/UserShopListing.css';

// Local Imports
import FilterContext from "../../context/FilterContext";

export default function UserFilter() {
    const storedProducts = JSON.parse(localStorage.getItem('products'));
    const uniqueSubCategories1 = [...new Set(storedProducts.map(product => product.sub_category_1))];
    const uniqueSubCategories2 = [...new Set(storedProducts.map(product => product.sub_category_2))];
    const uniqueSubCategories3 = [...new Set(storedProducts.map(product => product.sub_category_3))];
    const uniqueBrands = [...new Set(storedProducts.map(product => product.brand))];
    const uniqueSpecs1 = [...new Set(storedProducts.map(product => product.specs_1))];
    const uniqueSpecs2 = [...new Set(storedProducts.map(product => product.specs_2))];

    //filter prices
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);

    useEffect(() => {
        const prices = storedProducts.flatMap(product => [
            product.sales_price_1,
            product.sales_price_2,
            product.sales_price_3,
            product.sales_price_4
          ]).filter(price => price !== null);
      
          if (prices.length > 0) {
            setMinPrice(Math.min(...prices));
            setMaxPrice(Math.max(...prices));
          }
        }, []);


    // Filtered Products
    const { choosenProducts, setChoosenProducts } = useContext(FilterContext);
    const [filterStatus, setFilterStatus] = useState('clear');

    const handleFilter = () => {
        const category = document.getElementById('categorySelect').value;
        const subCategory1 = document.getElementById('subCategory1Select').value;
        const subCategory2 = document.getElementById('subCategory2Select').value;
        const spec1 = document.getElementById('spec1Select').value;
        const spec2 = document.getElementById('spec2Select').value;
        const minPrice = document.getElementById('minPriceInput').value;
        const maxPrice = document.getElementById('maxPriceInput').value;
        const brand = uniqueBrands
        .map((_, index) => document.getElementById(`brandCheck${index}`))
        .filter(element => element && element.checked)
        .map(element => element.value);

        // console.log(category);
        // console.log(storedProducts.length);
    
        const choosen = storedProducts.filter(product =>
            (product.sub_category_1 === category) ||
            (product.sub_category_2 === subCategory1) ||
            (product.sub_category_3 === subCategory2) ||
            (product.specs_1 === spec1) ||
            (product.specs_2 === spec2) ||
            (product.sales_price_1 >= minPrice) ||
            (product.sales_price_2 <= maxPrice) ||
            (brand.includes(product.brand))
          );
    
        setChoosenProducts(choosen);
        setFilterStatus('filtered');
        // console.log('filteredProducts:');
        // console.log(choosen);
        //localStorage.setItem('choosenProducts', JSON.stringify(choosen));
      };


      const handleClearFilter = () => {
        setChoosenProducts(storedProducts);
        setFilterStatus('clear');
      };


    

  return (
    <div className='shopSort'>
        <ul className="list-group">
            <li className="list-group-item">
                <label htmlFor="basic-url" className="form-label">Category</label>
                <div className="col-sm-3">
                    <select className="form-select" id="categorySelect">
                        <option selected>Category</option>
                        {uniqueSubCategories1.map((subCategory, index) => (
                            <option key={index} value={subCategory}>{subCategory}</option>
                        ))}
                    </select>
                </div>
            </li>

            <li className="list-group-item">
                <label htmlFor="basic-url" className="form-label">Sub Category one</label>
                <div className="col-sm-3">
                    <select className="form-select" id="subCategory1Select">
                        <option selected>Sub Category one</option>
                        {uniqueSubCategories2.map((subCategory, index) => (
                            <option key={index} value={subCategory}>{subCategory}</option>
                        ))}
                    </select>
                </div>
            </li>

            <li className="list-group-item">
                <label htmlFor="basic-url" className="form-label">Sub Category two</label>
                <div className="col-sm-3">
                    <select className="form-select" id="subCategory2Select">
                        <option selected>Sub Category two</option>
                        {uniqueSubCategories3.map((subCategory, index) => (
                            <option key={index} value={subCategory}>{subCategory}</option>
                        ))}
                    </select>
                </div>
            </li>

            <li className="list-group-item">
                <label htmlFor="basic-url" className="form-label">Specification one</label>
                <div className="col-sm-3">
                    <select className="form-select" id="spec1Select">
                        <option selected>Specification one</option>
                        {uniqueSpecs1.map((spec, index) => (
                            <option key={index} value={spec}>{spec}</option>
                        ))}
                    </select>
                </div>
            </li>

            <li className="list-group-item">
                <label htmlFor="basic-url" className="form-label">Specification two</label>
                <div className="col-sm-3">
                    <select className="form-select" id="spec2Select">
                        <option selected>Specification two</option>
                        {uniqueSpecs2.map((spec, index) => (
                            <option key={index} value={spec}>{spec}</option>
                        ))}
                    </select>
                </div>
            </li>

            <li className="list-group-item">
                <label htmlFor="basic-url" className="form-label">Price</label>
                <div className="row g-3 inputPrice">
                    <div className="col">
                        <input type="number" className="form-control" placeholder={minPrice} id="minPriceInput" />
                    </div>
                    <div className="col">
                        <input type="number" className="form-control" placeholder={maxPrice} id="maxPriceInput" />
                    </div>
                </div>
            </li>

            <li className="list-group-item">
                <label className="form-label">Brand</label>
                {uniqueBrands.map((brand, index) => (
                    <div className="col-auto" key={index}>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id={`brandCheck${index}`} />
                            <label className="form-check-label" htmlFor={`brandCheck${index}`}>
                                {brand}
                            </label>
                        </div>
                    </div>
                ))}
            </li>
            
            { filterStatus === 'clear' ?
            <button className='btn-filter'  onClick={handleFilter}>FILTER</button>
            :
            <button className='btn-filter'  onClick={handleClearFilter}>CLEAR FILTER</button>
        }
    </ul>
</div>
  )
}
