import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from 'axios';

import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import CardBtn from "../Shop/CardBtn";
import Spinner from '../Home/Spinner.jsx';
import "../Home/ProductCarousel.css";
import "./ProductSlider1.css";
import styles from "../Home/ProductCarousel.module.css";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function CareerSlider1() {
  const [products, setProducts] = useState([]);

  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 4, },},
      { breakpoint: 992, settings: { slidesToShow: 3, }, },
      { breakpoint: 768, settings: { slidesToShow: 2, },},
      { breakpoint: 576, settings: { slidesToShow: 1, },},
    ],
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/products/`)
      .then(response => {
        //console.log(response.data);
        setProducts(response.data);
        localStorage.setItem('products', JSON.stringify(response.data));
      })
      .catch(error => {
        //console.error('There was an error!', error);
      });
  }, []);


  // Retrieve the JSON string from localStorage and parse it into an object
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  const cards = products.map((product, index) => ({
    title: product.category,
    text: product.name,
    imgSrc: imgPaths[product.assortimed_pid][0]
  }))

  return (
    <div id="vshopSlider" className="slider-container">
      <Slider {...settings}>
      {Array.isArray(cards)  && cards.length > 0 ? cards.slice(0, 10).map((card, index) => (
            <Card key={index} className={styles.slider_card_body} >
            <div className={styles.slider_img_container}>
              <Card.Img variant="top" src={card.imgSrc} className={styles.slider_card_img}/>
            </div>
            <Card.Body>
            <div className={styles.slider_content_container}>
            <Card.Title className={styles.slider_card_title}>{card.title}</Card.Title>
              <Card.Text className={styles.slider_card_content}>{card.text}</Card.Text>
              </div>
              <Link to='/login'><CardBtn >{t("landing.view-cart-btn")}</CardBtn></Link>
            </Card.Body>
          </Card>
         )) : <Spinner/>}
      </Slider>
    </div>
  );
}

export default CareerSlider1;
