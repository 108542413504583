import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

const PackageProductItem = ({ packageProduct }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  const associatedProduct = packageProduct.product? storedProducts.find(p => p.id === packageProduct.product) : undefined;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // Handle the case where the product is not found
  if (!associatedProduct) {
    return <div className="mt-2 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between text-red-400 font-semibold">
       There're no associated products with this package!!
      </div>;
  }


  //console.log("Inside product: " + packageProduct);
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
  const imgUrl = imgPaths[associatedProduct.assortimed_pid][0];

  return (
    <>
      <div className="relative mt-4 flex w-full flex-row rounded border bg-white p-6 shadow">
        <div className="mt-1 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          <div className="mr-2 flex w-full flex-col ">
            <img
              src={imgUrl}
              alt="description_of_image"
              className="h-20 w-20 object-cover"
            />
          </div>
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Category</p>
            <span className="text-sm text-gray-500">{associatedProduct.category}</span>
          </div>
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">SubCategory</p>
            <span className="text-sm text-gray-500">{associatedProduct.sub_category_1}</span>
          </div>
        </div>
        <div className="mt-1 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Brand</p>
            <span className="text-sm text-gray-500">{associatedProduct.brand}</span>
          </div>
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Qty</p>
            <span className="text-sm text-gray-500">{packageProduct.quantity_per_product? packageProduct.quantity_per_product : "NA"}</span>
          </div>
          <div className="mt-3 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Price</p>
            <span className="text-sm text-gray-500">{packageProduct.total_price_per_product? packageProduct.total_price_per_product : "NA"}</span>
            <p className="mb-2 text-xs font-bold text-gray-500">(Vat incl.)</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageProductItem;
