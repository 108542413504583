import React, { useState } from 'react';



// Tab Content
import InfoCom from './InfoCom';
import InfoPer from './InfoPer';
import InfoPay from './InfoPay';
import InfoDel from './InfoDel';

export default function InfoSections() {
  const [fillActive, setFillActive] = useState('Com');

  const handleSelectChange = (event) => {
    setFillActive(event.target.value);
  };

  return (
    <>
      {/** Main Section */}
      <ul className="w-full rounded-lg text-center text-sm font-semibold text-gray-500 sm:flex">
        <li className="w-full">
          <a
            href="#"
            onClick={() => setFillActive('Com')}
            className={`inline-block w-full border-b-4 bg-white p-3 py-4 ${fillActive === 'Com' ? 'border-custom-green bg-gradient-to-b from-custom-gradient-start to-custom-gradient-end font-bold' : 'border-gray-200 hover:border-custom-green hover:bg-gray-100 hover:font-bold'} focus:outline-none`}
          >
            <span
              className={`inline-block ${fillActive === 'Com' ? 'text-custom-blue' : 'hover:text-custom-gray-900 text-gray-500'}`}
            >
              Comapny Information
            </span>
          </a>
        </li>

        <li className="w-full">
          <a
            href="#"
            onClick={() => setFillActive('Per')}
            className={`inline-block w-full border-b-4 bg-white p-3 py-4 ${fillActive === 'Per' ? 'border-custom-green bg-gradient-to-b from-custom-gradient-start to-custom-gradient-end font-bold' : 'border-gray-200 hover:border-custom-green hover:bg-gray-100 hover:font-bold'} focus:outline-none`}
          >
            <span
              className={`inline-block ${fillActive === 'Per' ? 'text-custom-blue' : 'hover:text-custom-gray-900 text-gray-500'}`}
            >
              Personal Information
            </span>
          </a>
        </li>

        <li className="w-full ">
          <a
            href="#"
            onClick={() => setFillActive('Pay')}
            className={`inline-block w-full border-b-4 bg-white p-3 py-4 ${fillActive === 'Pay' ? 'border-custom-green bg-gradient-to-b from-custom-gradient-start to-custom-gradient-end font-bold' : 'border-gray-200 hover:border-custom-green hover:bg-gray-100 hover:font-bold'} focus:outline-none`}
          >
            <span
              className={`inline-block ${fillActive === 'Pay' ? 'font-bold text-custom-blue' : 'text-gray-500 group-hover:text-gray-900'}`}
            >
              Payment Information
            </span>
          </a>
        </li>

        <li className="w-full ">
          <a
            href="#"
            onClick={() => setFillActive('Del')}
            className={`inline-block w-full border-b-4 bg-white p-3 py-4 ${fillActive === 'Del' ? 'border-custom-green bg-gradient-to-b from-custom-gradient-start to-custom-gradient-end font-bold' : 'border-gray-200 hover:border-custom-green hover:bg-gray-100 hover:font-bold'} focus:outline-none`}
          >
            <span
              className={`inline-block ${fillActive === 'Del' ? 'font-bold text-custom-blue' : 'text-gray-500 group-hover:text-gray-900'}`}
            >
              Delivary Information
            </span>
          </a>
        </li>
      </ul>
      {fillActive === 'Com' && (
        <div className="mx-auto w-[100%]">
          <InfoCom />
        </div>
      )}
      {fillActive === 'Per' && (
        <div className="mx-auto w-[100%]">
          <InfoPer />
        </div>
      )}
      {fillActive === 'Pay' && (
        <div className="mx-auto w-[100%]">
          <InfoPay />
        </div>
      )}
      {fillActive === 'Del' && (
        <div className="mx-auto w-[100%]">
          <InfoDel />
        </div>
      )}
    </>
  );
}
