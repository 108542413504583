import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { FaXmark } from 'react-icons/fa6';
import { CiSquarePlus } from 'react-icons/ci';
import PopupNewUser from './PopupAddUser';
import PopupDel from './PopupDel';
import PopupEditUser from './PopupEditUser';
import PopupPayment from './PopupPayment';

export default function Users() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showNewUser, setShowNewUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);

  const editUser = (user) => {
    setSelectedUser(user);
    setShowEditUser(true);
  };
  // Show Payment
  const [showPayment, setShowPayment] = useState(false);

  const editPayment = (user) => {
    setSelectedUser(user);
    setShowPayment(true);
  };

  // Show Delivery
  const [showDel, setShowDel] = useState(false);

  const editDel = (user) => {
    setSelectedUser(user);
    setShowDel(true);
  };

  const createNewUser = () => {
    setShowNewUser(true);
  };

  const deleteUser = () => {
    // setAdded(added.filter((id) => id !== id));
    setShowNewUser(false);
    
  };

  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const userRole =
    user.user_data.role === 'HD'
      ? 'Head User'
      : user.role === 'MG'
        ? 'Manager User'
        : 'Normal User';
  // const companyusers = user.company_users;
  const companyusers = user.company_users.filter(companyUser => companyUser.id !== user.user_data.id);
  //console.log(user);

  const [searchText, setSearchText] = useState('');
  const filteredUsers = companyusers.filter((user) =>
    user.fullname.toLowerCase().includes(searchText.toLowerCase()),
  );

  const indexOfLastUser = activePage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  useEffect(() => {
    const filteredUsers = companyusers.filter((user) =>
      user.fullname.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, companyusers]);

  return (
    <div className="mx-auto my-20 w-[85%] rounded-xl">
      <div className="flex flex-col items-center justify-between rounded-xl border-b-2 px-4 py-6 sm:flex-row md:items-start">
        <h2 className="mb-3 font-sans text-3xl font-semibold ">
          Institution Users
        </h2>
        <div className="flex flex-row items-end justify-between space-x-1">
        {userRole === 'Head User' || userRole === 'Manager User' ? (
          <button
            className="hover:bg-custom-white mr-4 flex items-center whitespace-nowrap rounded-lg border-2 border-custom-blue bg-custom-blue px-3 py-2 text-sm font-bold text-white shadow-sm hover:text-custom-blue"
            onClick={createNewUser}
          >
            Add New User
          </button>
          ) : null}
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search by Name"
            aria-label="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        {showNewUser && <PopupNewUser onClose={() => setShowNewUser(false)} />}
      </div>
      
      {/** Users List */}
      <div className="w-100 container mx-auto overflow-auto pb-8">
        {currentUsers.map((user) => {
          const {
            id,
            email,
            fullname,
            profile_picture,
            uphone,
            date_of_birth,
            language_preference,
            department_code,
            department_name,
            function: userFunction,
            budget,
            role,
          } = user;
          const currentuserRole =
    user.role === 'HD'
      ? 'Head User'
      : user.role === 'MG'
        ? 'Manager User'
        : 'Normal User';

          return (
            <div
              key={user.id}
              className="relative mt-4 flex w-full flex-col justify-center rounded-xl border bg-white p-6 shadow-md sm:flex-row sm:justify-between"
            >
              <div id="usermaininfo" className="flex flex-row">
                <img
                  className="h-14 w-14 rounded-full"
                  src={profile_picture ? `./img/${profile_picture}` : "./img/database_media/users/default.jpg"}
                  alt="Profile"
                />
                <div
                  id="username"
                  className="ml-4 mt-2 flex flex-row items-start justify-start"
                >
                  <h2 className="text-lg font-bold text-black">{fullname}</h2>
                  {userRole === 'Head User' || userRole === 'Manager User' ? (
                    <p className="ml-1 rounded-full bg-custom-blue px-3 py-1 text-xs font-medium text-white">
                      {currentuserRole}
                    </p>
                  ) : null}
                </div>
              </div>
              {userRole === 'Head User' || userRole === 'Manager User' ? (
                <>
                  <div
                    id="usermaininfo"
                    className="mt-2 flex flex-col  space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0"
                  >
                    <button
                      onClick={() => editUser(user)}
                      className="rounded-lg border px-3 py-2 text-base font-bold shadow-sm hover:bg-red-400 hover:text-white"
                    >
                      Edit
                    </button>

                    <button
                      onClick={() => editPayment(user)}
                      className=" flex items-center justify-center rounded-lg border px-3 py-2 text-sm font-bold shadow-sm hover:bg-red-400 hover:text-white"
                    >
                      <CiSquarePlus className="mr-2 text-base font-bold" />
                      Payment
                    </button>

                    <button
                      onClick={() => editDel(user)}
                      className="flex items-center justify-center rounded-lg border px-3 py-2 text-sm font-bold shadow-sm hover:bg-red-400 hover:text-white"
                    >
                      <CiSquarePlus className="mr-2 text-base font-extrabold" />
                      Delivery
                    </button>

                    <button
                      onClick={deleteUser}
                      className="flex items-center justify-center rounded-lg border-2 bg-red-400 p-2 font-bold text-xl text-slate-50 hover:bg-white hover:text-red-400  border-red-400"
                    >
                      <FaXmark className="text-center" />
                    </button>
                  </div>
                </>
              ) : null}

              {showEditUser && (
                <PopupEditUser currentUser={selectedUser} onClose={() => setShowEditUser(false)} />
              )}
              {showPayment && (
                <PopupPayment currentUser={selectedUser} onClose={() => setShowPayment(false)} />
              )}
              {showDel && <PopupDel currentUser={selectedUser} onClose={() => setShowDel(false)} />}
            </div>
          );
        })}
      </div>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={filteredUsers.length}
        pageRangeDisplayed={7}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </div>
  );
}
