import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Home/Spinner";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
export default function BrandSlider() {
  // const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1190,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 828,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  /* =======================fetching data =========================*/
  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get("YOUR_API_ENDPOINT_HERE")
  //     .then(response => {
  //       setBrands(response.data);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       setError(error);
  //       setLoading(false);
  //     });
  // }, []);
  /* ======================fetching data ==========================*/

  // dummy data ,,,,
  const brands = [
    {
      img: "../../img/jobDetailsLogo.png",
      brandName: "AssortiMid Medical",
    },
    {
      img: '../../img/jobDetailsLogo.png',
      brandName: "Jhonson & Jhonson ",
    },
    {
      img: '../../img/jobDetailsLogo.png',
      brandName: "Pfizer",
    },
    {
      img: '../../img/jobDetailsLogo.png',
      brandName: "AssortiMid Medical",
    },
    {
      img: '../../img/jobDetailsLogo.png',
      brandName: "AssortiMid Medical",
    },
    {
      img: '../../img/jobDetailsLogo.png',
      brandName: "AssortiMid Medical",
    }
  ];

  return (
    <div style={{ paddingBottom: "25px" }}>
      <Slider {...settings}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          brands.map((brand, index) => (
            <div key={index}>
              <div className="card" style={{ width: "18rem" }}>
                <div className="card-body ml-2">
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      margin:'5px auto',
                    }}
                  >
                    <img style={{borderRadius: "50%"}} src={brand.img} alt="brand logo" />
                  </div>
                  <p
                    className="card-title"
                    style={{ fontSize: "13px", fontWeight: "700" , marginTop:'10px'}}
                  >
                    {brand.brandName}
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
}
