import React from 'react';
import styles from './BaseSign.module.css';
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  
  return (

    <div className={styles["my-component-container"]}>
    <div className={styles['left-col']}>
      <div className={styles['background-image']}>
        <div className={styles['header-container']}>
        <h2 className={styles['title']}>
        {t('signprocess.loginbtn')}</h2>
            <LoginForm />
        </div>
      </div>
    </div>
    <div className={styles["right-col"]}>
      <div className={styles['partially-covered-image']}>
      </div>
    </div>
  </div>
);
};
export default Login;