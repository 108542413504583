import React, { useState } from "react";

// Style Attributes
import { BiSolidAddToQueue } from "react-icons/bi";
import { LuClipboardEdit } from "react-icons/lu";
import { IoCopySharp } from "react-icons/io5";


// Tab Content
import NewDsm from "./dsm/NewDsm";
import EditDsm from "./dsm/EditDsm";
import CopyDsm from "./dsm/CopyDSM";

export default function DSMPage() {
  const [fillActive, setFillActive] = useState("AddDsm");

  const handleSelectChange = (event) => {
    setFillActive(event.target.value);
  };

  return (
    <>
    <div className="sm:hidden">
        <select id="tabs" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm   block w-full p-2.5" onChange={handleSelectChange}>
          <option value="AddDsm"><BiSolidAddToQueue/>Create DSM</option>
          <option value="EditDsm"><LuClipboardEdit/>Edit DSM</option>
          <option value="EditDsm"><LuClipboardEdit/>Copy DSM</option>
        </select>
      </div>
     <ul className="hidden text-lg font-semibold text-center text-gray-500 rounded-lg shadow sm:flex w-full mt-0">
     <li className="w-full">
        <a href="#" onClick={() => setFillActive("AddDsm")}
          className={`inline-block w-full p-3 border-b-4 ${fillActive === "AddDsm" ? "bg-gray-100 border-custom-green font-bold" : "border-gray-200 hover:bg-gray-100 hover:border-custom-green"} focus:outline-none`}>
          <span className={` ${fillActive === "AddDsm" ? "text-custom-green" : "text-gray-500 hover:text-custom-green"}`}>
            <BiSolidAddToQueue className="inline-block h-6 w-6 mr-3 align-middle"/>
          </span>
          <span className={`inline-block ${fillActive === "AddDsm" ? "text-custom-blue" : "text-gray-500 hover:text-custom-gray-900"}`}>
            Create DSM
          </span>
        </a>
      </li>
      <li className="w-full">
        <a href="#" onClick={() => setFillActive("EditDsm")}
          className={`inline-block w-full p-3 border-b-4 ${fillActive === "EditDsm" ? "bg-gray-100 border-custom-green font-bold" : "border-gray-200 hover:bg-gray-100 hover:border-custom-green"} focus:outline-none`}>
          <span className={` ${fillActive === "EditDsm" ? "text-custom-green" : "text-gray-500 hover:text-custom-green"}`}>
            <LuClipboardEdit className="inline-block h-6 w-6 mr-3 align-middle" />
          </span>
          <span className={`inline-block ${fillActive === "EditDsm" ? "text-custom-blue" : "text-gray-500 hover:text-custom-gray-900"}`}>
            Edit DSM
          </span>
        </a>
      </li>
      <li className="w-full">
        <a href="#" onClick={() => setFillActive("CopyDsm")}
          className={`inline-block w-full p-3 border-b-4 ${fillActive === "CopyDsm" ? "bg-gray-100 border-custom-green font-bold" : "border-gray-200 hover:bg-gray-100 hover:border-custom-green"} focus:outline-none`}>
          <span className={` ${fillActive === "CopyDsm" ? "text-custom-green" : "text-gray-500 hover:text-custom-green"}`}>
            <IoCopySharp className="inline-block h-6 w-6 mr-3 align-middle" />
          </span>
          <span className={`inline-block ${fillActive === "CopyDsm" ? "text-custom-blue" : "text-gray-500 hover:text-custom-gray-900"}`}>
            Copy DSM
          </span>
        </a>
      </li>
      </ul>
      <div style={{ display: fillActive === 'AddDsm' ? 'block' : 'none' }}>
        <NewDsm />
      </div>
      <div style={{ display: fillActive === 'EditDsm' ? 'block' : 'none' }}>
        <EditDsm />
      </div>
      <div style={{ display: fillActive === 'CopyDsm' ? 'block' : 'none' }}>
        <CopyDsm />
      </div>
    </>
  );
}