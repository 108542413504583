import React, { useState } from "react";


// Icon Imports
import { BiSolidReport } from "react-icons/bi";

// In ComBase component
export default function OrderSum({ orderRecord }) {

    // Items Section
    const pbasket = orderRecord.products? orderRecord.products : [];
    const packbasket = orderRecord.packages? orderRecord.packages : [];
    const dsmbasket = orderRecord.dsms? orderRecord.dsms : [];

    const totalOrder = (pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty ? item.qty : 2) * ((item.tax ? item.tax : 0.14) + 1)), 0) + (packbasket? (250.00 + (250.00 * 0.14)):0)).toFixed(2);
  
    return (
        orderRecord ?
            <div className="w-full mt-3 border-1 border-custom-green px-5 py-1 rounded-lg h-fit shadow-custom font-sans">
              <div className="my-3">
                <div className="grid grid-cols-2 gap-5 my-1 mb-3 text-lg font-bold">
                  <p>Order Number</p>
                  <span className="text-teal-600">{orderRecord.order_number? orderRecord.order_number : '00000001'}</span>
                </div>

                <div className="grid grid-cols-2 gap-5 my-1 text-lg font-bold">
                  <span>Order Date</span>
                  <span>Order Status</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-3 font-bold text-teal-600">
                  <span>{orderRecord.order_date}</span>
                  <span>{orderRecord.order_status.charAt(0).toUpperCase() + orderRecord.order_status.slice(1)}</span>
                </div>

                <div className="grid grid-cols-2 gap-5 my-1 text-lg font-bold">
                  <span>Order Type</span>
                  <span>Order Total</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-bold text-teal-600">
                  <span>{orderRecord.order_type.charAt(0).toUpperCase() + orderRecord.order_type.slice(1)}</span>
                  <span>€ {totalOrder}</span>
                </div>

              </div>
          </div> : null 
    );
  }