import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

// Local Imports
import ProductItem from './ProductItem';

const DSMItem = ({ dsm }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const { name, creationDate, productCount, lastreview, status, products } =
    dsm;

  return (
    <>
      <div className="relative mt-4 flex w-full flex-row rounded border bg-white p-2 shadow">
        <div className="w-100 flex w-full flex-col justify-center p-1 sm:flex-row sm:items-center sm:justify-center">
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">DSM Name</p>
            <span className="text-sm text-gray-500">{name}</span>
          </div>
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Creation Date</p>
            <span className="text-sm text-gray-500">{creationDate}</span>
          </div>
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Product Count</p>
            <span className="text-sm text-gray-500">{productCount}</span>
          </div>
        </div>

        <div className="w-100 flex w-full flex-col justify-center p-1 sm:flex-row sm:items-center sm:justify-center">
          <div className="flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Latest Review Date</p>
            <span className="text-sm text-gray-500">{lastreview}</span>
          </div>
          {/** Status Btn Section */}
          <div className="mr-12 flex w-full flex-row space-x-4 p-1 text-sm font-bold">
            {status === 'active' && (
              <button className="w-75 rounded-full border-2 bg-green-400 p-2 text-white hover:bg-green-500">
                Active
              </button>
            )}
            {status === 'none' && (
              <button className="w-75 rounded-full border-2 bg-red-400 p-2 text-white hover:bg-red-500">
                Non Active
              </button>
            )}
            {status === 'paused' && (
              <button className="w-75 rounded-full border-2 bg-orange-300 p-2 text-white hover:bg-orange-400">
                Paused
              </button>
            )}
          </div>
        </div>

        <button
          className="absolute inset-y-0 right-0 mr-4 focus:outline-none"
          onClick={toggleExpanded}
        >
          <IoIosArrowDown />
        </button>
      </div>

      {/**Display Products upon Expanding */}
      {isExpanded && (
        <div className="w-100 container mx-auto overflow-auto pb-8">
          {products &&
            products.map((product) =>
              product ? (
                <ProductItem key={product.name} product={product} />
              ) : null,
            )}
        </div>
      )}
    </>
  );
};

export default DSMItem;
