import React, { useState } from 'react';
import Select from 'react-select';

// Icons Imports
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { MdApartment } from 'react-icons/md';
import { MdOutlineDateRange } from 'react-icons/md';
import { GrStatusGood } from 'react-icons/gr';
import { AiOutlineRedo } from 'react-icons/ai';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { TbBasketPause } from 'react-icons/tb';
import { MdOutlineMotionPhotosOff } from 'react-icons/md';
import { IoMdPricetags } from 'react-icons/io';
import { TbTruckDelivery } from 'react-icons/tb';
import { TiInfoLargeOutline } from 'react-icons/ti';
import { PiWifiLowBold } from 'react-icons/pi';
import { PiWifiHighBold } from 'react-icons/pi';
import { CgDollar } from 'react-icons/cg';
import { BsCalendarDateFill } from "react-icons/bs";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdContactMail } from "react-icons/md";
import { MdContactPhone } from 'react-icons/md';
import { MdContacts } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { AiTwotoneProfile } from "react-icons/ai";





// Select Style
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #2849ad' : '2px solid #d1d5db',
    borderRadius: '4px',
    ':hover': {
      border: '2px solid #d1d5db',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#07b1ab60',
    },
    ':first-child': {
      backgroundColor: '#07b1ab60',
    },
  }),
};

const InfoDel = () => {
  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
  console.log(user);


// List All Payment Profiles by Name
const options = user.delivery_data.map((data, index) => ({
  label: `Syrgery Closet ${index + 1}`,
  value: data
}));

const [showEditForm, setShowEditForm] = useState(true);
const [selectedDsm, setSelectedDsm] = useState(options[0].value);
const [selectedDsmOption, setSelectedDsmOption] = useState(options[0]);

const handleProductSelect = (selectedDsmOption) => {
  setSelectedDsmOption(selectedDsmOption);
  setSelectedDsm(selectedDsmOption.value);
  setShowEditForm(true);
};


  // Fill In Payment Profiles
const rows = [
  {
    icon: <AiTwotoneProfile />,
    label: "Profile Name",
    info: selectedDsmOption.del_name? selectedDsmOption.del_name : 'NA',
  },
  {
    icon: <FaMoneyBillAlt />,
    label: "Route number",
    info: selectedDsm.route_number,
  },
  {
    icon: <MdContacts />,
    label: "Contact Personal name",
    info: selectedDsm.contact_person_name,
  },
  {
    icon: <MdContactMail />,
    label: "Contact Personal email",
    info: selectedDsm.contact_person_email,
  },
  {
    icon: <MdContactPhone />,
    label: "Contact Personal mobile",
    info: selectedDsm.contact_person_phone,
  },
  {
    icon: <AiFillBank />,
    label: "Delivery Department",
    info: selectedDsm.ddept,
  },
  {
    icon: <AiFillBank />,
    label: "Delivery Address",
    info: selectedDsm.daddress,
  },
  {
    icon: <AiFillBank />,
    label: "Standard Delivary Address",
    info: selectedDsm.sdaddress,
  },
  {
    icon: <FaMapLocationDot />,
    label: "Postal code",
    info: selectedDsm.dpostal,
  },
  {
    icon: <FaMapLocationDot />,
    label: "City",
    info: selectedDsm.dcity,
  },
];

  return (
    <>
    {options.length > 1 && (
      <>
      <h2 className="mx-auto mt-10 mb-10 text-center text-xl font-semibold">
        Choose a Payment Profile to Display
      </h2>

    <div className="mx-auto mb-12 flex w-[50%] flex-col justify-around gap-14 sm:gap-6 lg:flex-row">
        <Select
          options={options}
          styles={customStyles}
          className="m-auto w-full"
          onChange={handleProductSelect}
          value={selectedDsmOption}
        />
      </div>
      </>
    )}


    {showEditForm && (
    <table className="table-auto w-full">
      <tbody>
        {rows.map((row) => (
          <tr key={row} className='h-14'>
            <td className="w-1/4 px-20 py-2 text-left bg-teal-100-accent"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <div className="flex items-center">
              <span className='text-base font-bold text-custom-green'>
                {row.icon}
                </span>
              
              <span className="ml-3 font-semibold"
                    style={{fontSize: "16px"}}>
                {row.label}
                </span>
            </div>
          </td>
            <td className="border-b px-20 py-2 text-right">
            <span className="ml-2 font-semibold"
                  style={{fontSize: "16px"}}>
              {row.info}
              </span>
              </td>
          </tr>
        ))}
      </tbody>
    </table>
  )}
    </>
  );
};

export default InfoDel;