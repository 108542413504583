import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

// Order status icons
import { MdLoop } from 'react-icons/md';
import { HiCheck } from 'react-icons/hi';
import { BsArrowClockwise } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';

// Local Imports
import ProductItem from './ProductItem';

const OrderItem = ({ invoice }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };


  const products = invoice?.products;

  return (
    <>
      <div
        className="relative mt-4 flex w-full flex-row rounded border bg-white p-3 shadow"
      >
        <div className="mt-1 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-1 text-xs font-bold">Order Number</p>
            <span className="text-sm text-gray-500">{invoice.order_number}</span>
          </div>
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-1 text-xs font-bold">Order Date</p>
            <span className="text-sm text-gray-500">{invoice.order_date}</span>
          </div>
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-1 text-xs font-bold">Order Total</p>
            <span className="text-sm text-gray-500">{invoice.order_total}</span>
          </div>
        </div>

        <div className="mt-1 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-1 text-xs font-bold">Invoice Number</p>
            <span className="text-sm text-gray-500">{invoice.invoice_number ? invoice.invoice_number : 'NA'}</span>
          </div>
          <div className="flex w-full flex-col">
            <p className="mb-1 text-xs font-bold">Order Status</p>
            <span className="text-sm text-gray-500">{invoice.order_status}</span>
          </div>
          <div className="mr-16 flex flex-col">
            <span className="text-4xl font-bold text-white">
              {(['in_progress', 'in_wharehouse', 'on_the_road', 'waiting', 'delayed'].includes(invoice.order_status)) && (
      <MdLoop className="rounded-full bg-orange-300 p-1" />
    )}
              {(['recieved', 'delivered', 'completed', 'parthly_delivered'].includes(invoice.order_status)) && (
                <HiCheck className="rounded-full bg-green-400 p-1" />
              )}
              {(['returned', 'partially_returned'].includes(invoice.order_status)) && (
                <BsArrowClockwise className="rounded-full bg-gray-400 p-1" />
              )}
              {(['cancelled', 'missing'].includes(invoice.order_status)) && (
                <RxCross2 className="rounded-full bg-red-400 p-1" />
              )}
            </span>
          </div>
          <button
            className="absolute inset-y-0 right-0 mr-4 focus:outline-none"
            onClick={toggleExpanded}
          >
            <IoIosArrowDown />
          </button>
        </div>
      </div>

      {/**Display Products upon Expanding */}
      {isExpanded && (
        <div className="w-100 container mx-auto overflow-auto pb-8">
          {products &&
            products.map((product) =>
              product ? (
                <ProductItem key={product.name} product={product} />
              ) : null,
            )}
        </div>
      )}
    </>
  );
};

export default OrderItem;
