import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";

import styles from './ContactForm.module.css';

import { useTranslation } from "react-i18next";

export default function ContactForm() {
  const [formStatus, setFormStatus] = useState(null);
  //const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      message: "",
    },
    onSubmit: (values, { resetForm }) => {
      axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/contact-us-admin-notice/`, values, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        }
    })
        .then(response => {
          // console.log('Success:', response.data);
          setFormStatus('Success');
          resetForm();
          setTimeout(() => setFormStatus(null), 60000); 
        })
        .catch((error) => {
          //console.error('Error:', error);
          setFormStatus('Error');
          setTimeout(() => setFormStatus(null), 60000); 
        });
    },
  });

  const { t } = useTranslation();

  return (
    <div>
      <h5>{t('contact-us.F-title')}</h5>
      <p style={{fontWeight: '600',
                fontSize: '14px', marginLeft: '5%'}}>
          {t('contact-us.F-subtitle')}</p>
      <form onSubmit={formik.handleSubmit}>
        <div className={`row ${styles.rowMargin}`}>
          <div className={`col mb-2 ${styles.rowMargin}`}>
            <input
              onChange={formik.handleChange}
              value={formik.values.name}
              className={`form-control ${styles.inputBox}`}
              placeholder={t('contact-us.F-name')}
              name="name"
              required
            />
          </div>
          <div className={`col mb-2 ${styles.rowMargin}`}>
            <input
              onChange={formik.handleChange}
              value={formik.values.name}
              className={`form-control ${styles.inputBox}`}
              placeholder={t('contact-us.F-surname')}
              name="surname"
              required
            />
          </div>
          </div>
          <div className={`row ${styles.rowMargin}`}>
          <div className={`col mb-2 ${styles.rowMargin}`}>
            <input
              onChange={formik.handleChange}
              value={formik.values.phone}
              type="tel"
              className={`form-control ${styles.inputBox}`}
              placeholder='Phone number'
              name="phone"
              required
            />
          </div>
          <div className={`col mb-2 ${styles.rowMargin}`}>
            <input
              onChange={formik.handleChange}
              value={formik.values.email}
              type="email"
              className={`form-control ${styles.inputBox}`}
              placeholder={t('contact-us.F-email')}
              name="email"
              required
            />
          </div>
          </div>
          <div className={`row ${styles.rowMargin}`}>
          <div className={`mb-2 ${styles.rowMargin}`}
                style={{width: '98%'}}>
            <textarea
              onChange={formik.handleChange}
              value={formik.values.message}
              className={`form-control ${styles.inputBox}`}
              id="exampleFormControlTextarea1"
              placeholder={t('contact-us.F-message')}
              rows="4"
              name="message"
              required
            ></textarea>
          </div>
          </div>
     
        <div className={styles.divBtn}>
        <button className={`btn btn-primary col-10 ${styles.button3D}`} type="submit">
          {t('contact-us.send-btn')}
        </button>
        </div>
      </form>
      {formStatus === 'Success' && 
      <div className={styles.successMessage}>
        Message sent successfully!
      </div>}
      {formStatus === 'Error' && 
      <div className={styles.failureMessage}>
        Error sending message..
      </div>}
    </div>
  );
}