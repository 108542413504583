import React, { useState } from 'react';
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { useFormik } from "formik";
import axios from 'axios';
// Validation Schemes
import * as Yup from 'yup';

// Local Imports
import '../creationPopupStyle.css';
import '../../components/Sign/Login.css';


// Icon Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { BsFillEyeFill } from 'react-icons/bs';






const personalInfoValidationSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Password is too short - should be 8 chars minimum.').required('Password is required'),
  passwordconfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password confirm is required'),
});



function PopupPass({ onClose }) {

    // Data from Local Storage
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
    //console.log(user);
    const accessToken = localStorage.getItem('accessToken');
    console.log("accessToken");
    console.log(accessToken);
  
  const formik = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      passwordconfirm: '',
    },
    validationSchema: personalInfoValidationSchema,
    onSubmit: async values => {
      try {
        const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/update-payment/${values.pk}/`, values, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
    
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
  });


  // Handling Password visibility
  const [inputType, setInputType] = useState("password");
  const handleClickShowPassword = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <div className="creation_popup">
      <Modal show={true} onHide={onClose}>
        <Modal.Header className="flex-row-reverse bg-neutral-50">
        <button
            className="btn text-white bg-custom-green hover:bg-[#07b1ab] border-none"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className="text-2xl m-auto font-sans font-semibold">
            Change your Password
          </h2>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={formik.handleSubmit} className="bg-white">
          <InputGroup className="form-group mb-3">
              <FormControl
                type={inputType}
                name="password"
                placeholder='Type old Password'
                className="form-control inputArea"
                value={formik.values.old_password}
                onChange={formik.handleChange}
              />
              <Button 
                variant="outline-secondary" 
                onClick={handleClickShowPassword} 
                className="inputArea button-no-border"
                type="button"
              >
                <BsFillEyeFill />
              </Button>
            </InputGroup>

          <InputGroup className="form-group mb-3">
            <FormControl
              type={inputType}
              name="password"
              placeholder='Type new Password'
              className="form-control inputArea"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <Button 
              variant="outline-secondary" 
              onClick={handleClickShowPassword} 
              className="inputArea button-no-border"
              type="button"
            >
              <BsFillEyeFill />
            </Button>
          </InputGroup>
          {formik.errors.password && <div style={{color: 'red'}}>{formik.errors.password}</div>}

          <InputGroup className="form-group mb-3">
            <FormControl
              type={inputType}
              name="passwordconfirm"
              placeholder='Confirm new Password'
              className="form-control inputArea"
              value={formik.values.passwordconfirm}
              onChange={formik.handleChange}
            />
            <Button 
              variant="outline-secondary" 
              onClick={handleClickShowPassword} 
              className="inputArea button-no-border"
              type="button"
            >
              <BsFillEyeFill />
            </Button>
          </InputGroup>
          {formik.errors.passwordconfirm && <div style={{color:'red'}}>{formik.errors.passwordconfirm}</div>}

          <Button
            variant="primary"
            type="submit"
            className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
          >
            Change Password
          </Button>
        </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PopupPass;
