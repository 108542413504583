import React, { useEffect } from 'react';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import ConfirmR from '../../components/Sign/ConfirmR';
import useLogged from '../../hooks/useLogged';

export default function ConfirmRPage() {
  useLogged();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav/>
         <ConfirmR />
        <Footer/>
    </>
  )
}
