// components/UShop/UserReviewSlider.jsx
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FaRegComment } from 'react-icons/fa6';
import Spinner from '../Home/Spinner';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default function UserReviewSlider({ handleChat }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1190,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 828,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const reviews = [
    {
      review: "I buy this product because it is very useful for me medically.",
      comment: 12,
      user: "Robet K",
    },
    {
      review: "I buy this product because it is very useful for me medically.",
      comment: 2,
      user: "Robet K",
    },
    {
      review: "I buy this product because it is very useful for me medically.",
      comment: 3,
      user: "Robet K",
    },
    {
      review: "I buy this product because it is very useful for me medically.",
      comment: 1,
      user: "Robet K",
    },
  ];

  return (
    <div style={{ margin: '30px 0' }}>
      <Slider {...settings}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          reviews.map((review, index) => (
            <div key={index}>
              <div
                className="card"
                style={{ width: '18rem', cursor: 'pointer' }}
                onClick={() => handleChat(review)}>
                <div className="card-body ml-2">
                  <span style={{ fontSize: '30px', color: '#2849ad' }}>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                  </span>
                  <p className="card-title" style={{ fontSize: '14px', fontWeight: '400' }}>
                    {review.review}
                  </p>
                  <div className="d-flex justify-content-between">
                    <div
                      style={{
                        fontSize: '15px',
                        color: '#a6a7a8',
                        alignItems: 'center',
                      }}
                      className="d-flex"
                    >
                      <p style={{ fontSize: '14px', paddingRight: '5px' }}>{review.comment}</p>
                      <FaRegComment />
                    </div>
                    <p style={{ fontSize: '13px' }}> - {review.user} </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
}
