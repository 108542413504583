import React from 'react';
import styles from './BaseSign.module.css';
import SignupForm from './SignUpForm';

const Signup = () => {
  return (

    <div className={styles["my-component-container"]}>
    <div className={styles['left-col']}>
      <div className={styles['background-image']}>
        <div className={`${styles['header-container']} ${styles['signup']}`}>
          <SignupForm />
        </div>
      </div>
    </div>
    <div className={styles["right-col"]}>
      <div className={styles['partially-covered-image']}>
      </div>
    </div>
  </div>
);
};
export default Signup;