import React, { useEffect } from 'react';

// Local Imports
import useAuth from "../hooks/useAuth.js";
import DashboardBase from './DashboardSTruct';


export default function ClientBoard() {
  useAuth();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
         <DashboardBase/>
    </>
  )
}
