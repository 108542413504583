import React, { useState } from 'react';
import Pagination from 'react-js-pagination';

// Icons
import { IoIosArrowDown } from 'react-icons/io';

// Local Imports
import DSMItem from './DSMItem';

const dsmData = [];

const DSMsTable = () => {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // Filtration SEction controls
  const [activeItem, setActiveItem] = useState('DSMs');

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const items = ['DSMs', 'ACTIVE DSMs', 'NonACTIVE DSMs', 'PAUSED DSMs'];

  return (
    <>
      {/**Filtration section Orders Focus */}
      <div className="w-100 container sticky top-0 border-b-2 border-gray-200 bg-white p-2">
        <ul className="w-100  flex font-bold ">
          {items.map((item, index) => (
            <li key={index} className="flex-1">
              <a
                className={`block rounded border-b-2 border-transparent  text-center text-xs sm:text-base
                ${activeItem === item ? 'font-bold text-gray-700' : 'text-gray-400'} 
                 hover:font-bold hover:text-gray-700`}
                onClick={() => handleItemClick(item)}
                href="#"
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Filtration section Apply filter button */}
      <div className="sticky top-0 z-50 flex w-full flex-wrap items-center justify-evenly border-b-2 border-gray-300 bg-white p-3 shadow-md">
        <div className="flex w-full justify-around md:w-[46%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
              <option value="">DSM name</option>
            </select>
          </div>
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
              <option value="">Creation Date</option>
            </select>
          </div>
        </div>
        <div className="flex w-full justify-around md:w-[44%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
              <option value="">Last Reviewd</option>
            </select>
          </div>
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <input
              type="number"
              className="w-full rounded border border-transparent bg-gray-200 p-1 px-2 outline-none"
              placeholder="Max Product Count"
            />
          </div>
        </div>
        <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
          <button className="text-md w-full rounded-lg bg-custom-blue px-6 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue sm:w-auto">
            Filter
          </button>
        </div>
      </div>

      {/**Display User / Filtered Orders */}
      <div className="w-100 container mx-auto overflow-auto pb-8">
        {dsmData &&
          dsmData
            .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
            .map((dsm) => (dsm ? <DSMItem key={dsm.name} dsm={dsm} /> : null))}
      </div>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={dsmData.length}
        pageRangeDisplayed={7}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </>
  );
};

export default DSMsTable;
