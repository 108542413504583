import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import Select from 'react-select';
import axios from 'axios';


// Local Imports
import '../creationPopupStyle.css';


// Icon Imports
import { CgDollar } from 'react-icons/cg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';



// Select Styling
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #F87171' : '2px solid #F87171',
    borderRadius: '4px',
    ':hover': {
      border: '2px solid #F87171',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#F87171',
    },
  }),
};

function PopupDel({ onClose, currentUser }) {

    // Data from Local Storage
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
  


// Assign Payment Profile API
const handleAssignClick = async () => {
  try {
    const postData = {
      delivery_profile: selectedDel.value.id
    }
    //console.log(postData);
    const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/user/${currentUser.id}/update/`, 
      postData
    );
    //console.log(response.data);
    console.log("Delivery Profile Assigned Successfully!");
    onClose();
  } catch (error) {
    console.error("There was an error assigning delivery profile!", error);
  }
};


// List All Delivery Profiles by Name
const options = user.delivery_data.map((data, index) => ({
  label: data.del_name ? data.del_name : data.daddress,
  value: data
}));

  // console.log("Options DEL Profiles:  ");
  // console.log(options);

  const [selectedDel, setSelectedDel] = useState(null);
  const [selectedDelOption, setSelectedDelOption] = useState(null);

  const handleDeliverySelect = (selectedDelOption) => {
    setSelectedDelOption(selectedDelOption);
    setSelectedDel(selectedDelOption);
  };

  return (
    <div className="creation_popup">
      <Modal show={true} onHide={onClose}>
        <Modal.Header className="flex-row-reverse bg-neutral-50">
        <button
            className="btn text-white bg-red-400 border-none hover:bg-red-500"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className="text-2xl m-auto font-sans font-semibold ">
            Assign new Delivery Profile
          </h2>
        </Modal.Header>
        <Modal.Body className="flex items-start h-56">
          <Select
            options={options}
            styles={customStyles}
            className="mt-2 m-auto w-2/4"
            placeholder="Choose ..."
            onChange={handleDeliverySelect}
            value={selectedDelOption}
          />
          <button onClick={handleAssignClick}
            className="px-4 py-3 font-bold text-red-500 text-base border-2 rounded-lg border-red-400 shadow-sm hover:text-white hover:bg-red-400 flex items-center mr-6" 
          >
            Assign
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PopupDel;
