// import { Link } from "react-router-dom";
import styles from "./Homepage.module.css";
import PageNav from '../../components/Home/PageNav';
import Footer from "../../components/Home/Footer";
import LandingPage from "./LandingPage";

export default function Homepage() {
    return (
        <main className={styles.homepage}>
            <PageNav/>
            <LandingPage/>
            <Footer/>
        </main>
    );
}
