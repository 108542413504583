import React, { useState } from 'react';
import ProductContext from './ProductContext';

const ProductContextProvider = ({ children }) => {
  const [data, setData] = useState(null);

  return (
    <ProductContext.Provider value={{ data, setData }}>
      {children}
    </ProductContext.Provider>
  );
}

export default ProductContextProvider;