import React, { useEffect, useState, useContext } from 'react';
import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './JobDetails.module.css';
import Button from '../../components/Home/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';


import FindJob from '../../components/Career/FindJob';
import JobSlider2 from '../../components/Career/JobSlider2';
import ApplyPopup from '../../components/Career/ApplyPopup';
import CareerContext from '../../context/CareerContext';
import { useTranslation } from 'react-i18next';



const JobDetails = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { data, setData } = useContext(CareerContext);
    // console.log("Transfered data ....");
    // console.log(data.perks);
    
    function handleOpen() {
        if (!isOpen) {
            // setIsOpen((show) => !show);
            setIsOpen(true);
        }
    }

    // Save data to local storage whenever it changes
    useEffect(() => {
            localStorage.setItem('data', JSON.stringify(data));
        }, [data]);
    
    // Load data from local storage when component mounts
    useEffect(() => {
            const savedData = localStorage.getItem('data');
            if (savedData) {
                // Update your context with the saved data here
            }
        }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      

    return (
        <>
            <PageNav />
            <div className={styles.topDetails}>
                <div className={styles.topDetailsContent}>
                <div style={{ 
                    width: '60px', 
                    height: '60px', 
                    backgroundColor: '#fff', 
                    borderRadius: '50%', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center' 
                }}>
                    <img src='./img/colored-4l-100.png' alt='logo img'
                        width='50px' height='50px' />
                </div>
                    <div className={styles.topDetailsText}>
                        <span>{data ? data.job_name : 'Loading...'}</span>
                        <h4>{data ? `${data.seniority_level} ${data.category}` : 'Loading...'}</h4>
                    </div>
                </div>
            </div>
            <section className={`container ${styles.details}`}>
                <div className={styles.detailOne}>
                    <h5> {t('career.job8')}</h5>
                    <ul>
                        {data?.perks ? data.perks.map(item => (
                            <li key={item}>
                                <span><FontAwesomeIcon icon={faCircleCheck} /></span>
                            {item}</li>
                        )): 'Loading...'}
                    </ul>
                </div>
                <div className={styles.detailTwo}>
                    <h5> {t('career.job9')}</h5>
                    <p> {t('career.p11')}</p>
                    <p> {t('career.p12')}</p>
                    <p> {t('career.p13')}</p>
                    {isOpen && <ApplyPopup isOpen={isOpen} setIsOpen={setIsOpen} />}
                    <Button onClick={handleOpen}
                        style={{ fontSize: '12px', padding: '8px 50px 8px 50px', backgroundColor: '#00477b' }} >Apply</Button>

                    <Button style={{ fontSize: '12px', padding: '8px ' }}> <svg fill='#fff' id="Capa_1" enableBackground="new 0 0 512.231 512.231" height="20" viewBox="0 0 512.231 512.231" width="20" xmlns="http://www.w3.org/2000/svg"><g><path d="m271.249 121.448c-70.515 3.65-136.443 33.004-187.025 83.587-54.152 54.151-83.975 125.899-83.975 202.025v105.171l38.098-87.825c44.499-88.669 134.739-147.458 232.902-152.927v121.202l240.733-196.638-240.733-196.043zm30 29.612v-87.94l163.267 132.958-163.267 133.361v-88.379h-15c-56.878 0-112.732 15.993-161.522 46.249-36.948 22.913-68.428 53.121-92.593 88.604 15.408-126.529 123.493-224.853 254.115-224.853z" /></g></svg></Button>
                </div>
            </section>

            <div className={styles.bottomDetail}>
                <h4> {t('career.job10')}</h4>
                <div className={styles.jobSlider}><JobSlider2 /></div>
            </div>
            <FindJob />
            <Footer />
        </>
    )
}

export default JobDetails;
