import React from 'react';
import { useDispatch } from 'react-redux';
import { addToBasket } from '../../features/basket/basketSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoClose } from 'react-icons/io5';

function BuyPopup({ isOpen, setIsOpen, product, selectedProduct }) {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      selectedProduct,
      purpose: '',
    },
    validationSchema: Yup.object({
      purpose: Yup.string(),
    }),
    onSubmit: (values) => {
      //console.log("Added Product: ");
      //console.log(values);
      const newProduct = { ...product, quantity: 1, purpose: values.purpose };
      dispatch(addToBasket(newProduct));
      setIsOpen(false);
    },
  });

  if (!isOpen) {
    return null;
  }
  return (
    <div className="absolute left-[50px] top-48 z-10 h-auto w-[280px] rounded-xl border border-[#ccc] bg-white p-[10px] shadow-lg">
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <button onClick={() => setIsOpen(false)} className="float-end m-1">
            <IoClose className="text-red-600 " />
          </button>
          <div className="mb-1">
            <div className="input-group-sm m-2">
              <label
                style={{ fontSize: '12px', fontWeight: '700' }}
                htmlFor="purpose">
                What is your purpose for purchasing <br />
                this product?
                <br />
              </label>
              <textarea
                name="purpose"
                id="purpose"
                className="form-control mt-1 rounded-xl p-[10px] text-xs"
                placeholder="Optional"
                rows="5"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose}
              ></textarea>
              {formik.touched.purpose && formik.errors.purpose ? (
                <div className="text-xs text-red-700">
                  {formik.errors.purpose}
                </div>
              ) : null}
            </div>
            <button
              type="submit"
              className="submit font-xs mx-auto my-0 w-[94%] rounded-sm bg-custom-blue font-semibold">
              Add to Basket
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BuyPopup;
