import React, { useState } from "react";
import styles from "../../pages/UShop/ReviewProduct.module.css";

export default function Rating() {
  const [visibleRatings, setVisibleRatings] = useState(4);

  function generateStars(rating) {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return (
      "★".repeat(fullStars) + (halfStar ? "★" : "") + "☆".repeat(emptyStars)
    );
  }

  const ratings = [
    {
      stars: 4,
      user: "cmexpert",
      comment:
        "Outstanding course now happy Let Us Know What You Thought About: Build A Professional Website With ComeForWork.Com nice",
    },
    {
      stars: 3.5,
      user: "sissiwedgwood",
      comment: "Pretty complete for building a basic WP site",
    },
    {
      stars: 3,
      user: "Oladiran Mary",
      comment:
        "It's amazing learning from Marjorie. From ComeForWork to Content Strategy, SEO and Marketing.... I was blessed beyond measure through her wealth of experience.",
    },
    {
      stars: 2,
      user: "harryferguso673",
      comment:
        "Information was very clear and easily implemented. Was able complete the course in two sittings without being stressed and overwhelmed.",
    },
    {
      stars: 2,
      user: "harryferguso673",
      comment:
        "Information was very clear and easily implemented. Was able complete the course in two sittings without being stressed and overwhelmed.",
    },
    {
      stars: 2,
      user: "harryferguso673",
      comment:
        "Information was very clear and easily implemented. Was able complete the course in two sittings without being stressed and overwhelmed.",
    },
    {
      stars: 2,
      user: "harryferguso673",
      comment:
        "Information was very clear and easily implemented. Was able complete the course in two sittings without being stressed and overwhelmed.",
    },
  ];

  const loadMore = () => {
    setVisibleRatings((prevVisibleRatings) => prevVisibleRatings + 2);
  };
  const loadLess = () => {
    setVisibleRatings(4);
  };

  return (
    <div className={styles.rating}>
      <div className={styles.reviewProductContainer}>
        <h2>938 Reviews</h2>
        <div className={styles.starContainer}>
          <div className={styles.wholeRating}>{generateStars(5)}</div>
          <span>4.7</span>
        </div>

        {ratings.slice(0, visibleRatings).map((rating, index) => (
          <div key={index} className={styles.userRating}>
            <div className={styles.starContainer}>
              <div className={styles.stars}>{generateStars(rating.stars)}</div>
              <span>{rating.user}</span>
            </div>
            <p className={styles.clintComment}>{rating.comment}</p>
          </div>
        ))}

        {visibleRatings < ratings.length ? (
          <button className={styles.btnRating} onClick={loadMore}>
            Load more
          </button>
        ) : (
          <button className={styles.btnRating} onClick={loadLess}>
            Show less
          </button>
        )}
      </div>
    </div>
  );
}

/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 40 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 /52 / 62 / 74 / 86 / 98
*/

/*
FULL STAR

<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20"
  fill="#000"
  stroke="#000"
>
  <path
    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
  />
</svg>


EMPTY STAR

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="#000"
>
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="{2}"
    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
  />
</svg>

*/
