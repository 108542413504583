import { Button } from 'react-bootstrap';
// import { AiOutlineAudio, AiOutlineAudioMuted  } from "react-icons/ai";
// import { GoMute, GoUnmute } from "react-icons/go";
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
// import { BiSolidVolumeMute } from "react-icons/bi";
// import { VscUnmute } from "react-icons/vsc";
// import { BiExpand } from "react-icons/bi";
import { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';

export default function VBanner({ videoUrl, title }) {
  const [isMuted, setIsMuted] = useState(true);
  const [isTitleVisible, setIsTitleVisible] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const videoRef = useRef(null);

  const handleButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
    setIsMuted(!isMuted);
    if (isTitleVisible) {
      setIsTitleVisible(!isTitleVisible);
    }
  };


  const handleExpandButtonClick = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTitleVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []); 



  return (
    <div className="relative w-full h-[720px] aspect-video mt-1 mb-10">
      <Modal isOpen={modalIsOpen} 
                 onRequestClose={closeModal}
                 contentLabel="Video Modal"
                 style={{
                  overlay: {
                    backgroundColor: 'transparent'
                  },
                  content: {
                    width: '55%', 
                    height: '60%',
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '100px',
                    background: 'linear-gradient(to right, #7acfd3, #2849ad)'
                  }
                }}
            >
        <video width="100%" height="100%" controls autoPlay>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
      <video ref={videoRef} className="absolute inset-0 object-cover rounded-md w-full h-full" autoPlay muted={isMuted} loop>
        <source src={videoUrl} type="video/mp4" />
      </video>
      <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-20 p-6 rounded-t-lg">
      {isTitleVisible && (
        <div className="absolute inset-0 flex flex-col items-center justify-start p-10 text-center mt-16">
          <h1 className="text-4xl font-semibold tracking-tight mt-14"
              style={{ fontFamily: 'Georgia, serif', color: '#172961' }}>{title}</h1>
        </div>
      )}

        <div className="absolute bottom-0 right-0 grid gap-4 text-center p-4"  
              style={{ zIndex: 1000 }}>
          <Button size="lg" variant="light" className="border-0"
                   onClick={handleButtonClick}
                   style={{color: '#172961'}}>
              {isMuted ? <FaVolumeMute size="2rem"/> : <FaVolumeUp size="2rem"/>}
          </Button>
        </div>

        {/* <div className="absolute bottom-0 right-0 grid gap-4 text-center p-4"  
              style={{ zIndex: 1000 }}>
          <Button size="lg" variant="light" className="border-0"
                   onClick={handleExpandButtonClick}
                   style={{color: '#172961'}}>
            <BiExpand size="2rem"/>
          </Button> 
      </div> */}
      </div>
     
    </div>
  )
}