import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';

// Icons Imports
import { MdApartment } from 'react-icons/md';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { IoMdPricetags } from 'react-icons/io';
import { BsCalendarDateFill } from 'react-icons/bs';
import { MdLanguage } from 'react-icons/md';
import { FaSuitcase } from 'react-icons/fa';
import { FaEuroSign } from 'react-icons/fa';

const PopupFormPer = () => {
  
  // Data from Local Storage
  const accessToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole =
    user.user_data.role === 'HD'
      ? 'Head User'
      : user.role === 'M'
      ? 'Manager User'
      : 'Normal User';

  
  // Handling Form Operations
  const [formStatus, setFormStatus] = useState('');
  const [formValues, setFormValues] = useState({
    fullname: '',
    uphone: '',
    date_of_birth: '',
    department_name: '',
    department_code: '',
    function: '',
    language_preference: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("User Id DataTYpe: " + typeof user.user_data.id);
    setFormStatus('Loading...');
    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/update-user/${user.user_data.email}/`, 
      formValues
    );
      
      setFormStatus('Success!');
      //console.log(response.data);
    } catch (error) {
      setFormStatus('An error occurred.');
      //console.error(error);
    }
  };

  // Icon map
  const iconMap = {
    fullname: <LiaFileInvoiceDollarSolid/>,
    uphone: <IoMdPricetags />,
    date_of_birth: <BsCalendarDateFill />,
    department_name: <MdApartment />,
    department_code: <MdApartment />,
    function: <FaSuitcase />,
    language_preference: <MdLanguage />,
  };

    // Icon map
    const typeMap = {
      fullname: "text",
      uphone: "phone",
      date_of_birth: "date",
      department_name: "text",
      department_code: "text",
      function: "text",
      language_preference: "",
    };

  return (
    <Form onSubmit={handleSubmit} className="bg-white py-4 px-12">
      {Object.keys(formValues).map((key) => (
        <div
          key={key}
          className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center"
        >
          <span className="text-xl text-custom-green">{iconMap[key]}</span>
          <label className="popup_review_label">{key.replace(/_/g, ' ')}</label>
          <div className="grow">
            {key === 'language_preference' ? (
              <select
                className="input w-full"
                name={key}
                value={formValues[key]}
                onChange={handleInputChange}
              >
                <option value="">Select Language</option>
                <option value="en">English</option>
                <option value="du">Dutch</option>
                <option value="de">German</option>
              </select>
            ) : (
              <input
                className="input w-full"
                name={key}
                type={typeMap[key]}
                value={formValues[key]}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
      ))}
      <div className="form-status text-red-500 font-semibold text-sm flex items-center justify-center">
        {formStatus}
    </div>
      <Button
        variant="primary"
        type="submit"
        className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
      >
        Submit
      </Button>
    </Form>
  );
};

export default PopupFormPer;
