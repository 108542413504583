import React, { useState, useRef } from "react";
import Papa from 'papaparse';
import JSZip from 'jszip';
import axios from 'axios';

// Style Attributes
import { LuCopyPlus } from "react-icons/lu";
import { MdPermMedia } from "react-icons/md";
import { FiUploadCloud } from "react-icons/fi";
import { FcApproval } from "react-icons/fc";



export default function Product1() {


  // Products CSV file Upload
  const fileInput = useRef();
  const [message, setMessage] = useState(null);
  const product = JSON.parse(localStorage.getItem('products'))[0];
  const featureNames = Object.keys(product).filter(key => key !== 'id' && key !== 'assortimed_pid');
  

  const columnNames = ['Status', 'Column', 'Note'];
  const [data, setData] = useState([]);
  const [parsedData, setParsedData] = useState(null);

  const handlePUploadClick = () => {
    //console.log("Upload Clicked....");
    fileInput.current.click();
    //console.log("After Upload Clicked....");
  };

  const handlePFileChange = (event) => {
    setData([]);
    //console.log("File Change IN.....");
    const file = event.target.files[0];
    //console.log(file);
  
    // 1. Check if it's a CSV file
    if (file.type !== "text/csv") {
      setData(prevData => [...prevData, ['x Failed', 'all', 'File is not a CSV']]);
      return;
    }
    setData(prevData => [...prevData, ['✓ Success', 'all', 'File is a CSV']]);
  
    Papa.parse(file, {
      complete: function(results) {
        // 2. Check if it has 30 columns
        // id and assortimed_pid are out of the calculation.
        const has45Columns = results.data[0].length === 45;
        if (!has45Columns) {
          setData(prevData => [...prevData, ['x Failed', 'all', 'CSV must have 45 columns']]);
          return;
        }

        setData(prevData => [...prevData, ['✓ Success', 'all', 'File has 45 columns']]);
  
        // 3. Check if the first row matches a given list
        for (let index = 0; index < results.data[0].length; index++) {
          if (results.data[0][index] !== featureNames[index]) {
            setData(prevData => [...prevData, ['x Failed', `Col ${index + 1}`, `Must be ${featureNames[index]}`]]);
            return;
          }
        }
        setData(prevData => [...prevData, ['✓ Success', 'all', 'First row matches the required feature name list']]);
        setMessage("Products Data is successfully uploaded, Please upload media files...");
        // Store the parsed data in state
        setParsedData(results.data);

        // Reset the value of the input to allow re-uploads
        event.target.value = null;
  
      //   // If all checks pass, you can use the file for uploading
      //   console.log("Here!!!!" + message);

      //   // Save CSV File
      //   // Convert the parsed data back to CSV
      // const csv = Papa.unparse(results.data);

      // // Create a Blob from the CSV string
      // const blob = new Blob([csv], { type: 'text/csv' });

      // // Create a Blob URL
      // const url = URL.createObjectURL(blob);

      // // Create an anchor element with a download attribute and a href that points to the Blob URL
      // const a = document.createElement('a');
      // a.download = 'parsed.csv';
      // a.href = url;

      // // Append the anchor to the document and click it to start the download
      // document.body.appendChild(a);
      // a.click();

      // // Clean up: remove the anchor and revoke the Blob URL
      // document.body.removeChild(a);
      // URL.revokeObjectURL(url);
      }
    });
  };


  // Products Media file Upload
  const zfileInput = useRef();
  const [zmessage, zsetMessage] = useState(null);
  

  const mcolumnNames = ['Status', 'Type', 'Note'];
  const [mdata, msetData] = useState([]);
  
  // read ZIP file as Input
  const handleMUploadClick = () => {
    zfileInput.current.click();
  };

  const handleMFileChange = async (event) => {
    msetData([]);
    zsetMessage(null);
    const file = event.target.files[0];
  
    // 1. Check if it's a ZIP file
    if (file.type !== "application/zip" && file.type !== "application/x-zip-compressed") {
      msetData(prevData => [...prevData, ['x Failed', 'file', 'File is not ZIP']]);
      console.log('File is not a ZIP');
      return;
    }

    msetData(prevData => [...prevData, ['✓ Success', 'file', 'File is Zip']]);

    //console.log('parsedData:', parsedData[0]);
    //console.log('categories:', parsedData.map(row => row.category));
    const uniqueCategories = [...new Set(parsedData.slice(1).map(row => row[2]).filter(Boolean))];
    console.log('Unique categories' + uniqueCategories.length);
  
    const zip = await JSZip.loadAsync(file);
    const folderNames = Object.keys(zip.files).filter(name => zip.files[name].dir);
    const mainfolderNames = Object.keys(zip.files)
    .filter(name => zip.files[name].dir && name.split('/').length === 2)
    .map(name => name.endsWith('/') ? name.slice(0, -1) : name);

    // console.log("Main Folder Names:");
    // for (const name of mainfolderNames){
    //   console.log(name);
    // }
  
    // 2. Check if there are 5 folders
    if (mainfolderNames.length !== uniqueCategories.length) {
      msetData(prevData => [...prevData, ['x Failed', 'category', `Number of Folders don\'t match the number of unique categories ${uniqueCategories.length} != ${mainfolderNames.length} in the Products file you uploaded.`]]);
      //console.log('ZIP must have 5 folders');
      return;
    }

    msetData(prevData => [...prevData, ['✓ Success', 'category', 'Number of Folders match the number of unique categories in the Products file you uploaded']]);
  
    // 3. Check if the folder names match a given list
    uniqueCategories.forEach(name => {
      if (!mainfolderNames.includes(name)) {
        msetData(prevData => [...prevData, ['x Failed', 'category', `Category ${name} dosen\'t have a folder with the same name`]]);
      }
    });

    msetData(prevData => [...prevData.slice(0,-1), ['✓ Success', 'category', 'All Categories have folders with the same name']]);
  
    
    // unique category product name map
    const categoryProductMap = parsedData.slice(1).reduce((acc, curr) => {
      const category = curr[2];
      const productName = curr[1]; 
    
      if (category && !acc[category]) {
        acc[category] = [];
      }
    
      if (category) {
        acc[category].push(productName);
      }
    
      return acc;
    }, {});

    console.log(categoryProductMap);
    
    // 4. Check the subfolders in each folder
    for (const folderName of mainfolderNames) {
      console.log("Folder Name:  " + folderName);
      const subfolderNames = Object.keys(zip.files)
    .filter(name => name.startsWith(folderName + '/') && zip.files[name].dir)
    .map(name => name.replace(folderName + '/', '').replace(/\/$/, ''))
    .filter(name => name !== '');

      const givenSubfolderNames = categoryProductMap[folderName] || [];

      console.log("givenSubfolderNames: ");
      for (const name of subfolderNames) {
        console.log(name);
      }

      // Check if the lengths of subfolderNames and givenSubfolderNames are equal
      if (subfolderNames.length !== givenSubfolderNames.length) {
        msetData(prevData => [...prevData, ['x Failed', 'products', `Category ${folderName} have ${subfolderNames.length} subfolders, it must have ${givenSubfolderNames.length} subfolders, one for each product.`]]);
        //console.log(`Number of subfolders in ${folderName} do not match the given list`);
        return;
      }

      msetData(prevData => [...prevData, ['✓ Success', 'category', `Category ${folderName} has ${givenSubfolderNames.length} subfolders}`]]);

      // Check if every name in givenSubfolderNames has a corresponding subfolder
      for (const name of givenSubfolderNames) {
        if (!subfolderNames.includes(name)) {
          msetData(prevData => [...prevData, ['x Failed', 'products', `Product ${name} dosen't have a folder inside ${folderName}.`]]);
          return;
        }
      }
      msetData(prevData => [...prevData.slice(0,-1), ['✓ Success', 'category', `Category ${folderName} has a subfolder for each product}`]]);
    }

    msetData(prevData => [...prevData.slice(0,-uniqueCategories.length), ['✓ Success', 'all', 'All products media are available.']]);
    zsetMessage("Products Media is successfully uploaded, press add to database to save new products.");
  
    //console.log('ZIP file is valid');
  };


  // Post Products Data to Database and save products media in Disk
  const postProductsData = async () => {
    try {
      const response = await axios.post('http://your-api-url.com', {
        // your data here
      });
  
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full h-full flex flex-row mt-6">
      
      {/**Section 1 */}
      <div id="products_file" className="w-[50%] flex justify-center">
        <div id="file" className="w-[95%] min-h-[500px] flex flex-col p-4 items-center border-1 border-custom-blue">
            <h1 id="title" 
                className="text-custom-blue font-serif font-semibold text-2xl">
                  <span className="flex items-center gap-4">
                    <LuCopyPlus />
                    Upload Products Data
                  </span>
              </h1>

            <div className="mt-4 flex w-full gap-4 justify-center">
                <div style={{ position: 'relative' }} className="rounded-md border-2 border-custom-blue border-dashed p-14 font-bold sm:w-auto flex flex-col justify-center items-center gap-6 bg-slate-50">
                  <FiUploadCloud className="text-slate-400 text-5xl"/>
                    <p className="text-slate-400 mt-2">
                      Choose CSV file to upload
                    </p>
                  <input type="file" onChange={handlePFileChange} 
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} accept=".csv" />
                </div>
              </div>

              <div className="mt-4 flex w-full gap-4 justify-center">
                <table className="w-[90%] bg-slate-50 text-sm font-sans border text-slate-600 border-gray-300 border-collapse">
                  <thead>
                    <tr>
                      {columnNames.map((name, index) => (
                        <th key={index} className={`${name === 'Note' ? 'w-1/2' : 'w-1/4'} bg-custom-green p-2 text-left border-b border-gray-300`}>
                          {name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td 
                            key={cellIndex} 
                            className={`
                              text-sm font-semibold border-b border-gray-300 px-2 py-1 
                              ${columnNames[cellIndex] === 'Note' ? 'w-1/2' : 'w-1/4'}
                              ${cell.includes('✓ Success') ? 'text-green-500' : cell.includes('x Failed') ? 'text-red-500' : ''}
                            `}
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


              { message ?
                <div className="mt-4 flex w-full gap-4 justify-center">
                <div style={{ position: 'relative' }} className="p-4 font-bold sm:w-auto flex flex-col justify-center items-center text-center gap-2">
                  <FcApproval className="text-3xl"/>
                    <p className="text-custom-blue mt-2">
                      {message}
                    </p>
                  
                </div>
              </div> : null
              }
        </div>
      </div>

      {/**Section 2 */}
      <div id="products_media" className="w-[50%] flex justify-center">
        <div id="media" className="w-[95%] min-h-[500px] flex flex-col p-4 items-center border-1 border-custom-blue">
            <h1 id="title" 
                className="text-custom-blue font-serif font-semibold text-2xl">
                  <span className="flex items-center gap-4">
                    <MdPermMedia />
                    Upload Products media
                  </span>
              </h1>
          

              <div className="mt-4 flex w-full gap-4 justify-center">
                <div style={{ position: 'relative' }} 
                     className="rounded-md border-2 border-custom-blue border-dashed p-14 font-bold sm:w-auto flex flex-col justify-center items-center gap-6 bg-slate-50">
                  <FiUploadCloud className="text-slate-400 text-5xl"/>
                    <p className="text-slate-400 mt-2">
                      Choose ZIP file to upload
                    </p>
                  <input type="file" onChange={handleMFileChange} 
                        style={{ position: 'absolute', 
                                top: 0, left: 0, width: '100%', 
                                height: '100%', opacity: 0 }} accept=".zip" />
                </div>
              </div>


              <div className="mt-4 flex w-full gap-4 justify-center">
                <table className="w-[90%] bg-slate-50 text-sm font-sans border text-slate-600 border-gray-300 border-collapse">
                  <thead>
                    <tr>
                      {mcolumnNames.map((name, index) => (
                        <th key={index} className={`${name === 'Note' ? 'w-1/2' : 'w-1/4'} bg-custom-green p-2 text-left border-b border-gray-300`}>
                          {name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {mdata.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td 
                            key={cellIndex} 
                            className={`
                              text-sm font-semibold border-b border-gray-300 px-2 py-1 
                              ${mcolumnNames[cellIndex] === 'Note' ? 'w-1/2' : 'w-1/4'}
                              ${cell.includes('✓ Success') ? 'text-green-500' : cell.includes('x Failed') ? 'text-red-500' : ''}
                            `}
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


              {/**Add to Database */}
              { zmessage ?
              <>
                <div className="mt-4 flex w-full gap-4 justify-center">
                <div style={{ position: 'relative' }} className="p-4 font-bold sm:w-auto flex flex-col justify-center items-center text-center gap-2">
                  <FcApproval className="text-3xl"/>
                    <p className="text-custom-blue mt-2">
                      {zmessage}
                    </p>
                </div>
              </div>

            <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
              <button onClick={postProductsData} 
                      className="text-sm w-full rounded-md bg-custom-blue text-white border-2 
                                px-6 py-2 font-semibold hover:bg-blue-700 hover:text-custom-blue 
                                sm:w-auto border-custom-blue">
                    add to Database 
              </button>
            </div> </> : null
            }

        </div>
      </div>
    
    </div>
  );
}