import React, { useState } from "react";


// In ComBase component
export default function PayCom({ payRecord }) {

  
    return (
        <div className="flex flex-col justify-center">

        {/** Payment Profile Information Forms */}
        <div className="flex flex-col justify-center">
                <div className="w-[95%] h-full p-6 rounded-md self-center">
                    <form class="w-full max-w-lg">
                    
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="pname">
                                Profile Name
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="pname" type="text" value={payRecord?.pname? payRecord.pname : "No Name Yet"} readOnly/>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="po">
                            PO Number
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="po" type="text" value={payRecord?.PO_number? payRecord.PO_number : ""} readOnly/>
                    </div>
                    </div>
                    <div class="flex flex-wrap mb-6">
                    <div class="w-full md:w-full mb-6 md:mb-0">
                        <span className="text-lg text-custom-blue font-semibold">
                            Budget Holder Information
                        </span>
                    </div>
                </div>
                
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="full-name">
                                Full Name
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="full-name" type="text" value={payRecord?.budget_holder_name? payRecord.budget_holder_name : ""} readOnly/>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="phone">
                            Phone
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="phone" type="phone" value={payRecord?.budget_holder_phone? payRecord.budget_holder_phone : ""} readOnly/>
                    </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-address">
                            Business Email
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-password" type="address" value={payRecord?.budget_holder_email? payRecord.budget_holder_email : ""} readOnly/>
                        </div>
                    </div>
                   

                <div class="flex flex-wrap mb-6">
                    <div class="w-full md:w-full mb-6 md:mb-0">
                        <span className="text-lg text-custom-blue font-semibold">
                            Financial Information
                        </span>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="bank">
                            Bank
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-password" type="text" value={payRecord?.bank? payRecord.bank : ""} readOnly/>
                        </div>
                    </div>


                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="iban">
                                IBAN
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="iban" type="text" value={payRecord?.IBAN? payRecord.IBAN : ""} readOnly/>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="swift">
                            Swift Code
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="swift" type="text" value={payRecord?.swift_code? payRecord.swift_code : ""} readOnly/>
                    </div>
                    </div>

                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="iaddress">
                            Invoicing Address
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="iaddress" type="address" value={payRecord?.invoicing_address? payRecord.invoicing_address : ""} readOnly/>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        {/** Payment & Delivey Profiles Information */}
        <div className="flex flex-col items-center mb-4">
            <div className="w-4/5 border-t border-custom-blue"></div>
        </div>
    </div>
    );
  }