import React, { useEffect } from 'react';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import Login  from '../../components/Sign/Login';
import useLogged from '../../hooks/useLogged';

export default function LoginPage() {
  useLogged();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <PageNav/>
    
    <Login />

    <Footer/>
  </>
  )
}
