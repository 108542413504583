import React, { useState } from 'react';
import CareerContext from './CareerContext';

const CareerContextProvider = ({ children }) => {
  const [data, setData] = useState(null);

  return (
    <CareerContext.Provider value={{ data, setData }}>
      {children}
    </CareerContext.Provider>
  );
}

export default CareerContextProvider;