import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './Login.css';

function OTPForm() {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const uidb64 = "1";

    const formik = useFormik({
      initialValues: {
        otp: "",
      },
      onSubmit: (values) => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/reset_password/${uidb64}/${values.otp}/`)
          .then(response => {
            // console.log(response.data);
            // Here you would handle the response from the server
            navigate('/client');
          })
          .catch(error => {
            setErrorMessage(t('signproces.otperrormsg'));
            // console.error('There was an error!', error);
          });
      },
    });

    const handleClick = () => {
        setErrorMessage(null);
        navigate('/login');
      };

  return (
        <div className="p-3 custom-width">
             <form onSubmit={formik.handleSubmit}>
             {errorMessage && <div style={{
                            textAlign: 'center', 
                            color: 'red', 
                            fontWeight: '500', 
                            marginBottom: '10px'
                    }}><p>{errorMessage}</p></div>}
                <div className="form-group mb-5">
                <input 
                    type="otp" 
                    className="form-control inputArea" 
                    id="otp" 
                    aria-describedby="otp" 
                    placeholder="Enter OTP" 
                    required
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                />
                </div>

                
                <button type="submit" 
                    className="btn btn-primary mb-4 w-100 button3D">
                    {t('signprocess.loginbtn')}
                </button>
                
            </form>

            <div className="bottom-note">
            <p className="text-left p-tag" 
                onClick={handleClick} 
                style={{cursor: 'pointer', 
                        color: '#2849ad',
                        fontWeight: '700',
                        fontSize: '17px'}}>
                    {t('signprocess.otpanothercode')}
            </p>
          </div>
        </div>
  );
}

export default OTPForm;