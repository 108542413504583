import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../Info/JoinUs.module.css";


export default function FindJob() {
  const { t } = useTranslation();
  return (
    <div className={styles.bottomInfo}>
      <h4 className={styles.btitle}>{t('career.findjob')}</h4>
      <div className={styles.findJobInputInfo}>
        <input
          placeholder="Search Jobs By Keyword"
          className={styles.findJobInput}
        />
        <span>
          <FontAwesomeIcon icon={faSearch} />
        </span>
        {/* <Link to="/SignUp">
          <button> {t('career.signupbtn')}</button>
        </Link> */}
      </div>
    </div>
  );
}
