import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-grid-system";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
// import CardBtn from "../Shop/CardBtn";
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

import CareerContext from "../../context/CareerContext";

// import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import "./JobGrid.css";



const JobGrid = ({ data }) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(1);
  const [careers, setCareers] = useState([]);
  const { setData } = useContext(CareerContext);
  const navigate = useNavigate();

  useEffect(() => {
    setCareers(data);
  }, [data]);
  
  const handleClick = (career) => {
    setData(career);
    navigate("/jobdetails");
  }

  const handlePageChange = (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
  };

  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_SERVER_URL}/api/careers/careers/`)
  //     .then(response => {
  //       console.log(response.data);
  //       setCareers(response.data);
  //     })
  //     .catch(error => {
  //       console.error('There was an error!', error);
  //     });
  // }, []);


  const itemsPerPage = 15;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, careers.length);
  //console.log(startIndex);
  //console.log(endIndex);

  return (
    <Container>
      <Row>
      {Array.isArray(careers)  && careers.length > 0 ? careers.slice(startIndex, endIndex).map((career, index) => (
          <Col xl={2.3} lg={3} sm={4} xs={8} key={index}  style={{padding: '10px'}}>
            <div className="card product-slider">
              <div className="card-body">
                <div className="topCardContent">
                  <div
                    style={{
                      backgroundImage:`url(${process.env.PUBLIC_URL + '/img/favicon-16x16.png'})`,
                      backgroundColor: "transparent",
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      width: "20px",
                      height: "20px",
                      borderRadius:'50%',
                    }}
                  ></div>
                  <p className="card-title">Assortix Medical</p>
                </div>

                <h6 className="card-title">{career.job_name}</h6>
                <ul style={{ display: 'flex', flexDirection: 'column' }}>
                    {career.skills.slice(0, 3).map((skill, index) => 
                        <li key={index}>{skill}</li>)}
                  </ul>
                <button  className="ccardbtn" onClick={() => handleClick(career)}
                  style={{ margin: "0", fontSize: "10px", padding: "5px 7px" }}>
                    {t('career.detailsbtn')}
                </button>
              </div>
            </div>
          </Col>
        )) : <p className="card-title"> {t('career.loadmsg')}</p>}
      </Row>

      {Array.isArray(careers) && careers.length > 0 && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={careers.length}
          pageRangeDisplayed={7}
          onChange={handlePageChange}
        />
      )}
    </Container>
  );
};

export default JobGrid;
