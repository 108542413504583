import React, { useState } from 'react';
import FilterContext from './FilterContext';

const FilterContextProvider = ({ children }) => {
  const [choosenProducts, setChoosenProducts] = useState(null);

  return (
    <FilterContext.Provider value={{ choosenProducts, setChoosenProducts }}>
      {children}
    </FilterContext.Provider>
  );
}

export default FilterContextProvider;