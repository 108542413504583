import React, { useState, useRef, useEffect } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import 'react-chat-elements/dist/main.css';
import './ChatPage.css';
import { ChatList, MessageList } from 'react-chat-elements';
import { FaPlus } from 'react-icons/fa';
import { LuSendHorizonal } from 'react-icons/lu';

import { Scrollbars } from 'react-custom-scrollbars-2';

import PopupContent from './PopupContent';




const additionalChats = [
  {
    id: 'assortedmed',
    name: 'AssortedMed',
    img: '../img/assortedmed.png',
  },
  {
    id: 'assortinetwork',
    name: 'AssortiNetwork',
    img: '../img/assortinetwork.png',
  },
];

const getTime = (date) => {
  if (!date) {
    return '';
  }

  const now = new Date();
  const seconds = Math.floor((now - new Date(date)) / 1000);

  if (seconds < 60) {
    return 'just now';
  }

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) return interval + ' years ago';
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return interval + ' months ago';
  interval = Math.floor(seconds / 86400);
  if (interval > 1) return interval + ' days ago';
  interval = Math.floor(seconds / 3600);
  if (interval > 1) return interval + ' hours ago';
  interval = Math.floor(seconds / 60);
  if (interval > 1) return interval + ' minutes ago';

  return '';
};

export default function ChatPage() {
  // const [users, setUsers] = useState([
  //   ...additionalChats,
  //   ...companyusers.map((companyUser) => ({
  //     id: companyUser.id,
  //     name: companyUser.fullname,
  //     img: companyUser.profile_picture || '../img/default.jpg',
  //     lastMessageTime: new Date(),
  //   })),
  // ]);
  const user = JSON.parse(localStorage.getItem('user'));
  const companyusers = user?.company_users || [];

  const [users, setUsers] = useState([
    ...companyusers?.map((companyUser) => ({
      id: companyUser.id,
      name: companyUser.fullname,
      img: './img/shop.png',
      lastMessageTime: new Date(),
    })),
  ]);
  const [currentUser, setCurrentUser] = useState(users[0]?.id);
  const [chatHistories, setChatHistories] = useState(
    additionalChats.concat(companyusers).reduce((acc, user) => {
      acc[user.id] = [];
      return acc;
    }, {}),
  );
  const [inputValue, setInputValue] = useState('');
  const messageListReference = useRef();
  const scrollbarRef = useRef();
  const popupRef = useRef();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const ws = useRef(null);

  useEffect(() => {
    const roomName = currentUser; // Use currentUser as the room name to dynamically join different chats
    // ws.current = new ReconnectingWebSocket(
    //   `ws://localhost:8000/ws/chat/${roomName}/`,
    // );

    ws.current.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log('Received message:', data);
      const newMessage = {
        position: 'left',
        type: 'text',
        text: data.message,
        date: new Date(),
      };
      setChatHistories((prevChatHistories) => ({
        ...prevChatHistories,
        [currentUser]: [...prevChatHistories[currentUser], newMessage],
      }));
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      ws.current.close();
    };
  }, [currentUser]);

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      const currentTime = new Date();
      const formattedTime = formatTime(currentTime);

      const newMessage = {
        position: 'right',
        type: 'text',
        text: inputValue,
        // date: currentTime,
        time: formattedTime,
      };

      setChatHistories((prevChatHistories) => ({
        ...prevChatHistories,
        [currentUser]: [...prevChatHistories[currentUser], newMessage],
      }));

      setUsers((prevUsers) => {
        const updatedUsers = prevUsers?.map((user) =>
          user.id === currentUser
            ? { ...user, lastMessageTime: currentTime }
            : user,
        );
        return updatedUsers;
      });

      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(
          JSON.stringify({
            message: inputValue,
          }),
        );
      }

      setInputValue('');
    }
  };

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollToBottom();
    }
  }, [chatHistories, currentUser]);

  const handlePopupOption = (option) => {
    setSelectedOption(option);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let selectedItem = null;

    if (selectedOption === 'order') {
      const selectedIndex = parseInt(selectedProduct.split('product')[1]) - 1;
      selectedItem = products[selectedIndex];
    } else if (selectedOption === 'addInvoice') {
      const selectedIndex = parseInt(selectedInvoice.split('invoice')[1]) - 1;
      selectedItem = invoices[selectedIndex];
    }

    if (selectedItem) {
      const currentTime = new Date();
      const formattedTime = formatTime(currentTime);

      const newMessage = {
        position: 'right',
        type: 'custom',
        data: {
          name: selectedItem.name,
          image: selectedItem.image,
          description: selectedItem.description,
          time: formattedTime,
        },
        // date: currentTime,
      };

      setChatHistories((prevChatHistories) => ({
        ...prevChatHistories,
        [currentUser]: [...prevChatHistories[currentUser], newMessage],
      }));

      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(
          JSON.stringify({
            message: newMessage,
          }),
        );
      }
    }

    setShowPopup(false);
    setSelectedOption(null);
    setSelectedProduct('');
    setSelectedInvoice('');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
        setSearchQuery('');
        setSelectedOption(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const products = [
    {
      name: 'Product 1',
      description:
        'Professional Product for your breath and your health industry',
      image: '../img/test.jpg',
    },
    {
      name: 'Product 2',
      description:
        'Professional Product for your breath and your health industry',
      image: '../img/test.jpg',
    },
    {
      name: 'Product 3',
      description:
        'Professional Product for your breath and your health industry',
      image: '../img/test.jpg',
    },
    {
      name: 'Product 4',
      description:
        'Professional Product for your breath and your health industry',
      image: '../img/test.jpg',
    },
  ];

  const invoices = [
    {
      name: 'Invoice A',
      description: 'Invoice for your recent purchase',
      image: '../img/test.jpg',
    },
    {
      name: 'Invoice B',
      description: 'Invoice for your recent purchase',
      image: '../img/test.jpg',
    },
    {
      name: 'Invoice C',
      description: 'Invoice for your recent purchase',
      image: '../img/test.jpg',
    },
    {
      name: 'Invoice D',
      description: 'Invoice for your recent purchase',
      image: '../img/test.jpg',
    },
  ];

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  const filteredItems =
    selectedOption === 'order'
      ? products.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : invoices.filter((invoice) =>
          invoice.name.toLowerCase().includes(searchQuery.toLowerCase()),
        );

  return (
    <div className='flex items-center justify-center'>
    <div className="flex w-[85%] h-[78vh] p-3 bg-white rounded-sm border-1 shadow ">
      <div className="flex w-full h-full rounded-md border-1 border-custom-blue">
      <div className="w-[30%] border-r border-custom-blue">
        <Scrollbars>
          <ChatList
            className="chat-list rounded p-2 mr-2"
            dataSource={users?.map((user, index) => {
              const lastMessage =
                chatHistories[user.id]?.length > 0
                  ? chatHistories[user.id][chatHistories[user.id].length - 1]
                      .text
                  : '';

              // const isCompanyChat = index < additionalChats.length;

              return {
                avatar: user.img,
                alt: 'Logo',
                title: user.name,
                subtitle: lastMessage || ' ',
                date: new Date(),
                unread: 0,
                id: user.id,
                className: currentUser === user.id
                    ? 'current-chat'
                    : '',
                dateString:
                  user.lastMessageTime && chatHistories[user.id]?.length > 0
                    ? getTime(user.lastMessageTime)
                    : '',
              };
            })}
            onClick={(e) => setCurrentUser(e.id)}
          />
        </Scrollbars>
      </div>
      <div className="flex flex-1 flex-col">
        <Scrollbars className="flex-1" ref={scrollbarRef}>
          <div
            className="flex-1 overflow-y-auto p-4"
            ref={messageListReference}>
            <MessageList
              className="message-list"
              lockable={true}
              toBottomHeight={'100%'}
              dataSource={chatHistories[currentUser]?.map((message) => {
                if (message.type === 'custom') {
                  return {
                    ...message,
                    type: 'text',
                    text: (
                      <div className="flex">
                        <div className="flex items-start space-x-4 bg-white p-2">
                          <img
                            src={message.data.image}
                            alt={message.data.name}
                            className="h-16 w-16 object-cover"
                          />
                          <div>
                            <h2 className="text-sm font-semibold">
                              {message.data.name}
                            </h2>
                            <p className="text-xs text-gray-600">
                              {message.data.description}
                            </p>
                          </div>
                        </div>
                        <p className="flex items-end px-2 text-xs text-gray-400">
                          {message.data.time}
                        </p>
                      </div>
                    ),
                  };
                } else {
                  return {
                    ...message,
                    text: (
                      <div>
                        <p>{message.text}</p>
                        <p className="flex justify-end text-xs text-gray-400">
                          {message.time}
                        </p>
                      </div>
                    ),
                  };
                }
              })}
              onClickAvatar={(user) => console.log(user)}
            />
          </div>
        </Scrollbars>

        <div className="flex border-t p-2">
          <input
            type="text"
            placeholder="Type a message..."
            className="mr-2 flex-1 rounded border border-[#eee] bg-[#f1f1f1] p-2"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
          {showPopup && (
            <PopupContent
              selectedOption={selectedOption}
              handleFormSubmit={handleFormSubmit}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              filteredItems={filteredItems}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              selectedInvoice={selectedInvoice}
              setSelectedInvoice={setSelectedInvoice}
              handlePopupOption={handlePopupOption}
            />
          )}
          <button
            onClick={() => setShowPopup(!showPopup)}
            className="relative mr-2 rounded bg-[#e3e3e3] p-2 text-white"
          >
            <FaPlus className="w-6 text-gray-400" />
          </button>
          <button
            className="rounded bg-custom-blue p-2 text-white"
            onClick={handleSendMessage}
          >
            <LuSendHorizonal className="w-6 text-white" />
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
  );
}
