import React, { useState } from "react";


// Icon Imports
import { BiSolidReport } from "react-icons/bi";

// In ComBase component
export default function DelSum({ delRecord }) {
  
    return (
        delRecord ?
        <div className="w-full mt-3 border-1 border-custom-green px-3 py-1 rounded-lg h-fit shadow-custom font-sans">

          <div className="my-1 mb-2 text-xl text-custom-blue font-extrabold flex justify-center">
            <span>{delRecord.dname? delRecord.dname : "Delivery profile " + delRecord.id}</span>
          </div>

          <div className="my-3">
            <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
              <p>Contact Personal Info.</p>
            </div>
            <div className="grid grid-cols-1 my-1 font-semibold">
              <span>- {delRecord.contact_person_name}</span>
            </div>
            <div className="grid grid-cols-1 my-1 font-semibold">
              <span>- {delRecord.contact_person_phone}</span>
            </div>
            <div className="grid grid-cols-1 my-1 font-semibold">
              <span>- {delRecord.contact_person_email}</span>
            </div>
            <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
              <p>Delivery Address</p>
            </div>
            <div className="grid grid-cols-2 gap-3 my-1 font-semibold">
              <p className="text-custom-blue">route number:</p>
              <span>{delRecord.route_number}</span>
            </div>
            <div className="grid grid-cols-2 gap-3 my-1 font-semibold">
              <p className="text-custom-blue">Address:</p>
              <p>{delRecord.ddaddress}, {delRecord.dpostal}, {delRecord.dcity}</p>
            </div>

          </div>
        </div> : null 
    );
  }