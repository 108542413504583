import React from 'react';
import styles from './RSec.module.css'; // Import the same CSS module


const RightSection = ({ head, text, imgSrc }) => {
  return (
          <div id="content" className={styles.container}>
            <div id="img" 
                  className={styles.image} 
                  style={{backgroundImage: `url(${imgSrc})`}} />
            <div className={styles.textContainer}>
              <h4>{head}</h4>
              <p>{text}</p>
            </div>
          </div>
  );
};

export default RightSection;