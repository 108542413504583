import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Row, Col } from 'react-grid-system';
import Card from 'react-bootstrap/Card';
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Icon Imports
import { IoMdSearch } from 'react-icons/io';
import { BsSearch } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';

// Local Imports
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../../components/UShop/UserShopSlider.module.css';
import PopupCreateDsm from './PopupCreateDsm';
import { addToBasket } from '../../features/basket/basketSlice';
import '../creationPopupStyle.css';


const NewDsm = () => {
  // Hadnling Filtration product section
  const [searchQuery, setSearchQuery] = useState('');
  const storedProducts = JSON.parse(localStorage.getItem('products')) || [];

  const filteredProducts = storedProducts.filter(
    (product) =>
      product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  const uniqueCategories = [
    ...new Set(storedProducts.map((product) => product.category)),
  ];
  const uniqueSubCategory1 = [
    ...new Set(storedProducts.map((product) => product.sub_category_1)),
  ];
  const uniqueSubCategory2 = [
    ...new Set(storedProducts.map((product) => product.sub_category_2)),
  ];
  const uniqueBrand = [
    ...new Set(storedProducts.map((product) => product.brand)),
  ];

  const options = [
    { value: 'All', label: 'All' },
    ...uniqueCategories.map((item) => ({ value: item, label: item })),
  ];

  const [activeItem, setActiveItem] = useState('All');
  const handleItemClick = (item) => {
    setActiveItem(item);
  };


  // Handling Toas Success and Failure
  const [toastKey, setToastKey] = useState(0);
  const showToast = (message) => {
    toast.success(message, {
      autoClose: 3000,
    });
    //setToastKey(prevKey => prevKey + 1);
    //console.log("In ShowToast: " + message);
  };
  const showFail = (message) => {
    toast.error(message, {
      autoClose: 3000,
    });
    //setToastKey(prevKey => prevKey + 1);
    //console.log("In ShowFail: " + message);
  };
  
  // Handling adding products (addedProducts in createPackData)
  // Different from New Package (Alaa's work)
  const [addedProducts, setAddedProducts] = useState([]);
  const handleAddProduct = (product) => {
    const existingProduct = addedProducts.find((p) => p.value === product.value);
    if (existingProduct) {
      //console.log('Product exists, triggering toast');
      showFail('This product already exists!');
    } else {
      //console.log('Adding new product');
      setAddedProducts([...addedProducts, { ...product}]);
    }
  };

  
  
  useEffect(() => {}, [searchQuery, filteredProducts]);
  
  // Display of searched Products
  const searchedProduct =
  searchQuery.length > 0
    ? storedProducts.filter((product) =>
        `${product.name}`.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : storedProducts;

const handleDeleteProduct = (product) => {
  setAddedProducts(addedProducts.filter((p) => p.value !== product.value));
};

  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const handleOpenForm = () => {
    setShowForm(true);
  };
  const handleCloseForm = (message, status, newDSMProduct) => {
    setShowForm(false);
    if (status == 'success') {
      showToast(message);
      // console.log("New DSM Dispatched Data: ");
      // console.log(newDSMProduct);
      dispatch(addToBasket(newDSMProduct));
    } else {
      showFail(message);
    }
  };


  // const displayedProducts = filteredProducts;

  //Pagination Controls
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 6;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, searchedProduct.length);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const status = [
    { value: 'active', label: 'Active' },
    { value: 'non_active', label: 'Non Active' },
    { value: 'paused', label: 'Paused' },
  ];


  const handleMaxQuantityChange = (value, product) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.value === product.value ? { ...p, maxQuantity: value } : p,
      ),
    );
  };

  const handleMinQuantityChange = (value, product) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.value === product.value ? { ...p, minQuantity: value } : p,
      ),
    );
  };
  const handleStatusChange = (selectedValue, product) => {
    if (selectedValue) {
      setAddedProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.value === product.value ? { ...p, status: selectedValue } : p,
        ),
      );
    } else {
      setAddedProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.value === product.value ? { ...p, status: 'active' } : p,
        ),
      );
    }
  };




    // Handling post sucess
    const handleCreationSucess = () => {
      //setAddedProducts([]);
    }
  
  return (
    <>
      {/**Filtration section Orders Focus */}
      <ToastContainer className="left-[40%]"/>
      <div className="w-full border-b-2 border-gray-200 bg-white p-2">
        {/* On small screens, display the select dropdown */}
        <div className="block lg:hidden">
          <Select
            options={options}
            value={{ value: activeItem, label: activeItem }}
            onChange={(selectedOption) => handleItemClick(selectedOption.value)}
          />
        </div>

        {/* On larger screens, display the list view */}
        <ul className="text-md flex hidden w-full font-bold lg:flex">
          <li key="all" className="flex-1">
            <a
              className={`block rounded border-b-2 border-transparent px-4 py-1 text-center 
          ${activeItem === 'All' ? 'font-bold text-gray-700' : 'text-gray-400'} 
           hover:font-bold hover:text-gray-700`}
              onClick={() => handleItemClick('All')}
              href="#"
            >
              All
            </a>
          </li>
          {uniqueCategories.map((item) => (
            <li key={item} className="flex-1">
              <a
                to="#"
                className={`block cursor-pointer rounded border-b-2 border-transparent px-4 py-1 text-center
          ${activeItem === item ? 'font-bold text-gray-700' : 'text-gray-400'} 
          hover:font-bold hover:text-gray-700`}
                onClick={() => handleItemClick(item)}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/**Filtration section Apply filter button */}
      <div className="shadow-md flex w-full flex-wrap items-center justify-between border-b-2 border-gray-300 bg-white p-3">
        <div className="flex w-full justify-around md:w-[46%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
              <option value="">Sub Category 1</option>
              {uniqueSubCategory1.map((item) => (
                <option key={item} value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
              <option value="">Sub Category 2</option>
              {uniqueSubCategory2.map((item) => (
                <option key={item} value={item}>{item}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex w-full justify-around md:w-[44%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <select className="w-full rounded border bg-gray-200 p-1 px-2 outline-none">
              <option value="">Brand</option>
              {uniqueBrand.map((item) => (
                <option key={item} value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <input
              type="search"
              aria-label="Search"
              className="form-control me-2 w-full rounded border bg-gray-200 p-1  px-2 outline-none"
              placeholder="Search Product"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
          <button className="text-md w-full rounded-lg bg-custom-blue px-6 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue sm:w-auto">
            Filter
          </button>
        </div>
      </div>

      {/* Side List (Chosen Product Section)*/}
      <div className="flex flex-col sm:flex sm:flex-row">
        <aside
          className="sm:md-2 mb-6 grid w-full grid-rows-3 sm:w-[36%]"
          style={{ gridTemplateRows: '84px 670px 50px' }}
        >
          <div>
            <div className="bg-[#4ebfc4] px-2 py-3 text-center text-base font-bold text-white">
              List of DSM Items/Products
            </div>
            <div className="bg-gray-100 p-2 text-center text-xs font-semibold text-neutral-400/75">
              <p>Click + to add an item/product</p>
            </div>
          </div>

          {addedProducts.length > 0 ? (
            <div className="m-1 w-full">
              <Scrollbars style={{ width: '100%' }}>
                <ul>
                  {addedProducts.map((product) => (
                    <li key={product.value} className="mt-6 flex justify-center">
                      <div className="hover relative shadow m-1 w-[90%] flex-col items-center justify-center overflow-hidden rounded border bg-white p-1">
                        <div className="flex h-full w-full flex-row items-center justify-between">
                          <span
                            className="absolute right-2 top-2 cursor-pointer bg-white"
                            onClick={() => handleDeleteProduct(product)}
                          >
                            <RiDeleteBin5Line className="text-red-500 " />
                          </span>
                          <div className="mr-2 flex w-2/5">
                            <img
                              src={product.image}
                              alt="Product"
                              className="w-full flex-grow object-cover"
                            />
                          </div>
                          <div className="mr-2 flex w-full flex-col items-center">
                            <p className="mb-2 mt-3 text-center text-sm font-bold text-custom-blue">
                              {product.label}
                            </p>
                            <div className="flex flex-row items-center justify-between text-xs font-semibold">
                              <span>Stock Limit: </span>
                              <span className="text-amber-600">from</span>
                              <input
                                className="w-[15%] rounded-lg border border-stone-200 p-1 text-xs transition-all duration-300 placeholder:text-stone-600  focus:outline-none"
                                name="lower_stock_limit"
                                value={product.minQuantity}
                                onChange={(e) =>
                                  handleMinQuantityChange(Number(e.target.value), product)
                                }
                              />
                              <span className="text-amber-600">to</span>
                              <input
                                className="w-[15%] rounded-lg border border-stone-200 p-1 text-xs transition-all duration-300 placeholder:text-stone-600  focus:outline-none"
                                name="higher_stock_limit"
                                value={product.maxQuantity}
                                onChange={(e) =>
                                  handleMaxQuantityChange(Number(e.target.value), product)
                                }
                              />
                              <h4 className="mr-2 text-sm font-bold text-custom-blue">
                                € {product.price}
                              </h4>
                            </div>
                            {/**Line 2: Product Status */}
                            <div className="mb-2 mt-2 flex flex-row items-center justify-between text-xs font-semibold">
                              <span>Product Status: </span>
                              <p className=" cursor-not-allowed rounded-lg border border-stone-200 p-2 text-xs font-semibold text-gray-800 transition-all  duration-300 focus:outline-none">
                                {product.status
                                  ? product.status === 'active'
                                    ? 'Active'
                                    : product.status === 'paused'
                                      ? 'Paused'
                                      : 'Non-Active'
                                  : ''}
                              </p>
                              <div className="ml-2">
                                <select
                                  className="w-full text-xs"
                                  onChange={(event) => {
                                    handleStatusChange(event.target.value, product);
                                  }}>
                                  <option value="all">Select...</option>
                                  {status.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </div>
          ) : (
            <div className="m-auto text-gray-400">
              <div className="m-auto flex h-20 w-20 items-center justify-center rounded-full bg-gray-200 ">
                <BsSearch className=" text-4xl " />
              </div>
            </div>
          )}

          <div className="mt-2 flex w-full justify-center">
            {addedProducts.length === 0 ? (
              <button
                disabled
                className="text-md w-full cursor-not-allowed rounded-lg bg-gray-300 py-1 font-semibold text-gray-500"
              >
                <IoMdSearch className="mr-3 inline-block h-6 w-6 align-middle" />
                Proceed to Review
              </button>
            ) : (
              <button
                onClick={handleOpenForm}
                className="text-md w-full rounded-lg bg-custom-blue py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue"
              >
                <IoMdSearch className="mr-3 inline-block h-6 w-6 align-middle" />
                Proceed to Review
              </button>
            )}
            {showForm && (
              <PopupCreateDsm
                onClose={handleCloseForm}
                addedProducts={addedProducts}
                showToast={showToast}
                showFail={showFail}
                handleSucess={handleCreationSucess}
              />
            )}
          </div>
        </aside>

        {/* MiniShop */}
        <div className="w-full overflow-hidden bg-custom-dsm sm:w-[64%]">
          <div className="h-20 w-full bg-[#d1eeee]"></div>
          <Row className="mb-8 mt-4 w-[97%] mx-auto">
            {Array.isArray(storedProducts) && storedProducts.length > 0 ? (
              searchedProduct.slice(startIndex, endIndex).map((card) => (
                <Col lg={4} md={6} sm={8} xs={12} key={card.assortimed_pid}>
                  <Card key={card.name} className={styles.card_body}>
                    <div className="h-1/2 w-full">
                      <Card.Img
                        variant="top"
                        src={imgPaths[card.assortimed_pid][0]}
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <Card.Body className="flex flex-grow flex-col justify-between">
                      <div>
                        <Card.Title className="text-center text-base font-bold">
                          {card.category}
                        </Card.Title>
                        <Card.Text className="text-center text-sm font-semibold">
                          {card.name}
                        </Card.Text>
                      </div>
                      <div className="flex cursor-pointer justify-between">
                        <h4 className="mr-2 font-bold text-custom-blue">
                          € {card.sales_price_c1}
                        </h4>
                        <MdAdd
                          className="right-0 rounded-full bg-custom-blue p-1 text-2xl text-white"
                          onClick={() =>
                            handleAddProduct({
                              image: imgPaths[card.assortimed_pid][0],
                              price: card.sales_price_c1,
                              value: card.id,
                              label: card.name,
                              category: card.category,
                              id: card.id,
                            })
                          }
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="card-title">No products found.</p>
            )}
          </Row>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={storedProducts.length}
            pageRangeDisplayed={4}
            onChange={handlePageChange}
            style={{ marginTop: '20px' }}
          />
        </div>
      </div>
    </>
  );
};

export default NewDsm;
