// pages/ReviewProduct.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FaCircleCheck } from 'react-icons/fa6';

import useAuth from '../../hooks/useAuth.js';
import PageNav from '../../components/Home/PageNav.jsx';
import Footer from '../../components/Home/Footer.jsx';
import ProductImgSlider from '../../components/UShop/ProductImgSlider.jsx';
import UserReviewSlider from '../../components/UShop/UserReviewSlider.jsx';
import OtherProductSlider from '../../components/UShop/OtherProductSlider.jsx';
import BrandSlider from '../../components/UShop/BrandSlider.jsx';
import Rating from '../../components/UShop/Rating.jsx';
import RelatedProductSlider from '../../components/UShop/RelatedProductSlider.jsx';
import { addToBasket, selectNumberOfItems } from '../../features/basket/basketSlice.js';
import ProductContext from '../../context/ProductContext';
import ChatReview from '../../components/UShop/ChatReview.jsx';
import styles from './ReviewProduct.module.css';

const ReviewProduct = () => {
  const navigate = useNavigate();
  const numberOfItems = useSelector(selectNumberOfItems);
  useAuth();

  const [openChat, setOpenChat] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  const handleChat = (review) => {
    setSelectedReview(review);
    setOpenChat(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseChat = () => {
    setOpenChat(false);
    setSelectedReview(null);
    document.body.style.overflow = 'auto';
  };

  const { data, setData } = useContext(ProductContext);
  
  useEffect(() => {
    localStorage.setItem('productdata', JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    const savedData = localStorage.getItem('productdata');
    if (savedData) {
      setData(JSON.parse(savedData));
    }
  }, [setData]);

  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  const handleAddToBasket = () => {
    //console.log("Add to basket Details Product: ");
    //console.log(data);
    dispatch(addToBasket(data));
  };

  const basketItems = useSelector((state) => state.basket.items.map((item) => item.id));
  const isInBasket = basketItems.includes(data.id);

  return (
    <>
      <PageNav />
      <section className={styles.reviewProduct}>
        <div className={styles.reviewProductContainer}>
          <div className="row">
            <div className={styles.cardReviewed}>
              <ProductImgSlider imgPaths={imgPaths[data.assortimed_pid]} />
              <div className={styles.pr_detail}>
                <div>
                  <img
                    className={styles.pr_detail_img}
                    src={imgPaths[data.assortimed_pid][0]}
                    alt="product_img"
                  />
                  <h2>{data.name}</h2>
                  <p>
                    {Array.from(
                      { length: 9 },
                      (_, i) => data[`sub_category_${i + 1}`],
                    )
                      .filter(Boolean)
                      .join(', ')}
                  </p>
                  <h6>$ {data.sales_price_c1}</h6>

                  {isInBasket ? (
                    <>
                      <div className='flex items-center gap-1'>
                        <span><FaCircleCheck className='text-green-800 text-lg' /></span>
                        <p className='font-semibold text-2xl'>Added to cart</p>
                      </div>
                      <div className='w-[220px]'>
                        <button className="py-2 w-full my-2 px-6 hover:bg-amber-400 bg-amber-500 rounded text-xs font-semibold" onClick={() => navigate('/userShopList')}>Continue Shopping ( {numberOfItems} item )</button>
                        <button className="py-2 px-6 w-full bg-white border border-[#f1f1f1] hover:underline hover:underline-offset-4 rounded text-xs font-semibold" onClick={() => navigate('/basket')}>Go To Basket</button>
                      </div>
                    </>
                  ) : (
                    <button className="py-[10px] px-5 bg-custom-blue hover:bg-blue-700 text-white rounded-md text-xs font-semibold" onClick={() => handleAddToBasket(data.id)}>Add To Basket</button>
                  )}

                </div>
              </div>
            </div>
          </div>

          <div className={styles.detailsRelated_pr}>
            <div>
              <div className={styles.pr_info}>
                <h2 style={{ width: '45rem' }}>{data.name}</h2>
                <ul>
                  <li style={{ width: '35rem' }}>{data.description}</li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    {data.category}
                  </li>
                  {Array.from({ length: 12 }, (_, i) => data[`specs_${i + 1}`])
                    .filter(Boolean)
                    .map((item, index) => (
                      <li key={index}>
                        <span>
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        {item}
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            <div className={styles.relatedSlider}>
              <h2>Alternative Products</h2>
              <RelatedProductSlider
                info={{
                  category: data.category,
                  subCategory: data.sub_category_1,
                }}
              />
            </div>
          </div>

          {/* ==============chat cards================= */}
          <div className={styles.pr_reviews}>
            <h2>That's our customers' opinions of it. </h2>
            <UserReviewSlider handleChat={handleChat} />
          </div>
          {/* ==============chat cards================= */}

          <div className={styles.pr_needed}>
            <h2>Other Specefications</h2>
            <ul>
              {Array.from({ length: 12 }, (_, i) => data[`specs_${i + 1}`])
                .filter(Boolean)
                .map((item, index) => (
                  <li key={index}>
                    <span>
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    {item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {/* =====================Brand Slider===================== */}
        <div className={styles.brandSlider}>
          <div className={styles.reviewProductContainer}>
            <h2>Bought these brands</h2>
            <BrandSlider />
          </div>
        </div>
        {/* =====================Brand Slider===================== */}

        {/* =====================Rating===================== */}
        <Rating />
        {/* =====================Rating===================== */}

        {/* =====================Other Product slider===================== */}
        <div className={styles.otherProducts}>
          <div className={styles.reviewProductContainer}>
            <h2>Other Products</h2>
            <OtherProductSlider
              info={{
                category: data.category,
                subCategory: data.sub_category_1,
              }}
            />
            <Link to="/usershoplist">
              <button className={styles.otherProductBtn}>View All</button>
            </Link>
          </div>
        </div>
        {/* =====================Other Product slider===================== */}
      </section>
      <Footer />

      {openChat && (
        <ChatReview
          handleClose={handleCloseChat}
          review={selectedReview}
        />
      )}
    </>
  );
};

export default ReviewProduct;
