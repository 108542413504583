import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Styling Imports
import "../Shop/SubListing.css";

// Local Imports
import BreadCrumbs from "../../components/UShop/BreadCrumbs.jsx";

// Context Imports
import SubListingContext from "../../context/SubListingContext.jsx";


export default function USubListing() {
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState("");
  const [activeSubLink, setActiveSubLink] = useState("");
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [categorySubCategoryMap, setCategorySubCategoryMap] = useState({});
  const [error, setError] = useState(null);
  let storedProducts = [];

  try {
    storedProducts = JSON.parse(localStorage.getItem('products'));
  } catch (err) {
    setError(err.message);
  }

  // Retrieve the JSON string from localStorage and parse it into an object
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));


  // List all Unique Categories and SubCategories
  useEffect(() => {
    try {
      const map = storedProducts.reduce((acc, product) => {
        if (!acc[product.category]) {
          acc[product.category] = [];
        }
        if (product.sub_category_1 && !acc[product.category].includes(product.sub_category_1)) {
          acc[product.category].push(product.sub_category_1);
        }
        return acc;
      }, {});
      setCategorySubCategoryMap(map);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  // Filtering Product List based on Chosen category and subCategory
  // Filtered Products
  const { subListings, setSubListings } = useContext(SubListingContext);

  const handleFilter = () => {
    try {
      const category = activeLink;
      const subCategory = activeSubLink;

      console.log("Chosen Category: " + category);
      if (!category && !subCategory) {
        setSubListings(storedProducts);
      } else {
        const chosen = storedProducts.filter(product =>
          (product.category === category) ||
          (product.sub_category_1 === subCategory)
        );
    
        setSubListings(chosen);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Handle Navigation between different categories
  const handleNavItem = (link, index) => {
    setActiveLink(link);
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleSubNavItem = (subLink) => {
    setActiveSubLink(subLink);
  };

  useEffect(() => {
    handleFilter();
  }, [activeLink, activeSubLink]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
    <div className="test">
      <Navbar bg="light" expand="lg" className="subListing-navbar">
        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse
          id="basic-navbar-nav "
          className="justify-content-around"
        >
          <Nav className="subListing">
          <Nav.Link
            onClick={() => {
              setActiveLink(null);
              setActiveSubLink(null);
            }}
            className={activeLink === null && activeSubLink === null ? "active" : ""}
          >
            All
          </Nav.Link>
            {Object.entries(categorySubCategoryMap).map(([category, subCategories], index) => (
              <div className="dropdown" key={index}
              onMouseEnter={() => setOpenDropdownIndex(index)}
                onMouseLeave={() => setOpenDropdownIndex(null)}>
                <Nav.Link
                  key={index}
                  onClick={() => handleNavItem(category, index)}
                  className={activeLink === category ? "active" : ""}
                >
                  {category}
                </Nav.Link>
                {openDropdownIndex === index && (
                  <div className="dropdown-content">
                    {subCategories.map((subCategory, subIndex) => (
                      <a key={subIndex} href="#" onClick={() => handleSubNavItem(subCategory)}>{subCategory}</a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>


        {/* =============== Breadcrumbs =================== */}
        <BreadCrumbs data={{category: activeLink, subCategory: activeSubLink}}  />
        {/* =============== Breadcrumbs =================== */}
      </>
  );
}