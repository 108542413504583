import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import CardBtn from "../Shop/CardBtn";
import axios from "axios";

import "../Career/JobSlider2.css";
import { useTranslation } from "react-i18next";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}



function CareerSlider1() {
  const { t } = useTranslation();
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/careers/careers/`)
      .then(response => {
        console.log(response.data);
        setCareers(response.data);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
      {Array.isArray(careers)  && careers.length > 0 ? careers.slice(0, 10).map((career, index) => (
          <div className="card job-slider">
            <div className="card-body">
              <div className="topCardContent">
              <div
                    style={{
                      backgroundImage:`url(${process.env.PUBLIC_URL + '/img/favicon-16x16.png'})`,
                      backgroundColor: "transparent",
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      width: "20px",
                      height: "20px",
                      borderRadius:'50%',
                    }}
                  ></div>
                <p className="card-title">Assortix Medical</p>
              </div>

              <h6 className="card-title">{career.job_name}</h6>
              <ul style={{ display: 'flex', flexDirection: 'column' }}>
                    {career.skills.slice(0, 3).map((skill, index) => 
                        <li key={index}>{skill}</li>)}
              </ul>
              <CardBtn
                style={{ marginButtom: "10px", fontSize: "10px", padding: "5px 7px" }}
              >
                <Link to={{ pathname: "/jobdetails",
                           state: { list: career } }}>
                        {t('career.detailsbtn')}</Link>
              </CardBtn>
            </div>
          </div>
        )) : <p className="card-title"> {t('career.loadmsg')}</p>}
      </Slider>
    </div>
  );
}

export default CareerSlider1;
