import ReactDOM from 'react-dom/client';
import React from 'react';
import Modal from 'react-modal';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'
import { Provider } from 'react-redux';
import store from  './store';

import './index.css';
import App from './App';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs : ['en', 'du', 'de'],
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    backend: {
      loadPath: '/static/locales/{{lng}}/{{lng}}.json',
    },
    react : {useSuspence: false},
    fallbackLng: "en",
    detection : {
      order: [ 'cookie','htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    }
  });

  Modal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
);
