import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import axios from 'axios';


// Local Imports
import './PopupEditInfo.css';
import PopupFormPay from "./PopupFormPay";
import PopupFormDel from "./PopupFormDel";


// Icon Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import PopupFormCom from './PopupFormCom.jsx';
import PopupFormPer from './PopupFormPer.jsx';


function PopupEditInfo({ onClose }) {

    // Data from Local Storage
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'MD' ? 'Manager User' : 'Normal User';
    console.log(user);
  
  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      uphone: '',
      date_of_birth: '',
      language_preference: '',
      department_code: '',
      department_name: '',
      function: '',
      budget: '',
      role: '',
    },
    onSubmit: async values => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/create-user/`, {
          user_data: values,
          current_user_email: user.user_data.email,

        });

        //console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
  });

  // Handle toggling between different Tabs
  const [fillActive, setFillActive] = useState("Per");
  const handleSelectChange = (event) => {
    setFillActive(event.target.value);
  };


  // Filling Tabs with Information
  const allItems = [
    // { fillActiveValue: "Com", text: "Company Information" },
    { fillActiveValue: "Per", text: "Personal Information" },
    { fillActiveValue: "Pay", text: "Payment Information" },
    { fillActiveValue: "Del", text: "Delivery Information" },
  ];
  
  let listItems;
  
  if (userRole === "Head User") {
    listItems = allItems;
  } else if (userRole === "Manager User") {
    listItems = allItems.filter(item => item.fillActiveValue !== "Com");
  } else if (userRole === "Normal User") {
    listItems = allItems.filter(item => item.fillActiveValue === "Per");
  }

  return (
    <div>
      <Modal show={true} onHide={onClose} className="PopupEditInfo">
        <Modal.Header className="flex-row-reverse">
        <button
            className="btn text-white bg-custom-green hover:bg-[#07b1ab] border-none"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className="text-2xl m-auto font-sans font-semibold ">
            Edir User Information
          </h2>
        </Modal.Header>
        <Modal.Body>
     {/** Main Section */}
     <ul className="text-sm font-semibold text-center text-gray-500 rounded-lg sm:flex w-full">
    {listItems.map((item) => (
      <li className="w-full" key={item.fillActiveValue}>
        <a
          href="#"
          onClick={() => setFillActive(item.fillActiveValue)}
          className={`inline-block w-full p-3 py-4 border-b-4 bg-white ${fillActive === item.fillActiveValue ? "bg-gradient-to-b from-custom-gradient-start to-custom-gradient-end border-custom-green font-bold" : "border-gray-200 hover:bg-gray-100 hover:font-bold hover:border-custom-green"} focus:outline-none`}
        >
          <span className={`inline-block ${fillActive === item.fillActiveValue ? "text-custom-blue" : "text-gray-500 hover:text-custom-gray-900"}`}>
            {item.text}
          </span>
        </a>
      </li>
    ))}
  </ul>
      <div style={{ display: fillActive === 'Com' ? 'block' : 'none' }}>
      <div className="w-[100%] mx-auto"><PopupFormCom /></div>
    </div>
    <div style={{ display: fillActive === 'Per' ? 'block' : 'none' }}>
      <div className="w-[100%] mx-auto"><PopupFormPer /></div>
    </div>
    <div style={{ display: fillActive === 'Pay' ? 'block' : 'none' }}>
      <div className="w-[100%] mx-auto"><PopupFormPay /></div>
    </div>
    <div style={{ display: fillActive === 'Del' ? 'block' : 'none' }}>
      <div className="w-[100%] mx-auto"><PopupFormDel /></div>
    </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PopupEditInfo;
