import { createSlice } from '@reduxjs/toolkit';

export const addressSlice = createSlice({
  name: 'address',
  initialState: {
    addressValue: '',
  },
  reducers: {
    setAddressValue: (state, action) => {
      state.addressValue = action.payload;
    },
  },
});

export const { setAddressValue } = addressSlice.actions;

export default addressSlice.reducer;
