import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FaRegComment } from 'react-icons/fa6';
import { LuSendHorizonal } from 'react-icons/lu';
import { MessageList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import './ChatReview.css';

const ChatReview = ({ handleClose, review }) => {
  const modalRef = useRef();
  const scrollbarsRef = useRef();
  const [messages, setMessages] = useState([
    {
      position: 'left',
      type: 'text',
      text: '"I buy this product because it is very useful for me medically."',
      user: {
        name: 'Richard Thomson',
        avatar: '../../img/dummy-person.jpg',
        jobTitle: 'Project Manage',
        companyName: 'Acıbadem Hospital Center'
      },
    },
  ]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClose]);

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;

    const newMessage = {
      position: 'right',
      type: 'text',
      text: inputValue,
      user: {
        name: 'You',
        avatar: '../../img/dummy-person.jpg',
        jobTitle: 'Customer',
        companyName: 'Acıbadem Hospital Center'
      },
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputValue('');

    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  };

  if (!review) {
    return null;
  }

  return (
    <div className="chatReview-modal">
      <div className="chatReview-modalContent" ref={modalRef}>
        <div className="chatReview-card">
          <span className="text-3xl text-[#2849ad]">
            <FontAwesomeIcon icon={faQuoteLeft} />
          </span>
          <p className="my-2 text-sm font-normal">"{review.review}"</p>
          <div className="flex justify-between">
            <div className="flex items-center text-[13px] font-bold text-[#a6a7a8]">
              <p className="pr-1">{review.comment}</p>
              <FaRegComment />
            </div>
            <p className="text-[13px] font-bold"> - {review.user} </p>
          </div>
        </div>

        <Scrollbars ref={scrollbarsRef}>
          <MessageList
            className="chatReview-messageList"
            lockable={true}
            toBottomHeight={'100%'}
            dataSource={messages.map((message) => ({
              ...message,
              title: (
                <div className='absolute top-[87px] flex align-middle'>
                  <img src={message.user.avatar} className='w-10 h-10 rounded-full object-cover' alt={message.user.avatar}/>
                  <div className='ml-2'>
                    <p className='font-bold text-black'>{message.user.name}</p>
                    <p className='text-xs font-semibold'>{message.user.jobTitle}</p>
                    <p className='text-[8px]'>{message.user.companyName}</p>
                  </div>
                </div>
              ),
            }))}
          />
        </Scrollbars>

        <div className="flex border-t p-2">
          <input
            type="text"
            placeholder="Type a message..."
            className="mr-2 flex-1 rounded border border-[#eee] bg-[#f1f1f1] p-2"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
          <button
            className="rounded bg-custom-blue p-2 text-white"
            onClick={handleSendMessage}
          >
            <LuSendHorizonal className="w-6 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatReview;
