import React from "react";
import { useSearch } from "../../context/SearchContext.jsx";

function Search() {
  const { searchQuery, setSearchQuery } = useSearch();
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <input
      className="form-control me-2"
      value={searchQuery}
      onChange={handleInputChange}
      type="search"
      placeholder="Search"
      aria-label="Search"
    />
  );
}
export default Search;
