import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './BaseSign.module.css';
import './Login.css';

const SuccessR = () => {

  const { t } = useTranslation();

  return (

    <div className={styles["my-component-container"]}>
    <div className={styles['left-col']}>
      <div className={styles['background-image']}>
      <div className={`${styles['header-container']} ${styles['no-form']}`}>
        <h2 className={styles['title']}>
            {t('signprocess.resetp4')}</h2>
        <div className={styles['icon-parent']}>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
<path fill="#4bad94" d="M44,24c0-2.909-1.653-5.448-4.066-6.73c0.659-2.511,0.017-5.303-1.943-7.262 C36.528,8.544,34.6,7.816,32.67,7.816c-0.653,0-1.306,0.083-1.941,0.25C29.448,5.653,26.909,4,24,4s-5.448,1.653-6.73,4.066 c-0.636-0.167-1.288-0.25-1.941-0.25c-1.93,0-3.857,0.729-5.321,2.192c-1.959,1.959-2.602,4.75-1.943,7.262 C5.653,18.552,4,21.091,4,24s1.653,5.448,4.066,6.73c-0.659,2.511-0.017,5.303,1.943,7.262c1.463,1.463,3.391,2.192,5.321,2.192 c0.653,0,1.306-0.083,1.941-0.25C18.552,42.347,21.091,44,24,44s5.448-1.653,6.73-4.066c0.636,0.167,1.288,0.25,1.941,0.25 c1.93,0,3.857-0.729,5.321-2.192c1.959-1.959,2.602-4.75,1.943-7.262C42.347,29.448,44,26.909,44,24z"></path><path fill="#fff" d="M22 32.829L14.586 25.414 17.414 22.586 22 27.171 31.586 17.586 34.414 20.414z"></path>
</svg>
        </div>
        <h4 className={styles['title']}>
        {t('signprocess.sucess1')} </h4>
        <p className={styles['subTitle']}>
        {t('signprocess.sucess2')} </p>

            <Link to="/login">
                <button type="submit" 
                className="btn btn-primary mb-4 w-100 button3D">
                  {t('signprocess.signinbtn')}   </button>
            </Link>
        </div>
      </div>
    </div>
    <div className={styles["right-col"]}>
      <div className={styles['partially-covered-image']}>
      </div>
    </div>
  </div>
);
};
export default SuccessR;