import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "react-bootstrap/Card";
import CardBtn from "../Shop/CardBtn";
import styles from "../Home/ProductCarousel.module.css";
import { useTranslation } from "react-i18next";
import Spinner from "../Home/Spinner";
import './RelatedProductSlider.css'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import ProductContext from '../../context/ProductContext';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right:'-49px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left:'-70px' }}
      onClick={onClick}
    />
  );
}


export default function RelatedProductSlider({ info }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(!isOpen);
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


    // Filtering Product List based on Choosen category and subCategory
    const productsData = localStorage.getItem('products');
    let products = [];
    if (productsData) {
      products = JSON.parse(productsData);
    }
    const [alternatives, setAlternatives] = useState([]);

    const handleFilter = () => {
      const category = info.category;
      const subCategory = info.subCategory;

      console.log("Choosen Category: " + info);
      // console.log(storedProducts.length);
      const choosen = products.filter(product =>
        (product.category === category) ||
        (product.sub_category_1 === subCategory)
      );

      setAlternatives(choosen);
    };

    useEffect(() => {
      handleFilter();
    }, [info]);


    // Retrieve the JSON string from localStorage and parse it into an object
    const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

    // Define card Visual Content
    const cards = alternatives.map((product, index) => ({
      title: product.category,
      text: product.name,
      imgSrc: imgPaths[product.assortimed_pid][0],
      product: product
    }));


    const navigate = useNavigate();
  const { setData } = useContext(ProductContext);
  const handleDetailsClick = (product) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setData(product);
    navigate('/reviewproduct');
  };

  return (
    <div className="RelatedProductSlider">
      <Slider {...settings}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <p>Error: {error.message}</p>
        ) : (
          cards.map((card, index) => (
            <div key={index}>
              <Card key={index} className={styles.card_body}>
                <div className={styles.img_container}>
                  <Card.Img
                    variant="top"
                    src={card.imgSrc}
                    className={styles.card_img}
                  />
                </div>
                <Card.Body>
                  <div className={styles.content_container}>
                    <Card.Title className={styles.card_title}>
                      {card.title}
                    </Card.Title>
                    <Card.Text className={styles.card_content}>
                      {card.text}
                    </Card.Text>
                  </div>
                  <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span className={styles.pricing}>{card.price}</span>

                          
                          <CardBtn
                      style={{ padding: '10px 20px' }}
                      onClick={() => handleDetailsClick(card.product)}
                    >
                      {t('vshop.details-cardbtn')}
                    </CardBtn>
                          </div>
                </Card.Body>
              </Card>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
}
