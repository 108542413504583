import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Local Imports
import DashboardPage from './DashboardPage';
import DSMPage from './DSMPage';
import PackagePage from './PackagePage';
import ProfilePage from './ProfilePage';
import ChatPage from './ChatPage';
import { selectNumberOfItems  } from '../features/basket/basketSlice';

// Icons
import { MdOutlineInventory } from 'react-icons/md';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { IoIosArrowDown } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";



export default function DashboardBase() {
  useEffect(() => {
    // Save the original overflow style
    const originalOverflow = document.body.style.overflow;
  
    // Set the overflow style to 'hidden'
    document.body.style.overflow = 'hidden';
  
    // Return a cleanup function that resets the overflow style
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);
  

  const toggleSidebar = useCallback(() => {
    setSidebarVisible(prev => !prev);
  }, []);

  // Handling SelectedPage
  const [selectedPage, setSelectedPage] = useState('Dashboard');
  const Dashboard = () => <DashboardPage />;
  const DSM = () => <DSMPage />;
  const Package = () => <PackagePage/>;
  const WebShop = () => <div></div>;
  const Profile = () => <ProfilePage />;
  const AssortiChat = () => <ChatPage/>;

  // Data from Local Storage
  const numberOfItems = useSelector(selectNumberOfItems);
  const user = JSON.parse(localStorage.getItem('user'));
  const [ profilePhototPath, setProfilePicturePath] = useState(user.user_data.profile_picture ? `./img/${user.user_data.profile_picture}` : "./img/database_media/users/default.jpg");

  // Handle DropMenu of Logout and Basket
  const [menuStatus, setMenuStatus] = useState(false);
  const menuStatusChange = useCallback(() => {
    setMenuStatus(prev => !prev);
  }, []);

  
  const [status, setStatus] = useState(localStorage.getItem('status'));
  const handleLogout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    localStorage.setItem('status', 'loggedout');
    setStatus(localStorage.getItem('status'));
    localStorage.removeItem('is_superuser');
    window.location.href = '/';
  }, []);


  return (
    <div key="1" className="min-h-screen bg-gray-50">
      <header className="fixed top-0 z-10 w-full bg-custom-blue">
        <div className="mx-auto flex max-w-7xl px-4 py-3">
          <h1 className="mt-2 text-xl leading-tight text-white flex-grow">
            <b>Welcome,</b> {user.user_data.fullname} to Assortimed Client Suite
          </h1>
          <img
            className="h-12 w-12 rounded-full ml-auto"
            src={profilePhototPath}
            alt="Profile"
          />
          <button className="ml-4 flex items-center font-bold justify-center rounded-full"
                  onClick={menuStatusChange}>
            <IoIosArrowDown className="h-4 w-4 text-white" />
          </button>
        </div>
      </header>
      {menuStatus && (
        <div className="absolute right-2 top-16 w-48 bg-white text-gray-600 font-semibold rounded-md shadow-lg z-20">
          <ul>
            <li className="px-4 py-6 hover:bg-gray-100 hover:text-bold cursor-pointer flex justify-between items-center">
              <Link to="/basket">Basket</Link>
              <span className='bg-custom-blue text-white font-bold rounded-full px-2'>{numberOfItems}</span>
            </li>
            <li className="border-t mt-2 border-gray-300 my-2"></li>
            <li className="px-4 py-2 hover:bg-gray-100 hover:text-bold cursor-pointer">
              <button className='w-full flex justify-between items-center'
                      onClick={handleLogout}>
                Logout
                <IoLogOutOutline className="h-5 w-5" />
              </button>
            </li>
          </ul>
        </div>
      )}

      <button className="lg:hidden" onClick={toggleSidebar}>
        Toggle Sidebar
      </button>
      
      <main className="overflow-hidden pt-20">
        <div className="mx-auto max-w-full">
          <div className="sm:px-0">
            <div className="flex h-screen overflow-hidden">
              <div className="h-100 w-25 fixed top-20 hidden bg-gray-50 shadow lg:block">
                <aside
                  aria-label="Sidebar"
                  className="h-100 ml-auto hidden w-60 lg:block"
                >
                  <div className="h-100 xl:pl-21 right-0 overflow-y-auto bg-gray-50 py-4">
                    <ul className="space-y-4">
                      <li className="mr-1 px-2">
                        <Link
                          to="/"
                          className="group flex items-center rounded-lg text-base font-normal text-gray-900">
                          <img
                            src="../img/Logo.png"
                            alt="Dashboard"
                            className="w-90 h-19 left-0 mb-10 transition duration-75"
                          />
                        </Link>
                      </li>
                      <li
                        className={`border-r-4 hover:border-custom-green cursor-pointer
                            ${selectedPage === 'Dashboard' ? 'border-custom-green' : 'border-transparent'}`}>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedPage('Dashboard')
                          }}
                          className={`group flex items-center rounded-lg p-2 text-base font-normal 
                        text-gray-900 ${selectedPage === 'Dashboard' ? 'bg-gray-100' : 'bg-none'} hover:bg-gray-100`}
                          
                        >
                          <LayoutDashboardIcon
                            className={`h-6 w-6 transition duration-75 
                                ${selectedPage === 'Dashboard' ? 'text-custom-green' : 'text-gray-500'}
                                 group-hover:text-custom-green`}
                          />
                          <span
                            className={`ml-3 ${selectedPage === 'Dashboard' ? 'font-bold text-blue-900' : ''}
                                   group-hover:font-bold group-hover:text-blue-900`}
                          >
                            Dashboard
                          </span>
                        </a>
                      </li>
                      <li
                        className={`border-r-4 hover:border-custom-green cursor-pointer
                          ${selectedPage === 'DSM' ? 'border-custom-green' : 'border-transparent'}`}
                      >
                        <a
                          onClick={(e) => {
                            e.preventDefault(); 
                            setSelectedPage('DSM')
                          }}
                          className={`group flex items-center rounded-lg p-2 text-base font-normal 
                              text-gray-900 ${selectedPage === 'DSM' ? 'bg-gray-100' : 'bg-none'} hover:bg-gray-100`}
                          
                        >
                          <MdOutlineInventory
                            className={`h-6 w-6 transition duration-75 
                                ${selectedPage === 'DSM' ? 'text-custom-green' : 'text-gray-500'}
                                 group-hover:text-custom-green`}
                          />
                          <span
                            className={`ml-3 ${selectedPage === 'DSM' ? 'font-bold text-blue-900' : ''}
                                   group-hover:font-bold group-hover:text-blue-900`}
                          >
                            DSM
                          </span>
                        </a>
                      </li>
                      <li
                        className={`border-r-4 hover:border-custom-green cursor-pointer
                          ${selectedPage === 'Package' ? 'border-custom-green' : 'border-transparent'}`}
                      >
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedPage('Package')
                          }}
                          className={`group flex items-center rounded-lg p-2 text-base font-normal 
                                  text-gray-900 ${selectedPage === 'Package' ? 'bg-gray-100' : 'bg-none'}
                                   hover:bg-gray-100`}
                          
                        >
                          <PackageIcon
                            className={`h-6 w-6 transition duration-75 
                                ${selectedPage === 'Package' ? 'text-custom-green' : 'text-gray-500'}
                                 group-hover:text-custom-green`}
                          />
                          <span
                            className={`ml-3 ${selectedPage === 'Package' ? 'font-bold text-blue-900' : ''}
                               group-hover:font-bold group-hover:text-blue-900`}
                          >
                            Package
                          </span>
                        </a>
                      </li>
                      <li className="group border-r-4 border-transparent hover:border-custom-green">
                        <Link
                          to="/usershoplist"
                          className="group flex items-center rounded-lg p-2 text-base font-normal 
                                text-gray-900 hover:bg-gray-100 "
                          
                        >
                          <ShoppingBagIcon
                            className="h-6 w-6 text-gray-500 transition duration-75
                                 group-hover:text-custom-green"
                          />
                          <span className="ml-3 group-hover:font-bold group-hover:text-blue-900">
                            Web Shop
                          </span>
                        </Link>
                      </li>
                      <li
                        className={`border-r-4 hover:border-custom-green cursor-pointer
                          ${selectedPage === 'Profile' ? 'border-custom-green' : 'border-transparent'}`}>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedPage('Profile')
                          }}
                          className={`group flex items-center rounded-lg p-2 text-base font-normal text-gray-900
                               ${selectedPage === 'Profile' ? 'bg-gray-100' : 'bg-none'} hover:bg-gray-100`}>
                          <UserIcon
                            className={`h-6 w-6 transition duration-75 
                                  ${selectedPage === 'Profile' ? 'text-custom-green' : 'text-gray-500'}
                                   group-hover:text-custom-green`}
                          />
                          <span
                            className={`ml-3 ${selectedPage === 'Profile' ? 'font-bold text-blue-900' : ''}
                               group-hover:font-bold group-hover:text-blue-900`}>
                            My Profile
                          </span>
                        </a>
                      </li>
                      <li
                        className={`border-r-4 hover:border-custom-green cursor-pointer
                          ${selectedPage === 'AssortiChat' ? 'border-custom-green' : 'border-transparent'}`}>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedPage('AssortiChat')
                          }}
                          className={`group flex items-center rounded-lg p-2 text-base font-normal text-gray-900
                             ${selectedPage === 'AssortiChat' ? 'bg-gray-100' : 'bg-none'} hover:bg-gray-100`}>
                          <IoChatbubblesOutline
                            className={`h-6 w-6 transition duration-75 
                                    ${selectedPage === 'AssortiChat' ? 'text-custom-green' : 'text-gray-500'}
                                     group-hover:text-custom-green`}
                          />
                          <span
                            className={`ml-3 ${selectedPage === 'AssortiChat' ? 'font-bold text-blue-900' : ''}
                               group-hover:font-bold group-hover:text-blue-900`}>
                            AssortiChat
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
              <div className="min-w-0 flex-1 bg-gray-50 pl-6 sm:pl-0 md:pl-0 lg:pl-48 xl:pl-96"
                    style={{ overflowY: 'auto',  height: 'calc(100vh - 80px)' }}>
                <div>
                <div style={{ display: selectedPage === 'Dashboard' ? 'block' : 'none'}}>
                  <Dashboard />
                </div>
                <div style={{ display: selectedPage === 'DSM' ? 'block' : 'none' }}>
                  <DSM />
                </div>
                <div style={{ display: selectedPage === 'Package' ? 'block' : 'none' }}>
                  <Package />
                </div>
                <div style={{ display: selectedPage === 'WebShop' ? 'block' : 'none' }}>
                  <WebShop />
                </div>
                <div style={{ display: selectedPage === 'Profile' ? 'block' : 'none' }}>
                  <Profile />
                </div>
                <div style={{ display: selectedPage === 'AssortiChat' ? 'block' : 'none' }}>
                  <AssortiChat />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

function LayoutDashboardIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="7" height="9" x="3" y="3" rx="1" />
      <rect width="7" height="5" x="14" y="3" rx="1" />
      <rect width="7" height="9" x="14" y="12" rx="1" />
      <rect width="7" height="5" x="3" y="16" rx="1" />
    </svg>
  );
}

function PackageIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m7.5 4.27 9 5.15" />
      <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
      <path d="m3.3 7 8.7 5 8.7-5" />
      <path d="M12 22V12" />
    </svg>
  );
}

function ShoppingBagIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z" />
      <path d="M3 6h18" />
      <path d="M16 10a4 4 0 0 1-8 0" />
    </svg>
  );
}

function UserIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  );
}
