import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Radio } from '@mui/material';
import { useFormik } from 'formik';
// import { useDispatch } from 'react-redux';
// import { setRepeatOrderValue } from '../../features/basket/repeatOrderSlice';

const FormControlLabel = ({
  name,
  value,
  label,
  checked,
  onChange,
  onBlur,
}) => (
  <label className="flex items-center space-x-2">
    <Radio
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      sx={{
        '& .MuiSvgIcon-root': { fontSize: 20 },
        color: '#a6a7a8',
        '&.Mui-checked': { color: '#2849ad' },
      }}
    />
    <div className="text-sm">
      <p className="text-sm font-semibold">{label}</p>
    </div>
  </label>
);

const DayPickerDropdown = ({ selectedDay, onSelectDay }) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  return (
    <div className="m-2 h-fit rounded-lg bg-white">
      {daysOfWeek.map((day) => (
        <div
          key={day}
          onClick={() => onSelectDay(day)}
          className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          {day}
        </div>
      ))}
    </div>
  );
};

const WeekPickerDropdown = ({ selectedWeek, onSelectWeek }) => {
  const weeksOfMonth = ['1. Week', '2. Week', '3. Week', '4. Week'];

  return (
    <div className="m-2 h-fit rounded-lg bg-white">
      {weeksOfMonth.map((week) => (
        <div
          key={week}
          onClick={() => onSelectWeek(week)}
          className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          {week}
        </div>
      ))}
    </div>
  );
};


export default function RepeatingOrder({ repeatOrder }) {
  // // dispatch reqpestOrder
  // const dispatch = useDispatch();


  const formik = useFormik({
    initialValues: {
      repeatingType: 'weekly',
      selectedDays: {
        weekly: 'Friday',
        byWeekly: 'Saturday',
        threeWeeks: 'Saturday',
        monthly: '1. Week',
        biMonthly: '1. Week',
        quarterly: '1. Week',
      },
    },
    onSubmit: (values) => {
      let selectedOption = {};
      const repeatingType = values.repeatingType;
      const selectedDay = values.selectedDays[repeatingType];
      selectedOption[repeatingType] = { type: repeatingType, selectedDay };
      if (repeatOrder) {
        repeatOrder(selectedOption);
      }
      // dispatch(setRepeatOrderValue(selectedOption));
      console.log("RepeatingOrder: ", selectedOption);
    },
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState({
    weekly: false,
    byWeekly: false,
    threeWeeks: false,
    monthly: false,
    biMonthly: false,
    quarterly: false,
  });

  const handlesetRepeatingTypeChange = (event) => {
    formik.setFieldValue('repeatingType', event.target.value);
    formik.handleSubmit();
  };
  const handleSelectDay = (type, day) => {
    formik.setFieldValue(`selectedDays.${type}`, day);
    setIsDropdownOpen((prev) => ({ ...prev, [type]: false }));
    formik.handleSubmit();
  };


  const handleDropdownToggle = (type) => {
    setIsDropdownOpen((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const divStyle = (type) => ({
    border: formik.values.repeatingType === type ? '2px solid #2849ad' : 'none',
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="min-h-[375px] rounded-lg bg-[#f6f6f6]">
          <div
            className="m-2 flex justify-between rounded-lg bg-white px-4 py-2"
            style={divStyle('weekly')}
          >
            <div className="flex items-center space-x-2 text-xs">
              <FormControlLabel
                name="repeatingType"
                value="weekly"
                label="Weekly"
                checked={formik.values.repeatingType === 'weekly'}
                onChange={handlesetRepeatingTypeChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="relative flex items-center space-x-2 text-xs">
              <button
                type="button"
                className="flex items-center space-x-2 text-xs font-semibold text-custom-blue"
                onClick={() => handleDropdownToggle('weekly')}
              >
                <span>{formik.values.selectedDays.weekly}</span>
                {isDropdownOpen.weekly ? (
                  <IoIosArrowUp className=" cursor-pointer text-lg text-[#a6a7a8]" />
                ) : (
                  <IoIosArrowDown className=" cursor-pointer text-lg text-[#a6a7a8]" />
                )}
              </button>
            </div>
          </div>

          {isDropdownOpen.weekly && (
            <DayPickerDropdown
              selectedDay={formik.values.selectedDays.weekly}
              onSelectDay={(day) => handleSelectDay('weekly', day)}
            />
          )}

          <div
            className="m-2 flex justify-between rounded-lg bg-white px-4 py-2"
            style={divStyle('byWeekly')}
          >
            <div className="flex items-center space-x-2 text-xs">
              <FormControlLabel
                name="repeatingType"
                value="byWeekly"
                label="By weekly"
                checked={formik.values.repeatingType === 'byWeekly'}
                onChange={handlesetRepeatingTypeChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="relative flex items-center space-x-2 text-xs">
              <button
                type="button"
                className="flex items-center space-x-2 text-xs font-semibold text-custom-blue"
                onClick={() => handleDropdownToggle('byWeekly')}
              >
                <span>{formik.values.selectedDays.byWeekly}</span>
                {isDropdownOpen.byWeekly ? (
                  <IoIosArrowUp className=" cursor-pointer text-lg text-[#a6a7a8]" />
                ) : (
                  <IoIosArrowDown className=" cursor-pointer text-lg text-[#a6a7a8]" />
                )}
              </button>
            </div>
          </div>

          {isDropdownOpen.byWeekly && (
            <DayPickerDropdown
              selectedDay={formik.values.selectedDays.byWeekly}
              onSelectDay={(day) => handleSelectDay('byWeekly', day)}
            />
          )}

          <div
            className="m-2 flex justify-between rounded-lg bg-white px-4 py-2"
            style={divStyle('threeWeeks')}
          >
            <div className="flex items-center space-x-2 text-xs">
              <FormControlLabel
                name="repeatingType"
                value="threeWeeks"
                label="Once in three weeks"
                checked={formik.values.repeatingType === 'threeWeeks'}
                onChange={handlesetRepeatingTypeChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="relative flex items-center space-x-2 text-xs">
              <button
                type="button"
                className="flex items-center space-x-2 text-xs font-semibold text-custom-blue"
                onClick={() => handleDropdownToggle('threeWeeks')}
              >
                <span>{formik.values.selectedDays.threeWeeks}</span>
                {isDropdownOpen.threeWeeks ? (
                  <IoIosArrowUp className=" cursor-pointer text-lg text-[#a6a7a8]" />
                ) : (
                  <IoIosArrowDown className=" cursor-pointer text-lg text-[#a6a7a8]" />
                )}
              </button>
            </div>
          </div>

          {isDropdownOpen.threeWeeks && (
            <DayPickerDropdown
              selectedDay={formik.values.selectedDays.threeWeeks}
              onSelectDay={(day) => handleSelectDay('threeWeeks', day)}
            />
          )}

          <div
            className="m-2 flex justify-between rounded-lg bg-white px-4 py-2"
            style={divStyle('monthly')}
          >
            <div className="flex items-center space-x-2 text-xs">
              <FormControlLabel
                name="repeatingType"
                value="monthly"
                label="Monthly"
                checked={formik.values.repeatingType === 'monthly'}
                onChange={handlesetRepeatingTypeChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="relative flex items-center space-x-2 text-xs">
              <button
                type="button"
                className="flex items-center space-x-2 text-xs font-semibold text-custom-blue"
                onClick={() => handleDropdownToggle('monthly')}
              >
                <span>{formik.values.selectedDays.monthly}</span>
                {isDropdownOpen.monthly ? (
                  <IoIosArrowUp className=" cursor-pointer text-lg text-[#a6a7a8]" />
                ) : (
                  <IoIosArrowDown className=" cursor-pointer text-lg text-[#a6a7a8]" />
                )}
              </button>
            </div>
          </div>

          {isDropdownOpen.monthly && (
            <WeekPickerDropdown
              selectedWeek={formik.values.selectedDays.monthly}
              onSelectWeek={(week) => handleSelectDay('monthly', week)}
            />
          )}

          <div
            className="m-2 flex justify-between rounded-lg bg-white px-4 py-2"
            style={divStyle('biMonthly')}
          >
            <div className="flex items-center space-x-2 text-xs">
              <FormControlLabel
                name="repeatingType"
                value="biMonthly"
                label="By monthly"
                checked={formik.values.repeatingType === 'biMonthly'}
                onChange={handlesetRepeatingTypeChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="relative flex items-center space-x-2 text-xs">
              <button
                type="button"
                className="flex items-center space-x-2 text-xs font-semibold text-custom-blue"
                onClick={() => handleDropdownToggle('biMonthly')}
              >
                <span>{formik.values.selectedDays.biMonthly}</span>
                {isDropdownOpen.biMonthly ? (
                  <IoIosArrowUp className=" cursor-pointer text-lg text-[#a6a7a8]" />
                ) : (
                  <IoIosArrowDown className=" cursor-pointer text-lg text-[#a6a7a8]" />
                )}
              </button>
            </div>
          </div>

          {isDropdownOpen.biMonthly && (
            <WeekPickerDropdown
              selectedWeek={formik.values.selectedDays.biMonthly}
              onSelectWeek={(week) => handleSelectDay('biMonthly', week)}
            />
          )}

          <div
            className="m-2 flex justify-between rounded-lg bg-white px-4 py-2"
            style={divStyle('quarterly')}
          >
            <div className="flex items-center space-x-2 text-xs">
              <FormControlLabel
                name="repeatingType"
                value="quarterly"
                label="Quarterly"
                checked={formik.values.repeatingType === 'quarterly'}
                onChange={handlesetRepeatingTypeChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="relative flex items-center space-x-2 text-xs">
              <button
                type="button"
                className="flex items-center space-x-2 text-xs font-semibold text-custom-blue"
                onClick={() => handleDropdownToggle('quarterly')}
              >
                <span>{formik.values.selectedDays.quarterly}</span>
                {isDropdownOpen.quarterly ? (
                  <IoIosArrowUp className=" cursor-pointer text-lg text-[#a6a7a8]" />
                ) : (
                  <IoIosArrowDown className=" cursor-pointer text-lg text-[#a6a7a8]" />
                )}
              </button>
            </div>
          </div>

          {isDropdownOpen.quarterly && (
            <WeekPickerDropdown
              selectedWeek={formik.values.selectedDays.quarterly}
              onSelectWeek={(week) => handleSelectDay('quarterly', week)}
            />
          )}
        </div>
      </form>
    </>
  );
}
