import React from 'react';
import { BsClipboard2CheckFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import styles from './BaseSign.module.css';
import './ConfirmR.css';

const ConfirmR = () => {
  const { t } = useTranslation();

  return (

    <div className={styles["my-component-container"]}>
    <div className={styles['left-col']}>
      <div className={styles['background-image']}>
      <div className={`${styles['header-container']} ${styles['no-form']}`}>
        <div className={styles['icon-parent']}>
        <BsClipboard2CheckFill className='icon'/>
        </div>
        <h4 className={styles['noform-title']}>
            {t('signprocess.confirmR1')}</h4>
        <p className={styles['subTitle']}>
            {t('signprocess.confirmR2')}</p>
        <p className={styles['subTitle3']}>
            {t('signprocess.confirmr4')}</p>
        <p className={styles['subTitle2']}>
            {t('signprocess.confirmr3')}</p>
       
        </div>
      </div>
    </div>
    <div className={styles["right-col"]}>
      <div className={styles['partially-covered-image']}>
      </div>
    </div>
  </div>
);
};
export default ConfirmR;