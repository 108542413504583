import { configureStore } from '@reduxjs/toolkit';
import basketReducer from '../src/features/basket/basketSlice';
import addressReducer from '../src/features/basket/addressSlice';

export default configureStore({
  reducer: {
    basket: basketReducer,
    address: addressReducer,
  },
});
