import React, { useState } from "react";


// In ComBase component
export default function DelCom({ delRecord }) {

  
    return (
        <div className="flex flex-col justify-center ">
                
            {/** Delivery Profile Information */}
            <div className="w-[95%] h-full p-6 rounded-md self-center">
                
                <form class="w-full max-w-lg">    
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="dname">
                                Profile Name
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="dname" type="text" value={delRecord?.dname? delRecord.dname : "No Name Yet"} readOnly/>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="route">
                            Route Number
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="route" type="text" value={delRecord?.route_number? delRecord.route_number : ""} readOnly/>
                    </div>
                    </div>
                    <div class="flex flex-wrap mb-6">
                    <div class="w-full md:w-full mb-6 md:mb-0">
                        <span className="text-lg text-custom-blue font-semibold">
                            Contact Personal Information
                        </span>
                    </div>
                </div>
                
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="full-name">
                                Full Name
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="full-name" type="text" value={delRecord?.contact_person_name? delRecord.contact_person_name : ""} readOnly/>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="phone">
                            Phone
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="phone" type="phone" value={delRecord?.contact_person_phone? delRecord.contact_person_phone : ""} readOnly/>
                    </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-address">
                            Business Email
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-password" type="address" value={delRecord?.contact_person_email? delRecord.contact_person_email : ""} readOnly/>
                        </div>
                    </div>
                   

                <div class="flex flex-wrap mb-6">
                    <div class="w-full md:w-full mb-6 md:mb-0">
                        <span className="text-lg text-custom-blue font-semibold">
                            Delivery Location
                        </span>
                    </div>
                </div>
                
                <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="ddept">
                                Department
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="ddept" type="text" value={delRecord?.ddept? delRecord.ddept : ""} readOnly/>
                        </div>
                    </div>

                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="saddress">
                            Standard Address
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="saddress" type="address" value={delRecord?.sdaddress? delRecord.sdaddress : ""} readOnly/>
                        </div>
                    </div>
                
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="daddress">
                            Delivery Address
                        </label>
                        <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="daddress" type="address" value={delRecord?.daddress? delRecord.daddress : ""} readOnly/>
                        </div>
                    </div>


                    <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="dpostal">
                                Postal
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="dpostal" type="postal" value={delRecord?.dpostal? delRecord.dpostal : ""} readOnly/>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="dcity">
                                City
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="dcity" type="city" value={delRecord?.dcity? delRecord.dcity : ""} readOnly/>
                        </div>
                        {/* <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="dcountry">
                                Country
                            </label>
                            <input class="appearance-none block w-full bg-teal-50 text-gray-700 font-semibold border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="dcountry" type="country" value={delRecord?.dcity? delRecord.dcity : ""} readOnly/>
                        </div> */}
                    </div>

                   
                </form>
            </div>
        

        {/** Payment & Delivey Profiles Information */}
        <div className="flex flex-col items-center mb-4">
            <div className="w-4/5 border-t border-custom-blue"></div>
        </div>
    </div>
    );
  }