import React, { useState } from 'react';
import Select from 'react-select';

// Icons Imports
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { MdApartment } from 'react-icons/md';
import { MdOutlineDateRange } from 'react-icons/md';
import { GrStatusGood } from 'react-icons/gr';
import { AiOutlineRedo } from 'react-icons/ai';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { TbBasketPause } from 'react-icons/tb';
import { MdOutlineMotionPhotosOff } from 'react-icons/md';
import { IoMdPricetags } from 'react-icons/io';
import { TbTruckDelivery } from 'react-icons/tb';
import { TiInfoLargeOutline } from 'react-icons/ti';
import { PiWifiLowBold } from 'react-icons/pi';
import { PiWifiHighBold } from 'react-icons/pi';
import { CgDollar } from 'react-icons/cg';
import { BsCalendarDateFill } from "react-icons/bs";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdContactMail } from "react-icons/md";




const InfoCom = () => {
  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
  //console.log(user);

  const rows = [
    {
      icon: <MdApartment />,
      label: "Institution name",
      info: user.company_data.cname,
    },
    {
      icon: <LiaFileInvoiceDollarSolid />,
      label: "Chamber of Commerce",
      info: user.company_data.cod_number,
    },
    {
      icon: <IoMdPricetags />,
      label: "VAT Tax",
      info: user.company_data.vat,
    },
    {
      icon: <BsCalendarDateFill />,
      label: "Contract Start Date",
      info: user.company_data.contract_start_date,
    },
    {
      icon: <BsCalendarDateFill />,
      label: "Contract End Date",
      info: user.company_data.contract_end_date,
    },
    {
      icon: <MdApartment />,
      label: "Main Office",
      info: user.company_data.mainoffice,
    },
    {
      icon: <FaMapLocationDot />,
      label: "Country",
      info: user.company_data.ccountry,
    },
    {
      icon: <FaMapLocationDot />,
      label: "City",
      info: user.company_data.ccity,
    },
    {
      icon: <FaMapLocationDot />,
      label: "Main Address",
      info: user.company_data.caddress,
    },
    {
      icon: <MdContactMail />,
      label: "Postal Code",
      info: user.company_data.cpostalcode,
    },
  ];

  return (
    <table className="table-auto w-full">
      <tbody>
        {rows.map((row) => (
          <tr key={row} className='h-14'>
            <td className="w-1/4 px-20 py-2 text-left bg-teal-100-accent"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <div className="flex items-center">
              <span className='text-base font-bold text-custom-green'>
                {row.icon}
                </span>
              
              <span className="ml-3 font-semibold"
                    style={{fontSize: "16px"}}>
                {row.label}
                </span>
            </div>
          </td>
            <td className="border-b px-20 py-2 text-right">
            <span className="ml-2 font-semibold"
                  style={{fontSize: "16px"}}>
              {row.info}
              </span>
              </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InfoCom;