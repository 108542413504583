import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './AboutUs.module.css';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import JoinUs from '../../components/Info/JoinUs';
import RightSection from '../../components/Info/RSec';
import LeftSection from '../../components/Info/LSec';
import VBanner from '../../components/Info/VideoBanner';



export default function ClientInfo() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav />
      <VBanner videoUrl='./img/videos/clientsuite.mp4' title={t('c-suite.title')}/>

      <div className={styles.dsmBackground}>
        <LeftSection 



          head={t('c-suite.head1')}

          text={t('c-suite.p1')}
          imgSrc={'/img/info/2.png'} />

        <RightSection



          head={t('c-suite.head2')} 

          text={t('c-suite.p2')}
          imgSrc={'/img/info/7.png'} />

        <LeftSection 



          head={t('c-suite.head3')}

          text={t('c-suite.p3')}
          imgSrc={'/img/info/8.png'} />

        <RightSection



          head={t('c-suite.head4')} 

          text={t('c-suite.p4')}
          imgSrc={'/img/info/9.png'} />

        <LeftSection 



          head={t('c-suite.head3')}

          text={t('c-suite.p5')}
          imgSrc={'/img/info/10.png'} />

        <RightSection



          head={t('c-suite.head4')} 

          text={t('c-suite.p6')}
          imgSrc={'/img/info/11.png'} />
      
      </div>

        <JoinUs />
      <Footer />
    </>
  )
}
