import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import RightSection from '../../components/Info/RSec';
import LeftSection from '../../components/Info/LSec';
import CareerSlider2 from '../../components/Career/CareerSlider2';
import FindJob from '../../components/Career/FindJob';
import VBanner from '../../components/Info/VideoBanner';
import CInfographic1 from '../../components/Career/CareerInfographic1';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Career.module.css';
import dsmstyles from '../Info/DSM.module.css';



export default function Career() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav />
      <VBanner videoUrl='./img/videos/career.mp4' title={t('career.title')}/>
      <div className={dsmstyles.dsmBackground}>
      <LeftSection 
          head={t('career.sub1')}
          text={t('career.p1')}
          imgSrc={'/img/career/1.png'} />

        <RightSection
          head={t('career.head1')}
          text={t('career.p2')}
          imgSrc={'/img/career/2.png'} />

        <LeftSection 
          head={t('career.head2')}
          text={t('career.p3')}
          imgSrc={'/img/career/3.png'} />

        <RightSection
          head={t('career.head3')}
          text={t('career.p4')}
          imgSrc={'/img/career/4.png'} />

        <LeftSection 
          head={t('career.head4')}
          text={t('career.p5')}
          imgSrc={'/img/career/7.png'}
          link='/contact'
          linkText='Join us' />
        
        <div className={`${styles.careerSlider1} ${dsmstyles.dsmBackground}`}>
          <h5>{t('career.sub2')}</h5>
          <div className={styles.careerSliderCards1}>
           <CInfographic1 />
            </div>
        </div>
        
        <div className={` container ${styles.careerSlider2}`}>
          <h5>{t('career.sub3')}</h5>
              <CareerSlider2 />
        </div>
      </div>
      
      <FindJob/>
      <Footer />
    </>
  )
}
