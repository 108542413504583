import React, { useEffect } from 'react';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import SuccessR from '../../components/Sign/SuccessR';
import useLogged from '../../hooks/useLogged';

export default function SuccessRPage() {
  useLogged();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav/>
         <SuccessR />
        <Footer/>
    </>
  )
}
