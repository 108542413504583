import React, { useEffect } from 'react';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import JoinUs from '../../components/Info/JoinUs';
import RightSection from '../../components/Info/RSec';
import LeftSection from '../../components/Info/LSec';
import VBanner from '../../components/Info/VideoBanner';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './DSM.module.css';

import { useTranslation } from 'react-i18next';

export default function AboutUs() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav />
      <VBanner videoUrl='./img/videos/home.mp4' title={t('about-us.title')}/>
       
      <div className={styles.dsmBackground}>
        {/* <div className={styles.about}>
        <h3 className={styles.dsmtitle}>
            {t('about-us.title')}
            <br/>
            {t('about-us.sub')}
          </h3>
        </div> */}

        <LeftSection 
          head={t('about-us.head1')}
          text={t('about-us.p1')}
          imgSrc={'/img/info/21.png'} />

        <RightSection
          head={t('about-us.head2')} 
          text={t('about-us.p2')}
          imgSrc={'/img/info/22.png'} />

        <LeftSection 
          head={t('about-us.head3')}
          text={t('about-us.p3')}
          imgSrc={'/img/info/28.png'} />

        <RightSection
          head={t('about-us.head4')} 
          text={t('about-us.p4')}
          imgSrc={'/img/dsm-small.jpg'} />

        <LeftSection 
          head={t('about-us.head5')}
          text={t('about-us.p5')}
          imgSrc={'/img/contact.jpg'} />

        </div>

        <JoinUs />
      <Footer />
    </>
  )
}
