import React, {useState, useMemo, useEffect} from "react";
import axios from "axios";

// Icon imports
import { FaBoxOpen, FaCalendarWeek, FaEdit, FaFileInvoice, FaTimesCircle } from "react-icons/fa";
import { FaBuilding, FaShoppingCart, FaCreditCard, FaTruck, FaUser } from 'react-icons/fa';
import { FaCheckCircle } from "react-icons/fa";
import { MdStorage } from 'react-icons/md';


// Local Imports
import EntityCom from "./EntityCom";
import UserCom from "./UserCom";
import PayCom from "./PayCom";
import DelCom from "./DelCom";
import EntitySum from "./EntitySum";
import OrderSum from "./OrderSum";
import InvoiceSum from "./InvoiceSum";
import UserSum from "./UserSum";
import PaySum from "./PaySum";
import DelSum from "./DevSum";
import PackSum from "./PackSum";
import PackProductSum from "./PackProductItem";
import DSMSum from "./DSMSum";
import DSMProductSum from "./DSMProductItem";


export default function TL2 ()  {
    const iconMap = {
      entity: <FaBuilding />,
      order: <FaShoppingCart />,
      pay: <FaCreditCard />,
      del: <FaTruck />,
      user: <FaUser />
    };


    const [items, setItems] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState({
      checkbox1: { isChecked: false, label: "All" },
      checkbox2: { isChecked: false, label: "Read" },
      checkbox3: { isChecked: true, label: "Unread" },
    });

    // Fetch notifications from the server
    useEffect(() => {
      const fetchNotifications = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/notifications/`);
          // setItems(response.data);
          if (response.status === 200 || response.status === 201) {
            setItems(response.data);
            console.log("Notification Fetch successful");
            console.log(response);
          }
        } catch (error) {
          console.error(`Error fetching notifications: ${error}`);
        }
      };

      fetchNotifications();
    }, []);
    
    // Function to handle checkbox state change
    const handleCheckboxChange = (id) => {
      setCheckboxStates(prevState => {
        // Create a new state object with all checkboxes set to false
        const newState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = { ...prevState[key], isChecked: false };
          return acc;
        }, {});
    
        // Set the clicked checkbox to true
        newState[id].isChecked = true;
    
        return newState;
      });
    };


    const itemsWithIcons = useMemo(() => {
      // Determine the current filter based on the checkbox state
      let currentFilter = null;
      Object.entries(checkboxStates).forEach(([key, value]) => {
        if (value.isChecked) {
          currentFilter = value.label.toLowerCase(); // "all", "read", or "unread"
        }
      });
    
      // Filter items based on the current filter
      const filteredItems = items.filter(item => {
        if (currentFilter === "all") return true; // If "all", no filtering is needed
        return item.status === currentFilter; // Otherwise, filter by "read" or "unread"
      });
    
      // Sort and map items to add icons
      return filteredItems
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map(item => ({
          ...item,
          icon: iconMap[item.ntype],
        }));
    }, [items, checkboxStates]); // Depend on items and checkboxStates to recompute when they change

    const markAsRead = (itemId) => {
      const newItems = items.map(item => 
        item.id === itemId ? { ...item, status: 'read' } : item
      );
      setItems(newItems);
    };

    // Detailed Item Display
    const [detailedItem, setDetailedItem] = useState(null);
    const [previewedItemId, setPreviewedItemId] = useState(null);

    const handlePreviewClick = (item) => {
      // Toggle the preview for the clicked item
      if (previewedItemId === item.id) {
        setPreviewedItemId(null); // Close the preview if the same item is clicked again
      } else {
        setPreviewedItemId(item.id); // Open the preview for the clicked item
        setDetailedItem(item);
      }
    };


    
    return (
      <div className="flex flex-row w-full">
        {/* Scrollable Section */}
        <div className="w-[45%] h-[78vh] flex flex-col gap-2 self-center px-10 overflow-auto">
          {/* Header Section -- Display Selections */}
          <div className="sticky top-0 w-full self-center bg-slate-50 flex justify-between">
            <h3 className="mb-6 text-2xl font-bold text-custom-blue">
              Notifications
            </h3>
            <div className="flex flex-row gap-3 items-center mb-3">
              {Object.entries(checkboxStates).map(([id, {isChecked, label}]) => (
                <div className="flex items-center" key={id}>
                  <label className="flex items-center cursor-pointer text-custom-blue font-semibold">
                    <input
                      type="checkbox"
                      className="sr-only"
                      id={id}
                      name={id}
                      checked={isChecked}
                      onChange={() => handleCheckboxChange(id)}
                    />
                    <button
                      className="w-5 h-5 inline-flex justify-center items-center mr-2 border-2 border-custom-blue rounded bg-white focus:outline-none"
                      onClick={() => handleCheckboxChange(id)}
                      aria-checked={isChecked}
                      role="checkbox"
                    >
                      <svg className={`w-4 h-4 text-custom-blue ${isChecked ? '' : 'hidden'}`} fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M5 13l4 4L19 7"></path>
                      </svg>
                    </button>
                    {label}
                  </label>
                </div>
              ))}
            </div>
          </div>
    
          <ol className="border-s-2 border-neutral-400">
            {itemsWithIcons.map((item, index) => (
              <li key={index}>
                <div className="flex-start md:flex">
                  <div className="-ms-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full text-custom-blue">
                    <FaCalendarWeek className="h-4 w-4 text-custom-blue" />
                  </div>
                  <div className={`mb-10 ms-6 block max-w-md rounded-lg border-1 ${previewedItemId === item.id ? 'border-custom-blue bg-teal-50' : 'hover:border-custom-blue bg-neutral-100 hover:bg-teal-50'} p-6 shadow shadow-neutral-200`}>
                    <div className="mb-4 flex justify-between">
                      <a href="#!" className="flex items-center text-base text-custom-blue font-bold transition duration-150 ease-in-out">
                        <span className="mr-2 text-custom-green">{item.icon}</span> {item.title}
                      </a>
                      <a href="#!" className="text-sm text-custom-blue font-semibold transition duration-150 ease-in-out">{item.date}</a>
                    </div>
                    <p className="mb-6 text-neutral-700 font-medium">{item.description}</p>
                    <button type="button" 
                      className={`inline-block rounded border-2 px-4 pb-[5px] pt-[6px] text-xs font-bold uppercase leading-normal transition duration-150 ease-in-out mr-4 ${
                        previewedItemId === item.id
                          ? 'border-custom-blue bg-white text-custom-blue hover:bg-white hover:text-custom-blue hover:border-custom-blue'
                          : 'bg-custom-blue text-neutral-50 hover:bg-white hover:text-custom-blue hover:border-custom-blue'
                      }`}
                      data-twe-ripple-init data-twe-ripple-color="light"
                      onClick={() => handlePreviewClick(item)}>
                      Preview
                    </button>
                    {item.status === 'unread' ?
                      <button type="button" 
                          className="inline-block rounded border-2 border-custom-green px-4 pb-[3px] pt-[4px] text-xs font-extrabold uppercase leading-normal text-teal-500 bg-neutral-50 transition duration-150 ease-in-out hover:bg-custom-green hover:text-neutral-50"
                        data-twe-ripple-init
                        onClick={() => markAsRead(item.id)}>
                          READ
                    </button>
                    : 
                    <button type="button" 
                    className="inline-block rounded px-4 pb-[3px] pt-[4px] text-xs font-extrabold uppercase leading-normal text-teal-500 transition duration-150 ease-in-out"
                  data-twe-ripple-init>
                    <div className="flex items-center gap-2">
                    <FaCheckCircle icon="primary" size={15} />
                    <span className="text-sm">Marked as Read</span>
                    </div>
                  </button>
                    }
                  </div>
                </div>
              </li>
            ))}
          </ol>
          {itemsWithIcons.length === 0?
              <div className="flex flex-col h-[500px] items-center justify-center">
                <FaCalendarWeek size={60} className="text-gray-400 mb-3" />
              <p className="text-sm font-semibold text-gray-500">No Unread Notifications!</p>
            </div>
            : null}
        </div>
    
        {/* Fixed Height Section */}
        <div className="w-[55%] h-[78vh] flex flex-col px-10 overflow-auto ">
          <div className="sticky top-0 w-full self-center flex justify-between bg-slate-50">
              <h3 className="mb-6 text-2xl font-bold text-custom-blue">
                {detailedItem && detailedItem.title}
              </h3>
              <a href="#!" className="text-base mt-1 text-custom-blue font-semibold transition duration-150 ease-in-out">{detailedItem && detailedItem.date}</a>
          </div>
          {detailedItem && <DetailComponent key={detailedItem.id} item={detailedItem} />}
        </div>
      </div>
    );
}



// Define components for each type
const Entity = ({ item }) => {
  const accessToken = localStorage.getItem('accessToken');
  const [entityItem, setEntityItem] = useState();
  const [userItem, setUserItem] = useState();
  const [payItem, setPayItem] = useState();
  const [delItem, setDelItem] = useState();

  useEffect(() => {
    const getEntity = async () => {
      try {
        // Fetch company data
        const comresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/companyId/${item.itemId}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setEntityItem(comresponse.data);
  
        // Fetch user data based on company response
        if (comresponse.data) {
          const userresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/userId/${comresponse.data.id}/`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          setUserItem(userresponse.data);
  
          // Fetch payment data based on user response
          if (userresponse.data) {
            const payresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/payId/${userresponse.data.id}/`, {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            });
            setPayItem(payresponse.data);
            //console.log("Payment Data: " + payresponse.data);

            const delresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/delId/${userresponse.data.id}/`, {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            });
            setDelItem(delresponse.data);
          }
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getEntity();
  }, []);

  const [approved, setApproved] = useState(null);

  const IsApproved = (status) => async () => {
    setApproved(status);

    if (approved) {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/approve/${entityItem.id}/`,  
        {
        approved_status: status
        }, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
      });
    }
  }

  return (
    entityItem ?
    <div >
      {/* Entity specific UI */}
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        Entity Information</h3>
      <EntityCom comRecord={entityItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        User Information</h3>
      <UserCom userRecord={userItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        Payment Profile Information</h3>
      <PayCom payRecord={payItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        Delivery Profile Information {delItem? delItem.id: "None"}</h3>
      <DelCom delRecord={delItem} />

      <div className="flex items-start justify-center mb-4 gap-2"> 

          <button  className=" w-full rounded-md hover:text-slate-50 text-teal-500 border-2 px-6 py-2 font-semibold bg-slate-50  hover:bg-teal-400 sm:w-auto flex flex-row gap-2 justify-center"
              onClick={IsApproved('true')}>
              <FaCheckCircle className="text-lg mt-1"/>
              <span>Approve</span>
            </button>

            <button  className=" w-full rounded-md text-red-500 border-2 px-6 py-2 font-semibold hover:bg-red-500 hover:text-white sm:w-auto flex flex-row gap-2 justify-center"
                    onClick={IsApproved('false')}>
                    <FaTimesCircle className="text-lg mt-1"/>
                    <span>Disapprove</span>
              </button>
        </div>
    </div>
    : null
  );
};


// New User Added by Head or Manager User --- Notification No actions Required
const UserProfile = ({ item }) => {
  
  const accessToken = localStorage.getItem('accessToken');
  const [userItem, setUserItem] = useState();
  const [entityItem, setEntityItem] = useState();
  const [headUserList, setHeadUserList] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const userresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/userById/${item.id}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setUserItem(userresponse.data);
  
        if (userresponse.data) {
          const companyUrl = `${process.env.REACT_APP_SERVER_URL}/api/admin/companyById/${userresponse.data.company}/`;
          const HeadUrl = `${process.env.REACT_APP_SERVER_URL}/api/admin/head-users/${userresponse.data.company}/`;
          const headers = {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          };
  
          // Parallel requests for entity and head users
          const [entityresponse, headresponse] = await Promise.all([
            axios.get(companyUrl, headers),
            axios.get(HeadUrl, headers) 
          ]);
  
          setEntityItem(entityresponse.data);
          setHeadUserList(headresponse.data);
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getUser();
  }, []);


  return (
    userItem ?
    <div >
      {/* Entity specific UI */}
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        User Information </h3>
      <UserCom userRecord={userItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        At Entity ....</h3>
      <EntityCom comRecord={entityItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
      Its Head User ....</h3>
      {
        headUserList.map((user, index) => (
          <UserCom key={index} userRecord={user} />
        ))
      }
    </div>
    : null
)};




// New Payment Profile Added by Head User --- Notification No actions Required
const PaymentProfile = ({ item }) => {

  const accessToken = localStorage.getItem('accessToken');
  const [payItem, setPayItem] = useState();
  const [userItem, setUserItem] = useState();
  const [entityItem, setEntityItem] = useState();
  const [headUserList, setHeadUserList] = useState([]);


  useEffect(() => {
    const getPayment = async () => {
      try {
        const payresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/payById/${item.id}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setPayItem(payresponse.data);
  
        if (payresponse.data) {
          const userresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/userById/${payresponse.data.created_by}/`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          setUserItem(userresponse.data);

          if (userresponse.data) {
            const companyUrl = `${process.env.REACT_APP_SERVER_URL}/api/admin/companyById/${userresponse.data.company}/`;
            const HeadUrl = `${process.env.REACT_APP_SERVER_URL}/api/admin/head-users/${userresponse.data.company}/`;
            const headers = {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            };
    
            // Parallel requests for entity and head users
            const [entityresponse, headresponse] = await Promise.all([
              axios.get(companyUrl, headers),
              axios.get(HeadUrl, headers) 
            ]);
    
            setEntityItem(entityresponse.data);
            setHeadUserList(headresponse.data);
          }
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getPayment();
  }, []);
  
  return (  
    payItem ?
    <div >
      {/* Entity specific UI */}
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        Payment Profile Information</h3>
      <PayCom payRecord={payItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        At Entity ....</h3>
      <EntityCom comRecord={entityItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
      Created By ....</h3>
      <UserCom userRecord={userItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
      Its Head User ....</h3>
      {
        headUserList.map((user, index) => (
          <UserCom key={index} userRecord={user} />
        ))
      }
    </div> 
    : null
)};




// New Delivery Profile Added by a User --- Notification No actions Required
const DeliveryProfile = ({ item }) => {

  const accessToken = localStorage.getItem('accessToken');
  const [delItem, setDelItem] = useState();
  const [userItem, setUserItem] = useState();
  const [entityItem, setEntityItem] = useState();
  const [issuerItem, setIssuerItem] = useState();


  useEffect(() => {
    const getDelivery = async () => {
      try {
        const delresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/delById/${item.id}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setDelItem(delresponse.data);
  
        if (delresponse.data) {
          const userresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/userById/${delresponse.data.created_by}/`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          setUserItem(userresponse.data);

          if (userresponse.data) {
            const companyUrl = `${process.env.REACT_APP_SERVER_URL}/api/admin/companyById/${userresponse.data.company}/`;
            const IssuerUrl = `${process.env.REACT_APP_SERVER_URL}/api/admin/userById/${delresponse.data.issuer}/`;
            const headers = {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            };
    
            // Parallel requests for entity and head users
            const [entityresponse, issuerresponse] = await Promise.all([
              axios.get(companyUrl, headers),
              axios.get(IssuerUrl, headers) 
            ]);
    
            setEntityItem(entityresponse.data);
            setIssuerItem(issuerresponse.data);
          }
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getDelivery();
  }, []);
  
  return (  
    delItem ?
    <div >
      {/* Entity specific UI */}
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        Delivery Profile Information </h3>
      <DelCom delRecord={delItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        At Entity ....</h3>
      <EntityCom comRecord={entityItem} />
      { delItem.created_by === delItem.issuer ?
      <>
      <h3 className="text-2xl text-custom-blue font-bold text-center">
      Created and Issued By ....</h3>
      <UserCom userRecord={userItem} />
      </>
      :
      <>
      <h3 className="text-2xl text-custom-blue font-bold text-center">
      Created By ....</h3>
      <UserCom userRecord={userItem} />
      <h3 className="text-2xl text-custom-blue font-bold text-center">
      Issued By ....</h3>
      <UserCom userRecord={issuerItem} />
      </>}
    </div>
    : null
)};




// Order Item Component
const Order = ({ item }) => {
  
  const accessToken = localStorage.getItem('accessToken');
  const [orderItem, setOrderItem] = useState();
  const [userItem, setUserItem] = useState();
  const [entityItem, setEntityItem] = useState();
  const [headUserList, setHeadUserList] = useState([]);

  useEffect(() => {
    const getOrder = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/orders/invoice/${item.id}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setOrderItem(response.data);
        console.log(response.data);
  
        if (response.data) {
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getOrder();
  }, []);

  
  const OpenOrder = (id) => async () => {
     //send orderid;
  }


  return (
    orderItem ?
    <div className="flex justify-center flex-col">
      {/* Entity specific UI */}
      <h3 className="text-2xl text-custom-blue font-bold text-center">
        Order Summary </h3>
      <div className="w-[75%] flex self-center">
        <OrderSum orderRecord={orderItem.order} />
      </div>

      <h3 className="text-2xl text-custom-blue mt-6 font-bold text-center">
      Invoice Information</h3>
      <div className="w-[100%] flex self-center">
        <InvoiceSum orderRecord={orderItem.order} />
      </div>
      
      <h3 className="text-2xl text-custom-blue mt-6 font-bold text-center">
      Order Information Summary </h3>
      <div className="flex flex-row w-full gap-6 mt-4 items-start">
        <div className="w-[50%] flex flex-col">
          <UserSum userRecord={orderItem.order.user} />
        </div>

        <div className="w-[50%] flex flex-col">
          <EntitySum comRecord={orderItem.order.company} />
        </div>
      </div>
      
      <div className="flex flex-row w-full gap-6 mt-6 items-start">
        <div className="w-[50%] flex flex-col">
          <PaySum payRecord={orderItem.order.payment_id} />
        </div>

        <div className="w-[50%] flex flex-col">
          <DelSum delRecord={orderItem.order.delivary_id} />
        </div>
      </div>

      <div className="flex items-start justify-center mb-4 mt-4 gap-2"> 
        <button  className=" w-full rounded-md hover:text-slate-50 text-teal-600 border-2 px-6 py-2 font-semibold bg-slate-50  hover:bg-teal-500 sm:w-auto flex flex-row gap-2 justify-center border-teal-600 hover:border-white"
            onClick={OpenOrder(orderItem.order.id)}>
            <FaFileInvoice className="text-lg mt-1"/>
            <span>Open Order</span>
          </button>
        </div>
      
    </div>
    : null
)};




// Package Item Component
const Package = ({ item }) => {
  
  const accessToken = localStorage.getItem('accessToken');
  const [dsmItem, setDsmItem] = useState();

  useEffect(() => {
    const getPackage = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/package/${item.itemId}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setDsmItem(response.data);
        //console.log("Package Call: ");
        //console.log(response.data);
  
        if (response.data) {
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getPackage();
  }, []);

  
  const OpenPackage = (id) => async () => {
     //send orderid;
  }


  return (
    dsmItem ?
    <div className="flex justify-center flex-col">
      {/* Entity specific UI */}
      {/* <h3 className="text-2xl text-custom-blue font-bold text-center">
        Package Details </h3> */}
      <div className="w-[75%] flex self-center">
        <PackSum packRecord={dsmItem} />
      </div>

      <h3 className="text-xl text-custom-blue mt-6 font-bold text-center">
          Products Information</h3>
      <div className="w-[100%] flex self-center">
        <PackProductSum packProducts={dsmItem} />
      </div>
      
      <h3 className="text-xl text-custom-blue mt-6 font-bold text-center">
      Package Creator Information </h3>
      <div className="flex flex-row w-full gap-6 mt-4 items-start">
        <div className="w-[50%] flex flex-col">
          <UserSum userRecord={dsmItem.pack_creator} />
        </div>

        <div className="w-[50%] flex flex-col">
          <EntitySum comRecord={dsmItem.company} />
        </div>
      </div>

      <div className="flex items-start justify-center mb-4 mt-4 gap-2"> 
        <button  className=" w-full rounded-md hover:text-slate-50 text-teal-600 border-2 px-6 py-2 font-semibold bg-slate-50  hover:bg-teal-500 sm:w-auto flex flex-row gap-2 justify-center border-teal-600 hover:border-white"
            onClick={OpenPackage(dsmItem.id)}>
            <FaBoxOpen className="text-lg mt-1"/>
            <span>Open Package</span>
          </button>
        </div>
      
    </div>
    : null
)};



// DSM Item COmponenet
const DSM = ({ item }) => {
  
  const accessToken = localStorage.getItem('accessToken');
  const [dsmItem, setDSMItem] = useState();

  useEffect(() => {
    const getDSM = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/dsm/${item.itemId}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setDSMItem(response.data);
        //console.log("Package Call: ");
        //console.log(response.data);
  
        if (response.data) {
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getDSM();
  }, []);

  
  const OpenDSM = (id) => async () => {
     //send orderid;
  }


  return (
    dsmItem ?
    <div className="flex justify-center flex-col">
      {/* Entity specific UI */}
      {/* <h3 className="text-2xl text-custom-blue font-bold text-center">
        DSM Details </h3> */}
      <div className="w-[75%] flex self-center">
        <DSMSum dsmRecord={dsmItem} />
      </div>

      <h3 className="text-xl text-custom-blue mt-6 font-bold text-center">
          Products Information</h3>
      <div className="w-[100%] flex self-center">
        <DSMProductSum dsmProducts={dsmItem} />
      </div>
      
      <h3 className="text-xl text-custom-blue mt-6 font-bold text-center">
      DSM Creator Information </h3>
      <div className="flex flex-row w-full gap-6 mt-4 items-start">
        <div className="w-[50%] flex flex-col">
          <UserSum userRecord={dsmItem.dsm_creator} />
        </div>

        <div className="w-[50%] flex flex-col">
          <EntitySum comRecord={dsmItem.company} />
        </div>
      </div>

      <div className="flex items-start justify-center mb-4 mt-4 gap-2"> 
        <button  className=" w-full rounded-md hover:text-slate-50 text-teal-600 border-2 px-6 py-2 font-semibold bg-slate-50  hover:bg-teal-500 sm:w-auto flex flex-row gap-2 justify-center border-teal-600 hover:border-white"
            onClick={OpenDSM(dsmItem.id)}>
            <MdStorage className="text-lg mt-1"/>
            <span>Open DSM</span>
          </button>
        </div>
      
    </div>
    : null
)};







// Component to decide which component to render based on item.type
const DetailComponent = ({ item }) => {
  const componentMap = {
    entity: Entity,
    order: Order,
    pay: PaymentProfile,
    del: DeliveryProfile,
    user: UserProfile,
    pack: Package,
    dsm: DSM,
  };

  const SpecificComponent = componentMap[item.ntype];
  return <SpecificComponent item={item} />;
};