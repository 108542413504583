import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from './Button';

import "./Accordion.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';


export default function Accordion() {
  const [curOpen, setCurOpen] = useState(null);
  const [numToShow, setNumToShow] = useState(4);

  const { t } = useTranslation();

  const faqs = [
    {
      title: t('faqs.q1'),
      text: t('faqs.a1'),
    },
    {
      title: t('faqs.q2'),
      text: t('faqs.a2'),
    },
    {
      title: t('faqs.q3'),
      text: t('faqs.a3'),
    },
    {
      title: t('faqs.q4'),
      text: t('faqs.a4'),
    },
    {
      title: t('faqs.q5'),
      text: t('faqs.a5'),
    },
    {
      title: t('faqs.q6'),
      text: t('faqs.a6'),
    },
    {
      title: t('faqs.q7'),
      text: t('faqs.a7'),
    },
    {
      title: t('faqs.q8'),
      text: t('faqs.a8'),
    },
    {
      title: t('faqs.q9'),
      text: t('faqs.a9'),
    },
    {
      title: t('faqs.q10'),
      text: t('faqs.a10'),
    },
    {
      title: t('faqs.q11'),
      text: t('faqs.a11'),
    },
    {
      title: t('faqs.q12'),
      text: t('faqs.a12'),
    },
    {
      title: t('faqs.q13'),
      text: t('faqs.a13'),
    },
    {
      title: t('faqs.q14'),
      text: t('faqs.a14'),
    },
    {
      title: t('faqs.q15'),
      text: t('faqs.a15'),
    },
  ];

  const handleShowMore = () => {
    setNumToShow(numToShow === faqs.length ? 4 : faqs.length);
  };

  return (
    <div className="accordion">
      {faqs.slice(0, numToShow).map((el, i) => (
        <AccordionItem
          title={el.title}
          num={i}
          key={el.title}
          curOpen={curOpen}
          onOpen={setCurOpen}
        >{el.text}</AccordionItem>
      ))}
       <Button onClick={handleShowMore}>
      {numToShow === faqs.length ? t('landing.view-less-btn') : t('landing.view-all-btn')}
    </Button>
    </div>
  );
}

function AccordionItem({ num, title, children, curOpen, onOpen }) {
  const isOpen = num === curOpen;
  function handleToggle() {
    onOpen(isOpen ? null : num);
  }
  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : `${num + 1}`}</p>
      <p className="title">{title}</p>
      <p className="ficon">{isOpen === true ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}</p>
      {isOpen === true ? <div className="content-box">{children}</div> : null}
    </div>
  );
}
