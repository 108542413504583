import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LSec.module.css';

const LeftSection = ({ head, text, imgSrc, link, linkText }) => {
  return (
          <div id="content" className={styles.container}>
            <div className={styles.textContainer}>
              <h4>{head}</h4>
              <p>{text}</p>
              {link && <Link to={link} className={styles.linkStyle}>{linkText}</Link>}
            </div>
            <div id="img" className={styles.image} style={{backgroundImage: `url(${imgSrc})`}} />
          </div>
  );
};

export default LeftSection;