import React, { useEffect } from 'react';
import PageNav from '../../components/Home/PageNav';
import Map from '../../components//Home/Map';
import Footer from '../../components/Home/Footer';
import ContactForm from '../../components/Home/ContactForm';
import InfoCard from '../../components/Home/InfoCard';
import IBanner from '../../components/Info/ImgBanner';

import styles from './Contact.module.css';

import { useTranslation } from 'react-i18next';

export default function Contact() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav />
      {/* <IBanner imageUrl='./img/info/1.png' title={t('contact-us.title')}/> */}
      <section>
        <div className={styles.sec11}>
          {/* <h2 className={styles.title}>{t('contact-us.title')}</h2> */}
          <div className={styles.contact}>
            <div className={styles.contactForm}>
              <ContactForm />
            </div>
            <div className={styles.informations}>
              <InfoCard />
            </div>
          </div>
        </div>
      </section>
      <Map />
      <Footer />
    </>
  )
}