import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./JoinUs.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import PopupFormnoBtn from "./PopupFormnoBtn";

function JoinUs() {

    const { t } = useTranslation();

    const [showForm, setShowForm] = useState(false);

    const handleOpenForm = () => {
      setShowForm(true);
    };
  
    const handleCloseForm = () => {
      setShowForm(false);
    };

    return (
        <div className={styles.bottomInfo}>
        <h3 className={styles.btitle}>{t('navbar.join')}</h3>
        <div className={styles.inputInfo}>
            <div style={{display: 'flex', displayDirection:"row"}}>
                <Link to="/login">
                <button>{t('navbar.login')}</button>
                </Link>

                <Link to="/SignUp">
                <button>{t('navbar.signup')}</button>
                </Link>
            </div>

        <div style={{width: '100%', display: 'flex', justifyItems: 'center', justifyContent: 'center'}}>
            <button style={{width: '100%'}}
                onClick={handleOpenForm}>
                    <FontAwesomeIcon icon={faPhone} style={{marginRight: '10px'}}/>
                    {t("footer.call")}
            </button>
                {showForm && <PopupFormnoBtn onClose={handleCloseForm} />}
            </div>
        </div>
    </div>
    );
}

export default JoinUs;
