import React, { useState } from "react";

import styles from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// import PopupForm from "./PopupForm";
// import PopupFormBtn from "../Info/PopupFormnoBtn";

import {
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faXTwitter,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import PopupFormnoBtn from "../Info/PopupFormnoBtn";

export default function Footer() {

  const { t } = useTranslation();

  const [showForm, setShowForm] = useState(false);

  const handleOpenForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.footerContent}>
            <div className={styles.footerSide}>
              <img src="../img/footerLogo.png"
                    alt="footer logo" />
            </div>
            <div className={styles.lists}>
              <div className={styles.subLists}>
              <div className={styles.list} id="list1">
                <h3>{t("footer.company")}</h3>
                <ul>
                  <li><Link to="/aboutus" className={styles.linksli}>
                    {t("footer.about")}</Link></li>
                  <li><Link to="/contact" className={styles.linksli}>
                    {t("footer.contact")}</Link></li>
                  <li><Link to="/career" className={styles.linksli}>
                    {t("footer.join")}</Link></li>
                  <li><HashLink to="/#faqSection" className={styles.linksli}>
                    {t("footer.ques")}</HashLink></li>
                </ul>
              </div>
              <div className={styles.list} id="list2">
                <h3>{t("footer.offering")}</h3>
                <ul>
                  <li><Link to="/dsm" className={styles.linksli}>
                    {t("footer.dsm")}</Link></li>
                  <li><Link to="/package" 
                        className={styles.linksli}>
                    {t("footer.package")}</Link></li>
                  <li><Link to="/clientsuite" className={styles.linksli}>
                    {t("footer.client")}</Link></li>
                  <li><Link to="/webshop" className={styles.linksli}>
                    {t("footer.shop")}</Link></li>
                </ul>
              </div>
              </div>
              <div className={styles.list} id="list3">
                <h3 className={styles.call} 
                      onClick={handleOpenForm}>
                  <span>
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  {t("footer.call")}
                </h3>
                {showForm && <PopupFormnoBtn onClose={handleCloseForm} />}

                <Link to="/Contact" className={styles.noUnderline}>
                <h3 className={styles.call}>
                  <span>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </span>
                  {t("footer.inquiry")}
                </h3>
                </Link>
                <ul className={styles.icons}>
                  <li>
                    <span>
                    <FontAwesomeIcon icon={faFacebookF} />
                    </span>
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faInstagram} />
                    </span>
                  </li>
                  <li>
                    <span>
                    <FontAwesomeIcon icon={faXTwitter} />
                    </span>
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faYoutube} />
                    </span>
                  </li>
                  <li>
                    <span>
                    <FontAwesomeIcon icon={faLinkedinIn} />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className={styles.end}>
        <div className={styles.container}>
          <div className={styles.endInfo}>
            <ul>
              <li>Corporate</li>
              <li>ASSORTIMED</li>
              <li>HEALTH Storage Systems</li>
              <li>Sustainability</li>
              <li>Media</li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
