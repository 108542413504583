import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SlBasketLoaded } from 'react-icons/sl';
import { FaFileInvoice } from 'react-icons/fa';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const CustomFormControlLabel = ({ name, value, label, checked, onChange }) => (
  <label className="flex items-center space-x-2">
    <Radio
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      sx={{
        '& .MuiSvgIcon-root': { fontSize: 20 },
        color: '#a6a7a8',
        '&.Mui-checked': { color: '#2849ad' },
      }}
    />
    <div className="text-sm">
      <p className="text-sm font-semibold">{label}</p>
    </div>
  </label>
);

const PopupContent = ({
  selectedOption,
  handleFormSubmit,
  searchQuery,
  setSearchQuery,
  filteredItems,
  selectedProduct,
  setSelectedProduct,
  selectedInvoice,
  setSelectedInvoice,
  handlePopupOption,
}) => {
  return (
    <div className="shadow-md absolute bottom-20 right-20 rounded bg-white p-1">
      {selectedOption === 'order' ? (
        <form onSubmit={handleFormSubmit} className="shadow-md h-auto w-96">
          <div className="flex items-center justify-between rounded border-b p-2">
            <div className="flex items-center">
              <SlBasketLoaded className="mr-2 text-[#a6a7a8]" />
              <span className="text-xs font-semibold text-gray-700">Order</span>
            </div>
            <div>
              <input
                type="search"
                placeholder="Search"
                className="rounded bg-[#f1f1f1] px-2 py-2 text-xs"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <Scrollbars style={{ height: 250 }}>
            <div className="w-full p-1">
              {filteredItems.length > 0 ? (
                filteredItems?.map((product, index) => (
                  <div key={index} className="m-2 flex rounded border p-1">
                    <img
                      className="w-1/5"
                      src={product.image}
                      alt={product.name}
                    />
                    <div className="ml-2 w-3/5">
                      <h2 className="text-sm font-semibold">{product.name}</h2>
                      <p className="text-xs text-[#a6a7a8]">
                        {product.description}
                      </p>
                    </div>
                    <div className="flex w-1/5 items-center justify-end">
                      <CustomFormControlLabel
                        name="product"
                        value={`product${index + 1}`}
                        label=""
                        checked={selectedProduct === `product${index + 1}`}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="m-2 text-sm text-gray-500">
                  No products found!
                </div>
              )}
            </div>
          </Scrollbars>
          <button
            type="submit"
            className="mt-2 w-full rounded bg-custom-blue py-2 text-xs text-white"
          >
            Send
          </button>
        </form>
      ) : selectedOption === 'addInvoice' ? (
        <form onSubmit={handleFormSubmit} className="shadow-md h-auto w-96">
          <div className="flex items-center justify-between rounded border-b p-2">
            <div className="flex items-center">
              <FaFileInvoice className="mr-2 text-[#a6a7a8]" />
              <span className="text-xs font-semibold text-gray-700">
                Add Invoice
              </span>
            </div>
            <div>
              <input
                type="search"
                placeholder="Search"
                className="rounded bg-[#f1f1f1] px-2 py-2 text-xs"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <Scrollbars style={{ height: 250 }}>
            <div className="w-full p-1">
              {filteredItems.length > 0 ? (
                filteredItems?.map((invoice, index) => (
                  <div key={index} className="m-2 flex rounded border p-1">
                    <img
                      className="w-1/5"
                      src={invoice.image}
                      alt={invoice.name}
                    />
                    <div className="ml-2 w-3/5">
                      <h2 className="text-sm font-semibold">{invoice.name}</h2>
                      <p className="text-xs text-[#a6a7a8]">
                        {invoice.description}
                      </p>
                    </div>
                    <div className="flex w-1/5 items-center justify-end">
                      <CustomFormControlLabel
                        name="invoice"
                        value={`invoice${index + 1}`}
                        label=""
                        checked={selectedInvoice === `invoice${index + 1}`}
                        onChange={(e) => setSelectedInvoice(e.target.value)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="m-2 text-sm text-gray-500">
                  No invoices found!
                </div>
              )}
            </div>
          </Scrollbars>
          <button
            type="submit"
            className="mt-2 w-full rounded bg-custom-blue py-2 text-xs text-white">
            Send
          </button>
        </form>
      ) : (
        <>
          <div
            className="mb-1 flex cursor-pointer items-center rounded border p-2"
            onClick={() => handlePopupOption('order')}
          >
            <SlBasketLoaded className="mr-2 text-[#a6a7a8]" />
            <span className="text-xs font-semibold text-gray-700">Order</span>
          </div>
          <div
            className="flex cursor-pointer items-center rounded border p-2"
            onClick={() => handlePopupOption('addInvoice')}
          >
            <FaFileInvoice className="mr-2 text-[#a6a7a8]" />
            <span className="text-xs font-semibold text-gray-700">
              Add Invoice
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default PopupContent;
