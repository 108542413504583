import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-grid-system';
import Pagination from 'react-js-pagination';
import Card from 'react-bootstrap/Card';
import Spinner from '../Home/Spinner.jsx';
import CardBtn from '../Shop/CardBtn';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './UserShopSlider.module.css';
import { SlBasketLoaded } from 'react-icons/sl';

import { useSearch } from '../../context/SearchContext.jsx';
import ProductContext from '../../context/ProductContext';
import FilterContext from '../../context/FilterContext';
import BuyPopup from './BuyPopup.jsx';
import SubListingContext from '../../context/SubListingContext.jsx';

export default function UserShopSlider({ storedProducts }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [activePopupProductId, setActivePopupProductId] = useState(null);

  const handleOpen = (productId) => {
    setActivePopupProductId(productId);
  };

  const handleClose = () => {
    setActivePopupProductId(null);
  };

  const { searchQuery } = useSearch();
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/products/products/`)
      .then((response) => {
        setProducts(response.data);
        localStorage.setItem('products', JSON.stringify(response.data));
        setDisplayedProducts(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  // Filter products based on searchQuery (SearchContext)
  const filteredProducts = products.filter(
    (product) =>
      product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  // Update displayedProducts when searchQuery changes
  useEffect(() => {
    setDisplayedProducts(filteredProducts);
  }, [searchQuery]);

  // Fetching FilterContext's Chosen Products
  const { chosenProducts, setChosenProducts } = useContext(FilterContext);

  // Update displayedProducts when chosenProducts changes
  useEffect(() => {
    if (chosenProducts) {
      setDisplayedProducts(chosenProducts);
    }
  }, [chosenProducts]);

  // Update displayedProducts when subListings changes
  const { subListings, setSubListings } = useContext(SubListingContext);
  useEffect(() => {
    if (subListings) {
      setDisplayedProducts(subListings);
    }
  }, [subListings]);

  // Retrieve the JSON string from localStorage and parse it into an object
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  // Define card Visual Content
  const cards = displayedProducts.map((product, index) => ({
    title: product.category,
    text: product.name,
    imgSrc: imgPaths[product.assortimed_pid][0],
    product: product,
  }));

  // Pagination Page Change
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const itemsPerPage = 18;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredProducts.length);

  const { t } = useTranslation();

  const { setData } = useContext(ProductContext);
  const navigate = useNavigate();

  const handleDetailsClick = (product) => {
    setData(product);
    navigate('/reviewproduct');
  };

  return (
    <>
      <div className={styles.userShopSlider}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <p className="card-title">Error: {error.message}</p>
        ) : (
          <>
            <Row>
              {Array.isArray(cards) && cards.length > 0 ? (
                cards.slice(startIndex, endIndex).map((card, index) => (
                  <Col
                    lg={4}
                    sm={8}
                    xs={12}
                    key={index}
                    className={styles.card_col}
                  >
                    <Card key={index} className={styles.card_body}>
                      <div className={styles.img_container}>
                        <Card.Img
                          variant="top"
                          src={card.imgSrc}
                          className={styles.card_img}
                        />
                      </div>
                      <Card.Body>
                        <div className={styles.content_container}>
                          <Card.Title className={styles.card_title}>
                            {card.title}
                          </Card.Title>
                          <Card.Text className={styles.card_content}>
                            {card.text}
                          </Card.Text>
                          <Card.Text className={styles.card_content}>
                            {card.product.sub_category_1}
                          </Card.Text>
                          <Card.Text className={styles.card_content}>
                            {card.product.sub_category_2}
                          </Card.Text>
                          <Card.Text className={styles.card_content}>
                            {card.product.sub_category_3}
                          </Card.Text>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <span className={styles.pricing}>
                            ${card.product.sales_price_c1}
                          </span>

                          <div className={styles.pr_cardBtn}>
                            <CardBtn
                              style={{ padding: '10px 20px' }}
                              onClick={() => handleDetailsClick(card.product)}
                            >
                              {t('vshop.details-cardbtn')}
                            </CardBtn>

                            <CardBtn
                              onClick={() => handleOpen(card.product.id)}
                              style={{
                                padding: '10px',
                                fontSize: '20px',
                                borderRadius: '50%',
                              }}>
                              <SlBasketLoaded />
                            </CardBtn>
                            {activePopupProductId === card.product.id && (
                              <div
                                className={styles.popup}
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                }}>
                                <BuyPopup
                                  isOpen={activePopupProductId === card.product.id}
                                  setIsOpen={handleClose}
                                  product={card.product}
                                  selectedProduct={card.product.id}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <p className="card-title">No products found.</p>
              )}
            </Row>

            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={filteredProducts.length}
              pageRangeDisplayed={7}
              onChange={handlePageChange}
              style={{ marginTop: '20px' }}
            />
          </>
        )}
      </div>
    </>
  );
}
