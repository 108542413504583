import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./PageNav.module.css";
import "../Home/PageNav.css";
import Logo from "./Logo";
import i18next from "i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Nav, Navbar, Dropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectNumberOfItems  } from '../../features/basket/basketSlice';

function PageNav() {
  const numberOfItems = useSelector(selectNumberOfItems);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "du",
      name: "Deutsch",
      country_code: "nl",
    },
    {
      code: "de",
      name: "German",
      country_code: "de",
    }
  ];

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const webshopIsActive = ['/webshop', '/shoplisting'].includes(location.pathname);
  const careerIsActive = ['/career', '/careerlisting'].includes(location.pathname);

  
  // Handling Routing Admins
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [issuper, setIsSuper] = useState(localStorage.getItem('is_superuser'));
  const [status, setStatus] = useState(localStorage.getItem('status'));

  useEffect(() => {
    const handleStorageChange = () => {
      setUser(JSON.parse(localStorage.getItem('user')));
      setIsSuper(localStorage.getItem('is_superuser'));
      setStatus(localStorage.getItem('status'));
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  //console.log("Is Super Nav: `", issuper);
  //console.log("Status Nav: `", status);

  const handleLogout = () => {
    // Do something here
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    localStorage.setItem('status', 'loggedout');
    setStatus(localStorage.getItem('status'));
    localStorage.removeItem('is_superuser');
    window.location.href = '/';
  };

  return (
    <Navbar
      expand="xxl"
      fixed="top"
      className={`bg-body-tertiary ${styles.navbarContainer}`}
    >
      <Container fluid className={styles.customContainer} style={{height: '100%'}}>
        <Navbar.Brand as={NavLink} to="/">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse
          style={{ justifyContent: "space-around", height: '100%' }}
          id="navbarScroll">
          {/** start Of NAv Links */}
          <Nav className="my-2 my-lg-0 page-nav" style={{ fontSize: "16px", height: '100%' }}>
            {/* <Nav.Link id="home" as={NavLink} to="/" exact="true" activeClassname="active">
              {t("navbar.home")}
            </Nav.Link> */}
            <Nav.Link id="dsm" as={NavLink} to="/dsm" activeClassname="active">
              {t("navbar.dsm")}
            </Nav.Link>
            <Nav.Link id="pac" as={NavLink} to="/package" activeClassname="active">
              {t("navbar.pac")}
            </Nav.Link>

            {/**webshop Drop Down */}
            {status === 'loggedIn' ? 
              (
                <Nav.Link as={NavLink} to="/usershoplist" activeClassName="active-link">
                  {t("navbar.web")}
                </Nav.Link>
              )
              : (
                <Dropdown as={Nav.Item} className="custom-dropdown">
                  <Dropdown.Toggle
                    as={Nav.Link}
                    id="dropdown-basic"
                    className={`custom-toggle ${webshopIsActive ? 'active' : ''}`}
                  >
                    {t("navbar.web")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="custom-menu">
                    <Dropdown.Item
                      as={NavLink}
                      to="/webshop"
                      activeClassName="active-link"
                    >
                      {t("navbar.webabout")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/shoplisting"
                      activeClassName="active-link"
                    >
                      {t("navbar.webvproducts")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )
            }
            
            
            {status === 'loggedIn' ?
                ( issuper == 'true'?
                  (
                    <Nav.Link id="client" as={NavLink} to="/adminsuite" activeClassName="active">
                      {t("navbar.client")}
                    </Nav.Link>
                  )
                  :(
                    <Nav.Link id="client" as={NavLink} to="/clientsuite" activeClassName="active">
                      {t("navbar.client")}
                    </Nav.Link>
                  )
                )
                : (
                  <Nav.Link id="client" as={NavLink} to="/clientinfo" activeClassName="active">
                    {t("navbar.client")}
                  </Nav.Link>
                )
              }
              
            {/**Career List */}
            <Dropdown as={Nav.Item} className="custom-dropdown">
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-basic"
                className={`custom-toggle ${careerIsActive ? 'active' : ''}`}
              >
                {t("navbar.career")}
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-menu">
                <Dropdown.Item
                  as={NavLink}
                  to="/career"
                  activeClassName="active-link"
                >
                  {t("navbar.aboutcareer")}
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/careerlisting"
                  activeClassName="active-link"
                >
                  {t("navbar.explorecareer")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link as={NavLink} to="/aboutus" activeClassname="active">
              {t("navbar.about")}
            </Nav.Link>
            <Nav.Link as={NavLink} to="/contact" activeClassname="active">
              {t("navbar.contact")}
            </Nav.Link>
          </Nav>
          {/** End Of NAv Links */}


          {/** Start of Right Side Block */}
          <div className={styles.rightSide}>
            <div className={styles.btnLog}>
            {status === 'loggedIn' ? (
               <Button className={styles.btnSignup}  onClick={handleLogout}>
               {t("navbar.signout")}</Button>
              ) : (
                     <>
                     <NavLink to="/login" className={styles.ctaLink}>
                       <Button className={styles.btnLogin}>{t("navbar.login")}</Button>
                     </NavLink>
                     <NavLink to="/SignUp" className={styles.ctaLink}>
                       <Button className={styles.btnSignup}>{t("navbar.signup")}</Button>
                     </NavLink>
                   </>
              )}

              <Link to="/basket"><div className={styles.cartContainer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: "#fff" }}
                  id="Outline"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path d="M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354A2.994,2.994,0,0,0,22.713,4.077ZM21.4,6.178l-.786,4.354A3,3,0,0,1,17.657,13H5.419L4.478,5H20.41A1,1,0,0,1,21.4,6.178Z" />
                  <circle cx="7" cy="22" r="2" />
                  <circle cx="17" cy="22" r="2" />
                </svg>
                <span>{numberOfItems}</span>
              </div>
              </Link>



              <div className="dropdown translate">
              <button
                className="btn dropdown-toggle"
                style={{ color: "#2849ad" }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  id="Layer_1"
                  fill="#2849ad"
                  enableBackground="new 0 0 512 512"
                  height="20"
                  viewBox="0 0 512 512"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m512 270.981-40 .038-.122-127c0-11.047-8.972-20.019-20-20.019h-121.594l25.858 25.858-28.284 28.284-73.142-73.142 73.142-73.142 28.284 28.284-23.858 23.858h119.594c33.084 0 60 26.916 60 60zm-366.142 93.161 25.858 25.858h-111.594c-11.028 0-20-8.972-20-20.019l-.122-127-40 .038.122 126.981c0 33.084 26.916 60 60 60h109.594l-23.858 23.858 28.284 28.284 73.142-73.142-73.142-73.142zm253.142-64.142h-40v30h-75v40h125.749c-3.801 12.742-12.573 33.266-27.488 52.697-.468.61-.95 1.224-1.437 1.839-1.63-2.095-3.179-4.178-4.632-6.227-4.324-6.1-8.131-12.275-11.466-18.31h-44.611c6.092 13.722 14.003 28.122 23.443 41.44 2.672 3.77 5.42 7.38 8.228 10.858-16.18 11.321-36.645 19.703-61.786 19.703v40c38.039 0 67.863-13.616 90.458-31.028 25.837 20.43 55.46 31.028 87.542 31.028v-40c-23.401 0-42.801-8.105-58.463-19.353 1.552-1.88 3.036-3.748 4.453-5.594 20.501-26.707 33.155-56.884 36.861-77.053h21.149v-40h-73zm-304.554-98h-42.446l71.717-202h30.902l70.693 202h-42.379l-11.899-34h-64.518zm26.273-74h36.318l-18.028-51.514z" />
                </svg>
              </button>
              <ul className={`dropdown-menu ${styles.customDropdown}`}>
                {languages.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <button
                      className="dropdown-item"
                      onClick={() => i18next.changeLanguage(code)}
                    >
                      <CountryFlag
                        countryCode={country_code}
                        width="16"
                        height="12"
                        alt={name}
                      />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            </div>
          </div>
          {/** end of Right Side Block */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PageNav;

const CountryFlag = ({ countryCode, width, height, alt }) => {
  const src = `https://flagcdn.com/16x12/${countryCode}.png`;
  const srcset = `https://flagcdn.com/32x24/${countryCode}.png 2x, https://flagcdn.com/48x36/${countryCode}.png 3x`;

  return (
    <img src={src} srcSet={srcset} width={width} height={height} alt={alt} />
  );
};

export { CountryFlag };
