import React from "react";
import Slider from "react-slick";
import "../../components/Shop/ShopListingSlider1.css";
import Button from "../Home/Button";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

export default function JobDetailSlider() {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  return (
<div className="first-job-detail-slider">
      <Slider {...settings}>
        <div className="job-detail-slider">
          <img
            src="../../img/dsm-small.jpg"
            style={{ width: "770px", height: "300px" }}
            alt="job detail slider img-1"
          />
          <div className="content-Slider">
            <h5 style={{fontSize: '20px', marginBottom: '15px',
                        fontWeight: '800'}}>
              {t('vshop.shop1')}</h5>
            <Button><Link to='/login'>{t('vshop.startbtn')}</Link></Button>
          </div>
        </div>
      </Slider>
      </div>
  );
}
