import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './Login.css';

function LoginForm() {
  const { t } = useTranslation();

  const [perrorMessage, psetErrorMessage] = useState(null);
  const [eerrorMessage, esetErrorMessage] = useState(null);

  // Handling Password visibility
  const [inputType, setInputType] = useState("password");
  const handleClickShowPassword = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  // recive data from Email Form
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      phone: "",
    },
    onSubmit: (values) => {
      // console.log(values);
      if (values.phone) {
        // Make axios post request with phone value
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/send_verification_code/`, { phone: values.phone })
          .then(response => {
            // console.log(response.data);
            // Here you would handle the response from the server
            navigate('/otp');
          })
          .catch(error => {
            psetErrorMessage(t('signprocess.loginphoneerror'));
            // console.error('There was an error!', error);
            // navigate('/otp');
          });
      } else {
        // Make axios post request with email and password values
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/login/`, { email: values.email, password: values.password })
          .then(response => {
            //console.log("User Data:");
            //console.log(response.data);
            // Here you would handle the response from the server
            localStorage.setItem('accessToken', response.data.access);
            //console.log("User Data Access Token:  " + response.data.access);
            localStorage.setItem('refreshToken', response.data.refresh);
            localStorage.setItem('status', "loggedIn");
            
            const userData = response.data;
            if (userData.user_data.profile_picture !== null) {
              const sanitizedEmail = userData.user_data.email.replace('@', '');
              const imageFile = `database_media/users/${sanitizedEmail}.jpg`;
              userData.user_data.profile_picture = imageFile;
            }

	    const companyUsers = userData.company_users;
            companyUsers.forEach(user => {
                if (user.profile_picture !== null) {
                    const sanitizedEmail = user.email.replace('@', '');
                    const imageFile = `database_media/users/${sanitizedEmail}.jpg`;
                    user.profile_picture = imageFile;
                }
              });
            userData.company_users = companyUsers;
	    
            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.setItem('is_superuser', response.data.user_data.is_superuser);
            //console.log("Login Data:  ");
            //console.log(response.data);
            if (response.data.user_data.is_superuser === true) {
              //console.log("Admin Suite");
              navigate('/adminsuite');
            } else {
              //console.log("Client Suite");
              navigate('/clientsuite');
            }
          })
          .catch(error => {
            esetErrorMessage(t('signprocess.loginemailerror'));
            // console.error('There was an error!', error);
          });
      }
    },
  });



  return (
    <Tabs
      defaultActiveKey="home"
      id="signinTab"
      className="mb-3"
      style={{ margin: '15px' }}
    >
      <Tab eventKey="home" 
        title={<span className="login-tab-title">by Email</span>}>
        <div className="p-3 custom-width">
        <form onSubmit={formik.handleSubmit}>
        {eerrorMessage && <div style={{
          textAlign: 'center', color: 'red', fontWeight: '500', marginBottom: '10px'
        }}><p>{eerrorMessage}</p></div>}
          <div className="form-group mb-3">
            <FormControl
              type="email"
              className="form-control inputArea"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              required
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group mb-3">
            <InputGroup>
              <FormControl
                type={inputType}
                className="form-control inputArea"
                id="password"
                placeholder="Password"
                required
                value={formik.values.password}
                onChange={formik.handleChange}
              />

              <Button 
                variant="outline-secondary" 
                onClick={handleClickShowPassword} 
                className="inputArea button-no-border"
                type="button"
              >
                <BsFillEyeFill />
              </Button>
            </InputGroup>
         </div>

          <button type="submit" 
                  className="btn btn-primary mb-4 w-100 button3D">
            {t('signprocess.loginbtn')}
          </button>
        </form>
        <div className="bottom-note">
            <p className="text-left p-tag">
            {t('signprocess.noaccountmsg')}
                <Link to="/SignUp" className='a-tag1'>
                      {t('signprocess.signuplink')}</Link>
            </p>
            <Link to="/forget" className='a-tag2'>
            {t('signprocess.forgetpassmsg')} </Link>
          </div>
        </div>
      </Tab>


      <Tab eventKey="profile" 
        title={<span className="login-tab-title">by Mobile</span>}>
        <div className="p-3 custom-width">
        <form onSubmit={formik.handleSubmit}>
        {perrorMessage && <div style={{
          textAlign: 'center', color: 'red', fontWeight: '500', marginBottom: '10px'
        }}><p>{perrorMessage}</p></div>}
          <div className="form-group mb-5">
            <input 
              type="phone" 
              className="form-control inputArea"
              id="phone" 
              aria-describedby="phonelogin" 
              placeholder="Enter Phone" 
              required
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </div>

          <button type="submit" 
                  className="btn btn-primary mb-4 w-100 button3D">
            {t('signprocess.otpbtn')}
          </button>
        </form>

        <div className="bottom-note">
            <p className="text-left p-tag">
            {t('signprocess.noaccountmsg')} 
                <Link to="/SignUp" className='a-tag1'>
                {t('signprocess.signuplink')}</Link>
            </p>
            <Link to="/forget" className='a-tag2'>
            {t('signprocess.forgetpassmsg')} </Link>
          </div>
      </div>

    </Tab>
  </Tabs>
  );
}

export default LoginForm;
