import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addToBasket: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item.id === newItem.id);
      if (existingItem) {
        if (!existingItem.isDsm && !existingItem.isPackage) {
          existingItem.quantity += 1;
        }
      } else {
        state.items.push({ ...newItem, quantity: newItem.quantity || 1 });
      }
    },
    removeFromBasket: (state, action) => {
      const { id } = action.payload;
      state.items = state.items.filter((item) => item.id !== id);
    },
    incrementQuantity: (state, action) => {
      const { id } = action.payload;
      const item = state.items.find(item => item.id === id);
      if (item && !item.isDsm) {
        item.quantity += 1;
        if (item.isPackage) {
          item.totalProductCost += item.packageProducts.reduce((acc, product) => acc + product.price * product.quantity, 0);
        }
      }
    },
    decrementQuantity: (state, action) => {
      const { id } = action.payload;
      const item = state.items.find(item => item.id === id);
      if (item && !item.isDsm && item.quantity > 1) {
        item.quantity -= 1;
        if (item.isPackage) {
          item.totalProductCost -= item.packageProducts.reduce((acc, product) => acc + product.price * product.quantity, 0);
        }
      }
    },
    incrementProductQuantity: (state, action) => {
      const { id, dsmProductIndex } = action.payload;
      const item = state.items.find(item => item.id === id);
      if (item && item.dsmProducts && dsmProductIndex < item.dsmProducts.length) {
        const product = item.dsmProducts[dsmProductIndex];
        product.maxQuantity += 1;
      }
    },
    decrementProductQuantity: (state, action) => {
      const { id, dsmProductIndex } = action.payload;
      const item = state.items.find(item => item.id === id);
      if (item && item.dsmProducts && dsmProductIndex < item.dsmProducts.length && item.dsmProducts[dsmProductIndex].maxQuantity > 1) {
        item.dsmProducts[dsmProductIndex].maxQuantity -= 1;
      }
    },
    removeDsmProduct: (state, action) => {
      const { id, dsmProductIndex } = action.payload;
      const item = state.items.find(item => item.id === id);
      if (item && item.dsmProducts && dsmProductIndex < item.dsmProducts.length) {
        item.dsmProducts.splice(dsmProductIndex, 1);
      }
    },
    removePackageProduct: (state, action) => {
      const { id, packageProductIndex } = action.payload;
      const item = state.items.find(item => item.id === id);
      if (item && item.packageProducts && packageProductIndex < item.packageProducts.length) {
        item.packageProducts.splice(packageProductIndex, 1);
      }
    },
  },
});

export const { 
  addToBasket, 
  removeFromBasket, 
  incrementQuantity, 
  decrementQuantity, 
  incrementProductQuantity, 
  decrementProductQuantity, 
  removeDsmProduct, 
  removePackageProduct 
} = basketSlice.actions;

export const selectNumberOfItems = (state) => state.basket.items.length;

export default basketSlice.reducer;
