import React from "react";
import Slider from "react-slick";
import Card from 'react-bootstrap/Card';
import { useTranslation } from "react-i18next";

import "./CareerSlider2.css";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default function CareerSlider2() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <></>,
          prevArrow: <></>
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <></>,
          prevArrow: <></>
        },
      },
    ],
  };

  const { t } = useTranslation();

  const data = [
    {cardstyle: "career3-card card1", text: t('career.p11')},
    {cardstyle: "career3-card card2", text: t('career.p12')},
    {cardstyle: "career3-card card3", text: t('career.p13')},
    {cardstyle: "career3-card card4", text: t('career.p14')},
    {cardstyle: "career3-card card5", text: t('career.p15')},
    // add more text sources as needed
  ];

  return (
    <div className="slider-container">
      <Slider id="picSlider" {...settings}>
        {data.map((item, index) => (
              <Card className={item.cardstyle}>
                <Card.Body  className="career3-card-body">
                  <Card.Text className="career3-card-text">
                    {item.text}
                  </Card.Text>
                </Card.Body>
              </Card>
        ))}
      </Slider>
    </div>
  );
}