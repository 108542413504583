import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import axios from 'axios';


// Local Imports
import { addToBasket } from '../../features/basket/basketSlice';
import '../creationPopupStyle.css';

// Icon Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { GiExpense } from 'react-icons/gi';
import { MdEuro, MdFormatListNumbered } from 'react-icons/md';
import { AiOutlineRedo } from 'react-icons/ai';
import { IoMdPricetags } from 'react-icons/io';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';



function PopupReviewDsm({ 
      onClose, 
      addedProducts, 
      dsmData,
      showToast,
      showFail,
     }) {
  const dispatch = useDispatch();
  
  
  const numOfItems = addedProducts.length;
  const getCreationFee = (numOfItems) => {
    if (numOfItems === 0) {
      return 0;
    } else if (numOfItems > 0 && numOfItems <= 15) {
      return 250;
    } else if (numOfItems > 15 && numOfItems <= 25) {
      return 375;
    } else if (numOfItems > 25 && numOfItems <= 35) {
      return 450;
    } else {
      return 625;
    }
  };
  const creation_fee = getCreationFee(numOfItems);

  // console.log("Data:");
  // console.log(dsmData);
  // console.log(addedProducts);

  const formik = useFormik({
    initialValues: {
      dsm_name: '',
      creation_fee: creation_fee,
      refill_period: '',
    },
    onSubmit: async (values) => {
      const newDsmProduct = {
        id: new Date().getTime(),
        name: values.dsm_name,
        category: 'DSM',
        sales_price_c1: creation_fee,
        quantity: 1,
        isDsm: true,
        dsmProducts: addedProducts.map((product) => ({
          name: product.label,
          category: product.category,
          price: product.sales_price_c1,
          minQuantity: product.minQuantity || 100,
          maxQuantity: product.maxQuantity || 1000,
          imageUrl: product.image,
          id: product.id,
        })),
      };
      //console.log("DSM Data Being Dispatch in Cpoy: ");
      //console.log(newDsmProduct);
      await createNewDSM(values, addedProducts, newDsmProduct);

      // dispatch(addToBasket(newDsmProduct));
      // onClose();
    },
  });


  // createNewPackage API
  const user = JSON.parse(localStorage.getItem('user'));
  const today = new Date();
  ////console.log("User: ", user);
  //console.log("Added Products: ", addedProducts);
  const createNewDSM = async (values, addedProducts, newDSMProduct) => {
    const postDSMProduct = {
      company: user.user_data.company,
      dsm_creator: user.user_data.id,
      dsm_name: values.dsm_name,
      department: user.user_data.department_name,
      creation_date: new Date().toISOString(),
      creation_fee: values.creation_fee,
      creation_fee_status: 'created',
      num_of_products: addedProducts.length,
      monthly_fee_dsm: values.creation_fee, 
      refill_period: parseInt(values.refill_period, 10),
      refill_proposal_status: null,
      refill_proposal_date: new Date(today.setDate(today.getDate() + values.refill_period)),
      dsm_status: 'active',
      dsm_active_date: today.toISOString(),
      dsm_paused_date: null,
      dsm_non_active_date: null,
      products: addedProducts.map((product) => ({
        product: product.value,
        lower_spec_stock: product.minQuantity || 100,
        upper_spec_stock: product.maxQuantity || 1000,
        product_status: product.status || 'active',
      })),
    };
    //console.log("NewProductReadyData: ", postDSMProduct);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/products/dsm/create/`, postDSMProduct);
      //console.log(response);
      if (response.status === 201 || response.status === 200) {
          // console.log("DSM created successfully!");
          // console.log(response.data);
          newDSMProduct.dsm = response.data.id;
          dispatch(addToBasket(newDSMProduct));
          showToast('DSM created successfully!');
          //handleSucess();
          onClose();
      } else {
          //console.error('Failed to create DSM.');
          //dispatch(addToBasket(newDSMProduct));
          showFail('Failed to create DSM.');
          onClose();
      }
    } catch (error) {
      console.log('There\'s another DSM with that name already exists.', error);
      console.log(error);
      //dispatch(addToBasket(newDSMProduct));
      showFail('There\'s another DSM with that name already exists.');
      onClose();
    }
  };

  return (
    <div className="creation_popup">
      <Modal show={true} onHide={onClose}>
        <Modal.Header className="flex-row-reverse bg-neutral-50">
        <button
            className="btn text-white bg-custom-green hover:bg-[#07b1ab] border-none"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className="text-2xl m-auto font-sans font-semibold ">
            DSM Details
          </h2>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={formik.handleSubmit}
            className="bg-white m-4">
            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdFormatListNumbered />
              </span>
              <label className="popup_review_label">DSM Name</label>
              <div className="grow">
                <input
                  className="input w-full placeholder:text-gray-500"
                  name="dsm_name"
                  type="text"
                  placeholder={dsmData.dsm_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dsm_name}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <AiOutlineRedo />{' '}
              </span>
              <label className="popup_review_label">Refill Period</label>
              <div className="flex grow justify-between ">
                <input
                  className="input w-full"
                  name="refill_period"
                  type="number"
                  placeholder={dsmData.refill_period}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.refill_period}
                />
                <span className="flex text-center text-xs font-bold text-zinc-600">
                  In Days
                </span>
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdFormatListNumbered />
              </span>
              <label className="popup_review_label">Num Of Products</label>
              <div className="grow">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  name="num_of_products"
                  type="number"
                  placeholder="Number of products"
                  value={numOfItems}
                  disabled
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <GiExpense />
              </span>
              <label className="popup_review_label">Creation Fee</label>
              <div className="grow">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  value={dsmData.creation_fee}
                  name="creation_fee"
                  disabled
                />
              </div>
              <span className="text-base text-zinc-600">
                <MdEuro />
              </span>
            </div>



            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <LiaFileInvoiceDollarSolid />{' '}
              </span>
              <label className="popup_review_label">DSM Monthly Fee</label>
              <div className="grow">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  value={creation_fee}
                  disabled
                  name="monthly_fee_dsm"
                />
              </div>
              <span className="text-base text-zinc-600">
                <MdEuro />
              </span>
            </div>

            {/* <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <IoMdPricetags />{' '}
              </span>
              <label className="popup_review_label">
                Total Price Of Proposal
              </label>
              <div className="grow">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  value={totalPrice}
                  disabled
                  name="total_price_of_proposal"
                />
              </div>
              <span className="text-base text-zinc-600">
                <MdEuro />
              </span>
            </div> */}

            <Button
              variant="primary"
              type="submit"
              className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold">
              Create new DSM
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PopupReviewDsm;
