import React from 'react';
import { useTranslation } from 'react-i18next';


import styles from './BaseSign.module.css';
import ForgetForm from './ForgetForm';

const Forget = () => {
  const { t } = useTranslation();

  return (

    <div className={styles["my-component-container"]}>
    <div className={styles['left-col']}>
      <div className={styles['background-image']}>
        <div className={styles['header-container']}>
        <div className={styles['icon-parent']}>
        <div className={styles['icon']}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" 
                width="60" height="60" viewBox="0,0,300,300">
            <g fill="#5cb0b4" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode: 'normal'}}>
                <g transform="scale(2,2)">
                <path d="M86,1c-22.6,0 -41,18.4 -41,41c0,22.6 18.4,41 41,41c22.6,0 41,-18.4 41,-41c0,-22.6 -18.4,-41 -41,-41zM86,7c19.3,0 35,15.7 35,35c0,19.3 -15.7,35 -35,35c-19.3,0 -35,-15.7 -35,-35c0,-19.3 15.7,-35 35,-35zM86,32c-5.52285,0 -10,4.47715 -10,10c0,5.52285 4.47715,10 10,10c5.52285,0 10,-4.47715 10,-10c0,-5.52285 -4.47715,-10 -10,-10zM42.36328,62.59961c-0.7625,0 -1.5125,0.30039 -2.0625,0.90039l-38.40039,38.40039c-0.6,0.5 -0.90039,1.29961 -0.90039,2.09961v20c0,1.7 1.3,3 3,3h20c1.7,0 3,-1.3 3,-3v-17h17c1.7,0 3,-1.3 3,-3v-17h14c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3h-17c-1.7,0 -3,1.3 -3,3v17h-17c-1.7,0 -3,1.3 -3,3v17h-14v-15.80078l37.5,-37.5c1.2,-1.2 1.2,-3.09922 0,-4.19922c-0.6,-0.6 -1.37422,-0.90039 -2.13672,-0.90039z"></path></g></g>
            </svg>
        </div>
        </div>
        <h2 className={styles['title']}>
            {t('signprocess.forget1')}</h2>
        <p className={styles['subTitle']}>{t('signprocess.forget2')}</p>
          <ForgetForm />
        </div>
      </div>
    </div>
    <div className={styles["right-col"]}>
      <div className={styles['partially-covered-image']}>
      </div>
    </div>
  </div>
);
};
export default Forget;