import React, { useState } from "react";


// Icon Imports
import { BiSolidReport } from "react-icons/bi";

// In ComBase component
export default function EntitySum({ comRecord }) {
  
    return (
        comRecord ?
            <div className="w-full mt-3 border-1 border-custom-green px-3 py-1 rounded-lg h-fit shadow-custom font-sans">
              <div className="my-3">
                <div className="my-1 mb-2 text-xl text-custom-blue font-extrabold flex justify-center">
                  <span>{comRecord.cname}</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <p className="text-custom-blue">COD number</p>
                  <span>{comRecord.cod_number? comRecord.cod_number : 22345}</span>
                </div>
                <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
                  <p>Contract Info.</p>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">contract start date</span>
                  <span className="text-custom-blue">contract end date</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{comRecord.contract_start_date}</span>
                  <span>{comRecord.contract_end_date}</span>
                </div>

                <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
                  <p>Service Level Agreements</p>
                </div>
                <div className="grid grid-cols-1 my-1 mb-2 font-semibold">
                  {Object.keys(comRecord)
                    .filter(key => key.startsWith('sla_') && comRecord[key])
                    .map((key, index) => (
                      <p className="text-custom-blue" key={index}>{comRecord[key]}</p>
                    )).length > 0 ? (
                      Object.keys(comRecord)
                        .filter(key => key.startsWith('sla_') && comRecord[key])
                        .map((key, index) => (
                          <p key={index}>- {comRecord[key]}</p>
                        ))
                    ) : (
                      <p>No service level agreements</p>
                    )}
                </div>

                <div className="my-1 mt-3 text-lg text-teal-600 font-bold flex justify-center">
                  <p>Location</p>
                </div>
                <div className="grid grid-cols-1 my-1 font-semibold">
                  <p>{comRecord.caddress}, {comRecord.cpostalcode}, {comRecord.ccity}, {comRecord.ccountry}</p>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-1 font-semibold">
                  <p className="text-custom-blue">Main Office</p>
                  <span>{comRecord.mainoffice}</span>
                </div>
              </div>
          </div> : null 
    );
  }