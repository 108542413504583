import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import axios from 'axios';


// Local Imports
import '../creationPopupStyle.css';


// Icon Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { MdApartment, MdContactMail, MdContactPhone, MdContacts, MdLanguage } from 'react-icons/md';
import { BsCalendarDateFill } from 'react-icons/bs';
import { FaSuitcase } from "react-icons/fa";
import { FaEuroSign } from 'react-icons/fa6';


function PopupNewUser({ onClose }) {

    // Data from Local Storage
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
    const accessToken = user.access ? user.access : null;
    //console.log(user);
  
  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      uphone: '',
      date_of_birth: '',
      language_preference: '',
      department_code: '',
      department_name: '',
      function: '',
      budget: '',
      role: '',
      company: user.user_data.company,
      is_approved: true,
    },
    onSubmit: async values => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/create-user/`, {
          user_data: values,
          current_user_email: user.user_data.email,
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        console.log("New User Created Successfully!");
        onClose();
      } catch (error) {
        console.error("There were an issue creating new User", error);
      }
    },
  });

  return (
    <div className="creation_popup">
      <Modal show={true} onHide={onClose}>
        <Modal.Header className="flex-row-reverse bg-neutral-50">
        <button
            className="btn text-white border-none bg-custom-green  hover:bg-[#07b1ab]"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className="text-2xl m-auto font-sans font-semibold ">
            Fill in User's Personal Information
          </h2>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={formik.handleSubmit}
            className="bg-white"
          >
            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdContacts />
              </span>
              <label className="popup_review_label">
                Full Name
              </label>
              <div className="grow">
                <input
                  className="input w-full text-gray-500"
                  name="fullname"
                  id="fullname"
                  type="text"
                  placeholder="type full name"
                  value={formik.values.fullname}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
              <MdContactMail />
              </span>
              <label className="popup_review_label">
                Email
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdContactPhone />{' '}
              </span>
              <label className="popup_review_label">
                Mobile
              </label>
              <div className="flex grow justify-between ">
                <input
                  className="input w-full"
                  name="uphone"
                  type="phone"
                  value={formik.values.uphone}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <BsCalendarDateFill />{' '}
              </span>
              <label className="popup_review_label">
                Date of Birth
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="date"
                  name="date_of_birth"
                  value={formik.values.date_of_birth}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <FaSuitcase />{' '}
              </span>
              <label className="popup_review_label">
                Role in Client Suite
              </label>
              <div className="grow">
                <select
                  className="input w-full  text-gray-500"
                  name="role"
                  value={formik.values.role}
  onChange={formik.handleChange}
                >
                  <option value="HD">Head</option>
                  <option value="MG">Manager</option>
                  <option value="NM">Normal</option>
                </select>
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdLanguage />{' '}
              </span>
              <label className="popup_review_label">
                Language Preference
              </label>
              <div className="grow">
                <select
                  className="input w-full  text-gray-500"
                  name="language_preference"
                  value={formik.values.language_preference}
  onChange={formik.handleChange}
                >
                  <option value="du">Dutch</option>
                  <option value="en">English</option>
                  <option value="de">German</option>
                </select>
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdApartment />{' '}
              </span>
              <label className="popup_review_label">
                Department
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="text"
                  name="department_name"
                  value={formik.values.department_name}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdApartment />{' '}
              </span>
              <label className="popup_review_label">
                Department Code
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="text"
                  name="department_code"
                  value={formik.values.department_code}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <FaSuitcase />{' '}
              </span>
              <label className="popup_review_label">
                Job Title
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="text"
                  name="function"
                  value={formik.values.function}
                  onChange={formik.handleChange}
                />
              </div>
            </div>


            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <FaEuroSign />{' '}
              </span>
              <label className="popup_review_label">
                Assigned Budget
              </label>
              <div className="grow">
                <input
                  className="input w-full  text-gray-500"
                  type="number"
                  name="budget"
                  value={formik.values.budget}
                  onChange={formik.handleChange}
                />
              </div>
              <span className="text-lg">
                <FaEuroSign />{' '}
              </span>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
            >
              Add New User
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PopupNewUser;
