import React, { useState, useRef } from 'react';
import Select from 'react-select';
import axios from 'axios';

//Icons Import
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { IoKey } from 'react-icons/io5';
import { MdOutlineEdit } from "react-icons/md";


// Local Imports
import InfoSections from './PInfoSections';
import PopupPass from './PopupPass';
import PopupEditInfo from './PopupEditInfo';

export default function BaseProfile() {
  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole =
    user.user_data.role === 'HD'
      ? 'Head User'
      : user.role === 'MG'
        ? 'Manager User'
        : 'Normal User';

  // Show Change Password
    const [showPass, setShowPass] = useState(false);
  
    const changePass = () => {
      setShowPass(true);
    };

    // Show Edit Informations
    const [showInfo, setShowInfo] = useState(false);
  
    const editInfo = () => {
      setShowInfo(true);
    };


    // Update User Image
    const [profilePicturePath, setProfilePicturePath] = useState(user.user_data.profile_picture ? `./img/${user.user_data.profile_picture}` : "./img/database_media/users/default.jpg");
    const [fileExtension, setFileExtension] = useState('');
    const fileInputRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleButtonClick = () => {
      // console.log("Button Clicked!");
      fileInputRef.current.click();
    };
  
    const handleFileChange = (event) => {
      // console.log("File Changed!");
      const file = event.target.files[0];
      if (file && !file.type.startsWith('image/')) {
          setErrorMessage('Only Images are allowed.');
      } else {
          setErrorMessage(null);
          const formData = new FormData();
          formData.append('profile_picture', file);
    
          // console.log("Uploaded Profile Image: " + file);

          const extension = file.name.split('.').pop();
          setFileExtension(extension);
          // console.log("File Extension: " + extension);
          
          // Call the callback function with the formData
          updateUserImage(formData);
      }
    };

    const updateUserImage = async (formData) => {
        // console.log("Updating User Image...");
        try {
            const fileresponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/media/user/upload/${user.user_data.email}/`,
              formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log("File Uploaded Successfully!")
            // console.log("File Response: ", fileresponse.data);
            // console.log("File Extension: ", fileExtension);
            
            const sanitizedEmail = user.user_data.email.replace('@', '');
            const newProfilePicturePath = `./img/database_media/users/${sanitizedEmail}.jpg`;
            setProfilePicturePath(newProfilePicturePath);
            // console.log("Profile Picture: ", newProfilePicturePath);
            
            // update user data in localStorage
            const imageFile = `database_media/users/${sanitizedEmail}.jpg`;
            user.user_data.profile_picture = imageFile;
            const updatedUser = JSON.stringify(user);
            localStorage.setItem('user', updatedUser);
        } catch (error) {
              // console.log("Error uploading file: ", error);
              console.error('Error uploading file:', error);
        }
    };

  return (
    <div className="mx-auto my-12 w-[85%] rounded-xl bg-gray-200 shadow-sm">
      {/**User Identification (Profle Head)*/}
      <div
        id="profileheader"
        className="flex flex-col md:flex-row md:items-start items-center justify-between rounded-xl px-4 py-6">
        <div id="usermaininfo" className="flex flex-row">
          <div className="relative">
            <img
              className="h-20 w-20 rounded-full"
              src={profilePicturePath}
              alt="Profile"
            />
            <button 
                  onClick={handleButtonClick}
                  className="absolute top-0 right-0 h-7 w-7 bg-custom-blue text-white rounded-full flex items-center justify-center cursor-pointer">
               <MdOutlineEdit className='font-extrabold text-lg'/>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
          <div
            id="username"
            className="ml-4 mt-4 flex flex-row items-start justify-start"
          >
            <h2 className="text-lg font-bold text-black">
              {user.user_data.fullname}
            </h2>
            {userRole === 'Head User' || userRole === 'Manager User' ? (
              <p className="ml-2 rounded-full bg-custom-blue px-3 py-1 text-xs font-medium text-white">
                {userRole}
              </p>
            ) : null}
          </div>
        </div>
        {/** Edit and Change Password Buttons */}
        <div
          id="mainactionbuttons"
          className="mt-5 flex flex-row items-end justify-between"
        >
          <button className="mr-2 flex items-center rounded-lg border-2 border-custom-blue p-3 text-sm font-bold text-custom-blue shadow-sm hover:bg-custom-blue hover:text-white"
          onClick={changePass}>
            <IoKey className="mr-2 text-base" />
            Change Password
          </button>
          <button className="flex items-center rounded-lg border-2 border-custom-blue p-3 text-sm font-bold text-custom-blue shadow-sm hover:bg-custom-blue hover:text-white"
              onClick={editInfo}>
            <MdOutlineDriveFileRenameOutline className="mr-2 text-lg" />
            Edit Informations
          </button>
        </div>
        {showPass && <PopupPass onClose={() => setShowPass(false)} />}
        {showInfo && <PopupEditInfo onClose={() => setShowInfo(false)} />}
      </div>
      {/************************************ */}

      {/** Information Sections */}
      <div className="bg-white shadow-sm">
        <InfoSections />
      </div>
    </div>
  );
}
