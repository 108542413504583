import React, { useEffect } from 'react';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';

export default function ClientBoard() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav/>
         <p>client suite under development</p>
        <Footer/>
    </>
  )
}
