import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "../Home/Button.module.css";
import fstyles from "../Home/PopupForm.module.css";
import { useTranslation } from "react-i18next";

function PopupFormnoBtn({ onClose }) {
  const [formStatus, setFormStatus] = useState(null);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      phone: "",
      date: "",
      time: "",
      form_type: "popup",
    },
    onSubmit: (values, { resetForm }) => {
      fetch(`${process.env.REACT_APP_SERVER_URL}/api/contact/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          // console.log("Success:", data);
          setFormStatus("Success");
          resetForm();
          setTimeout(() => setFormStatus(null), 60000);
        })
        .catch((error) => {
          console.error("Error:", error);
          // console.error("Error:", error);
          setFormStatus("Error");
          setTimeout(() => setFormStatus(null), 60000);
        });
    },
  });

  return (
    <>
      <Modal show={true} onHide={onClose}>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <Form
            onSubmit={formik.handleSubmit}
            className={fstyles.formBackground}
          >
                    <button
                    className="btn btn-warning"
                    onClick={onClose}
                    style={{float:'right', marginBottom: '10px', 
                            color:'#fff', backgroundColor: '#7acfd3',
                            border: 'none'}}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>


            <Form.Group controlId="formName">
              <Form.Label></Form.Label>
              <Form.Control
                type="text"
                placeholder={t("contact-us.F-name")}
                className={fstyles.inputBox}
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                required
                
              />
            </Form.Group>

            <Form.Group controlId="formName">
              <Form.Label></Form.Label>
              <Form.Control
                type="text"
                placeholder={t("contact-us.F-surname")}
                className={fstyles.inputBox}
                onChange={formik.handleChange}
                value={formik.values.surname}
                name="surname"
                required
                
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label></Form.Label>
              <Form.Control
                type="tel"
                placeholder={t("contact-us.F-phone")}
                className={fstyles.inputBox}
                onChange={formik.handleChange}
                value={formik.values.phone}
                name="phone"
                required
              />
            </Form.Group>

            <Form.Group controlId="formDate">
              <Form.Label></Form.Label>
              <Form.Control
                type="date"
                className={fstyles.dateInput}
                onChange={formik.handleChange}
                value={formik.values.date}
                name="date"
                required
              />
            </Form.Group>

            <Form.Group controlId="formTime">
              <Form.Label></Form.Label>
              <Form.Control
                type="time"
                className={fstyles.inputBox}
                onChange={formik.handleChange}
                value={formik.values.time}
                name="time"
                required
              />
            </Form.Group>

            <Button style={{display:'block' ,margin:'20px auto', backgroundColor:'#00477b', fontSize:'13px'}} variant="primary" type="submit" className={styles.btn}>
              {t("contact-us.call-me")}
            </Button>
          </Form>



        </Modal.Body>
      </Modal>
    </>
  );
}

export default PopupFormnoBtn;
