import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';

// Local Imports
import Footer from '../../components/Home/Footer';
import PageNav from '../../components/Home/PageNav';




function getDeliveryInfo(addressData) {
  // console.log("Post Address Data: ");
  // console.log(addressData);
  // Prepare necessary Delivery Data to be sat
    if (addressData && addressData.addressType === "standart_address")
    {
      return addressData.addressValues.id;
    } else {
      return null;
    }
}

function getOneTimeDeliveryInfo(addressData) {
  // console.log("Post Address Data: ");
  // console.log(addressData);
  // Prepare necessary Delivery Data to be sat
  if (addressData && addressData.addressType === "one_time_address")
  {
    return addressData.addressValues.id;
  } else {
    return null;
  }
}


function getOrderType(basketData) {
  // Prepare necessary Order Type Data to be sat
  let dsms = false;
  let products = false;
  let packages = false;

  basketData.flatMap(item => {
    if (item.isDsm && item.dsmProducts) {
      dsms = true;
    }
    else if (item.isPackage && item.packageProducts) {
      packages = true;
    }
    else {
      products = true;
    }
  })

  if (dsms || packages || products) {
    return 'mixed';
  } else if (dsms && !packages && !products) {
    return 'dsm';
  } else if (!dsms && packages && !products) {
    return 'pack';
  } else if (!dsms && !packages && products) {
    return 'products';
  }
}


function getProducts(basketData) {
  return basketData.flatMap(item => {
    let baseItem = [];
  
    if (item.isDsm && item.dsmProducts) {
      // Handle DSM products
      baseItem = baseItem.concat(
        item.dsmProducts.map(product => ({
          product: product.id,
          quantity: product.maxQuantity,
          record_value_excl: product.price * product.maxQuantity,
          record_value_incl: product.price * product.maxQuantity * 1.14,
          source: "dsm",
        }))
      );
    } 
    
    if (item.isPackage && item.packageProducts) {
      // Handle Package products
      baseItem = baseItem.concat(
        item.packageProducts.map(product => ({
          product: product.id,
          quantity: product.quantity,
          record_value_excl: product.price * product.quantity,
          record_value_incl: product.price * product.quantity * 1.14,
          source: "package",
        }))
      );
    }

    if (!item.isDsm && !item.isPackage) {
      baseItem.push({
        product: item.id,
        quantity: item.quantity,
        record_value_excl: item.sales_price_c1 * item.quantity,
        record_value_incl: item.sales_price_c1 * item.quantity * 1.14,
        source: "product",
      });
    }
    return baseItem;
  });
}


function getDSMs(basketData) {
  return basketData.flatMap(item => {
    if (item.isDsm) {
      // console.log("print DSM");
      // console.log(item);
      return {
        dsm: item.dsm,
        quantity: item.quantity,
        record_value_excl: item.sales_price_c1,
        record_value_incl: item.sales_price_c1 * 1.14,
        record_type: 'creation'
      };
    } else {
      return [];
    }
  });
}


function getPackages(basketData) {
  return basketData.flatMap(item => {
    if (item.isPackage) {
      // console.log("print Package");
      // console.log(item);
      return {
        package: item.package,
        quantity: item.quantity,
        num_of_products: item.num_of_products,
        record_value_excl: item.sales_price_c1 * item.num_of_products,
        record_value_incl: item.sales_price_c1 * item.num_of_products * 1.14,
        record_type: 'creation'
      };
    } else {
      return [];
    }
  });
}



function getNextOrderDate() {
  return null;
}



// Function to get today's date in YYYY-MM-DD format
const getFormattedDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};





// Function to create and post an admin notification record
async function sendNotificationData(orderData) {
  const notificationData =  {
    itemId: orderData.id,
    title: "New Order Creation",
    ntype: "order",
    date: getFormattedDate(),
    description: `A new Order has created at ${orderData.company.cname} Entity, by User ${orderData.user.fullname}, email: ${orderData.user.email}, phone ${orderData.user.uphone}. Click on Preview to decide on its approval.`,
    status: "unread"
  };

  const clientData = {
    comapny: orderData.company.cname,
    name: orderData.user.fullname,
    email: orderData.user.email,
    phone: orderData.user.uphone
  }
  const emailData = {notification_data: notificationData, client_data: clientData};

  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/notifications/create/`, notificationData);
    if (response.status >= 200 && response.status < 300) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      
      // Proceed with the second post request
      const secondResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/send-client-notification/`, emailData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        }
      });
      console.log('Second notification created successfully!');
    } else {
      // Handle the case where the first request was not successful
      console.error('First notification creation failed:', response.status);
    }
    //console.log('Notification created successfully:', response.data);
  } catch (error) {
    console.error('Error creating notification:', error);
  }
}



// Function to create and post an admin notification record
async function sendInvoiceNotificationData(orderData, invoiceData) {
  const emailData =  {
    invoice_date: invoiceData.invoice_date,
    PO_number: orderData.payment.PO_number,
    invoice_number: invoiceData.invoice_number,
    order_number: orderData.order_number,
    order_status: orderData.order_status,
    invoice_total: invoiceData.invoice_total_incl,
    email: orderData.user.email,
    name: orderData.user.fullname
  };

  try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      
      // Proceed with the second post request
      const secondResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/order-invoice-notification/`, emailData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        }
      });
      console.log('Second notification created successfully!');
    //console.log('Notification created successfully:', response.data);
  } catch (error) {
    console.error('Error creating notification:', error);
  }
}


async function createInvoiceRecord(orderData, payData, totals) {
  const invoiceData = {
    order: orderData.id,
    payment: payData.id,
    vat: null,
    company: null,
    dsm: null,
    discount: null,
    invoice_status: 'in_progress',
    invoice_type: 'order',
    invoice_date: getFormattedDate(),
    invoice_total_excl: totals.productTotal,
    invoice_total_incl: totals.orderTotal,
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/orders/invoice/create/`, invoiceData);
    if (response.status === 201 || response.status === 200) {
      console.log('Invoice created successfully!');
      await sendInvoiceNotificationData(orderData, response.data);
      return response.data.invoice_number;
    } else {
      console.error('Failed to create invoice:', response.status);
    }
  } catch (error) {
    console.error('Error creating invoice:', error);
    //console.log(error.response.data);
  }

  return null;
}


async function postOrderRecord(data) {
  // Extract necessary information from the data
  // console.log("Repeating Post Order:");
  // console.log(data.repeatOrder);
  let orderNumber = null;
  let invoiceNumber = null;

  const postOrderData = {
    user: data.userData.id,
    company: data.userData.company,
    payment: data.selectedProfileData.id,
    delivery: getDeliveryInfo(data.addressData),
    onetimedelivery: getOneTimeDeliveryInfo(data.addressData),
    order_type: getOrderType(data.basket),
    order_status: 'in_progress',
    order_status_date: getFormattedDate(),
    products: getProducts(data.basket),
    dsms: getDSMs(data.basket),
    packages: getPackages(data.basket),
    order_total: data.orderTotal,
    repeat_order: data.repeatOrder !== null,
    repeat_order_type: data.repeatOrder ? Object.keys(data.repeatOrder)[0] : null,
    repeat_order_subtype: data.repeatOrder ? data.repeatOrder[Object.keys(data.repeatOrder)[0]].selectedDay : null,
    next_order_date: getNextOrderDate(),
  }
  // console.log("OrderData: ");
  // console.log(postOrderData);


  // Posting new OrderData
  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/orders/order/create/`, postOrderData);
    //console.log("Package response: ");
    //console.log(response);
    if (response.status === 201 || response.status === 200) {
      //dispatch(addToBasket(newPackageProduct));
      // console.log("Package response: ");
      // console.log(response.data);
      console.log('Order created successfully.');
      orderNumber = response.data.order_number;
      invoiceNumber = await createInvoiceRecord(response.data, data.selectedProfileData, data.totals);
      await sendNotificationData(response.data);
    } else {
      console.log('Failed to create order.');
    }
  } catch (error) {
    //console.error('Error creating package:', error);
    console.error('Error Creating Order!!');
    // console.log(error.response.data);
  }


  // Return a single text value
  return { orderNumber, invoiceNumber };
}



export default function CompleteOrder() {
  const basket = useSelector((state) => state.basket.items);
  const [termsChecked, setTermsChecked] = useState(false);
  const [infoChecked, setInfoChecked] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [infoError, setInfoError] = useState(false);

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked);
    setTermsError(false);
  };

  const handleInfoChange = (event) => {
    setInfoChecked(event.target.checked);
    setInfoError(false);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { total, tax, selectedProfileData, companyData, user, postAddressData, repeatOrderState } = location.state || { total: 0, tax: 0, selectedProfileData: '', companyData: '', user: '', postAddressData: '', repeatOrderState: '' };

  // Calculate order total
  const orderTotal = total + tax;
  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const gatherInvoiceData = () => {
    // console.log("basketData:");
    // console.log(basket);
    // console.log("Source Address Data: ");
    // console.log(postAddressData);
    return {
      userData: user.user_data,
      companyData: companyData,
      selectedProfileData: selectedProfileData,
      addressData: postAddressData,
      discountData: user.discount_data,
      orderDate: formattedDate,
      repeatOrder: repeatOrderState,
      basket: basket,
      totals: {
        productTotal: total.toFixed(2),
        taxTotal: tax.toFixed(2),
        orderTotal: orderTotal.toFixed(2)
      }
    };
  };


  
  // Where the axios containing function should be located
  const handleContinue = async () => {
    if (!termsChecked || !infoChecked) {
      setTermsError(!termsChecked);
      setInfoError(!infoChecked);
      return;
    }

    const invoiceData = gatherInvoiceData();
    const { orderNumber, invoiceNumber } = await postOrderRecord(invoiceData);
    //console.log('Invoice Data:', JSON.stringify(invoiceData, null, 2));

    // use this to send the ordernumber as a result to post a new order
    if (orderNumber  !== null && invoiceNumber !== null) {
      navigate('/OrderCompleted', { state: { orderNumber } });
    }
  };


  return (
    <>
      <PageNav />
      <div className="mx-auto my-14 w-[90%]">
        <p className="my-5 text-xl font-semibold">Complete Order</p>
        <div className="flex lg:flex-row flex-col justify-center gap-10">
          <div className="lg:w-[75%] w-full">
            <div className='w-full shadow rounded-lg p-3 '>
              <div className='border-1 border-custom-blue rounded-lg p-4'>
                <div className='text-custom-blue flex w-full gap-3 border-b-4 border-b-custom-blue'>
                  <div className='w-2/5 border-t-4 border-t-custom-blue flex items-center'><img className='w-2/3 py-1' src='../../img/Logo.png' alt='logo' /></div>
                  <div className='w-2/5 text-xs border-t-4 border-t-custom-blue'>
                    <p className=' font-bold mb-2'>Name and Address</p>
                    <p className='font-bold'>{companyData.cname}</p>
                    <p>{selectedProfileData.invoicing_address}</p>
                    <p>{selectedProfileData.budget_holder_phone}</p>
                    <p>{selectedProfileData.PO_number}</p>
                    <p><span className='font-semibold'>Payment via: </span>{selectedProfileData.bank} - {selectedProfileData.IBAN}</p>
                  </div>
                  <div className='w-1/5 text-xs border-t-4 border-t-custom-blue'>
                    <div className='bg-[#e9edf2] flex flex-col pb-1'>
                      <p className=' font-bold '>Invoice Number </p>
                      <p className='flex justify-around p-1'>--------</p>
                    </div>
                    <div className='flex flex-col pb-1'>
                      <p className=' font-bold '>PO Number </p>
                      <p className='flex justify-around p-1'>{selectedProfileData.PO_number}</p>
                    </div>
                    <div className='flex flex-col pb-1 '>
                      <p className=' font-bold border-t-4 border-t-custom-blue'>Date </p>
                      <p className='flex justify-around p-1'>{formattedDate}</p>
                    </div>
                  </div>
                </div>
                {basket.length > 0 && (
                  <table className="mt-4 mx-auto text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                    <thead>
                      <tr>
                        <th className="border-1 border-white bg-custom-blue text-white text-center p-2"></th>
                        <th className="border-1 border-white bg-custom-blue text-white text-center p-2">Product name</th>
                        <th className="border-1 border-white bg-custom-blue text-white text-center p-2">Price per unit</th>
                        <th className="border-1 border-white bg-custom-blue text-white text-center p-2">Number of units</th>
                        <th className="border-1 border-white bg-custom-blue text-white text-center p-2">Total price (excl.)</th>
                        <th className="border-1 border-white bg-custom-blue text-white text-center p-2">Tax percentage</th>
                        <th className="border-1 border-white bg-custom-blue text-white text-center p-2">Tax amount</th>
                        <th className="border-1 border-white bg-custom-blue text-white text-center p-2">Total price (incl.)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {basket.flatMap((item, index) => {
                        const baseRow = (<>

                          <tr key={item.id}>
                            <td className="border-1 border-custom-blue p-2 bg-white font-bold text-center">{index + 1}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white font-bold text-center">
                              {(item.isDsm) ? `${item.name} - Creation fee` : `${item.name}`}
                            </td>
                            <td className="border-1 border-custom-blue p-2 bg-white font-bold text-center">${item.totalProductCost}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white font-bold text-center">{item.quantity}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white font-bold text-center">${(item.sales_price_c1 * item.quantity).toFixed(2)}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white font-bold text-center">14%</td>
                            <td className="border-1 border-custom-blue p-2 bg-white font-bold text-center">${(item.sales_price_c1 * item.quantity * 0.14).toFixed(2)}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white font-bold text-center">${(item.sales_price_c1 * item.quantity * 1.14).toFixed(2)}</td>
                          </tr>
                          {item.isPackage ? <tr>
                          <td colSpan="8" className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">Creation Fee: ${item.sales_price_c1}</td>
                          </tr>: null}
                          </>

                        );

                        const dsmRows = item.isDsm && item.dsmProducts ? item.dsmProducts.map((product, dsmIndex) => (
                          <tr key={`${item.id}-dsm-${dsmIndex}`}>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center"></td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">{product.name} (DSM Product)</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">${product.price}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">{product.maxQuantity}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">${(product.price * product.maxQuantity).toFixed(2)}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">14%</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">${(product.price * product.maxQuantity * 0.14).toFixed(2)}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">${(product.price * product.maxQuantity * 1.14).toFixed(2)}</td>
                          </tr>
                        )) : [];

                        const packageRows = item.isPackage && item.packageProducts ? item.packageProducts.map((product, packageIndex) => (
                          <tr key={`${item.id}-package-${packageIndex}`}>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center"></td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">{product.name} (Package Product)</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">${product.price}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">{product.quantity}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">${(product.price * product.quantity).toFixed(2)}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">14%</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">${(product.price * product.quantity * 0.14).toFixed(2)}</td>
                            <td className="border-1 border-custom-blue p-2 bg-white text-center">${(product.price * product.quantity * 1.14).toFixed(2)}</td>
                          </tr>
                        )) : [];

                        return [baseRow, ...dsmRows, ...packageRows];
                      })}
                      <tr>
                        <td className="border-1 border-white bg-custom-blue text-white p-2 font-semibold text-center" colSpan="4">Total</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          ${basket.reduce((sum, item) => {
                            let total = item.sales_price_c1 * item.quantity;
                            if (item.isDsm && item.dsmProducts) {
                              total += item.dsmProducts.reduce((productSum, product) => productSum + (product.price * product.maxQuantity), 0);
                            }
                            if (item.isPackage && item.packageProducts) {
                              total += item.packageProducts.reduce((productSum, product) => productSum + (product.price * product.quantity), 0);
                            }
                            return sum + total;
                          }, 0).toFixed(2)}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">-</td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          ${basket.reduce((sum, item) => {
                            let total = item.sales_price_c1 * item.quantity * 0.14;
                            if (item.isDsm && item.dsmProducts) {
                              total += item.dsmProducts.reduce((productSum, product) => productSum + (product.price * product.maxQuantity * 0.14), 0);
                            }
                            if (item.isPackage && item.packageProducts) {
                              total += item.packageProducts.reduce((productSum, product) => productSum + (product.price * product.quantity * 0.14), 0);
                            }
                            return sum + total;
                          }, 0).toFixed(2)}
                        </td>
                        <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          ${basket.reduce((sum, item) => {
                            let total = item.sales_price_c1 * item.quantity * 1.14;
                            if (item.isDsm && item.dsmProducts) {
                              total += item.dsmProducts.reduce((productSum, product) => productSum + (product.price * product.maxQuantity * 1.14), 0);
                            }
                            if (item.isPackage && item.packageProducts) {
                              total += item.packageProducts.reduce((productSum, product) => productSum + (product.price * product.quantity * 1.14), 0);
                            }
                            return sum + total;
                          }, 0).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}


                                <table className=" mt-4 text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                                    <thead>
                                        <tr>
                                            <th className="border-1 border-custom-blue p-2">Order Number</th>
                                            <th className="border-1 border-custom-blue p-2">Price</th>
                                            <th className="border-1 border-custom-blue p-2">Contrat Discount</th>
                                            <th className="border-1 border-custom-blue p-2">Price After Discount</th>
                                            <th className="border-1 border-custom-blue p-2">Tax</th>
                                            <th className="border-1 border-custom-blue p-2">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center ">__</td>
                                            <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">${total.toFixed(2)}</td>
                                            <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">20%</td>
                                            <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">$100</td>
                                            <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">${tax.toFixed(2)}</td>
                                            <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">${orderTotal.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className='font-bold text-custom-blue py-5 flex justify-end items-center gap-3'>TOTAL AMOUNT TO PAY <span className='border-2 border-custom-blue py-1 px-4'>${orderTotal.toFixed(2)}</span></p>
                                {/* <div className='w-fit'>
                                    <p className='my-1 text-center text-custom-blue'>ADDITIONAL INFORMATION</p>
                                    <div className='border-t-4 border-t-custom-blue flex gap-2 w-fit'>
                                        <table className="mt-2 text-xs border-collapse border-1 border-custom-blue">
                                            <thead>
                                                <tr>
                                                    <th className="border-1 border-white p-2 bg-custom-blue text-white text-center" colSpan="3">Cost Price Payed</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="border-1 border-white p-2 bg-custom-blue text-white">For The Medication</td>
                                                    <td className="border-1 border-white p-2 bg-custom-blue text-white">For The Distibuter</td>
                                                    <td className="border-1 border-white p-2 bg-custom-blue text-white">Total</td>
                                                </tr>
                                                <tr className='text-custom-blue'>
                                                    <td className="border-1 border-custom-blue p-2 text-center">__</td>
                                                    <td className="border-1 border-custom-blue p-2 text-center">__</td>
                                                    <td className="border-1 border-custom-blue p-2 text-center">__</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="mt-2 text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                                            <thead>
                                                <tr><th className="border-1 border-custom-blue p-2">Professtional Fees</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td className="border-1 border-custom-blue p-2 text-center">__</td></tr>
                                            </tbody>
                                        </table>
                                        <table className="mt-2 text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                                            <thead>
                                                <tr>
                                                    <th rowSpan="2" className="border-1 border-custom-blue p-2">RGAM Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="border-1 border-custom-blue p-2 text-center">__</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div> */}
                            </div>
                        </div>

            <p className='font-semibold mb-3 mt-5 text-lg'>Contracts and Forms</p>
            <p className='font-semibold mb-1 text-sm'>Return Policy</p>
            <div className='bg-[#fafafa] text-stone-400 p-4 text-xs rounded h-40'>
              <Scrollbars>
                <p className='w-full'>
                  Teslimat tarihinden itibaren 15 gün içerisinde iade edebilirsiniz.
                  Nasıl İade Ederim?<br />
                  Assortix ve mobile uygulamalarda yer alan “Hesabım” bölümünden “Siparişlerime” gidin.
                  “Sipariş detay” butonuna basın ve siparişinizin detaylarını görüntüleyin.<br />
                  Kolay iade butonuna basın.<br />
                  İade edilecek ürün ve iade nedeni seçin. Aynı üründen birden fazla satın aldıysanıziade edilecek ürün adedini de seçmeniz gerekir.
                  Birden fazla kargo seçeneği çıktığı durumda kargo seçiminizi yapın.<br />
                  Ekranda çıkan iade kargo kodunu not alın.<br />
                  İade kargo kodunuza siparişlerim sayfasından ve üyelik mail adresinize gönderilen bilgilendirme mesajından daulaşabilirsiniz.<br />
                  İade edilecek ürünler ile birlikte faturayı tek bir pakete koyun.Paketi iade kargo koduyla birlikte seçtiğiniz kargoya 7 gün içinde teslim
                </p>
              </Scrollbars>
            </div>

            <p className='font-semibold mb-1 text-sm mt-4'>Preliminary Information Form</p>
            <div className='bg-[#fafafa] text-stone-400 p-4 text-xs rounded h-40'>
              <Scrollbars>
                <p className='w-full'>
                  Teslimat tarihinden itibaren 15 gün içerisinde iade edebilirsiniz.
                  Nasıl İade Ederim?<br />
                  Assortix ve mobile uygulamalarda yer alan “Hesabım” bölümünden “Siparişlerime” gidin.
                  “Sipariş detay” butonuna basın ve siparişinizin detaylarını görüntüleyin.<br />
                  Kolay iade butonuna basın.<br />
                  İade edilecek ürün ve iade nedeni seçin. Aynı üründen birden fazla satın aldıysanıziade edilecek ürün adedini de seçmeniz gerekir.
                  Birden fazla kargo seçeneği çıktığı durumda kargo seçiminizi yapın.<br />
                  Ekranda çıkan iade kargo kodunu not alın.<br />
                  İade kargo kodunuza siparişlerim sayfasından ve üyelik mail adresinize gönderilen bilgilendirme mesajından daulaşabilirsiniz.<br />
                  İade edilecek ürünler ile birlikte faturayı tek bir pakete koyun.Paketi iade kargo koduyla birlikte seçtiğiniz kargoya 7 gün içinde teslim
                  Teslimat tarihinden itibaren 15 gün içerisinde iade edebilirsiniz.
                  Nasıl İade Ederim?<br />
                  Assortix ve mobile uygulamalarda yer alan “Hesabım” bölümünden “Siparişlerime” gidin.
                  “Sipariş detay” butonuna basın ve siparişinizin detaylarını görüntüleyin.<br />
                  Kolay iade butonuna basın.<br />
                  İade edilecek ürün ve iade nedeni seçin. Aynı üründen birden fazla satın aldıysanıziade edilecek ürün adedini de seçmeniz gerekir.
                  Birden fazla kargo seçeneği çıktığı durumda kargo seçiminizi yapın.<br />
                  Ekranda çıkan iade kargo kodunu not alın.<br />
                  İade kargo kodunuza siparişlerim sayfasından ve üyelik mail adresinize gönderilen bilgilendirme mesajından daulaşabilirsiniz.<br />
                  İade edilecek ürünler ile birlikte faturayı tek bir pakete koyun.Paketi iade kargo koduyla birlikte seçtiğiniz kargoya 7 gün içinde teslim
                </p>
              </Scrollbars>
            </div>
          </div>

          <div className="lg:w-[25%] w-full">
            <div className={`bg-[#f8f8f8] items-center rounded flex p-3 ${termsError ? 'border border-red-500' : ''}`}>
              <Checkbox
                checked={termsChecked}
                onChange={handleTermsChange}
                sx={{
                  color: '#a6a7a8',
                  '&.Mui-checked': {
                    color: '#2849ad',
                  },
                  '& .MuiSvgIcon-root': {
                    borderRadius: '50%',
                  },
                }}
              />
              <p className={`text-[10px] font-semibold underline underline-offset-1 ${termsError ? 'text-red-500' : ''}`}>
                Invoice Information Conditions and Distance<br />
                I have read and accept the Sales Agreement.
              </p>
            </div>

            <div className={`bg-[#f8f8f8] items-center rounded flex p-3 my-3 ${infoError ? 'border border-red-500' : ''}`}>
              <Checkbox
                checked={infoChecked}
                onChange={handleInfoChange}
                sx={{
                  color: '#a6a7a8',
                  '&.Mui-checked': {
                    color: '#2849ad',
                  },
                  '& .MuiSvgIcon-root': {
                    borderRadius: '50%',
                  },
                }}
              />
              <p className={`text-[10px] font-semibold underline underline-offset-1 ${infoError ? 'text-red-500' : ''}`}>
                Preliminary Information Conditions and Distance<br />
                I have read and accept the Sales Agreement.
              </p>
            </div>

            <div className="mt-2 h-fit rounded-lg bg-[#DCF6F7] p-4 text-custom-blue">
              <h2 className="text-xl font-bold">Card Totals</h2>
              <div className="my-3 text-sm">
                <div className="my-1 grid grid-cols-2 gap-3">
                  <p>Product Total:</p>
                  <span>${total.toFixed(2)}</span>
                </div>
                <div className="my-1 grid grid-cols-2 gap-3">
                  <p>Tax Total:</p>
                  <span>${tax.toFixed(2)}</span>
                </div>
                <div className="my-1 grid grid-cols-2 gap-3">
                  <p>Order Totals:</p>
                  <span>${(total + tax).toFixed(2)}<span> (VAT incl.)</span></span>
                </div>
              </div>

              <div className="mt-4">
                <button onClick={handleContinue} className="m-auto w-full rounded-md bg-custom-blue py-2 text-sm font-semibold text-white hover:bg-blue-600">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
