import React from "react";



export default function PackProductSum({ packProducts }) {

  // Items Section
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  //const [displayProducts, setdisplayProducts] = useState([]);

  const packData = packProducts;
  const displayedProducts = packProducts.products.map(product => {
    const productRecord = storedProducts.find(storedProduct => storedProduct.id === product.product);
    return {
      ...product,
      product: productRecord,
    };
  });



  let count = 0;
  //const [displayProducts, setdisplayProducts] = useState([]);
  const tableHeads = ['', 'Product name', 'Price per unit', 'Number of units', 'Total packageProduct price (excl.)', 'Total Pack Price (excl.)'];


    // Invoice Review - Checkout Model
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

  

  return (

    <div className="w-full h-full flex flex-col">

      {/** Checkout Summary Section */}
      <div className="w-full h-full flex justify-center mt-2">
          <div className='w-full shadow rounded-lg p-3 '>
              <div className='border-1 border-custom-blue rounded-lg p-4'>
              
              {/** Order Listings Table */}
                <table className="mt-2 mx-auto text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                    <thead>
                      <tr>
                        {tableHeads.map((head, index) => (
                          <th key={index} className="border-1 border-white bg-custom-blue text-white text-center p-2">
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {displayedProducts && displayedProducts.map((item, index) => (
                        <tr key={item.id}>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {index + 1}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.product.name}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.product.sales_price_c1).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.quantity_per_product? item.quantity_per_product : 1}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.product.sales_price_c1 * (item.quantity_per_product? item.quantity_per_product : 2)).toFixed(2)}</td>
                          
                          
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          -- </td>
                        </tr>
                      ))}
                      {displayedProducts && (() => {
                        count = displayedProducts.length + 1;
                        console.log(`Total items processed: ${count}`);
                        return null;
                      })()}

              
                      {/** Total Products Line */}
                        <tr>
                          <td className="border-1 border-custom-blue bg-white text-custom-blue p-2 font-bold text-center"
                              colSpan="4">Packge Products Total</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
  € {(displayedProducts.reduce((sum, item) => sum + (item.product.sales_price_c1 * (item.quantity_per_product ? item.quantity_per_product : 2)), 0)).toFixed(2)}
</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">--</td>
                        </tr>

                        {/** Document Price */}
                        <tr>
                          <td className="border-1 border-custom-blue bg-white text-custom-blue p-2 font-bold text-center"
                              colSpan="4">Document Price</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
  € {(packData.doc_price).toFixed(2)}
</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">--</td>
                        </tr> 

                         {/** Total Package Price (excl.)*/}
                         <tr>
                          <td className="border-1 border-white bg-custom-blue text-white p-2 font-semibold text-center"
                              colSpan="4">Total Package Price (excl.)</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">--</td>
                          <td className="border-1 border-custom-blue p-2 text-base text-center font-bold">
  € {(packData.total_pack_price).toFixed(2)}
</td>
                        </tr> 
                      </tbody>
                    </table>
            </div>
          </div>
      </div> 
    </div>
  );
}