import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from "axios";

import { Container, Row, Col } from "react-grid-system";
import Pagination from "react-js-pagination";
import Card from "react-bootstrap/Card";

import CardBtn from "../Shop/CardBtn";

import "bootstrap/dist/css/bootstrap.min.css";
import "../Career/JobGrid.css";
import "../Shop/ShopListingSlider2.css";
import styles from "../Home/ProductCarousel.module.css";
import Spinner from "../Home/Spinner";

const JobDetailSlider2 = () => {
  const [activePage, setActivePage] = useState(1);
  const [products, setProducts] = useState([]);

  const { t } = useTranslation();

  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
  };


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/products/`)
      .then(response => {
        // console.log(response.data);
        setProducts(response.data);
        localStorage.setItem('products', JSON.stringify(response.data));
      })
      .catch(error => {
        // console.error('There was an error!', error);
      });
  }, []);


  
  // Retrieve the JSON string from localStorage and parse it into an object
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  const cards = products.map((product, index) => ({
    title: product.category,
    text: product.name,
    imgSrc: imgPaths[product.assortimed_pid][0]
  }))

  const itemsPerPage = 16;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, products.length);

  return (
    <div style={{width:'95%', margin:'0', marginLeft:'60px', marginBottom: '30px'}}>
      <Row>
      {Array.isArray(cards)  && cards.length > 0 ? cards.slice(startIndex, endIndex).map((card, index) => (
          <Col xxl={2.3} xl={2.8} lg={4} sm={5} xs={8.4} key={index} style={{padding:'0'}}>
            <Card key={index} className={styles.card_body}>
              <div className={styles.img_container}>
                <Card.Img variant="top" src={card.imgSrc} className={styles.card_img}/>
              </div>
              <Card.Body>
              <div className={styles.content_container}>
              <Card.Title className={styles.card_title}>{card.title}</Card.Title>
                <Card.Text className={styles.card_content}>{card.text}</Card.Text>
                </div>
                <Link to='/login'><CardBtn >{t("landing.view-cart-btn")}</CardBtn></Link>
              </Card.Body>
            </Card>
          </Col>
        )) : <Spinner/>}
      </Row>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={products.length}
        pageRangeDisplayed={7}
        onChange={handlePageChange}
        style={{marginTop: '20px'}}
      />
    </div>
  );
};

export default JobDetailSlider2;
