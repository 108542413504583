import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Button from "./Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

import styles from "./Slider.module.css";
import 'react-modal-video/scss/modal-video.scss';
import './Slider.css';

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function MySlider() {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          position: 'absolute',
          top: '75%',
          width: '30%',
          textAlign: 'start',
          left:'130px',
          margin:'20px 0',
        }}
      >
        <ul style={{ 
          margin: '0',
          }}>{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openModal = (url) => {
    setVideoUrl(url);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };


  // Button Status Sign in or Start
  const [status, setStatus] = useState(localStorage.getItem('status'));

  // Handling Admin Suite Routing
  const user = JSON.parse(localStorage.getItem('user'));
  const issuper = localStorage.getItem('is_superuser');


  return (
    <div  className={styles.sliderContainer}>
          <Modal isOpen={modalIsOpen} 
                 onRequestClose={closeModal} 
                 contentLabel="Video Modal"
                 style={{
                  overlay: {
                    backgroundColor: 'transparent'
                  },
                  content: {
                    width: '55%', 
                    height: '70%',
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '100px',
                    background: 'linear-gradient(to right, #7acfd3, #2849ad)'
                  }
                }}
            >
        <video width="100%" height="100%" controls autoPlay>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
    
    <Slider {...settings}>
      <div className={styles.slider}>
      <img src={isMobile ? "../img/home/mobile/2.png" : "../img/home/2.png"} alt="first slider img" />
        <div className={styles.sliderContent}>
          <h1>{t("landing.sec1-title")}</h1>
          <h5>{t("landing.sec1-s1")}</h5>
          <div className={styles.displayPart}>
          { status === 'loggedIn' ?
            ( issuper == 'true'?
              <Link to='/adminsuite'><Button>{t("landing.start-btn")}</Button></Link>
              :
              <Link to='/clientsuite'><Button>{t("landing.start-btn")}</Button></Link>
            )
            :
            <Link to='/signup'><Button>{t("navbar.signup")}</Button></Link>
          }
          <div className={styles.display}  onClick={() => openModal('./img/videos/home.mp4')}>
            <span><FontAwesomeIcon icon={faCaretRight} /></span>
          </div>
            <p>{t("landing.sec1-video")}</p>
          </div>
        </div>
      </div>
      <div className={styles.slider}>
      <img src={isMobile ? "../img/home/mobile/1.png" : "../img/home/1.png"} alt="third slider img" />
        <div className={styles.sliderContent}>
          <h1>{t("landing.sec1-title")}</h1>
          <h5>{t("landing.sec1-s2")}</h5>
          <div className={styles.displayPart}>
          { status === 'loggedIn' ?
            ( issuper == 'true'?
              <Link to='/adminsuite'><Button>{t("landing.start-btn")}</Button></Link>
              :
              <Link to='/clientsuite'><Button>{t("landing.start-btn")}</Button></Link>
            )
            :
            <Link to='/signup'><Button>{t("navbar.signup")}</Button></Link>
          }
          <div className={styles.display}  onClick={() => openModal('./img/videos/home.mp4')}>
            <span><FontAwesomeIcon icon={faCaretRight} /></span>
          </div>
            <p>{t("landing.sec1-video")}</p>
          </div>
        </div>
      </div>
      <div className={styles.slider}>
      <img src={isMobile ? "../img/home/mobile/4.png" : "../img/home/4.png"} alt="second slider img" />
        <div className={styles.sliderContent}>
          <h1>{t("landing.sec1-title")}</h1>
          <h5>{t("landing.sec1-s3")}</h5>
          <div className={styles.displayPart}>
          { status === 'loggedIn' ?
            ( issuper == 'true'?
              <Link to='/adminsuite'><Button>{t("landing.start-btn")}</Button></Link>
              :
              <Link to='/clientsuite'><Button>{t("landing.start-btn")}</Button></Link>
            )
            :
            <Link to='/signup'><Button>{t("navbar.signup")}</Button></Link>
          }
          <div className={styles.display}  onClick={() => openModal('./img/videos/home.mp4')}>
            <span><FontAwesomeIcon icon={faCaretRight} /></span>
          </div>
            <p>{t("landing.sec1-video")}</p>
          </div>
        </div>
      </div>
    </Slider>
    </div>
  );
}
