import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

const ProductItem = ({ product }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  if (!product || !product.name) {
    return null; // or some fallback UI
  }

  // const { imgUrl, Category, category2, branch, name, quantity, price } =
  //   product;
  console.log("Inside product: " + product.name);
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
  const imgUrl = imgPaths[product.assortimed_pid][0];

  return (
    <>
      <div className="relative mt-4 flex w-full flex-row rounded border bg-white p-6 shadow">
        <div className="mt-1 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          <div className="mr-2 flex w-full flex-col ">
            <img
              src={imgUrl}
              alt="description_of_image"
              className="h-20 w-20 object-cover"
            />
          </div>
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Category</p>
            <span className="text-sm text-gray-500">{product.category}</span>
          </div>
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">SubCategory</p>
            <span className="text-sm text-gray-500">{product.sub_category_1}</span>
          </div>
        </div>
        <div className="mt-1 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Brand</p>
            <span className="text-sm text-gray-500">{product.brand}</span>
          </div>
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Qty</p>
            <span className="text-sm text-gray-500">{10}</span>
          </div>
          <div className="mt-3 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Price</p>
            <span className="text-sm text-gray-500">{product.sales_price_1}</span>
            <p className="mb-2 text-xs font-bold text-gray-500">(Vat incl.)</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductItem;
