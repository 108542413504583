import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../UShop/ProductImgSlider.css";
import './RelatedProductSlider.css'

export default function ProductImgSlider({ imgPaths }) {
  // const images = [
  //   "../../img/contact.jpg",
  //   "../../img/contact.jpg",
  //   "../../img/contact.jpg",
  //   "../../img/contact.jpg",
  // ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="productImage">
      <Slider {...settings}>
        {imgPaths.map((image, index) => (
          <div key={index}>
            <img className="imgSlider" src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
