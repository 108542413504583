import React, { useEffect } from 'react';

// Local Imports
import useAuth from "../hooks/useAuth.js";
import BoardBase from './BoardStruct';


export default function AdminBoard() {
  useAuth();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
         <BoardBase/>
    </>
  )
}
