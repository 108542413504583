import React from "react";




export default function DSMProductSum({ dsmProducts }) {

  // Items Section
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  //const [displayProducts, setdisplayProducts] = useState([]);

  const dsmData = dsmProducts;
  const displayedProducts = dsmProducts.products.map(product => {
    const productRecord = storedProducts.find(storedProduct => storedProduct.id === product.product);
    return {
      ...product,
      product: productRecord,
    };
  });



    let count = 0;
    //const [displayProducts, setdisplayProducts] = useState([]);
    const tableHeads = ['', 'Product name', 'Price per unit', 'Lower stock limit', 'Upper stock limit', 'Product Status'];

    function capitalizeFirstLetter(word) {
      if (!word) return '';
      return word.charAt(0).toUpperCase() + word.slice(1);
    }


    // Invoice Review - Checkout Model
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

  

  return (

    <div className="w-full h-full flex flex-col">

      {/** Checkout Summary Section */}
      <div className="w-full h-full flex justify-center mt-2">
          <div className='w-full shadow rounded-lg p-3 '>
              <div className='border-1 border-custom-blue rounded-lg p-4'>
              
              {/** Order Listings Table */}
                <table className="mt-2 mx-auto text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                    <thead>
                      <tr>
                        {tableHeads.map((head, index) => (
                          <th key={index} className="border-1 border-white bg-custom-blue text-white text-center p-2">
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {displayedProducts && displayedProducts.map((item, index) => (
                        <tr key={item.id}>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {index + 1}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.product.name}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.product.sales_price_c1).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.lower_spec_stock? item.lower_spec_stock : 100}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          {item.upper_spec_stock? item.upper_spec_stock : 1000}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          {item.product_status? capitalizeFirstLetter(item.product_status) : 'NA'} </td>
                        </tr>
                      ))}
                      {displayedProducts && (() => {
                        count = displayedProducts.length + 1;
                        //console.log(`Total items processed: ${count}`);
                        return null;
                      })()}

              
                      </tbody>
                  </table>
            </div>
          </div>
      </div> 
    </div>
  );
}