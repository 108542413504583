
import { Button, Tabs, Tab, Table, Badge } from 'react-bootstrap';

// Intrenal Imports
import BaseProfile from './profile/BaseProfile';
import Users from './profile/Users';

export default function ProfilePage() {
  return (
            <>
                <BaseProfile />

                <Users />
            </>
  )
}
