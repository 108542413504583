import React, { useMemo, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import PdfUploader from './PdfUploader';
import { useDispatch } from 'react-redux';
import axios from 'axios';


// Icon Imports
import { LuFileType2 } from 'react-icons/lu';
import { GiExpense } from 'react-icons/gi';
import { IoMdPricetags } from 'react-icons/io';
import { CgDollar } from 'react-icons/cg';
import {
  MdOutlineDriveFileRenameOutline,
  MdFormatListNumbered,
} from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


// Local Imports
import { addToBasket } from '../../features/basket/basketSlice';
import '../creationPopupStyle.css';



// Function to get today's date in YYYY-MM-DD format
const getFormattedDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Function to create and post an admin notification record
async function sendNotificationData(packData) {
  const notificationData =  {
    itemId: packData.id,
    title: "New Package Creation",
    ntype: "pack",
    date: getFormattedDate(),
    description: `A new Package has created at ${packData.company.cname} Entity, by User ${packData.pack_creator.fullname}, email: ${packData.pack_creator.email}, phone ${packData.pack_creator.uphone}, at department ${packData.department}. Click on Preview to decide on its approval.`,
    status: "unread"
  };

  const clientData = {
    comapny: packData.company.cname,
    name: packData.pack_creator.fullname,
    email: packData.pack_creator.email,
    phone: packData.pack_creator.uphone
  }
  const emailData = {notification_data: notificationData, client_data: clientData};

  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/notifications/create/`, notificationData);
    if (response.status >= 200 && response.status < 300) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      
      // Proceed with the second post request
      const secondResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/send-client-notification/`, emailData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        }
      });
      console.log('Second notification created successfully!');
    } else {
      // Handle the case where the first request was not successful
      console.error('First notification creation failed:', response.status);
    }
    //console.log('Notification created successfully:', response.data);
  } catch (error) {
    console.error('Error creating notification:', error);
  }
}

export default function PopupCreatePackage({
  onClose,
  addedProducts,
  showToast,
  showFail,
  handleSucess,
}) {
  const dispatch = useDispatch();
  //console.log("New Final Added Products: ", addedProducts);

  // Calculate total quantity of all products
  const totalQuantity = useMemo(() => {
    return addedProducts.reduce(
      (total, product) => total + (product.quantity || 1),
      0,
    );
  }, [addedProducts]);

  // Calculate Creation fees
  const creationFee = useMemo(() => {
    return 2.75 * totalQuantity; //totalQuantity * 2.75;
  }, [totalQuantity]);

  const totalPrice = useMemo(() => {
    return addedProducts.reduce(
      (sum, product) =>
        sum + (Number(product.sales_price_c1) * product.quantity || 0),
      0,
    );
  }, [addedProducts]);


  // Document Type Select options
  const docTypeOptions = [
    { value: 'colored', label: 'Colored' },
    { value: 'non_colored', label: 'Non Colored' }
  ];

  const [docType, setdocType] = useState('');
  const docPriceMap = {
    colored: 0.95,
    non_colored: 0.5,
  };

  let packID = null;

  const formik = useFormik({
    initialValues: {
      package_name: '',
      num_of_products: totalQuantity,
      document_type: '',
      creation_fee: creationFee,
      package_fulfillment_price: parseFloat(2.75.toFixed(2)),
      total_pack_price: totalPrice,
    },
    onSubmit: async (values) => {
      const newPackageProduct = {
        id: new Date().getTime(),
        name: values.package_name,
        category: 'Package',
        sales_price_c1: 2.75, //creationFee,
        quantity: 1,
        isPackage: true,
        totalProductCost: values.total_pack_price + (values.document_type? docPriceMap[values.document_type]: 0),
        num_of_products: values.num_of_products,
        packageProducts: addedProducts.map((product) => ({
          name: product.name,
          category: product.category,
          price: product.sales_price_c1,
          imageUrl: product.photo,
          quantity: product.quantity || 1,
          id: product.id,
        })),
      };

      await createNewPackage(values, addedProducts);

      newPackageProduct.package = packID;
      dispatch(addToBasket(newPackageProduct));
      //showToast('Package created successfully!');
      onClose();
      //console.log(newPackageProduct, 'tr');
    },
  });


  // PDF File Upload
  const [uploadedFileData, setUploadedFileData] = useState(null);

  const handleFileUpload = async (formData) => {
    setUploadedFileData(formData);
  };

  // createNewPackage API
  const user = JSON.parse(localStorage.getItem('user'));
  ////console.log("User: ", user);
  //console.log("Added Products: ", addedProducts);
  const createNewPackage = async (values, addedProducts) => {
    const postPackageProduct = {
      company: user.user_data.company,
      pack_creator: user.user_data.id,
      package_name: values.package_name,
      department: user.user_data.department_name,
      creation_date: new Date().toISOString(),
      creation_fee: creationFee || (addedProducts.length * 2.75),
      doc_type: values.document_type || null,
      doc_price: docPriceMap[values.document_type] || 0,
      package_fulfillment_price: values.package_fulfillment_price,
      total_pack_price: values.total_pack_price + docPriceMap[values.document_type],
      package_status: 'active',
      active_date: new Date().toISOString(),
      paused_date: null,
      non_active_date: null,
      num_of_products: addedProducts.length,
      products: addedProducts.map((product) => ({
        product: product.id,
        quantity_per_product: product.quantity || 1,
      })),
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/products/package/create/`, postPackageProduct);
      if (response.status === 201) {
        packID = response.data.id;
        const newFileName = response.data.package_name + '.pdf';
        const renamedFile = new File([uploadedFileData], newFileName, { type: uploadedFileData.type });
        const formData = new FormData();
        formData.append('doc_file', renamedFile);
        // Save Uploaded File
        try {
            const fileresponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/media/package/upload/${response.data.id}/`, 
              formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            await sendNotificationData(response.data);
            showToast('Package created successfully!');
            handleSucess();
        } catch (error) {
              console.error('Error uploading file:', error);
        }
      } else {
        showFail('Failed to create package.');
      }
    } catch (error) {
      showFail('There\'s another Package with that name already exists.');
    }
  };

  return (
    <div className="creation_popup">
      <Modal show={true} onHide={onClose}>
        <Modal.Header className="flex-row-reverse bg-neutral-50">
          <button
            className="btn border-none bg-custom-green text-white hover:bg-[#07b1ab]"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h2 className="m-auto font-sans text-2xl font-semibold">
            Package Details
          </h2>
        </Modal.Header>
        {/** Package Details Form */}
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit} className="m-4 bg-white">
            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdOutlineDriveFileRenameOutline />
              </span>
              <label className="popup_review_label">Package Name</label>
              <div className="grow">
                <input
                  required
                  className="input w-full"
                  name="package_name"
                  type="text"
                  placeholder="Package Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.package_name}
                />
              </div>
            </div>

            <PdfUploader onFileUpload={handleFileUpload}/>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <LuFileType2 />
              </span>
              <label className="popup_review_label">Document Type</label>
              <div className="grow">
                <Select
                  className="w-full"
                  value={docTypeOptions.find(option => option.value === formik.values.document_type)}
                  onChange={selectedOption => {
                    formik.setFieldValue('document_type', selectedOption.value);
                    setdocType(selectedOption.value);
                  }}
                  options={docTypeOptions}
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <CgDollar />
              </span>
              <label className="popup_review_label">
                Document Price
              </label>
              <div className="grow">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  value={docPriceMap[docType]}
                  name="package_fulfillment_price"
                  disabled
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <MdFormatListNumbered />
              </span>
              <label className="popup_review_label">Number Of Products</label>
              <div className="grow">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  name="num_of_products"
                  type="number"
                  placeholder="Number of products"
                  value={formik.values.num_of_products}
                  disabled
                />
              </div>
            </div>

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <GiExpense />
              </span>
              <label className="popup_review_label">Creation Fee</label>
              <div className="grow">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  type="number"
                  placeholder="100"
                  name="creation_fee"
                  value={formik.values.creation_fee}
                  disabled
                />
              </div>
            </div>

            {/* <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <CgDollar />
              </span>
              <label className="popup_review_label">
                Package Fulfillment Price
              </label>
              <div className="grow">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  value={formik.values.package_fulfillment_price}
                  name="package_fulfillment_price"
                  disabled
                />
              </div>
            </div> */}

            <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
              <span className="text-xl text-custom-green">
                <IoMdPricetags />
              </span>
              <label className="popup_review_label">Total Pack Price</label>
              <div className="flex grow justify-between">
                <input
                  className="input w-full cursor-not-allowed text-gray-500"
                  name="total_pack_price"
                  type="number"
                  value={formik.values.total_pack_price + docPriceMap[docType]}
                  disabled
                />
              </div>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
            >
              Create
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
