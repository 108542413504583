import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
// Icons Imports
import { MdApartment } from 'react-icons/md';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { IoMdPricetags } from 'react-icons/io';
import { BsCalendarDateFill } from "react-icons/bs";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdContactMail } from "react-icons/md";

const PopupFormCom = () => {
  const [formValues, setFormValues] = useState({
    institution_name: '',
    chamber_of_commerce: '',
    VAT_tax: '',
    contract_start_date: '',
    contract_end_date: '',
    main_office: '',
    country: '',
    city: '',
    main_address: '',
    postal_code: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues);
  };

  const iconMap = {
    institution_name: <MdApartment />,
    chamber_of_commerce: <LiaFileInvoiceDollarSolid />,
    VAT_tax: <IoMdPricetags />,
    contract_start_date: <BsCalendarDateFill />,
    contract_end_date: <BsCalendarDateFill />,
    main_office: <MdApartment />,
    country: <FaMapLocationDot />,
    city: <FaMapLocationDot />,
    main_address: <FaMapLocationDot />,
    postal_code: <MdContactMail />,
  };

  const rows = [
    { name: "institution_name", label: "Institution name", type: "text" },
    { name: "chamber_of_commerce", label: "Chamber of Commerce", type: "text" },
    { name: "VAT_tax", label: "VAT Tax", type: "number" },
    { name: "main_office", label: "Main Office", type: "address" },
    { name: "country", label: "Country", type: "text" },
    { name: "city", label: "City", type: "text" },
    { name: "main_address", label: "Main Address", type: "address" },
    { name: "postal_code", label: "Postal Code", type: "text" },
  ];

  return (
    <Form onSubmit={handleSubmit} className="bg-white py-4 px-12">
      {rows.map((row) => (
        <div key={row.name} className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
          <span className="text-xl text-custom-green">
            {iconMap[row.name]}
          </span>
          <label className="popup_review_label">{row.label}</label>
          <div className="grow">
            <input
              className="input w-full"
              type={row.type}
              name={row.name}
              value={formValues[row.name]}
              onChange={handleInputChange}
            />
          </div>
        </div>
      ))}
      <Button
        variant="primary"
        type="submit"
        className="mx-auto my-3 block cursor-pointer border-0 bg-custom-blue px-[100px] py-[10px] text-sm font-semibold"
      >
        Submit
      </Button>
    </Form>
  );
};

export default PopupFormCom;
