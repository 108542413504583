// import { useEffect } from 'react';
export default function PageNotFound() {

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //   }, []);
    return (
        <div>
            <h1>Page not found 😢</h1>
        </div>
    );
}