import React, { useReducer, useEffect } from "react";
import styles from "./Numbers.module.css";

import { useTranslation } from "react-i18next";

const numbersReducer = (state, action) => {
  switch (action.type) {
    case "INCREMENT":
      return {
        customerSatisfaction: Math.min(state.customerSatisfaction + 1, 98),
        monthlyActiveUsers: Math.min(state.monthlyActiveUsers + 10, 205),
        newUsersPerWeek: Math.min(state.newUsersPerWeek + 1, 100),
        yearOverYearGrowth: Math.min(state.yearOverYearGrowth + 1, 55),
      };
    default:
      return state;
  }
};

export default function Numbers() {
  const initialState = {
    customerSatisfaction: 0,
    monthlyActiveUsers: 0,
    newUsersPerWeek: 0,
    yearOverYearGrowth: 0,
  };

  const [state, dispatch] = useReducer(numbersReducer, initialState);

  useEffect(() => {
    const incrementNumbers = () => {
      dispatch({ type: "INCREMENT" });
    };

    const interval = setInterval(() => {
      incrementNumbers();
    }, 40);

    const clearIntervalId = setTimeout(() => {
      clearInterval(interval);
    }, 4000);

    return () => {
      clearInterval(interval);
      clearTimeout(clearIntervalId);
    };
  }, []);

  const { t } = useTranslation();

  return (
    <div className={styles.numbers}>
      {/* <div>
        <h3>{state.customerSatisfaction}%</h3>
        <p>{t('landing.sec10-t1')}</p>
      </div>
      <div>
        <h3>{state.monthlyActiveUsers}M+</h3>
        <p>{t('landing.sec10-t2')}</p>
      </div>
      <div>
        <h3>{state.newUsersPerWeek}+</h3>
        <p>{t('landing.sec10-t3')}</p>
      </div>
      <div>
        <h3>{state.yearOverYearGrowth}%</h3>
        <p>{t('landing.sec10-t4')}</p>
      </div> */}
      <div>
        <p>{t('landing.numbers')}</p>
      </div>
    </div>
  );
}
