import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Icon imports
import { IoIosArrowDown } from 'react-icons/io';
import { ImCross } from 'react-icons/im';

// PDF Model
import Modal from 'react-modal';

// Local Imports
// import ProductItem from './ProductItem';
import PackageProductItem from './PackageProductItem';


const PackageItem = ({ pack }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // const {
  //   name,
  //   creationDate,
  //   coloredPDF,
  //   price,
  //   productCount,
  //   creationFee,
  //   desc_pdf,
  //   products,
  // } = pack;

  // PDF reading section
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  useEffect(() => {
    Modal.setAppElement('#pdf');
  }, []);

  const productsData = pack?.products;
  //console.log(pack);
  //console.log("Package Products: " + productsData);

  return (
    <>
      <div className="relative mt-4 flex w-full flex-row rounded border bg-white p-[10px] shadow">
        <div className="flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2  text-xs font-bold">Package Name</p>
            <span className="text-sm text-gray-500">{pack.package_name}</span>
          </div>
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Creation Date</p>
            <span className="text-sm text-gray-500">
            {new Date(pack.creation_date).toISOString().split('T')[0]}
            </span>
          </div>
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Product Count</p>
            <span className="text-sm text-gray-500">{pack.num_of_products? 
             pack.num_of_products : "NA"}</span>
          </div>
        </div>
        
        {/** Section Two */}
        <div className="flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm-gap-0">
          {/** The Text Section */}
          <div className="flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Package Status</p>
            <span className="text-sm text-gray-500"> {pack.package_status? pack.package_status.charAt(0).toUpperCase() + pack.package_status.slice(1): null}</span>
          </div>

          <div className="flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Unit Price</p>
            <span className="text-sm text-gray-500"> {pack.total_pack_price? "€ " + pack.total_pack_price: "NA"}</span>
          </div>

          {/** The Buttons Section */}
          <div className="mr-8 flex w-full flex-row space-x-2 p-1 text-sm font-bold">
            {/**Package Description PDF Showcase Model*/}
            <div
              id="pdf"
              className="w-75 rounded border-2 border-custom-green text-custom-green hover:bg-custom-green hover:text-white"
            >
              <button onClick={openModal} className="w-100 h-100">
                PDF
              </button>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="PDF Modal"
                style={{
                  overlay: {
                    backgroundColor: 'transparent',
                  },
                  content: {
                    top: '150px',
                    left: '10%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '10%',
                    width: '80%',
                    backgroundColor: '',
                    border: 'none',
                  },
                }}
              >
                <button
                  onClick={closeModal}
                  className="text-lg font-bold  text-custom-blue"
                >
                  <ImCross />
                </button>
                <iframe src={pack.doc_type ? `../../img/database_media/pdfs/packages/pack_${pack.id}.pdf` : '../../img/database_media/pdfs/packages/default.pdf'} width="100%" height="500px" />
              </Modal>
            </div>

            {/**Reorder heating back to Order system (ADD TO BASKET>>>)*/}
            <div id="reorder" class="w-100">
              <Link>
                <button className="w-100 rounded border-2 border-custom-blue bg-custom-blue p-2 text-white hover:bg-blue-700">
                  Re Order
                </button>
              </Link>
            </div>
          </div>
        
          </div>
        <button
          className="absolute inset-y-0 right-0 mr-4 focus:outline-none"
          onClick={toggleExpanded}
        >
          <IoIosArrowDown />
        </button>


      </div>

      {/**Display Products upon Expanding */}
      {isExpanded && (
        <>
        {/* <h3>Headings...</h3> */}
        <div className="w-100 container mx-auto overflow-auto pb-8">
          {productsData &&
            productsData.map((product) => {
              return product ? (
                <PackageProductItem key={product.id} packageProduct={product} />
              ) : null;
            })}
        </div>
        </>
      )}
    </>
  );
};

export default PackageItem;
