import React, { useState } from 'react';
import SubListingContext from './SubListingContext';

const SubListingContextProvider = ({ children }) => {
  const [subListings, setSubListings] = useState(null);

  return (
    <SubListingContext.Provider value={{ subListings, setSubListings }}>
      {children}
    </SubListingContext.Provider>
  );
}

export default SubListingContextProvider;