import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import "../Career/ApplyPopup.css";
import { useTranslation } from 'react-i18next';


const ApplyPopup = ({ isOpen, setIsOpen }) => {

  const { t } = useTranslation();
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="custom-modal">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Login Modal"
        appElement={document.getElementById("root")}
        style={{
          content: {
            width: "600px",
            height: "400px",
            margin: "auto",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Formik
          initialValues={{ name: "", cvResume: "", coverLetter: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {



              //console.log(JSON.stringify(values, null, 2));

              setSubmitting(false);
              closeModal();
            }, 400);}}>
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <div className="mb-3">
                  <button




                    className="btn btn-primary close"
                    onClick={closeModal}
                    style={{ backgroundColor: '#7acfd3', border: 'none' }}

                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>





                  <div className="input-group input-group-sm m-3">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      {t('career.name')}
                    </span>
                    <div className="col-10">
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                    </div>
                  </div>

                  <div className="input-group-sm m-3">
                    <label htmlFor="cvResume" className="form-label">
                    {t('career.cv')}
                    </label>
                    <Field
                      name="cvResume"
                      type="file"
                      className="form-control"
                      id="cvResume"
                      required
                    />
                  </div>

                  <div className="input-group-sm m-3">
                    <label htmlFor="coverLetter" className="form-label">
                    {t('career.cletter')}
                    </label>
                    <Field
                      name="coverLetter"
                      type="file"
                      className="form-control"
                      id="coverLetter"
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="submit"
                    style={{ width: '50%', fontWeight: '600', fontSize: '14px' }}
                  >
                     {t('career.submitbtn')}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        
      </Modal>
    </div>
  );
};

export default ApplyPopup;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  cvResume: Yup.mixed().required("CV/Resume is required"),
  coverLetter: Yup.mixed(),
});
