import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import JoinUs from '../../components/Info/JoinUs';
import RightSection from '../../components/Info/RSec';
import LeftSection from '../../components/Info/LSec';
import VBanner from '../../components/Info/VideoBanner';

import styles from './DSM.module.css';

import { useTranslation } from 'react-i18next';




export default function DSMInfo() {
  
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav />
      <VBanner videoUrl='./img/videos/dsm.mp4' title={t('dsm.title')}/>
      
      <div className={styles.dsmBackground}>
        <LeftSection 
          head={t('dsm.head1')}
          text={t('dsm.p1')}
          imgSrc={'/img/info/12.png'} />

        <RightSection
          head={t('dsm.head2')} 
          text={t('dsm.p2')}
          imgSrc={'/img/info/13.png'} />

        <LeftSection 
          head={t('dsm.head3')}
          text={t('dsm.p3')}
          imgSrc={'/img/info/14.png'} />

        <RightSection
          head={t('dsm.head4')} 
          text={t('dsm.p4')}



          imgSrc={'/img/info/37.png'} />

      
      </div>
        
      <JoinUs />
      <Footer />
    </>
  )
}