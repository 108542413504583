import React, { useState, useEffect } from 'react';

// Invoice Buttons icons
import { FaEye } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';

// PDF Model
import Modal from 'react-modal';


const InvoiceItem = ({ bill }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // const { number, creationDate, price, Discount, CompanyDiscount, desc_pdf } =
  //   bill;

  // PDF reading section
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  useEffect(() => {
    Modal.setAppElement('#pdf');
  }, []);

  const desc_pdf =  '../../img/database_media/pdfs/product_info.pdf';

  // console.log("PDF: " + desc_pdf);
  // console.log(bill.id ? `../../img/database_media/pdfs/packages/pack_${bill.id}.pdf` : '../../img/database_media/pdfs/packages/default.pdf');

  return (
    <>
      <div className="relative mt-4 flex w-full flex-row rounded border bg-white p-3 shadow">
        <div className="w-100 flex flex-col justify-between sm:flex-row ">
          <div className="flex w-full flex-row">
            <div className="mr-2 flex w-1/3 flex-col">
              <p className="mb-2 text-xs font-bold">Invoice Number</p>
              <span className="text-sm text-gray-500">{bill.invoice_number}</span>
            </div>
            <div className="mr-2 flex w-1/3 flex-col">
              <p className="mb-2 text-xs font-bold">Issue Date</p>
              <span className="text-sm text-gray-500">{bill.invoice_date}</span>
            </div>
            <div className="mr-2 flex w-1/3 flex-col">
              <p className="mb-2 text-xs font-bold">Total</p>
              <span className="text-sm text-gray-500">{bill.order.order_total? bill.order.order_total : 4.25}</span>
            </div>
          </div>

          <div className="mr-12 flex w-full flex-row  space-x-4 p-1 text-sm font-bold ">
            {/**View PDF Model (View Invoice)*/}
            <div
              id="pdf"
              className="w-75 rounded border-2 border-custom-green text-custom-green hover:bg-custom-green hover:text-white"
            >
              <button onClick={openModal} className="w-100 h-100">
                <FaEye className="mr-3 inline-block h-5 w-5 align-middle" />
                view invoice
              </button>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="PDF Modal"
                style={{
                  overlay: {
                    backgroundColor: 'transparent',
                  },
                  content: {
                    top: '150px',
                    left: '10%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '10%',
                    width: '80%',
                    backgroundColor: '',
                    border: 'none',
                  },
                }}
              >
                <button
                  onClick={closeModal}
                  className="text-lg font-bold  text-custom-blue"
                >
                  <ImCross />
                </button>
                <iframe src={'../../img/database_media/pdfs/product_info.pdf'} width="100%" height="500px" />
              </Modal>
            </div>

            {/**Download Invoice Handling */}
            <div
              id="pdf"
              className="w-75 rounded border-2 border-custom-blue bg-custom-blue p-2 text-white hover:bg-blue-700"
            >
              <a href={desc_pdf} download className="w-100 h-100">
                <FaDownload className="ml-6 mr-3 inline-block h-4 w-4 align-middle" />
                download invoice
              </a>
            </div>
          </div>
        </div>

        {/* <button
            className="absolute inset-y-0 right-0 mr-4 focus:outline-none"
            onClick={toggleExpanded}>
           <IoIosArrowDown />
          </button> */}
      </div>
      {isExpanded && (
        <div className="mt-2">
          {/* Place your additional details to show on expansion here */}
          Content Here
        </div>
      )}
    </>
  );
};

export default InvoiceItem;
