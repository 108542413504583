import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Radio } from '@mui/material';
import { AiOutlineSend } from 'react-icons/ai';
import { SlLocationPin } from 'react-icons/sl';
import { useFormik } from 'formik';
import { setAddressValue } from '../../features/basket/addressSlice';
import { useDispatch } from 'react-redux';




const FormControlLabel = ({ value, label, checked, onChange, Data=[] }) => (
  <label className="flex items-center space-x-3">
    <Radio
      value={value}
      checked={checked}
      onChange={onChange}
      sx={{
        '& .MuiSvgIcon-root': {
          fontSize: 20,
        },
        color: ' #a6a7a8',
        '&.Mui-checked': {
          color: '#2849ad',
        },
      }}
    />
    <div className="text-sm">
      <p className="text-sm font-semibold">{label}</p>
      {label === 'Standart Address' && (
        <>
          <p className="text-xs">
            {Data.daddress}, {Data.dpostal}, {Data.dcity}.
          </p>
          <p className="text-xs">
            {Data.contact_person_name}, {Data.contact_person_phone}, {Data.contact_person_email}.
          </p>
        </>
      )}
    </div>
  </label>
);




export default function Address ({ addressData }) {
  // extract user delivery profiles
  const userData = JSON.parse(localStorage.getItem('user'));
  const delProfiles = userData? userData.delivery_data : [];

  const [formVisible, setFormVisible] = useState(false);
  const [detechChange, setDetechChange] = useState(false);
  const dispatch = useDispatch();

  const [addressType, setAddressType] = useState('standart_address');
  const [addressValues, setAddressValues] = useState(
    {addressType: 'standart_address', addressValues: delProfiles[0]});
  const [addressSubject, setAddressSubject] = useState(null);
  const [addressDetail, setAddressDetail] = useState(null);

  const handleSubmit = async () => {
    // Validate form values if necessary
    if (addressType === 'one_time_address' && (!addressSubject || !addressDetail)) {
      alert('Please fill in all fields.');
      return;
    }
    
    const dispatchData = {
            addressType: addressType,
            addressValues: {
              addressSubject: addressSubject,
              addressDetail: addressDetail,
            },
      };
    setAddressValues(dispatchData);
    if (addressData) {
      addressData(dispatchData);
    }
    // Handle form visibility
    setFormVisible(false);

    // Log form values for debugging
    //console.log('After Posting the new Address');
  };

  const handleAddressTypeChange = (event, Data='') => {
    const selectedType = event.target.value;
    setAddressType(selectedType);
    setDetechChange(true);
    if (selectedType === 'one_time_address') {
      setFormVisible(true);
    } else {
      setFormVisible(false);
      // Prepare and dispatch profile data if it's not new one
      const dispatchData = {
        addressType: selectedType,
        addressValues: Data
      }
      setAddressValues(dispatchData);
      if (addressData) {
        addressData(dispatchData);
      }
      //dispatch(setAddressValue(dispatchData));
      //console.log("Data", dispatchData);
    }
  };


  useEffect(() => {
    if (!detechChange) {
      // Prepare and dispatch profile data if it's not new one
      const dispatchData = {
        addressType: addressType,
        addressValues: delProfiles? delProfiles[0] : []
      }
      setAddressValues(dispatchData);
      if (addressData) {
        addressData(dispatchData);
      }
      //dispatch(setAddressValue(dispatchData));
      //console.log("Effect Data", dispatchData);
    }
  }, []);

  const divStyle = (type) => ({
    border: addressType === type ? '2px solid #2849ad' : 'none',
  });

  

  return (
    <div className="rounded-lg bg-[#f6f6f6]">
      <div className="flex items-center space-x-1 rounded-lg bg-[#ededed] p-3 text-sm font-semibold">
        <span className="text-base text-[#a6a7a8]">
          <SlLocationPin />
        </span>
        <p>Address</p>
      </div>
      <div className="h-44 rounded-lg bg-[#f6f6f6]">
        <div
          className="m-3 flex space-x-3 rounded-lg bg-white p-3"
          style={divStyle('standart_address')}>
          {delProfiles.map((profile) => (
            <FormControlLabel
              value="standart_address"
              label="Standart Address"
              checked={addressType === 'standart_address'}
              onChange={(event) => handleAddressTypeChange(event, profile)}
              Data={profile}
            />
        ))}
        </div>
        <div
          className="m-3 flex space-x-3 rounded-lg bg-white p-3"
          style={divStyle('one_time_address')}>
          <FormControlLabel
            value="one_time_address"
            label="Use a one-time different address"
            checked={addressType === 'one_time_address'}
            onChange={handleAddressTypeChange}
          />
        </div>
      </div>
      {formVisible && addressType === 'one_time_address' && (
        <div className="h-56 rounded-lg bg-[#f6f6f6]">
            <div className="m-3 space-x-3 space-y-1 rounded-lg bg-white p-3 text-sm">
              <input
                name="addressSubject"
                placeholder="Address Subject"
                className="mb-2 w-full rounded-lg border p-2"
                value={addressSubject? addressSubject : ''}
                onChange={(e) => setAddressSubject(e.target.value)}
              />
              <textarea
                name="addressDetail"
                placeholder="Address Detail"
                className="m-0 h-[100px] w-full rounded-lg border p-2"
                value={addressDetail? addressDetail : ''}
                onChange={(e) => setAddressDetail(e.target.value)}
              />
              <div className="flex items-center justify-end space-x-1 text-sm text-custom-blue">
                <AiOutlineSend />
                <button  onClick={handleSubmit} className="underline underline-offset-2">
                  Request New Profile
                </button>
              </div>
            </div>
        </div>
      )}
    </div>
  );
};
