import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import Select from 'react-select';
import axios from 'axios';


// Local Imports
import '../creationPopupStyle.css';


// Icon Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';



// Select Styling
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #F87171' : '2px solid #F87171',
    borderRadius: '4px',
    ':hover': {
      border: '2px solid #F87171',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#F87171',
    },
  }),
};

function PopupPayment({ onClose, currentUser }) {

  // Data from Local Storage
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user.user_data.role === 'HD' ? 'Head User' : user.role === 'M' ? 'Manager User' : 'Normal User';
  

  // Assign Payment Profile API
  const handleAssignClick = async () => {
    try {
      const postData = {
        payment_profile: selectedPay.id
      }
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/user/${currentUser.id}/update/`, 
        postData
      );
      //console.log(response.data);
      console.log('Payment Profile Assigned Successfully!');
      onClose();
    } catch (error) {
      console.error("There was an error assigning new Payment Profile!", error);
    }
  };

  // List All Payment Profiles by Name
  const options = user.payment_data.map((data, index) => ({
    label: data.pay_name ? data.pay_name : data.PO_number,
    value: data
  }));

  const [selectedPay, setSelectedPay] = useState(options[0].value);
  const [selectedPayOption, setSelectedPayOption] = useState(options[0]);

  const handlePaymentProfileSelect = (selectedPayOption) => {
    setSelectedPayOption(selectedPayOption);
    setSelectedPay(selectedPayOption.value);
  };

  return (
    <div className="creation_popup">
    <Modal show={true} onHide={onClose}>
      <Modal.Header className="flex-row-reverse">
      <button
          className="btn text-white bg-red-400 border-none hover:bg-red-500"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <h2 className="text-2xl m-auto font-sans font-semibold ">
          Assign new Payment Profile
        </h2>
      </Modal.Header>
      <Modal.Body className="flex items-start h-56">
        <Select
          options={options}
          styles={customStyles}
          className="mt-2 m-auto w-2/4"
          placeholder="Choose ..."
          onChange={handlePaymentProfileSelect}
          value={selectedPayOption}
        />
        <button
          className="px-4 py-3 font-bold text-red-500 text-base border-2 rounded-lg border-red-400 shadow-sm hover:text-white hover:bg-red-400 flex items-center mr-6" // Adjust width as needed
          onClick={handleAssignClick}
        >
          Assign
        </button>
      </Modal.Body>
    </Modal>
  </div>
  );
}

export default PopupPayment;
