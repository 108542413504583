import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "react-bootstrap/Card";
import CardBtn from "../Shop/CardBtn";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "../Home/ProductCarousel.module.css";
import { useTranslation } from "react-i18next";
import Spinner from "../Home/Spinner";
import { useNavigate } from 'react-router-dom';
import ProductContext from '../../context/ProductContext';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

// dummy data to test card ,,
const cards = [
  {
    imgSrc: "../../img/test.jpg",
    title: "Product 1",
    text: "Description of Product 1",
    price: "$10.99",
  },
  {
    imgSrc: "../../img/test.jpg",
    title: "Product 2",
    text: "Description of Product 2",
    price: "$15.99",
  },
  {
    imgSrc: "../../img/test.jpg",
    title: "Product 3",
    text: "Description of Product 3",
    price: "$20.99",
  },
  {
    imgSrc: "../../img/test.jpg",
    title: "Product 4",
    text: "Description of Product 4",
    price: "$25.99",
  },
  {
    imgSrc: "../../img/test.jpg",
    title: "Product 5",
    text: "Description of Product 5",
    price: "$30.99",
  },
];
// dummy data to test card ,,

export default function RelatedProductSlider({ info }) {
  // const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(!isOpen);
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

   // Filtering Product List based on Choosen category and subCategory
   const productsData = localStorage.getItem('products');
   let products = [];
   if (productsData) {
     products = JSON.parse(productsData);
   }
   const [alternatives, setAlternatives] = useState([]);

   const handleFilter = () => {
     const category = info.category;
     const subCategory = info.subCategory;

     console.log("Choosen Category: " + info);
     // console.log(storedProducts.length);
     const choosen = products.filter(product =>
       (product.category === category)
     );

     setAlternatives(choosen);
   };

   useEffect(() => {
     handleFilter();
   }, [info]);


  // Retrieve the JSON string from localStorage and parse it into an object
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

   // Define card Visual Content
   const cards = alternatives.map((product, index) => ({
     title: product.category,
     text: product.name,
     imgSrc: imgPaths[product.assortimed_pid][0],
     product: product
   }));

   const navigate = useNavigate();
   const { setData } = useContext(ProductContext);
     const handleDetailsClick = (product) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
       setData(product);
       navigate('/reviewproduct');
     };
  return (
    <div style={{ paddingBottom: "30px" }}>
      <Slider {...settings}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <p>Error: {error.message}</p>
        ) : (
          cards.map((card, index) => (
            <div key={index}>
              <Card key={index} className={styles.card_body}>
                <div className={styles.img_container}>
                  <Card.Img
                    variant="top"
                    src={card.imgSrc}
                    className={styles.card_img}
                  />
                </div>
                <Card.Body>
                  <div className={styles.content_container}>
                    <Card.Title className={styles.card_title}>
                      {card.title}
                    </Card.Title>
                    <Card.Text className={styles.card_content}>
                      {card.text}
                    </Card.Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className={styles.pricing}>{card.price}</span>

                    <CardBtn
                      style={{ padding: '10px 20px' }}
                      onClick={() => handleDetailsClick(card.product)}
                    >
                      {t('vshop.details-cardbtn')}
                    </CardBtn>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
}
