import React from "react";


// In ComBase component
export default function DSMSum({ dsmRecord }) {
  dsmRecord = dsmRecord || null;


    function capitalizeFirstLetter(word) {
      if (!word) return '';
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  
    return (
        dsmRecord ?
            <div className="w-full mt-3 border-1 border-custom-green px-3 py-1 rounded-lg h-fit shadow-custom font-sans">
              <div className="my-3">
                <div className="my-1 mb-2 text-xl text-custom-blue font-extrabold flex justify-center">
                  <span>{dsmRecord.dsm_name}</span>
                </div>
                <div className="my-1 mb-2 text-lg text-custom-blue font-semibold flex justify-center">
                  <span>{dsmRecord.department} Department</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Number of products</span>
                  <span className="text-custom-blue">DSM Status</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{dsmRecord.num_of_products}</span>
                  <span>{capitalizeFirstLetter(dsmRecord.dsm_status)}</span>
                </div>
                

                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Creation fee</span>
                  <span className="text-custom-blue">Monthly fee</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>€ {dsmRecord.creation_fee}</span>
                  <span>€ {dsmRecord.monthly_fee_dsm}</span>
                </div>

                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                <span className="text-custom-blue">Creation Status</span>
                  <span className="text-custom-blue">Creation Date</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{capitalizeFirstLetter(dsmRecord.creation_fee_status)}</span>
                  <span>{dsmRecord.creation_date.substring(0, 10)}</span>
                </div>


                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Refill status</span>
                  <span className="text-custom-blue">Refill period</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{dsmRecord.refill_status? capitalizeFirstLetter(dsmRecord.refill_status): 'NA'}</span>
                  <span>{dsmRecord.refill_period} days</span>
                </div>

                <div className="grid grid-cols-2 gap-5 my-1 font-semibold">
                  <span className="text-custom-blue">Refill Proposal Date</span>
                </div>
                <div className="grid grid-cols-2 gap-5 my-1 mb-2 font-semibold">
                  <span>{dsmRecord.refill_proposal_date.substring(0, 10)}</span>
                </div>

                

              </div>
          </div> : null 
    );
  }