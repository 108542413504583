import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


import './CareerInfographic1.css';

export default function CInfographic1() {

    const { t } = useTranslation();

    const data = [
        { name: t('career.p6'), location: '', image: './img/career/8.png'},
        { name: '', location: t('career.p7'), image: './img/career/9.png' },
        { name: t('career.p8'), location: '', image: './img/career/5.png' },
        { name: '', location: t('career.p9'), image: './img/career/11.png' },
        { name: t('career.p10'), location: '', image: './img/career/12.png' },
      ];
      
  return (
    <Table>
      <tbody style={{display: 'flex', 
                    alignItems: 'center', 
                    alignContent: 'center',
                    flexDirection: 'column'}}>
        {data.map((item, index) => (
          <tr key={index}>
            <td  style={{backgroundColor: 'transparent'}}>
              <div 
                    id="career-info"
                    className="p-4 career_info_card_body">
                {item.name ? <p  
                                className='career_info_card_title'>
                                            {item.name}</p> :
                        <img variant="top" src={item.image} />}
              </div>
            </td>

            <td style={{backgroundColor: 'transparent'}}/>
            
            <td style={{backgroundColor: 'transparent'}}>
              <div 
                    id="career-info"
                    className="p-4 career_info_card_body">
                {item.location ? <p 
                                    className='career_info_card_title'>
                                {item.location}</p> : 
                    <img variant="top" src={item.image} />}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function LocateIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="2" x2="5" y1="12" y2="12" />
      <line x1="19" x2="22" y1="12" y2="12" />
      <line x1="12" x2="12" y1="2" y2="5" />
      <line x1="12" x2="12" y1="19" y2="22" />
      <circle cx="12" cy="12" r="7" />
    </svg>
  );
}

function UserIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  );
}