import { Link } from "react-router-dom";
import styles from "./Logo.module.css";

function Logo() {
  return <Link to='/'> 
            <img src="../img/Logo.png" alt="App logo" 
                  className={styles.logo} />
          </Link>;
}

export default Logo;
