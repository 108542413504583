import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import PageNav from '../../components/Home/PageNav';
import Footer from '../../components/Home/Footer';
import JoinUs from '../../components/Info/JoinUs';
import RightSection from '../../components/Info/RSec';
import LeftSection from '../../components/Info/LSec';
import VBanner from '../../components/Info/VideoBanner';

import styles from './DSM.module.css';

import { useTranslation } from 'react-i18next';




export default function PackageInfo() {
  
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageNav />
      <VBanner videoUrl='./img/videos/package.mp4' title={t('package.title')}/>


      <div className={styles.dsmBackground}>
        <LeftSection 
          head={t('package.head1')}
          text={t('package.p1')}
          imgSrc={'/img/info/16.png'} />

        <RightSection
          head={t('package.head2')} 
          text={t('package.p2')}
          imgSrc={'/img/info/17.png'} />

        <LeftSection 
          head={t('package.head3')}
          text={t('package.p3')}
          imgSrc={'/img/info/18.png'} />

        <RightSection
          head={t('package.head4')} 
          text={t('package.p4')}
          imgSrc={'/img/info/19.png'} />

        <LeftSection 
          head={t('package.head5')}
          text={t('package.p5')}
          imgSrc={'/img/info/20.png'} />
      
      </div>
        
      <JoinUs />
      <Footer />
    </>
  )
}