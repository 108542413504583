import React, { useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';


// Style Attributes
import { FaDownload, FaFileInvoiceDollar } from "react-icons/fa6";
import { FaEuroSign } from "react-icons/fa";
import { TbPercentage, TbTruckDelivery } from "react-icons/tb";
import { FaThList } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { FaCirclePlus } from "react-icons/fa6";
import { LuEuro } from "react-icons/lu";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { GoMultiSelect } from "react-icons/go";
import { VscPreview } from "react-icons/vsc";
import { FcApproval } from "react-icons/fc";



export default function InvoiceSum({ orderRecord }) {

  // Items Section
  const pbasket = orderRecord.products? orderRecord.products : [];
  const packbasket = orderRecord.packages? orderRecord.packages : [];
  const dsmbasket = orderRecord.dsms? orderRecord.dsms : [];
  let count = 0;
  //const [pbasket, setpbasket] = useState([]);
  const tableHeads = ['', 'Product name', 'Price per unit', 'Number ofunits', 'Total price (excl.)',
                      'Tax percentage', 'Tax amount', 'Total price (incl.)', ''];


    // Invoice Review - Checkout Model
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    const [startCheckout, setCheckout] = useState(false);
    const initiateCheckoutModel = () => {
      setCheckout(true);
    };
    const DownloadInvoice = () => {};
    
    const [issueInv, setIssueInv] = useState(false);
    const IssueInvoice = () => {

    };
  

  return (

    <div className="w-full h-full flex flex-col">

      {/** Checkout Summary Section */}
      <div className="w-full h-full flex justify-center mt-2">
          <div className='w-full shadow rounded-lg p-3 '>
            {/* <div className="flex items-start justify-end mb-4 gap-2"> 
                <button onClick={DownloadInvoice} 
                        className=" w-full rounded-md text-custom-blue border-2
                                    px-6 py-2 font-semibold hover:bg-custom-blue hover:text-white 
                                    sm:w-auto flex flex-row gap-2 justify-center">
                        <FaDownload className="text-xl"/>
                        <span>Download</span>
                  </button>
                  <button onClick={IssueInvoice} 
                        className=" w-full rounded-md hover:text-custom-blue text-slate-50 border-2 px-6 py-2 font-semibold bg-custom-blue  hover:bg-white sm:w-auto flex flex-row gap-2 justify-center">
                        <VscPreview className="text-xl"/>
                        <span>Issue Invoice</span>
                  </button>
              </div> */}
              <div className='border-1 border-custom-blue rounded-lg p-4'>
                  <div className='text-custom-blue flex w-full gap-3 border-b-4 border-b-custom-blue'>
                      <div className='w-2/5 border-t-4 border-t-custom-blue flex items-center'>
                        <img className='w-2/3 py-1' src='../../img/Logo.png' alt='logo' /></div>
                      <div className='w-2/5 text-xs border-t-4 border-t-custom-blue'>
                        <p className=' font-bold mb-2'>Name and Address</p>
                        <p className='font-bold'>{orderRecord.company.cname}</p>
                        <p>{orderRecord.payment_id.invoicing_address}</p>
                        <p>{orderRecord.payment_id.budget_holder_phone}</p>
                        <p>{orderRecord.payment_id.PO_number}</p>
                        <p>
                          <span className='font-semibold'>Payment via: </span>
                          {orderRecord.payment_id.bank} - {orderRecord.payment_id.IBAN}</p>
                      </div>
              <div className='w-1/5 text-xs border-t-4 border-t-custom-blue'>
                  <div className='bg-[#e9edf2] flex flex-col pb-1'>
                      <p className=' font-bold '>Invoice NP </p>
                      <p className='flex justify-around p-1'>12456</p>
                    </div>
                  <div className='flex flex-col pb-1'>
                      <p className=' font-bold '>PO Number </p>
                      <p className='flex justify-around p-1'>{orderRecord.payment_id.PO_number}</p>
                  </div>
                  <div className='flex flex-col pb-1 '>
                      <p className=' font-bold border-t-4 border-t-custom-blue'>Date </p>
                      <p className='flex justify-around p-1'>{formattedDate}</p>
                  </div>
                </div>
              </div>
              
              {/** Order Listings Table */}
                <table className="mt-4 mx-auto text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                    <thead>
                      <tr>
                        {tableHeads.slice(0,-1).map((head, index) => (
                          <th key={index} className="border-1 border-white bg-custom-blue text-white text-center p-2">
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {pbasket && pbasket.map((item, index) => (
                        <tr key={item.id}>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{index + 1}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.name}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.sales_price_c1).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.qty? item.qty : 2}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.sales_price_c1 * (item.qty? item.qty : 2)).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{((item.tax? item.tax : 0.14) * 100).toFixed(2)} %</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.sales_price_c1 * (item.qty? item.qty : 2) * (item.tax? item.tax : 0.14)).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.sales_price_c1 * (item.qty? item.qty : 2) * ((item.tax? item.tax : 0.14) + 1)).toFixed(2)}</td>
                        </tr>
                      ))}
                      {pbasket && (() => {
                        count = pbasket.length + 1;
                        //console.log(`Total items processed: ${count}`);
                        return null;
                      })()}

                      {packbasket && packbasket.map((item, index) => (
                        <tr key={item.id}>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{count + index}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          Creation fee of {item.package_name}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">€ 250.00</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{item.qty? item.qty : 1}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">€ 250.00</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{((item.tax? item.tax : 0.14) * 100).toFixed(2)} %</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(250.00 * (item.tax? item.tax : 0.14)).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(250.00 + (250.00 * (item.tax? item.tax : 0.14))).toFixed(2)}</td>
                        </tr>
                      ))}
                      {packbasket && (() => {
                        count = count + packbasket.length + 1;
                        //console.log(`Total items processed: ${count}`);
                        return null;
                      })()}

                      {dsmbasket && dsmbasket.map((item, index) => (
                        <tr key={item.id}>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{index + 1}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.name}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.sales_price_c1).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                            {item.qty? item.qty : 2}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.sales_price_c1 * (item.qty? item.qty : 2)).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{((item.tax? item.tax : 0.14) * 100).toFixed(2)} %</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.sales_price_c1 * (item.qty? item.qty : 2) * (item.tax? item.tax : 0.14)).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                          € {(item.sales_price_c1 * (item.qty? item.qty : 2) * ((item.tax? item.tax : 0.14) + 1)).toFixed(2)}</td>
                        </tr>
                      ))}
              
                      {/** Total Line */}
                        <tr>
                          <td className="border-1 border-white bg-custom-blue text-white p-2 font-semibold text-center"
                              colSpan="4">Total</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                          € {(pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty? item.qty : 2)), 0) + (packbasket ? (250.00 ) : 0)).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">-</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                          € {(pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty ? item.qty : 2) * (item.tax ? item.tax : 0.14)), 0) + (packbasket ? (250.00 * 0.14) : 0)).toFixed(2)}</td>
                          <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center font-bold">
                          € {(pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty ? item.qty : 2) * ((item.tax ? item.tax : 0.14) + 1)), 0) + (packbasket? (250.00 + (250.00 * 0.14)):0)).toFixed(2)}</td>
                        </tr> 
                      </tbody>
                    </table>
                  

              {/**Summary Table WHite Background */}
              <table className=" mt-4 text-xs text-custom-blue border-collapse border-1 border-custom-blue">
                  <thead>
                    <tr>
                      <th className="border-1 border-custom-blue p-2">Order Number</th>
                      <th className="border-1 border-custom-blue p-2">Price</th>
                      <th className="border-1 border-custom-blue p-2">Contrat Discount</th>
                      <th className="border-1 border-custom-blue p-2">Price After Discount</th>
                      <th className="border-1 border-custom-blue p-2">Tax</th>
                      <th className="border-1 border-custom-blue p-2">Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center ">{orderRecord.order_number? orderRecord.order_number: "00000001"}</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                      € {(pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty? item.qty : 2)), 0) + (packbasket ? (250.00 ) : 0)).toFixed(2)}</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">{orderRecord.discount_id? orderRecord.discount_id: 0} %</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                      € {(() => {
                      const totalPrice = (pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty? item.qty : 2)), 0) + (packbasket ? (250.00 ) : 0)).toFixed(2);
                      const discountPercentage = orderRecord.discount_id ? orderRecord.discount_id : 0;
                      const discountAmount = totalPrice * (discountPercentage / 100);
                      return (totalPrice - discountAmount).toFixed(2);
                    })()}
                </td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center"> 
                      € {(pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty ? item.qty : 2) * (item.tax ? item.tax : 0.14)), 0) + (packbasket ? (250.00 * 0.14) : 0)).toFixed(2)}</td>
                      <td className="border-1 border-custom-blue p-2 bg-[#e9edf2] text-center">
                      € {(pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty ? item.qty : 2) * ((item.tax ? item.tax : 0.14) + 1)), 0) + (packbasket? (250.00 + (250.00 * 0.14)):0)).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
              </table>
                                
              <p className='font-bold text-custom-blue py-5 flex justify-end items-center gap-3'>
                  TOTAL AMOUNT TO PAY 
                  <span className='border-2 border-custom-blue py-1 px-4'>
                  € {(pbasket.reduce((sum, item) => sum + (item.sales_price_c1 * (item.qty ? item.qty : 2) * ((item.tax ? item.tax : 0.14) + 1)), 0) + (packbasket? (250.00 + (250.00 * 0.14)):0)).toFixed(2)}
                  </span></p>
            </div>
          </div>
      </div> 

      {issueInv ? 
      <div className="flex flex-col min-h-[200px] items-center justify-center">
          <FcApproval size={60} className="text-gray-400 mb-3" />
          <p className="text-sm font-semibold text-gray-500">Your invoice has been issued successfully!</p>
          <p className="text-sm font-semibold text-gray-500">Preforma Invoice has been sent to the client via email for approval</p>
        </div>
      : null}
    
    </div>
  );
}