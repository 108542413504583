import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from 'axios';

import PageNav from "../../components/Home/PageNav";
import Footer from "../../components/Home/Footer";
import CareerSlider2 from "../../components/Career/CareerSlider2";
import JobGrid from "../../components/Career/JobGrid";
import Button from "../../components/Shop/CardBtn";
import FindJob from "../../components/Career/FindJob";

import styles from "../Career/CareerListing.module.css";

export default function CareerListings() {
  const [seniority_data, setSeniorityData] = useState([]);
  const [category_data, setCategoryData] = useState([]);
  const [selectedSeniority, setSelectedSeniority] = useState('All');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [jobData, setJobData] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const { t } = useTranslation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/careers/unique-seniority-levels/`)
      .then(response => {
        setSeniorityData(response.data);
      })
      .catch(error => {
        //console.error('There was an error!', error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/careers/unique-job-category/`)
      .then(response => {
        setCategoryData(response.data);
      })
      .catch(error => {
        //console.error('There was an error!', error);
      });
  }, []);


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/careers/filter/`, {
      params: {
        seniority_level: 'all',
        category: 'all'
      }
    })
    .then(response => {
      //console.log('Initial Data ....');
      //console.log(response.data);
      setJobData(response.data);
    })
    .catch(error => {
      // console.error('There was an error!', error);
    });
  }, []);


  const handleButtonClick = () => {
    // console.log('Filter Button clicked');
    let seniority_level = selectedSeniority === 'All' ? 'all' : selectedSeniority;
    let category = selectedCategory === 'All' ? 'all' : selectedCategory;

    axios.get('http://localhost:8000/api/careers/filter/', {
      params: {
        seniority_level: seniority_level,
        category: category
      }
    })
    .then(response => {
      if (response.data.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
        setJobData(response.data);
      }
    })
    .catch(error => {
      // console.error('There was an error!', error);
    });
  };
  
  
  return (
    <>
      <PageNav />

      <div className={styles.career}>
        <img src="../img/career/16.png" height="70%" width='100%' alt="main banner" className="img-fluid"/>
        <div style={{backgroundColor: '#f8f8f8', width: '100%', paddingTop: '20px'}}>
        <div className={`container ${styles.jobResults}`}>
          <h2> {t('career.job1')}</h2>
          <div>
            <b>{jobData.length}</b>
            <span>{t('career.job3')}</span>
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.filterContent}>
            {/* ---------start first filter------------- */}
            
            <div className="btn-group">
            <p style={{width:'120px',
                      fontSize: '14px', 
                      fontWeight: '600',
                      marginRight: '10%',
                      marginTop: '1%',
                      color: '#00477b'}}>
                  {t('career.job4')}</p>
              <button
                style={{
                  backgroundColor: "#fff",
                  color: "#00477b",
                  borderRadius: "8px",
                  border: "1px solid #d1d1d1",
                  padding: "5px 70px 5px 70px",
                }}
                className="btn btn-secondary btn-sm dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                  {selectedCategory || t('career.job4')}
              </button>
              <ul className="dropdown-menu" style={{width: '100%'}}>
                <li className={styles.styled_list_item} 
                    onClick={() => setSelectedCategory('All')}>All</li>
                  {category_data.map((item, index) => (
                   <li className={styles.styled_list_item} key={index}
                        onClick={() => setSelectedCategory(item)}>
                        {item}</li>
                ))}
              </ul>
            </div>
            {/* ---------end first filter------------- */}

            {/* ---------start second filter------------- */}
            <div className="btn-group">
            <p style={{width:'165px',
                      fontSize: '14px', 
                      fontWeight: '600',
                      marginRight: '1%',
                      marginTop: '1%',
                      color: '#00477b'}}>
                  {t('career.job5')}</p>
              <button
                style={{
                  backgroundColor: "#fff",
                  color: "#00477b",
                  borderRadius: "8px",
                  border: "1px solid #d1d1d1",
                  padding: "5px 70px 5px 70px",
                }}
                className="btn btn-secondary btn-sm dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {selectedSeniority || t('career.job5')}
              </button>
              <ul className="dropdown-menu" style={{width: '100%'}}>
                <li className={styles.styled_list_item}
                    onClick={() => setSelectedSeniority('All')}>All</li>
                {seniority_data.map((item, index) => (
                   <li className={styles.styled_list_item} 
                        key={index} onClick={() => setSelectedSeniority(item)}>
                      {item}</li>
                ))}
              </ul>
            </div>
            {/* ---------end second filter------------- */}
            <button  onClick={handleButtonClick}> {t('career.job6')}</button>
          </div>
        </div>
        {/* <div style={{
            display: 'flex', 
            flexDirection: 'row', 
            gap: '50px',
            marginleft: '30%',
            width: '100%'
          }}>
          <p>{selectedCategory}</p>
          <p>{selectedSeniority}</p>
        </div> */}
      </div>

        {/* ===============start sliders========== */}
        <div className={styles.productSlider}>
        {noResults ? 
          <p style={{textAlign: 'center', color: '#4bad94', fontWeight: 'bold'}}>
            No results found for your selected filters.
          </p> 
          : 
          <JobGrid data={jobData} />
        }
        </div>
        <div className={`container ${styles.careerSliders}`}>
          <div className={` ${styles.careerSlider2}`}>
            <h5 style={{fontSize: '26px'}}> {t('career.job7')}</h5>
            <CareerSlider2 />
          </div>
        </div>
        {/* ===============end sliders========== */}
      </div>
      <FindJob />
      <Footer />
    </>
  );
}
