import React, { useState } from "react";

// Style Attributes
import { RiShoppingCart2Line } from "react-icons/ri";
import { TfiPackage } from "react-icons/tfi";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { MdOutlineInventory } from "react-icons/md";

// Tab Content
import OrdersTable from "./dashboard/OrdersTab";
import PackagesTable from "./dashboard/PackagesTab";
import InvoicesTable from "./dashboard/InvoicesTab";
import DSMsTable from "./dashboard/DSMsTab";

export default function DashboardPage() {
  const [fillActive, setFillActive] = useState("Orders");

  const handleSelectChange = (event) => {
    setFillActive(event.target.value);
  };

  return (
    <>
    <div className="sm:hidden">
        <select id="tabs" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm   block w-full p-2.5" onChange={handleSelectChange}>
          <option value="Orders"><RiShoppingCart2Line/>My Orders</option>
          <option value="Packages"><TfiPackage/>My Packages</option>
          <option value="Invoices"><LiaFileInvoiceSolid/>My Invoices</option>
          <option value="DSMs"><MdOutlineInventory/>My Digital Stock</option>
        </select>
      </div>
     <ul className="hidden text-lg font-semibold text-center text-gray-500 rounded-lg shadow sm:flex w-full mt-0">
     <li className="w-full">
        <a href="#" onClick={() => setFillActive("Orders")}
          className={`inline-block w-full p-3 border-b-4 ${fillActive === "Orders" ? "bg-gray-100 border-custom-green font-bold" : "border-gray-200 hover:bg-gray-100 hover:border-custom-green"} focus:outline-none`}>
          <span className={` ${fillActive === "Orders" ? "text-custom-green" : "text-gray-500 hover:text-custom-green"}`}>
            <RiShoppingCart2Line className="inline-block h-6 w-6 mr-3 align-middle"/>
          </span>
          <span className={`inline-block ${fillActive === "Orders" ? "text-custom-blue" : "text-gray-500 hover:text-custom-gray-900"}`}>
            My Orders
          </span>
        </a>
      </li>


      <li className="w-full">
        <a href="#" onClick={() => setFillActive("Packages")}
          className={`inline-block w-full p-3 border-b-4 ${fillActive === "Packages" ? "bg-gray-100 border-custom-green font-bold" : "border-gray-200 hover:bg-gray-100 hover:border-custom-green"} focus:outline-none`}>
          <span className={` ${fillActive === "Packages" ? "text-custom-green" : "text-gray-500 hover:text-custom-green"}`}>
            <TfiPackage className="inline-block h-6 w-6 mr-3 align-middle" />
          </span>
          <span className={`inline-block ${fillActive === "Packages" ? "text-custom-blue" : "text-gray-500 hover:text-custom-gray-900"}`}>
            My Packages
          </span>
        </a>
      </li>
        <li className="w-full ">
          <a href="#" onClick={() => setFillActive("Invoices")}
            className={`inline-block w-full p-3 border-b-4 ${fillActive === "Invoices" ? "bg-gray-100 border-custom-green font-bold" : "border-gray-200 hover:bg-gray-100 hover:border-custom-green"} focus:outline-none`}>
            <span className={` ${fillActive === "Invoices" ? "text-custom-green font-bold" : "text-gray-500 group-hover:text-gray-900"}`}>
              <LiaFileInvoiceSolid className="inline-block h-6 w-6 mr-3 align-middle"/>
            </span>
            <span className={`inline-block ${fillActive === "Invoices" ? "text-custom-blue font-bold" : "text-gray-500 group-hover:text-gray-900"}`}>
              My Invoices
            </span>
          </a>
        </li>
        <li className="w-full ">
          <a href="#" onClick={() => setFillActive("DSMs")}
            className={`inline-block w-full p-3 border-b-4 ${fillActive === "DSMs" ? "bg-gray-100 border-custom-green font-bold" : "border-gray-200 hover:bg-gray-100 hover:border-custom-green"} focus:outline-none`}>
            <span className={` ${fillActive === "DSMs" ? "text-custom-green font-bold" : "text-gray-500 group-hover:text-gray-900"}`}>
              <MdOutlineInventory className="inline-block h-6 w-6 mr-3 align-middle"/>
            </span>
            <span className={`inline-block ${fillActive === "DSMs" ? "text-custom-blue font-bold" : "text-gray-500 group-hover:text-gray-900"}`}>
              My Digital Stock
            </span>
          </a>
        </li>
      </ul>
      <div style={{ display: fillActive === 'Orders' ? 'block' : 'none' }}>
        <OrdersTable />
      </div>
      <div style={{ display: fillActive === 'Packages' ? 'block' : 'none' }}>
        <PackagesTable />
      </div>
      <div style={{ display: fillActive === 'Invoices' ? 'block' : 'none' }}>
        <InvoicesTable />
      </div>
      <div style={{ display: fillActive === 'DSMs' ? 'block' : 'none' }}>
        <DSMsTable />
      </div>
    </>
  );
}